<!--
  Travel time plots
-->

<template>
  <v-card elevation="0">
    <div id="tp_plots_div">
      <Plotly
        :data="plots"
        :layout="layout"
        :config="config"
        id="plotly_tp"
        :responsive="true"
        :autosizable="true"
        :scrollZoom="false"
        :displaylogo="false"
        :displayModeBar="true"
        :toImageButtonOptions="config['toImageButtonOptions']"
      ></Plotly>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { Plotly } from "@wellcaffeinated/vue-plotly";

const config = {
  toImageButtonOptions: {
    format: "png",
    filename: "temps_parcours",
    height: 1000,
    width: 1500
  }
};

export default Vue.component("travel-time-plots", {
  components: {
    Plotly
  },
  props: ["plots", "title"],
  data: () => ({
    config
  }),
  computed: {
    layout() {
      return {
        height: "650",
        title: { text: this.title, font: { size: 25 } },
        legend: { orientation: "h" },
        modebar: {
          add: ["togglespikelines", "hoverclosest", "hovercompare"]
        },
        hovermode: "x",
        xaxis: {
          zeroline: false,
          hoverformat: ".1f",
          dtick: 1,
          gridcolor: "#eeeeee",
          linecolor: "#eeeeee",
          linewidth: 2,
          mirror: "ticks"
        },
        yaxis: {
          zeroline: false,
          hoverformat: ".0f",
          dtick: 1,
          gridcolor: "#eeeeee",
          linecolor: "#eeeeee",
          linewidth: 2,
          mirror: "ticks"
        }
      };
    }
  }
});
</script>

<style>
#tp_plots_div {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  height: 700px;
}
</style>
