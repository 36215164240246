var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"inherit","width":"inherit"},attrs:{"id":"main-map"}},[_c('v-overlay',_vm._b({attrs:{"opacity":".9","color":"grey lighten-1","value":_vm.loading,"z-index":"1"}},'v-overlay',_vm.overlayProps,false),[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('h1',[_vm._v(_vm._s(_vm.$t("visualisation.loading")))]),_c('v-progress-circular',{attrs:{"width":6,"color":"primary","indeterminate":""}})],1)]),_c('div',{staticClass:"decktooltip",style:(_vm.cssVars),attrs:{"id":"decktooltip"}}),_c('mapbox',{style:(_vm.mapCss),attrs:{"access-token":_vm.mapboxkey,"map-options":{
      style: {
        version: 8,
        name: 'Empty',
        metadata: {
          'mapbox:autocomposite': true
        },
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        sources: {},
        layers: [
          {
            id: 'background',
            type: 'background',
            paint: {
              'background-color': 'white'
            }
          }
        ]
      },
      // or 'mapbox://styles/mapbox/light-v10'
      center: [2.571, 46.815],
      zoom: 5,
      maxZoom: 20,
      pitch: 0,
      bearing: 0,
      preserveDrawingBuffer: true,
      transformRequest: (url, resourceType) => {
        if (url.startsWith('http://localhost:18080') || url.includes('whale.tellae.fr')) {
          return {
            url: url,
            credentials: 'include'
          };
        }
      },
      antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
      ..._vm.mapProps
    },"nav-control":{
      show: true,
      showCompass: 'true',
      position: 'top-right'
    },"geolocate-control":{
      show: false,
      position: 'top-left'
    },"scale-control":{
      show: true,
      position: 'bottom-right'
    },"fullscreen-control":{
      show: false,
      position: 'top-left'
    }},on:{"map-load":_vm.mapLoaded,"map-init":_vm.mapInitialized}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }