<!--
  Capucine data table base component
-->

<template>
  <v-card style="height: 75vh; width: 100%">
    <KiteMap
      v-show="non_empty"
      map-css="height: inherit; width: inherit;"
      :loading="loading"
      @map:initialised="mapInitialized"
      @map:loaded="mapLoaded"
      :map-props="{
        style: {
          version: 8,
          sources: {},
          layers: [
            {
              id: 'background',
              type: 'background',
              paint: {
                'background-color': 'white'
              }
            }
          ]
        },
        antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
      }"
      :overlay-props="{ style: 'position: absolute' }"
    />
    <div v-show="!non_empty">Pas de tracé pour cette ligne</div>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { zoomOnGeojson } from "@/functions-tools";
import * as turf from "@turf/turf";
import { change_base_layer, add_background_layers } from "@/kite_layers";
import { ALL_ROUTES } from "@/capucine_utils";
import KiteMap from "@/components/base/kite_map.vue";

const capucine_background = "osm-classic";

let bus_line_layer = {
  id: "bus_line",
  type: "line",
  source: "bus_line",
  layout: {},
  paint: {
    "line-color": ["get", "linecolor"],
    "line-width": 3
  }
};

export default Vue.component("capucine-map", {
  components: {
    KiteMap
  },
  props: {
    loading: Boolean,
    idLine: String
  },
  data: () => ({
    map: null,
    non_empty: true,
    loaded: false
  }),
  computed: {
    ...mapState("capucine_analysis", ["lot", "tab", "page", "data_batch_id"]),
    ...mapGetters("capucine_analysis", ["lotGeojson"])
  },
  watch: {
    // watcher with timeout for dealing with map grey area
    // https://docs.mapbox.com/help/troubleshooting/blank-tiles/
    // with tab change
    lotGeojson() {
      this.updateSource();
    },
    tab(value, valueOld) {
      if (value !== valueOld) {
        setTimeout(() => {
          this.refresh_map();
        }, 100);
      }
    },
    // same with page change
    page(value, valueOld) {
      if (value !== valueOld) {
        setTimeout(() => {
          this.refresh_map();
        }, 100);
      }
    },
    idLine() {
      this.updatePaintProps();
    }
  },
  methods: {
    mapInitialized(map) {
      this.map = map;
      //this.map.showPadding = true;
    },
    async mapLoaded(map) {
      // set a base layer
      add_background_layers(map);
      change_base_layer(map, capucine_background);
      map.setPaintProperty(capucine_background, "raster-opacity", 0.3);

      this.refresh_map();

      // add bus line routes
      this.updateSource();

      this.loaded = true;
    },
    refresh_map() {
      // detect the map's new width and height and resize it
      this.map.resize();
    },
    updatePaintProps() {
      if (this.lotGeojson === undefined) {
        return;
      }
      if (this.idLine === undefined) {
        // case ALL_ROUTES: all traces are visible
        this.map.setPaintProperty("bus_line", "line-width", 3);
        this.map.setPaintProperty("bus_line", "line-color", ["get", "linecolor"]);
      } else {
        let filtered = this.lotGeojson.features.filter(item => {
          let props = item.properties;
          return props.lineid == this.idLine;
        });
        if (filtered.length == 0) {
          // case no trace: display message
          this.non_empty = false;
        } else {
          // case selected route:
          this.map.setPaintProperty("bus_line", "line-width", ["case", ["==", ["get", "lineid"], this.idLine], 6, 1]);
          this.map.setPaintProperty("bus_line", "line-color", [
            "case",
            ["==", ["get", "lineid"], this.idLine],
            ["get", "linecolor"],
            "#aaaaaa"
          ]);
          this.non_empty = true;
        }
      }
    },
    updateSource() {
      setTimeout(() => {
        let source = this.map.getSource("bus_line");
        if (source) {
          this.map.removeLayer("bus_line");
          this.map.removeSource("bus_line");
        }
        this.map.addSource("bus_line", {
          type: "geojson",
          data: this.lotGeojson,
          attribution: this.lot?.traces_source || ""
        });
        this.map.addLayer(bus_line_layer);
        if (this.lotGeojson.features.length > 0) {
          zoomOnGeojson(this.map, this.lotGeojson, 10, [0, 0]);
        }
        this.updatePaintProps();
      }, 1000);
    }
  }
});
</script>
