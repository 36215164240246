<!--
  Presentation (lot or line)
-->

<template>
  <kpi-card :title="title" :suffix="''" :subtitle="''">
    <v-row class="ma-n4 pa-n4" align="center" justify="center" height="75vh">
      <v-col cols="8" style="align-self: start">
        <capucine-map :loading="lot_loading" :id-line="route.id_line"></capucine-map>
      </v-col>
      <v-col cols="4" style="align-self: start">
        <v-card height="75vh" class="overflow-y-auto overflow-x-hidden">
          <v-tabs
            v-if="stats_computed"
            v-model="line_info_tab"
            background-color="primary"
            class="elevation-2"
            dark
            grow
            :slider-size="SLIDER_SIZE"
          >
            <v-tabs-slider :color="SLIDER_COLOR" />
            <v-tab :href="`#tab-0`">
              {{ "Couverture" }}
            </v-tab>

            <v-tab :href="`#tab-1`" :disabled="route_id == ALL_ROUTES">
              {{ "Voirie" }}
            </v-tab>

            <v-tab-item :key="0" :value="'tab-0'" eager height="70vh">
              <pt-line-stats :line-geojson="line_geojson" :content-keys="['poptot', 'comp', 'sch']" />
            </v-tab-item>

            <v-tab-item :key="1" :value="'tab-1'" eager height="70vh">
              <pt-line-osm :line-geojson="line_geojson" :line-id="osm_line_id" />
            </v-tab-item>
          </v-tabs>
          <div v-else>
            <v-card-text style="align: center">
              <p>Statistiques de population, entreprises et enseignement</p>
              <p><u>Attention:</u> le calcul pour l'ensemble des lignes peut être long.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn block large color="primary" @click="compute_stats" :loading="lot_loading"> Calculer </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card height="50" class="elevation-0">
      <v-row align="center" justify="center" class="mb-4 mt-4">
        <div style="max-width: 95%">
          <v-overlay
            opacity="0.5"
            color="grey lighten-1"
            absolute
            :value="lot_loading || data_batch_routes.length == 0"
          >
            <div class="d-flex flex-column align-center">
              <h3>Chargement</h3>
              <v-progress-linear color="primary" indeterminate></v-progress-linear>
            </div>
          </v-overlay>
          <v-slide-group multiple show-arrows>
            <v-slide-item v-for="item in data_batch_routes" :key="item.id">
              <route-chip
                class="px-3 mx-1"
                :route="item"
                :text="route => routeNames[route.id]"
                :color="route => chipColor(route).substring(1)"
                :text-color="route => chipTextColor(route).substring(1)"
                @click="selectRoute(item.id)"
              />
            </v-slide-item>
          </v-slide-group>
        </div>
      </v-row>
    </v-card>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import KpiCard from "@/components/capucine/kpi_card.vue";
import RouteChip from "@/components/transports/route_chip.vue";
import CapucineMap from "@/components/capucine/capucine_map.vue";
import { routeChipColor, routeChipTextColor } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";
import PtLineStats from "@/components/kite/network/pt_line_stats.vue";
import PtLineOsm from "@/components/kite/network/pt_line_osm.vue";
import { ALL_ROUTES } from "@/capucine_utils";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";

export default Vue.component("presentation", {
  components: {
    KpiCard,
    CapucineMap,
    PtLineStats,
    PtLineOsm,
    RouteChip
  },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    SLIDER_SIZE,
    SLIDER_COLOR,
    line_info_tab: "tab-0",
    ALL_ROUTES,
    kpi_name: "analysis_synthesis",
    line_geojson: { features: [] },
    stats_computed: false,
    osm_line_id: null
  }),
  watch: {
    route(value) {
      this.stats_computed = false;
      if (value.id == ALL_ROUTES) {
        this.line_info_tab = "tab-0";
      }
    }
  },
  computed: {
    ...mapState(["async"]),
    ...mapState("capucine_analysis", ["type", "route_id", "data_batch_id", "lot", "routes"]),
    ...mapGetters("capucine_analysis", ["routeNames", "lotGeojson"]),
    data_batch_routes() {
      return this.routes.filter(route => route.data_batch == this.data_batch_id);
    },
    lot_loading() {
      return this.$store.state.capucine_analysis.loading.lot;
    },
    title() {
      if (this.route.isAllRoutes) {
        let lot = this.lot.name;
        let type = this.lot.type;
        if (lot == undefined) {
          lot = "";
          type = "lot";
        }
        return `Présentation du ${type.toLowerCase()} ${lot}`;
      } else {
        return "Présentation de la ligne " + this.route.name;
      }
    },
    synthesis_data() {
      let synthesis_obj = this.data[this.data_batch_id];
      if (!synthesis_obj) {
        return {};
      } else {
        return synthesis_obj;
      }
    }
  },
  methods: {
    ...mapActions("capucine_analysis", ["changeRoute"]),
    chipColor(item) {
      if (this.route.id == ALL_ROUTES || this.route_id == item.id) {
        return routeChipColor(item, "id");
      } else {
        return "grey lighten-1";
      }
    },
    chipTextColor(item) {
      if (this.route.id == ALL_ROUTES || this.route_id == item.id) {
        return routeChipTextColor(item, "id");
      } else {
        return "white";
      }
    },
    selectRoute(route) {
      if (route == this.route_id) {
        route = ALL_ROUTES;
      }
      this.changeRoute(route);
    },
    compute_stats() {
      this.stats_computed = true;
      this.line_geojson = this.evaluateLineGeojson();
      this.osm_line_id = this.route_id;
    },
    evaluateLineGeojson() {
      let filtered_geojson;
      if (this.route.id_line) {
        filtered_geojson = JSON.parse(JSON.stringify(this.lotGeojson));
        filtered_geojson.features = filtered_geojson.features.filter(feature => {
          return feature.properties.lineid == this.route.id_line;
        });
      } else {
        filtered_geojson = this.lotGeojson;
      }
      return filtered_geojson;
    }
  }
});
</script>
