<!--
  Page to display simulation results
-->

<template>
  <div>
    <v-tabs-items v-model="tab" :vertical="true">
      <v-tab-item value="synthesis"> <lot-synthesis /> </v-tab-item>
      <v-tab-item value="section_travel_time"> <section-travel-time /> </v-tab-item>
      <v-tab-item value="robustness"> <robustness /> </v-tab-item>
      <v-tab-item value="conformity"> <conformity /> </v-tab-item>
      <v-tab-item value="kcc_loss"> <kcc-loss /> </v-tab-item>
      <v-tab-item value="vehicle_load"> <vehicle-load /></v-tab-item>
      <v-tab-item value="number_vehicles"> <number-vehicles /> </v-tab-item>
      <v-tab-item value="drive_duration"> <drive-duration /> </v-tab-item>
      <v-tab-item value="kcc_reduction"> <kcc-reduction /> </v-tab-item>
      <v-tab-item value="bonus_malus"> <bonus-malus /> </v-tab-item>
      <v-tab-item value="iso_indicators"> <iso-indicators /> </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SectionTravelTime from "./section_travel_time.vue";
import Robustness from "./robustness.vue";
import Conformity from "./conformity.vue";
import KccLoss from "./kcc_loss.vue";
import VehicleLoad from "./vehicle_load.vue";
import NumberVehicles from "./number_vehicles.vue";
import DriveDuration from "./drive_duration.vue";
import KccReduction from "./kcc_reduction.vue";
import BonusMalus from "./bonus_malus.vue";
import IsoIndicators from "./iso_indicators.vue";
import LotSynthesis from "./lot_synthesis.vue";

import { mapState } from "vuex";

export default Vue.component("results-page", {
  components: {
    SectionTravelTime,
    Robustness,
    Conformity,
    KccLoss,
    VehicleLoad,
    NumberVehicles,
    DriveDuration,
    KccReduction,
    BonusMalus,
    IsoIndicators,
    LotSynthesis
  },
  computed: {
    ...mapState("capucine_results", ["tab"])
  }
});
</script>
