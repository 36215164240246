<!--
Kite dialog that contains tabs sitting under a toolbar with a title
-->

<template>
  <kite-dialog v-bind="$attrs" hide-overlay persistent no-click-animation :value="dialog">
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn large icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title fixed-tabs :slider-size="SLIDER_SIZE">
            <v-tabs-slider :color="SLIDER_COLOR" />
            <v-tab v-for="(item, i) in tabs" :key="i" :tab-value="item.key">
              {{ $t(item.text) }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card height="79vh" class="overflow-auto">
        <slot :tab="tab" />
      </v-card>
    </v-card>
  </kite-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import KiteDialog from "@/components/kite/base/kite_dialog.vue";

export default Vue.component("kite-tabs-dialog", {
  props: {
    // dialog model
    dialog: {
      type: Boolean,
      required: true
    },
    // describes the available tabs
    // array of { key, text } objects
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // toolbar title
    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tab: null,
      SLIDER_SIZE,
      SLIDER_COLOR
    };
  },
  watch: {
    tabs: {
      handler(value) {
        if (this.tabs.length > 0) {
          this.tab = this.tabs[0].key;
        }
      },
      immediate: true
    }
  },

  computed: {},

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    }
  }
});
</script>
