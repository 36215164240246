<!--
Dialog that sits next to the main navigation drawer
-->

<template>
  <v-dialog width="64vw" content-class="kite-dialog" v-bind="$attrs" v-model="dialog">
    <kite-card-title v-if="title" :title="title" :tooltip="tooltip" />
    <slot />
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import KiteCardTitle from "@/components/base/kite_card_title.vue";

export default Vue.component("kite-dialog", {
  components: {
    KiteCardTitle
  },
  props: ["value", "title", "tooltip"],

  data() {
    return {};
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {}
});
</script>

<style>
.kite-dialog {
  position: absolute;
  right: 30px;
  top: 40px;
}
</style>
