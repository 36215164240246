<!--
  Mixin containing basics for network info display 
-->

<script lang="ts">
import { mapState, mapActions, mapGetters } from "vuex";
import RouteTypeIcon from "@/components/transports/route_type_icon.vue";
import RouteChip from "@/components/transports/route_chip.vue";
import InfoDayTypeSelector from "./info_day_type_selector.vue";
import DirectionSelector from "@/components/transports/direction_selector.vue";

export default {
  components: { RouteTypeIcon, RouteChip, InfoDayTypeSelector, DirectionSelector },
  props: ["content", "tab"],
  data: function () {
    return {
      direction: "0",
      value_table_props: {
        class: "mx-auto",
        width: "400px",
        hideDefaultFooter: true,
        itemsPerPage: -1,
        dense: true
      }
    };
  },

  computed: {
    ...mapState("network", ["infoDayType", "infoDialogContent", "currentNetworkView"]),
    export_filename() {
      return "data.xls";
    }
  },
  methods: {
    ...mapActions("network", ["setInfoDialogContent"]),
    getMatchingItem(items, matcher, multiple = false) {
      let filtered_items = items.filter(item => {
        // test if item matches all matcher keys
        for (const key in matcher) {
          if (item[key] != matcher[key]) {
            return false;
          }
        }
        return true;
      });

      // return based on length and multiple param
      if (filtered_items.length == 0) {
        return undefined;
      } else if (multiple) {
        return filtered_items;
      } else if (filtered_items.length > 1) {
        throw new Error("Should be exactly one stat item");
      } else {
        return filtered_items[0];
      }
    }
  }
};
</script>
