<!--
Persistent dialog with no overlay that sits on the left of the map
-->

<template>
  <v-dialog
    v-model="dialog"
    v-bind="$attrs"
    width="auto"
    hide-overlay
    persistent
    no-click-animation
    :content-class="contentClass"
  >
    <slot />
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("kite-left-dialog", {
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true
    },
    width: {
      type: String
    }
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(["mainDrawerOpen"]),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    contentClass() {
      if (this.mainDrawerOpen) {
        return "left-with-drawer";
      } else {
        return "left-without-drawer";
      }
    }
  },

  methods: {}
});
</script>

<style>
.left-with-drawer {
  position: absolute;
  left: 32.5vw;
  top: 40px;
}

.left-without-drawer {
  position: absolute;
  left: 0;
  top: 40px;
}
</style>
