// data related to available simulation models in the simulation menu

import i18n from "./plugins/lang";
import { coordinatesToText } from "@/functions-tools";

// Scenario status color
const SCENARIO_STATUS_COLORS = {
  QUEUED: "grey darken-2",
  STARTING: "lime",
  RUNNING: "amber",
  SUCCESS: "green",
  ERROR: "red"
};

// stepper specification
const STEPS = {
  intro: 1,
  environment: 2,
  demand: 3,
  offer: 4,
  info: 5,
  validation: 6
};

// list of transport services available for simulation
// these services can correspond to one or more Starling models
// each service have one or more use case to simulate
const SERVICES = {
  SB_VS: {
    code: "SB_VS",
    description: "TODO",
    description_displayed: false,
    models: ["SB_VS", "SB_VS_R"],
    use_cases: ["sb_vs_service_reorganization", "sb_vs_new_service", "sb_vs_relocation", "sb_vs_carsharing_new"],
    available: true
  },
  FF_VS: {
    code: "FF_VS",
    description: "TODO",
    description_displayed: false,
    models: ["FF_VS"],
    use_cases: ["ff_vs_new_service", "ff_vs_competition", "ff_vs_pricing", "ff_vs_relocation"],
    available: true
  },
  TT_PT: {
    code: "TT_PT",
    description: "TODO",
    description_displayed: false,
    models: ["TT_PT"],
    use_cases: ["tt_pt_add_new_route", "tt_pt_delete_bus_routes", "tt_pt_timetable", "tt_pt_perturbation"],
    available: false
  },
  ODT: {
    code: "ODT",
    description: "TODO",
    description_displayed: false,
    models: ["ODT", "OD_P_T"],
    use_cases: ["odt_basic", "odt_railway_station", "odt_replace_bus", "odt_rural_area"],
    available: true
  },
  CP: {
    code: "CP",
    description: "TODO",
    description_displayed: false,
    models: ["CP"],
    use_cases: [],
    available: false
  },
  BUSARD: {
    code: "BUSARD",
    description: "TODO",
    description_displayed: false,
    models: ["BUSARD"],
    use_cases: [],
    available: false
  }
};

// simulation use cases and their default parameters
const USE_CASES = {
  odt_basic: {
    code: "odt_basic",
    available: true,
    parameters: {
      demand: null,
      number_vehicles: 1,
      capacity: 8,
      max_waiting_time: 30,
      speeds: [
        { highway: "residential", speed: "20" },
        { highway: "secondary", speed: "30" },
        { highway: "primary", speed: "30" },
        { highway: "trunk", speed: "30" },
        { highway: "tertiary", speed: "30" },
        { highway: "living_street", speed: "20" },
        { highway: "motorway", speed: "90" },
        { highway: "other", speed: "30" }
      ],
      depot_point: undefined
    },
    recap: [
      {
        value: "demand.name",
        text: "generic.demand_file"
      },
      "generic.number_vehicles",
      "generic.capacity",
      "generic.max_waiting_time",
      {
        value: p => {
          if (p.depot_point) {
            return coordinatesToText(p.depot_point.coordinates);
          }
        },
        text: "depot"
      }
    ],
    img: "./static/simulation/models/ODT.jpg"
  },
  odt_railway_station: {
    code: "odt_railway_station",
    available: true,
    parameters: {
      railway_station: null,
      radius: 5000,
      speeds: [
        { highway: "residential", speed: "20" },
        { highway: "secondary", speed: "30" },
        { highway: "primary", speed: "30" },
        { highway: "trunk", speed: "30" },
        { highway: "tertiary", speed: "30" },
        { highway: "living_street", speed: "20" },
        { highway: "motorway", speed: "90" },
        { highway: "other", speed: "30" }
      ],
      penetration_rate: 10,
      random_seed: 42,
      number_vehicles: 1,
      capacity: 8,
      max_waiting_time: 30
    },
    recap: [
      {
        value: "railway_station.railway_station_name",
        text: "railway_station"
      },
      "radius",
      "generic.penetration_rate",
      "generic.random_seed",
      "generic.number_vehicles",
      "generic.capacity",
      "generic.max_waiting_time"
    ]
  },
  odt_reduced_mobility: {
    code: "odt_reduced_mobility",
    available: false
  },
  odt_rural_area: {
    code: "odt_rural_area",
    available: false
  },
  odt_replace_bus: {
    code: "odt_replace_bus",
    available: false
  },
  ff_vs_new_service: {
    code: "ff_vs_new_service",
    img: "./static/simulation/models/FF_VS.jpg",
    available: true,
    parameters: {
      commune_id: null,
      penetration_rate: 1,
      number_bikes: 50,
      location: null
    },
    recap: [
      {
        text: "commune",
        value: "commune_id.name"
      },
      "generic.penetration_rate",
      "number_bikes",
      {
        text: "location",
        value: "location.text"
      }
    ]
  },
  ff_vs_competition: {
    code: "ff_vs_competition",
    available: false
  },
  ff_vs_pricing: {
    code: "ff_vs_pricing",
    available: false
  },
  ff_vs_relocation: {
    code: "ff_vs_relocation",
    available: false
  },
  tt_pt_delete_bus_routes: {
    code: "tt_pt_delete_bus_routes",
    available: true,
    parameters: {
      metropolis: null,
      penetration_rate: 1,
      closed_routes: []
    },
    recap: [
      {
        text: "metropolis",
        value: "metropolis.text"
      },
      "generic.penetration_rate",
      {
        value: p => p.closed_routes.map(item => item.route_short_name),
        text: "closed_routes"
      }
    ]
  },
  tt_pt_add_new_route: {
    code: "tt_pt_add_new_route",
    available: false
  },
  tt_pt_timetable: {
    code: "tt_pt_timetable",
    available: false
  },
  tt_pt_perturbation: {
    code: "tt_pt_perturbation",
    available: false
  },
  sb_vs_service_reorganization: {
    code: "sb_vs_service_reorganization",
    available: true,
    parameters: {
      city: null,
      date: "2021-09-07",
      // day_type: null,
      demand_percentage: 100,
      number_bikes: 100,
      location: null,
      relocation: false
    },
    recap: [
      {
        text: "city",
        value: "city.text"
      },
      "date",
      "demand_percentage",
      "number_bikes",
      {
        text: "location",
        value: "location.text"
      },
      {
        text: "relocation",
        value: p => {
          return i18n.t("boolean." + p.relocation);
        }
      }
    ]
  },
  sb_vs_carsharing_new: {
    code: "sb_vs_carsharing_new",
    available: false
  },
  sb_vs_new_service: {
    code: "sb_vs_new_service",
    available: false
  },
  sb_vs_relocation: {
    code: "sb_vs_relocation",
    available: false
  }
};

// headers for the table model KPIs
// the 'scenario' and 'actions' headers are automatically added
const KPI_HEADERS = {
  SB_VS: [
    { text: "generic.number_users", value: "number_users", align: "right", width: "9%" },
    { text: "generic.number_vehicles", value: "number_vehicles", align: "right", width: "9%" },
    { text: "SB_VS.number_stations", value: "number_stations", align: "right", width: "9%" },
    { text: "generic.mean_walk_time", value: "mean_walk_time", align: "right", width: "9%" },
    { text: "generic.mean_vehicle_time", value: "mean_vehicle_time", align: "right", width: "9%" },
    { text: "generic.mean_total_time", value: "mean_total_time", align: "right", width: "9%" },
    { text: "SB_VS.number_fails", value: "number_fails", align: "right", width: "9%" },
    {
      text: "SB_VS.empty_time_stations",
      value: "empty_time_stations",
      align: "right",
      width: "9%"
    },
    { text: "SB_VS.full_time_stations", value: "full_time_stations", align: "right", width: "9%" },
    { text: "SB_VS.staff_drive_distance", value: "staff_drive_distance", align: "right", width: "9%" }
  ],
  FF_VS: [
    { text: "generic.number_users", value: "number_users", align: "right", width: "9%" },
    { text: "generic.number_vehicles", value: "number_vehicles", align: "right", width: "9%" },
    { text: "generic.mean_walk_time", value: "mean_walk_time", align: "right", width: "9%" },
    { text: "generic.mean_vehicle_time", value: "mean_vehicle_time", align: "right", width: "9%" },
    { text: "generic.mean_total_time", value: "mean_total_time", align: "right", width: "9%" },
    { text: "FF_VS.mean_utilization_rate", value: "mean_utilization_rate", align: "right", width: "9%" },
    { text: "FF_VS.mean_utilization_time", value: "mean_utilization_time", align: "right", width: "9%" },
    {
      text: "FF_VS.number_unused_vehicles",
      value: "number_unused_vehicles",
      align: "right",
      width: "9%"
    }
  ],
  ODT: [
    { text: "generic.number_users", value: "number_users", align: "right", width: "9%" },
    { text: "ODT.journeys", value: "number_success", align: "right", width: "9%" },
    { text: "ODT.success", value: "percentage_success", align: "right", width: "9%" },
    { text: "ODT.KT", value: "kt", align: "right", width: "9%" },
    { text: "ODT.KCC", value: "kcc", align: "right", width: "9%" },
    { text: "ODT.HLP", value: "hlp", align: "right", width: "9%" },
    { text: "ODT.VK", value: "vk", align: "right", width: "9%" },
    { text: "ODT.vehicles", value: "number_used_vehicles", align: "right", width: "9%" },
    { text: "ODT.courses", value: "number_trips", align: "right", width: "9%" },
    { text: "ODT.bulking", value: "bulking", align: "right", width: "9%" }
  ],
  TT_PT: [
    { text: "generic.number_users", value: "number_users", align: "right", width: "9%" },
    { text: "generic.mean_walk_time", value: "mean_walk_time", align: "right", width: "9%" },
    { text: "generic.mean_vehicle_time", value: "mean_vehicle_time", align: "right", width: "9%" },
    { text: "generic.mean_wait_time", value: "mean_wait_time", align: "right", width: "9%" },
    { text: "generic.mean_total_time", value: "mean_total_time", align: "right", width: "9%" },
    { text: "TT_PT.kcc", value: "kcc", align: "right", width: "9%" },
    { text: "TT_PT.service_time", value: "service_time", align: "right", width: "9%" }
  ]
};

const HOLIDAYS = [
  "2021-09-01",
  "2021-10-23",
  "2021-10-24",
  "2021-10-25",
  "2021-10-26",
  "2021-10-27",
  "2021-10-28",
  "2021-10-29",
  "2021-10-30",
  "2021-10-31",
  "2021-11-01",
  "2021-11-02",
  "2021-11-03",
  "2021-11-04",
  "2021-11-05",
  "2021-11-06",
  "2021-11-07",
  "2021-12-18",
  "2021-12-19",
  "2021-12-20",
  "2021-12-21",
  "2021-12-22",
  "2021-12-23",
  "2021-12-24",
  "2021-12-25",
  "2021-12-26",
  "2021-12-27",
  "2021-12-28",
  "2021-12-29",
  "2021-12-30",
  "2021-12-31"
];

const BANK_HOLIDAYS = ["2021-11-01", "2021-11-11", "2021-12-25"];

export { SCENARIO_STATUS_COLORS, STEPS, SERVICES, USE_CASES, KPI_HEADERS, HOLIDAYS, BANK_HOLIDAYS };
