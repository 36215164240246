<!--
  Travel time buffers configuration dialog
-->

<template>
  <v-dialog v-model="dialog" persistent :max-width="'700px'" content-class="tp-config-dialog">
    <template #activator="{ on }">
      <v-btn large elevation="0" :loading="loading" color="primary" v-on="on" @click="copyBuffers" class="pa-2 ma-2">
        <v-icon class="mr-2">u_turn_right</v-icon>
        Paramètres des battements
      </v-btn>
    </template>

    <v-card>
      <kite-card-title
        title="Configuration des battements optimaux"
        tooltip="Configurez les battements optimaux"
      ></kite-card-title>
      <v-card-text class="pt-8 pl-8 pr-8">
        <v-row>
          <p>Trois méthodes de calcul des battements optimaux sont visualisables sur le graphique.</p>
          <ul>
            <li>
              <b>Battement ({{ local_buffers.pX }}% des mesures):</b> battement couvrant {{ local_buffers.pX }}% des
              mesures retenues
            </li>
            <li><b>Battement (en %):</b> battement en pourcentage du temps moyen réalisé</li>
            <li>
              <b>Battement (écart type):</b> battement en fonction de l'écart type des mesures et de l'intervalle entre
              les courses
            </li>
          </ul>
        </v-row>
        <v-row>
          <v-col>
            <v-slider
              v-model="local_buffers.pX"
              thumb-label="always"
              ticks
              min="70"
              max="100"
              label="Battement (% de couverture des mesures)"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-slider
              v-model="local_buffers.percentage"
              thumb-label="always"
              ticks
              min="5"
              max="60"
              label="Battement (en % ajouté au temps réalisé)"
              @input="updateRealisedPercentage"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-slider
              v-model="local_buffers.percentage_theoretical"
              thumb-label="always"
              ticks
              min="5"
              max="60"
              label="Battement (en % ajouté au temps théorique)"
              @input="updateTheoreticalPercentage"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"><span class="v-label"> Battement (écart type par classe d'intervalle)</span> </v-col>
          <v-col cols="2">
            <v-text-field
              label="3 à 6 min"
              v-model="local_buffers.std_3_6"
              required
              type="number"
              :rules="[v => v > 0]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="6 à 10 min"
              v-model="local_buffers.std_6_10"
              required
              type="number"
              :rules="[v => v > 0]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="10 min et +"
              v-model="local_buffers.std_10_more"
              required
              type="number"
              :rules="[v => v > 0]"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="
            cancelBufferUpdate();
            closeDialog();
          "
        >
          {{ $t("basic_dialogs.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            updateBuffers();
            closeDialog();
          "
        >
          {{ $t("basic_dialogs.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.component("travel-time-buffers", {
  props: ["buffers", "loading"],
  data: () => ({
    dialog: false,
    local_buffers: {
      percentage: 10,
      percentage_theoretical: 10,
      pX: 95,
      std_3_6: 2,
      std_6_10: 1.5,
      std_10_more: 1
    }
  }),
  computed: {},

  methods: {
    updateBuffers() {
      this.$emit("update", this.local_buffers);
    },
    cancelBufferUpdate() {
      this.$emit("cancel");
      return;
      this.updateRealisedPercentage(this.local_buffers.percentage);
      this.updateTheoreticalPercentage(this.local_buffers.percentage_theoretical);
    },
    updateRealisedPercentage(value) {
      this.$emit("update:realised", value);
    },
    updateTheoreticalPercentage(value) {
      this.$emit("update:theoretical", value);
    },
    copyBuffers() {
      this.local_buffers = JSON.parse(JSON.stringify(this.buffers));
    },
    closeDialog() {
      this.dialog = false;
    }
  }
});
</script>
