<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="primary" class="elevation-2" rounded large left dark @click="openMenuDialog">
          <v-icon left>save</v-icon> {{ $t("map_layers.maps") }}
        </v-btn>
      </template>
      <span>{{ $t("map_layers.tooltips.map_views") }}</span>
    </v-tooltip>

    <kite-dialog :value="menuDialog && !addDialog" @input="menuDialog = $event">
      <map-manager @newMapView="openAddDialog" @updateMenuDialog="menuDialog = $event" />
    </kite-dialog>
    <kite-dialog v-model="addDialog">
      <add-map-view :new-map-view="new_map_view" @updateAddDialog="addDialog = $event" />
    </kite-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import MapManager from "./map_manager.vue";
import AddMapView from "./add_map_view.vue";

export default Vue.component("map-manager-menu", {
  components: { MapManager, AddMapView },

  data: function () {
    return {
      menuDialog: false,
      addDialog: false,
      new_map_view: null
    };
  },

  computed: {},
  methods: {
    openMenuDialog() {
      this.menuDialog = true;
    },
    openAddDialog(new_map_view) {
      this.new_map_view = new_map_view;
      this.addDialog = true;
    }
  }
});
</script>
