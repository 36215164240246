<template>
  <kite-map-action
    action="point_selection"
    :on-open="onOpen"
    :show-clear="false"
    :disable-validation="!longitude || !latitude || onGoingMapClick"
    :title="$t('map_actions.point_selection.title')"
    :get-result="getPoint"
    :clear-content="deletePoint"
  >
    <v-card-text>
      <v-row class="pt-3 pl-3">
        <span
          ><h3>{{ $t("map_actions.point_selection.map_select") }}</h3></span
        >
      </v-row>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="longitude"
            prepend-icon="place"
            :label="$t('map_actions.point_selection.lng')"
            readonly
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field v-model="latitude" :label="$t('map_actions.point_selection.lat')" readonly></v-text-field>
        </v-col>
        <v-col md="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="!longitude || !latitude"
                @click="zoomOnPoint"
                class="my-3"
                color="primary"
                icon
              >
                <v-icon> my_location</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("map_actions.point_selection.zoom") }}</span>
          </v-tooltip>
        </v-col>
        <v-divider class="flex ma-8" vertical />
        <v-col md="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                large
                @click="clickPointOnMap"
                :loading="onGoingMapClick"
                class="my-2"
                color="primary"
                icon
              >
                <v-icon> edit_location_alt</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("map_actions.point_selection.click") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-divider />
      </v-row>
      <v-row class="pt-3 pl-3">
        <span
          ><h3>{{ $t("map_actions.point_selection.address_select") }}</h3></span
        >
      </v-row>
      <v-row>
        <v-col md="9">
          <v-text-field
            v-model="adress"
            :label="$t('map_actions.point_selection.address')"
            prepend-icon="apartment"
            @change="adress_message = true"
            :loading="searching_adress"
            :rules="[adress_message]"
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="my-3"
                color="primary"
                icon
                :disabled="!adress || onGoingMapClick"
                :loading="searching_adress"
                @click="searchAdress"
              >
                <v-icon> done</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("basic_dialogs.validate") }}</span>
          </v-tooltip>
        </v-col>
        <v-col md="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="my-3"
                color="primary"
                icon
                :loading="searching_adress"
                @click="clearAdress"
              >
                <v-icon> close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("basic_dialogs.clear") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </kite-map-action>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import KiteMapAction from "./kite_map_action.vue";
import axios from "axios";
import mapboxgl from "mapbox-gl";

export default Vue.component("kite-point-selection", {
  components: {
    KiteMapAction
  },
  data: function () {
    return {
      marker: null,
      longitude: null,
      latitude: null,
      adress: "",
      searching_adress: false,
      adress_message: true,
      click_listener: null
    };
  },
  computed: {
    ...mapState(["mapActionState"]),
    ...mapState("layers", ["map"]),
    onGoingMapClick() {
      return this.click_listener != null;
    }
  },
  methods: {
    ...mapActions("layers", ["setLayersData", "setLayerVisibility"]),
    onOpen(map_action_state) {
      // if init point, set point and zoom to it
      let init_point = map_action_state.options?.point;
      if (init_point) {
        this.setPoint(init_point.lng, init_point.lat);
        this.zoomOnPoint();
      }
    },
    // point actions
    setPoint(longitude, latitude) {
      // create new mapbox Marker
      if (!this.marker) {
        this.marker = new mapboxgl.Marker({ color: "#151EDC", draggable: true })
          .setLngLat([longitude, latitude])
          .addTo(this.map);
        this.marker.on("dragend", () => {
          const lngLat = this.marker.getLngLat();
          this.longitude = lngLat.lng;
          this.latitude = lngLat.lat;
        });
      }

      // set new coordinates and update marker
      this.longitude = longitude;
      this.latitude = latitude;
      this.marker.setLngLat([longitude, latitude]).addTo(this.map);
    },
    deletePoint() {
      this.longitude = null;
      this.latitude = null;
      if (this.marker) {
        this.marker.remove();
      }
    },
    getPoint() {
      return {
        coordinates: {
          lng: this.longitude,
          lat: this.latitude
        }
      };
    },
    zoomOnPoint() {
      this.map.flyTo({
        center: {
          lng: this.longitude,
          lat: this.latitude
        },
        offset: [150, 0]
      });
    },
    // adress actions
    clearAdress() {
      this.adress = null;
    },
    searchAdress() {
      if (this.adress) {
        let url = `https://api-adresse.data.gouv.fr/search/?q=${this.adress}`;
        this.searching_adress = true;
        axios
          .get(url)
          .then(response => {
            if (response.status != 200) {
              throw new Error(response.statusText);
            }
            let coordinates = response.data.features[0].geometry.coordinates;
            this.setPoint(coordinates[0], coordinates[1]);
          })
          .catch(e => {
            this.adress_message = e.message;
          })
          .finally(() => {
            this.searching_adress = false;
          });
      }
    },
    // map point actions
    clickPointOnMap() {
      this.removeClickListener();
      this.click_listener = this.map.once("click", e => {
        this.setPoint(e.lngLat.lng, e.lngLat.lat);
        this.click_listener = null;
      });
    },
    removeClickListener() {
      if (this.click_listener) {
        this.map.off(this.click_listener);
      }
      this.click_listener = null;
    },
    clear() {
      this.deletePoint();
      this.removeClickListener();
      this.clearAdress();
    }
  }
});
</script>

<style>
.myClass:focus::before {
  opacity: 0 !important;
}
</style>
