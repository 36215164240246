<!--
  Data table with lot synthesis
-->

<template>
  <kpi-card :title="title" :subtitle="SUBTITLES.lot_synthesis">
    <v-row>
      <v-col align="center" md="12">
        <capucine-table
          :headers="headers"
          :items="lotSynthesisData"
          :loading="loading"
          card-width="1300"
          :tableProps="{
            'hide-default-footer': false,
            'disable-pagination': false,
            'footer-props': {
              'items-per-page-text': 'Lignes par page',
              'disable-items-per-page': true
            },
            'disable-sort': false,
            'must-sort': true,
            'sort-by': 'route',
            'custom-sort': sortLotTable
          }"
          :networkType="lot.type"
        >
        </capucine-table>
      </v-col>
    </v-row>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import KpiCard from "@/components/capucine/kpi_card.vue";
import { SUBTITLES, formatTableContent, routeChipColor, routeChipTextColor, sortLotTable } from "@/capucine_utils";

export default Vue.component("lot-synthesis", {
  components: { KpiCard },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapState("capucine_simulation", ["lot"]),
    ...mapGetters("capucine_simulation", ["routeNames"]),
    ...mapGetters("capucine_results", ["loading", "lotSynthesisData"]),
    title() {
      return `Synthèse du ${(this.lot.type || "Lot").toLowerCase()} ${this.lot.name}`;
    },
    headers() {
      return [
        {
          text: "Ligne",
          value: "route",
          align: "left",
          color: routeChipColor,
          "chip-text-color": routeChipTextColor,
          format: val => this.routeNames[val]
        },
        { text: "Robustesse", value: "robustness", align: "right", format: "%" },
        { text: "Ponctualité", value: "punctuality", align: "right", format: "%" },
        { text: "Régularité", value: "regularity", align: "right", format: "%" },
        { text: "Hybride", value: "hybride", align: "right", format: "%" },
        { text: "Pertes KCC", value: "kcc_loss", align: "right", format: "%" },
        { text: "Charge (Max)", value: "vehicle_load", align: "right", format: "%" },
        { text: "Nombre de véhicules", value: "number_vehicles", align: "right", format: "" },
        { text: "Heures de conduite", value: "drive_duration", align: "right", format: "" },
        { text: "Réfaction kilométrique", value: "kcc_reduction", align: "right", format: "€" },
        { text: "Bonus/Malus Ponctualité", value: "bonus_malus_punctuality", align: "right", format: "€" },
        { text: "Bonus/Malus Régularité", value: "bonus_malus_regularity", align: "right", format: "€" }
      ];
    }
  },
  methods: {
    formatTableContent,
    sortLotTable
  }
});
</script>
