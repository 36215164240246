<template>
  <base-aggregation v-on="$listeners" :agg-options="number_options">
    <p>
      {{ $t("flows.aggregation.dbscan.text") }}
    </p>
    <v-row>
      <v-col md="4">
        <v-text-field
          class="mr-5"
          prepend-icon="mdi-radius-outline"
          :label="$t('flows.aggregation.dbscan.distance')"
          :suffix="$t('simulations.use_cases.ODT.odt_railway_station.suffix.radius')"
          v-model="options.distance"
          type="number"
          :rules="[mandatoryRule, positiveRule]"
          style="width: 300px"
        >
        </v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field
          prepend-icon="mdi-hexagon-multiple-outline"
          :label="$t('flows.aggregation.dbscan.min_samples')"
          v-model="options.min_samples"
          :rules="[mandatoryRule, positiveRule]"
          type="number"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </base-aggregation>
</template>

<script lang="ts">
import Vue from "vue";
import { mandatoryRule, positiveRule } from "@/validation";
import BaseAggregation from "./base_aggregation.vue";

export default Vue.component("dbscan-aggregation", {
  components: {
    BaseAggregation
  },

  data: function () {
    return {
      options: {
        distance: 500,
        min_samples: 1
      }
    };
  },

  computed: {
    number_options() {
      return {
        distance: Number(this.options.distance),
        min_samples: Number(this.options.min_samples)
      };
    }
  },

  methods: {
    mandatoryRule,
    positiveRule
  }
});
</script>
