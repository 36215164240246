<!--
  Travel time filters configuration dialog
-->

<template>
  <v-dialog v-model="dialog" persistent :max-width="'700px'" content-class="tp-config-dialog">
    <template #activator="{ on }">
      <v-badge
        :offset-x="15"
        :value="currentFilterModifState != 'default'"
        bordered
        overlap
        :color="stateBadgeColor"
        :icon="stateBadgeIcon"
      >
        <v-btn
          large
          elevation="0"
          :loading="loading"
          color="primary"
          v-on="on"
          @click="inferLocalFilter()"
          class="mr-1"
        >
          <v-icon class="mr-1">scatter_plot</v-icon>
          Filtre des mesures
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <kite-card-title
        title="Filtre des données"
        tooltip="Configurez le filtre des mesures de temps de parcours"
      ></kite-card-title>
      <v-card-text class="pa-8">
        <p>
          Le filtre des mesures est appliqué avec deux filtres successifs (1.1 et 1.2, puis 2) qui peuvent être activées
          ou non.
        </p>
        <ol>
          <li>
            Seuils de tolérance de prise en compte des mesures par rapport à la médiane en valeur absolue : les valeurs
            en dessous de la médiane (1.1) et les valeurs au dessus de la médiane (1.2)
          </li>
          <li>
            Ensuite, un seuil de tolérance de prise en compte des mesures par rapport à l’écart-type autour de la
            moyenne des mesures (2)
          </li>
        </ol>
        <v-form ref="form" v-model="valid_filter" lazy-validation>
          <v-row v-for="(filter_key, index) of filter_order" :key="index" align="center" class="mb-n12">
            <v-col cols="1">
              <v-simple-checkbox v-model="local_filter[filter_key].activate" :ripple="false"></v-simple-checkbox>
            </v-col>
            <v-col cols="5"> <span v-html="labelText(filter_key)" /></v-col>
            <v-col cols="2">
              <v-text-field
                v-model="local_filter[filter_key].value"
                required
                type="number"
                :disabled="!local_filter[filter_key].activate"
                :rules="[v => v > 0]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text :loading="saving" :disabled="!valid_filter" @click="saveFilter">
          {{ $t("basic_dialogs.validate_and_save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="saving" @click="resetFilter">
          {{ $t("basic_dialogs.reset") }}
        </v-btn>
        <v-btn color="primary" text :disabled="saving" @click="closeDialog">
          {{ $t("basic_dialogs.cancel") }}
        </v-btn>
        <v-btn color="primary" text :disabled="!valid_filter || saving" @click="updateFilter">
          {{ $t("basic_dialogs.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { isEqual } from "lodash";
import { getDefaultTravelTimeFilter } from "@/capucine_utils";

const labels = {
  below_median: "(1.1) En dessous de la médiane",
  above_median: "(1.2) Au dessus de la médiane",
  number_std: "(2) Nombre d'écart type"
};

const state_badge = {
  default: {
    color: "",
    icon: ""
  },
  changed: {
    color: "orange",
    icon: "edit"
  },
  saved: {
    color: "secondary",
    icon: "save"
  }
};

export default Vue.component("travel-time-filter", {
  props: ["loading"],
  data: () => ({
    dialog: false,
    local_filter: null,
    valid_filter: true,
    labels,
    saving: false
  }),
  computed: {
    ...mapState("capucine_analysis", {
      travel_time_direction: (state: any) => state.travel_time.direction,
      day_type: (state: any) => state.day_type,
      travel_time_filter: (state: any) => state.travel_time.filter.current_filter,
      saved_filter: (state: any) => state.travel_time.filter.saved_filter
    }),
    ...mapGetters("capucine_analysis", ["defaultTravelTimeFilter", "currentFilterModifState"]),
    filter_order() {
      if (this.local_filter) {
        return ["below_median", "above_median", "number_std"];
      } else {
        return [];
      }
    },
    stateBadgeColor() {
      return state_badge[this.currentFilterModifState].color;
    },
    stateBadgeIcon() {
      return state_badge[this.currentFilterModifState].icon;
    }
  },

  methods: {
    ...mapActions("capucine_analysis", ["setTravelTimeFilter", "saveTravelTimeFilter"]),
    resetFilter() {
      // set local filter with default filter value
      this.local_filter = JSON.parse(JSON.stringify(this.defaultTravelTimeFilter));
    },
    updateFilter() {
      this.setStoreFilter(this.local_filter);
    },
    saveFilter() {
      let promise = new Promise(resolve => {
        // create a save function
        let saveFilter = async () => {
          // signal saving (for loading display)
          this.saving = true;

          // check if the saved filter is different from the one stored
          let filter_changed = !isEqual(this.local_filter, this.travel_time_filter[this.travel_time_direction]);

          // save filter and update store filtered
          let success = await this.saveTravelTimeFilter(this.local_filter).finally(() => (this.saving = false));

          if (success) {
            if (filter_changed) {
              // if filter was changed, signal update
              this.signalFilterUpdate();
            } else {
              // otherwise, just close the dialog
              this.closeDialog();
            }

            resolve(true);
          }
        };

        if (this.saved_filter) {
          alert({
            message: "Une sauvegarde existe déjà, voulez vous l'écraser ?",
            action: {
              text: "oui",
              handler: saveFilter
            },
            type: "warning"
          });
        } else {
          saveFilter();
        }
      });
      return promise;
    },
    setStoreFilter(new_filter) {
      this.setTravelTimeFilter({ direction: this.travel_time_direction, filter: new_filter });
      this.signalFilterUpdate();
    },
    signalFilterUpdate() {
      this.$emit("update");
      this.closeDialog();
    },
    inferLocalFilter() {
      this.local_filter = JSON.parse(JSON.stringify(this.travel_time_filter[this.travel_time_direction]));
    },
    labelText(filter_key) {
      let local_value = this.local_filter[filter_key];
      let default_value = this.defaultTravelTimeFilter[filter_key];
      if (
        local_value.activate != default_value.activate ||
        (local_value.activate && local_value.value != default_value.value)
      ) {
        return `<b>${labels[filter_key]}</b>`;
      } else {
        return labels[filter_key];
      }
    },
    closeDialog() {
      this.dialog = false;
    }
  }
});
</script>

<style>
.tp-config-dialog {
  position: absolute;
  left: 10px;
  top: 40px;
}
</style>
