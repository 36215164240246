<!--
  Plots for flows 
-->

<template>
  <div>
    <td>
      <v-select
        v-model="plotAttribute"
        :items="attributes_items"
        :label="$t('flows.plots.category')"
        item-value="value"
        item-text="text"
        @change="plotInterval = 1"
      ></v-select>
    </td>
    <td>
      <v-text-field
        v-model="plotInterval"
        hide-details
        type="number"
        :label="$t('flows.plots.interval')"
        min="1"
        :disabled="plot.type != 'continuous' || !plotAttribute"
      ></v-text-field>
    </td>
    <td>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-n2 mb-n2 ml-3"
            color="primary"
            text
            @click="plotPercentage = !plotPercentage"
            :disabled="!plotAttribute"
            v-on="on"
          >
            <v-icon left> swap_horiz </v-icon> {{ "#/%" }}
          </v-btn>
        </template>
        <span v-if="plotPercentage">{{ $t("flows.plots.to_numbers") }}</span>
        <span v-if="!plotPercentage">{{ $t("flows.plots.to_percent") }}</span>
      </v-tooltip>
    </td>
    <Plotly
      :data="flowsPlot.data"
      :layout="flowsPlot.layout"
      id="plotly_custom"
      :responsive="true"
      :autosizable="true"
      :scrollZoom="false"
      :displaylogo="false"
      :displayModeBar="true"
      :mode-bar-buttons="buttons"
      :toImageButtonOptions="{
        height: 500,
        width: 700,
        filename: 'flows_graph',
        scale: 2
      }"
      v-if="plotAttribute"
    ></Plotly>
  </div>
</template>

<script>
import Vue from "vue";
import { Plotly } from "@wellcaffeinated/vue-plotly"; // https://github.com/David-Desmaisons/vue-plotly
import { mapGetters, mapState } from "vuex";
import { MODE_BAR_BUTTONS } from "@/global";
import { formatText } from "@/functions-tools";

export default Vue.component("flows-plots", {
  components: {
    Plotly
  },
  data: () => ({
    buttons: MODE_BAR_BUTTONS
  }),
  computed: {
    ...mapState("flows", ["plot"]),
    ...mapGetters("flows", ["flowsPlot", "getAttributes"]),
    plotAttribute: {
      get() {
        return this.plot.attribute;
      },
      set(value) {
        this.$store.commit("flows/SET_PLOT_ATTRIBUTE", value);
      }
    },
    plotInterval: {
      get() {
        return this.plot.interval;
      },
      set(value) {
        this.$store.commit("flows/SET_PLOT_INTERVAL", value);
      }
    },
    plotPercentage: {
      get() {
        return this.plot.percentage;
      },
      set(value) {
        this.$store.commit("flows/SET_PLOT_PERCENTAGE", value);
      }
    },
    attributes_items() {
      let attributes = this.getAttributes.map(attribute => {
        return { value: attribute, text: formatText(attribute) };
      });
      return [{ text: "Aucun", value: null }, ...attributes];
    }
  },
  methods: {}
});
</script>
