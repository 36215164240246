<!--
  Main application component
-->
<template>
  <v-app id="app">
    <Welcome v-if="overlay" @close="overlay = false" />
    <Capucine
      v-else-if="
        $whale.project._application == 'CAPUCINE_ANALYSIS' || $whale.project._application == 'CAPUCINE_SIMULATION'
      "
    />
    <Contemplative v-else-if="$whale.project._application == 'CONTEMPLATIVE'" />
    <Main v-else />
  </v-app>
</template>

<script lang="ts">
import Main from "@/pages/Main.vue";
import Welcome from "@/pages/Welcome.vue";
import Capucine from "@/pages/Capucine.vue";
import Contemplative from "@/pages/Contemplative.vue";

export default {
  name: "App",
  components: {
    Main,
    Welcome,
    Capucine,
    Contemplative
  },
  data: () => ({
    overlay: true
  })
};
</script>

<style scoped lang="css" src="./css/style_visualisation.css"></style>
<style>
.footer {
  color: white;
  text-align: center;
}
.v-overlay__scrim {
  background-color: #3d6482 !important;
}
.full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
