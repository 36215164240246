<!--
  Data table with production values (for both directions)
-->

<template>
  <kpi-card title="Production" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-row>
      <v-col md="4">
        <first-last-switch />
      </v-col>
      <v-col align="center" md="4">
        <capucine-table
          :headers="headers"
          :items="data"
          :loading="loading"
          card-width="30vh"
          :exportFileName="export_table_filename"
        >
        </capucine-table>
      </v-col>
    </v-row>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import KpiCard from "@/components/capucine/kpi_card.vue";
import FirstLastSwitch from "./first_last_switch.vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("production", {
  components: { KpiCard, FirstLastSwitch },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "production",
    headers: [
      { text: "Tranche horaire", value: "période", align: "left" },
      { text: "Production", value: "proportion", align: "right", format: "%" }
    ]
  }),
  computed: {
    raw_data() {
      if (this.first_last) {
        return this.$store.state.capucine_analysis.data.production_first_last_only;
      } else {
        return this.$store.state.capucine_analysis.data.production;
      }
    }
  }
});
</script>
