<!--
  Data tables with vehicle load per stop per direction
-->

<template>
  <kpi-card title="Charge" :suffix="surveyDate" :subtitle="subtitle">
    <v-row>
      <v-col align="center" md="6">
        <capucine-table
          :headers="headers['0']"
          :items="data['0']"
          :loading="loading"
          :title="oneWayName"
          :card-width="table_width"
          :indicator="kpi_name"
          :exportFileName="export_table_filename_with_context({ direction: '0' })"
        >
        </capucine-table>
      </v-col>
      <v-col align="center" md="6">
        <capucine-table
          :headers="headers['1']"
          :items="data['1']"
          :loading="loading"
          :title="returnName"
          :card-width="table_width"
          :indicator="kpi_name"
          :exportFileName="export_table_filename_with_context({ direction: '1' })"
        >
        </capucine-table>
      </v-col>
    </v-row>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { SUBTITLES, vehicleLoadChipColor } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("vehicle_load", {
  components: { KpiCard },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "vehicle_load"
  }),
  computed: {
    subtitle() {
      return SUBTITLES.vehicle_load.analysis + this.route.capacity + " passagers.";
    },
    headers() {
      return {
        0: this.getHeaders(this.sections, this.segmentsPairs, 0),
        1: this.getHeaders(this.sections, this.segmentsPairs, 1)
      };
    },
    table_width() {
      // change table width depending on number of headers
      const n_headers = this.headers[0].length;
      let table_width = "40vh";
      switch (n_headers) {
        case 2:
          table_width = "40vh";
          break;
        case 3:
          table_width = "50vh";
          break;
        case 4:
          table_width = "60vh";
          break;
        case 5:
          table_width = "70vh";
          break;
        default:
          table_width = "80vh";
      }
      return table_width;
    }
  },
  methods: {
    getHeaders(sections, segmentsPairs, direction) {
      if (direction == 1) {
        sections = [...sections].reverse();
      }
      let headers = sections.slice(0, sections.length - 1).map((element, index) => {
        return {
          text: segmentsPairs[direction][index],
          value: element,
          align: "left",
          color: vehicleLoadChipColor,
          format: "%"
        };
      });
      headers.unshift({ text: "Horaire", value: "horaire", align: "left" });
      headers.unshift({ text: "Période", value: "période", align: "left" });
      return headers;
    }
  }
});
</script>
