<template>
  <kite-add-dialog
    :dialog="dialog.add"
    :update-dialog="updateDialog"
    :button-text="$t('flows.manager.add.button')"
    :tabs="['file', 'project', 'database']"
    :tooltip-text="$t('flows.manager.add.tooltip')"
  >
    <template #database>
      <flow-from-database />
    </template>
    <template #file> <flow-from-file /> </template>
    <template #project> <flow-from-project /> </template>
  </kite-add-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import FlowFromFile from "./flow_from_file.vue";
import FlowFromDatabase from "./flow_from_database.vue";
import FlowFromProject from "./flow_from_project.vue";
import KiteAddDialog from "@/components/kite/base/kite_add_dialog.vue";

export default Vue.component("add-flow-dialog", {
  components: {
    KiteAddDialog,
    FlowFromDatabase,
    FlowFromFile,
    FlowFromProject
  },

  data: function () {
    return {};
  },

  computed: {
    ...mapState("flows", ["dialog"])
  },

  methods: {
    updateDialog(value) {
      this.$store.commit("flows/UPDATE_DIALOG", { dialog: "add", value });
    }
  }
});
</script>
