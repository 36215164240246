<template>
  <div>
    <v-card class="mt-n4" min-height="525">
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="scenario_list"
          :items-per-page="10"
          item-key="uuid"
          sort-by="_creationDate"
          :sort-desc="true"
          :loading="scenario_list.length == 0"
          :loading-text="$t('visualisation.loading')"
          :footer-props="{
            'items-per-page-text': $t('simulations.scenario_list.items_per_page'),
            'disable-items-per-page': true
          }"
        >
          <!-- scenario name -->
          <template v-slot:item.scenario_name="{ item }">
            {{ truncateString(item.scenario_name, 22) }}
          </template>
          <!-- scenario selection -->
          <template v-slot:item.selected="{ item }">
            <v-simple-checkbox
              :value="item.uuid == selectedScenario"
              color="black"
              on-icon="radio_button_checked"
              off-icon="radio_button_unchecked"
              :ripple="false"
              :disabled="!isSuccessful(item)"
              @click="scenarioSelection(item)"
            ></v-simple-checkbox>
          </template>
          <!-- display status in colored chip -->
          <template v-slot:item.status="{ item }">
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small :color="scenario_status_colors[item.status]" dark></v-chip>
              </template>
              <span>
                {{ $t("simulations.scenario_list.status." + item.status) }}
              </span>
            </v-tooltip>
          </template>
          <!-- date formatting -->
          <template v-slot:item._creationDate="{ item }">
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  new Date(item._creationDate).toLocaleString(language, { dateStyle: "short" })
                }}</span>
              </template>
              <span>
                {{ new Date(item._creationDate).toLocaleString(language, { dateStyle: "short", timeStyle: "short" }) }}
              </span>
            </v-tooltip>
          </template>
          <!-- scenario zoom -->
          <template v-slot:item.action="{ item }">
            <kite-table-action icon="zoom_in" @click="focused_scenario = item" tooltip="Information" color="primary" />
          </template>
          <template slot="no-data">{{ $t("simulations.scenario_list.empty") }}</template>
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} - {{ items.pageStop }} {{ $t("simulations.scenario_list.of") }}
            {{ items.itemsLength }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-expand-transition>
        <v-card
          v-if="focused_scenario"
          class="transition-fast-in-fast-out v-card--reveal overflow-auto"
          style="height: 100%"
        >
          <v-card-title class="pt-8">
            {{ truncateString(focused_scenario.scenario_name, 40) }}
            <v-spacer />
            <v-chip :color="scenario_status_colors[focused_scenario.status]" text-color="white">
              {{ $t("simulations.scenario_list.status." + focused_scenario.status) }}
            </v-chip>
            <v-btn @click="focused_scenario = null" icon class="hidden-xs-only ml-7">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            {{ $t(`simulations.use_cases.${current_service}.${focused_scenario.use_case}.title`) }} <br />
            <br />
            {{
              $t("simulations.scenario_list.date", {
                date: new Date(focused_scenario._creationDate).toLocaleString(language, { dateStyle: "short" }),
                hour: new Date(focused_scenario._creationDate).toLocaleString(language, { timeStyle: "short" })
              })
            }}
          </v-card-subtitle>
          <v-card-text v-html="focused_scenario.text"> </v-card-text>
          <v-card-text>
            <v-card>
              <stepper-recap-table
                :service="focused_scenario.service"
                :use-case="focused_scenario.use_case"
                :parameters="focused_scenario.parameters"
              />
            </v-card>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  icon
                  @click="scenarioSelection(focused_scenario)"
                  :disabled="!isSuccessful(focused_scenario) || focused_scenario.uuid == selectedScenario"
                  ><v-icon>mdi-play-outline</v-icon></v-btn
                >
              </template>
              <span> {{ "Visualiser" }} </span>
            </v-tooltip>
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" icon @click="duplicateScenario(focused_scenario)"
                  ><v-icon>content_copy</v-icon></v-btn
                >
              </template>
              <span> {{ $t("basic_dialogs.copy") }} </span>
            </v-tooltip>

            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="primary"
                  icon
                  @click="scenario_files = focused_scenario"
                  :disabled="!isFinished(focused_scenario)"
                  ><v-icon>mdi-cloud-download-outline</v-icon></v-btn
                >
              </template>
              <span> {{ $t("simulations.scenario_list.actions.download") }} </span>
            </v-tooltip>

            <v-spacer />
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" icon @click="deleted_scenario = focused_scenario">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("basic_dialogs.delete") }} </span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-card>
    <scenario-files :scenario="scenario_files" @closeDialog="scenario_files = undefined" />
    <v-snackbar absolute :value="deleted_scenario != null" timeout="-1" style="z-index: 20005">
      {{ $t("simulations.scenario_list.confirm_delete") }}
      <i>{{ deleted_scenario != null ? deleted_scenario.scenario_name : "" }}</i>
      <template v-slot:action="{ attrs }">
        <v-btn @click="deleted_scenario = null" text color="secondary"> {{ $t("boolean.false") }} </v-btn>
        <v-btn
          @click="
            deleteScenario(deleted_scenario);
            deleted_scenario = null;
            focused_scenario = null;
          "
          color="secondary"
        >
          {{ $t("boolean.true") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { truncateString } from "@/functions-tools";
import { USE_CASES, SCENARIO_STATUS_COLORS } from "@/simulation";
import StepperRecapTable from "../run/use_case_steppers/stepper_recap_table.vue";
import ScenarioFiles from "./scenario_files.vue";

export default Vue.component("scenario-list", {
  components: {
    StepperRecapTable,
    ScenarioFiles
  },

  data: function () {
    return {
      headers: [
        {
          text: "",
          value: "selected",
          // align: "start",
          sortable: false,
          align: "center",
          width: "10%"
        },
        {
          text: "Scénario",
          value: "scenario_name",
          // align: "start",
          sortable: true,
          width: "50%"
        },
        {
          text: "Statut",
          value: "status",
          // align: "start",
          sortable: false,
          align: "center"
        },
        {
          text: "Date",
          value: "_creationDate",
          // align: "start",
          sortable: true,
          align: "center"
        },
        {
          text: "",
          value: "action",
          // align: "start",
          sortable: false,
          align: "center"
        }
      ],
      scenario_status_colors: SCENARIO_STATUS_COLORS,
      scenario_files: undefined,
      deleted_scenario: null,
      focused_scenario: null,
      USE_CASES
    };
  },

  computed: {
    ...mapState("traces", ["run"]),
    ...mapState("simulations", ["current_service"]),
    ...mapGetters("simulations", ["current_service_scenarios"]),
    ...mapState("simulations", ["model_kpis", "selectedScenario"]),
    ...mapState("layers", ["map"]),
    ...mapState(["async", "language"]),
    scenario_list() {
      return this.current_service_scenarios.filter(scenario => scenario.service === this.current_service);
    }
  },

  methods: {
    ...mapActions("simulations", [
      "selectScenario",
      "unselectedScenario",
      "addModelKpi",
      "startUseCase",
      "changePage",
      "deleteScenario",
      "duplicateScenario"
    ]),
    truncateString,
    isFinished(scenarioItem) {
      return ["SUCCESS", "ERROR"].includes(scenarioItem.status);
    },
    isSuccessful(scenarioItem) {
      return scenarioItem.status == "SUCCESS";
    },
    scenarioSelection(item) {
      // unselect scenario if already selected
      if (item.uuid == this.selectedScenario) {
        this.unselectedScenario()
      } else {
        if (!this.isSuccessful(item)) {
          return;
        }
        this.selectScenario(item)
      }
    }
  }
});
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
