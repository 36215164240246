<!--
  Data table with vehicle load
-->

<template>
  <kpi-card title="Charge" :suffix="surveyDate" :subtitle="SUBTITLES.vehicle_load.results">
    <result-table
      :items="selectedData['vehicle_load']"
      :chipColor="vehicleLoadChipColor"
      :formats="{ actuel: '%', simulé: '%', écart: '-' }"
      indicator="vehicle_load"
      :width="50"
    />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES, vehicleLoadChipColor } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("vehicle-load", {
  components: {
    KpiCard,
    ResultTable
  },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "surveyDate"])
  },
  methods: {
    vehicleLoadChipColor
  }
});
</script>
