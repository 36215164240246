<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="10">
          <v-slider
            v-if="slider_mode"
            class="align-center mt-5"
            v-model.number="model"
            :step="sliderConstraints.step"
            thumb-label="always"
            :min="sliderConstraints.min"
            :max="sliderConstraints.max"
            :label="label"
            @change="update"
          />
          <v-text-field
            v-else
            v-model.number="model"
            type="number"
            @input="update"
            :rules="textFieldRules"
            :label="label"
            hide-spin-buttons
            hide-details
          />
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" class="mt-3" fab small @click="slider_mode = !slider_mode" color="primary">
                <v-icon>sync_alt</v-icon></v-btn
              >
            </template>
            <span>{{ $t("props_mapping.generic.change_input_mode") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.component("numeric-selector", {
  props: ["value", "sliderConstraints", "textFieldRules", "label"],
  data() {
    return {
      slider_mode: true
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    update() {
      this.$emit("change");
    }
  }
});
</script>
