<!--
  Changed log
-->
<template>
  <div>
    <v-dialog :value="dialog" max-width="700px" scrollable @click:outside="closeDialog">
      <v-card max-height="500px">
        <v-card-title class="headline grey lighten-2"
          >{{ $t("about.button") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text class="text--primary">
          <p>{{ $t("welcome.update") }}: {{ version }} - {{ buildDate.toLocaleString() }}</p>
          <p>
            <span v-html="$t('about.description')"></span>
          </p>
          <p>
            Contact: <a :href="`mailto:${KITE_CONTACT}`"> {{ KITE_CONTACT }} </a>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { KITE_CONTACT } from "@/global";

export default Vue.component("about", {
  components: {},

  props: ["dialog"],

  computed: {
    ...mapState(["buildDate", "version"])
  },
  data: function () {
    return {
      reverse: false,
      KITE_CONTACT
    };
  },
  watch: {
    dialog(value) {
      this.dialog = value;
    }
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    }
  }
});
</script>
