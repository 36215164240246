import { render, staticRenderFns } from "./flows_page.vue?vue&type=template&id=52f327dc"
import script from "./flows_page.vue?vue&type=script&lang=ts"
export * from "./flows_page.vue?vue&type=script&lang=ts"
import style0 from "./flows_page.vue?vue&type=style&index=0&id=52f327dc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-22685427/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports