<!--
  Headway adjustment
-->

<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col md="6">
          <capucine-excel-param
            :items="headway_params[direction_param]"
            :headers="currentHeader"
            title="Ajustement des intervalles (en minutes)"
            param="headway_params"
          />
        </v-col>
        <v-col md="3">
          <capucine-excel-param
            :items="capacity_param"
            :headers="capacity_param_headers"
            title="Capacité du véhicule"
            param="capacity_param"
          />
          <v-card-text />
          <v-text-field
            v-model="neutralisation_param"
            suffix="%"
            type="number"
            hide-spin-buttons
            label="Taux de neutralisation des avances"
            filled
            :rules="[v => 0 <= v && v <= 100]"
            @change="watchNeutralisationParameter"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import CapucineExcelParam from "./capucine_excel_param.vue";

function assertPositive(value) {
  let int_val = parseFloat(value);
  if (value < 0) {
    return "Valeur positive requise";
  }
  return "";
}

export default Vue.component("headway-param", {
  components: { CapucineExcelParam },
  data: () => ({
    capacity_param_headers: [
      {
        text: "Capacité actuelle",
        value: "theoretical",
        type: "number",
        readonly: true
      },
      {
        text: "Capacité ajustée",
        value: "adjusted",
        type: "number",
        validate: assertPositive,
        "to-value": parseInt
      }
    ]
  }),
  computed: {
    ...mapState("capucine_simulation", ["headway_params", "capacity_param", "direction_param", "first_last"]),
    ...mapGetters("capucine_simulation", ["segmentsPairs", "sections"]),
    headers() {
      let headers = {
        0: this.getHeaders(this.sections, this.segmentsPairs, 0),
        1: this.getHeaders(this.sections, this.segmentsPairs, 1)
      };
      return headers;
    },
    currentHeader() {
      return this.headers[this.direction_param];
    },
    neutralisation_param: {
      get() {
        return this.$store.state.capucine_simulation.neutralisation_param;
      },
      set(value: string) {
        this.setSimulationParameters({ neutralisation_param: parseFloat(value) });
      }
    }
  },
  methods: {
    ...mapActions("capucine_simulation", ["setSimulationParameters", "watchNeutralisationParameter"]),
    getHeaders(sections, segmentsPairs, direction) {
      if (direction == 1) {
        sections = [...sections].reverse();
      }
      let headers;
      if (this.first_last) {
        headers = [
          {
            text: "Minimum théorique",
            value: "minimum_theoretical",
            type: "number",
            readonly: true,
            width: "90px"
          },
          {
            text: "Minimum ajusté",
            value: "minimum_adjusted",
            type: "number",
            validate: this.assertPositive,
            width: "90px",
            mandatory: "Cette valeur est obligatoire",
            "to-value": parseFloat
          }
        ];
      } else {
        headers = sections.slice(0, sections.length - 1).map((element, index) => {
          return [
            {
              text: segmentsPairs[direction][index] + " Théorique",
              value: element + "_theoretical",
              type: "number",
              readonly: true,
              width: "90px"
            },
            {
              text: segmentsPairs[direction][index] + " Ajusté",
              value: element + "_adjusted",
              type: "number",
              validate: this.assertPositive,
              width: "90px",
              mandatory: "Cette valeur est obligatoire",
              "to-value": parseFloat
            }
          ];
        });
      }
      headers.unshift({
        text: "Tranche horaire",
        value: "période",
        readonly: true,
        width: "120px"
      });
      headers = headers.flat();
      return headers;
    }
  }
});
</script>
