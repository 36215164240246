<!--
  Div showing selected agent planning in a table
  All to select another agent by its type and name
-->
<template>
  <div id="agent_planning_div" v-show="display">
    <v-card class="pa-0" style="background: rgba(255, 255, 255, 0.8)">
      <v-card-title>{{ $t("trace.follow_dialog.title") }}</v-card-title>
      <v-card-text>
        <h3>{{ $t("trace.follow_dialog.selection") }}</h3>
        <v-list-item>
          <v-select
            class="mb-n2 ml-3"
            :items="agentTypeList"
            v-model="selectedAgentTypeLocal"
            :label="$t('trace.follow_dialog.agent_type')"
            @change="selectedAgent = null"
          ></v-select>
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            class="mt-n4 mb-n2 ml-3"
            :items="listAgents"
            v-model="selectedAgent"
            :search-input.sync="searchAgent"
            :label="$t('trace.follow_dialog.agent_id')"
          ></v-autocomplete>
        </v-list-item>
        <h3>{{ $t("trace.follow_dialog.agent") }} {{ selectedAgent }} ({{ selectedAgentTypeLocal }})</h3>
        <kite-table-old
          :headers="tabheaders.agent_planning"
          :headerLangText="$t('trace.follow_dialog.headers')"
          :items="agentPlanning"
          :noDataMessage="$t('trace.follow_dialog.no_data')"
        ></kite-table-old>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { TABHEADERS } from "@/global";
import { sec2hourformat } from "@/functions-tools";
import { zoomToSelectedAgent, agentTypeIds } from "@/components/kite/trace/trace";
import KiteTableOld from "@/components/kite/base/kite_table_old.vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("agent-planning", {
  components: {
    KiteTableOld
  },

  props: ["map"],

  data: function () {
    return {
      searchAgent: null,
      tabheaders: TABHEADERS,
      agentPlanning: [],
      agent_planning_page: 1,
      pageCount1: null,
      selectedAgentTypeLocal: null
    };
  },
  computed: {
    ...mapState("traces", ["agentTypeList", "checkFollowAgent", "currentClock", "mobileFeatures", "pos", "dur"]),
    listAgents() {
      if (this.selectedAgentTypeLocal == null || this.selectedAgentTypeLocal.length == 0) {
        return [];
      } else {
        return agentTypeIds(this.mobileFeatures, this.selectedAgentTypeLocal);
      }
    },
    display() {
      return this.checkFollowAgent;
    },
    selectedAgent: {
      get() {
        return this.$store.state.traces.selectedAgent;
      },
      set(newValue) {
        this.$store.commit("traces/SET_SELECTED_AGENT", newValue);
      }
    }
  },

  watch: {
    display: function () {
      if (!this.display) {
        this.selectedAgentTypeLocal = null;
        this.selectedAgent = null;
      }
    },
    agentTypeList: function () {
      this.selectedAgentTypeLocal = null;
      // this set was muted because it was called whenever using Vue Devtools
      // this.selectedAgent = null;
    },
    selectedAgent: function () {
      this.agentPlanning = this.computeAgentPlanning(this.selectedAgent);
    }
  },
  methods: {
    // zoom to a selected agent by id
    computeAgentPlanning(agentId) {
      if (agentId == null) {
        return [];
      }

      let { duration, position } = zoomToSelectedAgent(
        agentId,
        this.mobileFeatures,
        this.map,
        this.currentClock,
        0,
        this.pos,
        this.dur
      );

      // get agent planning
      try {
        return this.evaluatePlanningFromActivity(agentId);
      } catch (e) {
        return this.evaluatePlanningFromPositions(position, duration);
      }
    },
    zoomToAgent(agentId) {
      //this.$emit("handle_update_agentid", agentId);

      // TODO : set selectedAgent to null when selectors are reset (eg new trace upload)
      this.$store.commit("traces/SET_SELECTED_AGENT", agentId);

      let { duration, position } = zoomToSelectedAgent(
        agentId,
        this.mobileFeatures,
        this.map,
        this.currentClock,
        0,
        this.pos,
        this.dur
      );

      // get agent planning
      try {
        this.agentPlanning = this.evaluatePlanningFromActivity(agentId);
      } catch (e) {
        this.agentPlanning = this.evaluatePlanningFromPositions(position, duration);
      }
    },
    evaluatePlanningFromActivity(agentId) {
      let agentPlanning = [];
      let user_data = this.mobileFeatures.filter(d => d.properties.agent_id === agentId);
      let user_information = user_data[0].properties.information;
      if (!("activity" in user_information)) {
        throw new Error("No activity property");
      }
      let activity_values = user_information.activity.values;
      let activity_timestamps = user_information.activity.timestamps;
      let current_activity = 0;

      for (let i = 0; i < activity_values.length; i++) {
        let next_activity = activity_values[i];
        if (next_activity != current_activity) {
          if (next_activity == 1) {
            agentPlanning.push({
              position: 0,
              timestamp: 0,
              hour: sec2hourformat(activity_timestamps[i]),
              status: "Début de mouvement"
            });
          } else {
            agentPlanning.push({
              position: 0,
              timestamp: 0,
              hour: sec2hourformat(activity_timestamps[i]),
              status: "Fin de mouvement"
            });
          }
        }
        current_activity = next_activity;
      }
      return agentPlanning;
    },
    evaluatePlanningFromPositions(position, duration) {
      let agentPlanning = [];

      let str_positions = position.map(arr => {
        return arr[0].toString() + ", " + arr[1].toString();
      });
      let unique_positions = [...new Set(str_positions)];

      if (unique_positions.length == 1) {
        return [];
      }

      agentPlanning.push({
        position: position[0],
        timestamp: duration[0],
        hour: sec2hourformat(duration[0]),
        status: "Position initiale"
      });
      for (let t = 0; t < position.length; t++) {
        let currPosition = position[t];
        let status = "";
        if (currPosition[0] === position[0][0] && currPosition[1] === position[0][1] && duration[t] > 0) {
          if (duration[t] > agentPlanning[agentPlanning.length - 1].timestamp) {
            status = "Début de mouvement";
          }
        } else if (t < position.length - 1 && duration[t] < 99999) {
          if (duration[t + 1] > 99990) {
            status = "Fin de mouvement";
          }
        }
        let filtre_status = ["Début de mouvement", "Fin de mouvement"];
        if (filtre_status.indexOf(status) > -1) {
          agentPlanning.push({
            position: position[t],
            timestamp: duration[t],
            hour: sec2hourformat(duration[t]),
            status: status
          });
        }
      }
      agentPlanning = agentPlanning.filter(v => v.status !== "Position initiale");
      return agentPlanning;
    }
  }
});
</script>
