<template>
  <div>
    <!-- aggregation configuration -->
    <div>
      <v-form ref="agg_form" v-model="configIsValid">
        <slot :attributes="getAttributes" />
      </v-form>
    </div>
    <!-- attributes selection -->
    <div v-if="!hideAttributes">
      <p>
        {{ $t("flows.aggregation.select_attributes") }}
      </p>
      <v-select
        prepend-icon="rule"
        v-model="selectedAttributes"
        :items="getAttributes"
        :label="$t('flows.aggregation.attributes_label')"
        multiple
      ></v-select>
    </div>
    <!-- validation and cancel actions -->
    <v-card-actions class="justify-end">
      <v-btn color="primary" text @click="cancel">
        {{ $t("basic_dialogs.cancel") }}
      </v-btn>
      <v-btn color="primary" :disabled="!configIsValid" text @click="validate">
        {{ $t("basic_dialogs.validate") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.component("base-aggregation", {
  props: {
    aggOptions: {
      type: Object,
      required: true
    },
    hideAttributes: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      selectedAttributes: [],
      configIsValid: true
    };
  },

  computed: {
    ...mapGetters("flows", ["getAttributes"]),
    isValid() {
      return true;
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    validate() {
      this.$emit("validate", {
        attributes: this.selectedAttributes,
        options: this.aggOptions
      });
      // this.selectedAttributes = [];
    }
  }
});
</script>
