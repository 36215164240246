<template>
  <kite-add-network :title="$t('network.add.database.title')" :read-input-data="readInputData">
    <template v-slot:networkImport="{ inputState, newInput }">
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            :label="$t('network.add.database.network')"
            v-model="selected_pt_network"
            return-object
            :items="database_pt_networks"
            item-text="display_text"
            :loading="async.database_networks > 0"
            :no-data-text="$t('basic_dialogs.no_data')"
            @change="
              inputState.value = filteredGtfs.length == 0 ? null : filteredGtfs[0];
              newInput();
            "
          />
        </v-col>
        <v-col cols="6">
          <v-select
            :label="$t('network.add.database.dates')"
            v-model="inputState.value"
            item-value="uuid"
            return-object
            :items="filteredGtfs"
            :item-text="gtfsDisplayText"
            :disabled="!selected_pt_network"
            @change="newInput"
            :rules="[inputState.message]"
            :loading="async.database_networks > 0"
          />
        </v-col>
      </v-row>
    </template>
  </kite-add-network>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { dateListToItems } from "@/functions-tools";
import KiteAddNetwork from "./kite_add_network.vue";

export default Vue.component("network-from-database", {
  components: {
    KiteAddNetwork
  },

  data: function () {
    return {
      selected_pt_network: null
    };
  },

  computed: {
    ...mapState(["async", "language"]),
    ...mapState("network", ["database_gtfs"]),
    ...mapGetters("network", ["database_pt_networks"]),
    filteredGtfs() {
      if (!this.selected_pt_network) {
        return [];
      }
      let res = this.database_gtfs.filter(item => {
        return item.pt_network.uuid == this.selected_pt_network.uuid;
      });

      res = res.sort((a, b) => {
        let date_a = new Date(a.start_date);
        let date_b = new Date(b.start_date);

        if (date_a > date_b) {
          return -1;
        } else if (date_a < date_b) {
          return 1;
        } else {
          return 0;
        }
      });

      return res;
    }
  },
  methods: {
    ...mapActions("network", ["getDatabaseGtfs"]),

    async readInputData(input, setDataName) {
      let gtfs = input;
      setDataName(this.selected_pt_network.name);
      return gtfs;
    },
    gtfsDisplayText(gtfs) {
      return (
        new Date(gtfs.start_date).toLocaleString(this.language, { dateStyle: "short" }) +
        " - " +
        new Date(gtfs.end_date).toLocaleString(this.language, { dateStyle: "short" })
      );
    }
  }
});
</script>
