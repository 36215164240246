<!--
  Data tables with iso indicators
-->

<template>
  <kpi-card title="Indicateurs à moyen constant" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.iso_indicators">
    <v-card-actions>
      <v-row>
        <v-col cols="3">
          <direction-selector v-model="direction" prepend-icon="tune" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row>
      <v-col cols="6">
        <result-table
          title="Intervalles minimums à moyen constant"
          :items="selectedData['iso_headway'][direction]"
          :formats="{ actuel: '', simulé: '', écart: '-' }"
          :width="50"
        />
      </v-col>
      <v-col cols="6">
        <result-table
          title="Taux de charge à moyen constant"
          :items="selectedData['iso_vehicle_load'][direction]"
          :formats="{ actuel: '%', simulé: '%', écart: '-' }"
          :width="50"
        />
      </v-col>
    </v-row>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import DirectionSelector from "@/components/transports/direction_selector.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("iso-indicators", {
  components: {
    KpiCard,
    ResultTable,
    DirectionSelector
  },
  data: () => ({
    SUBTITLES,
    direction: "0"
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  }
});
</script>
