<template>
  <v-progress-circular
    :indeterminate="indeterminate"
    :rotate="-90"
    :width="3"
    :size="20"
    :value="progress"
    v-if="saving"
  ></v-progress-circular>
  <kite-table-action v-else icon="save" @click="save()" :tooltip="tooltip" v-bind="$attrs" />
</template>

<script>
import i18n from "@/plugins/lang";

export default {
  props: {
    tooltip: {
      type: String,
      default: function () {
        return i18n.t("basic_dialogs.save");
      }
    }
  },
  data() {
    return {
      saving: false,
      progress: 0,
      indeterminate: true
    };
  },
  methods: {
    updateProgress: function (p) {
      if (p >= 100) {
        this.saving = false;
      }
      this.indeterminate = false;
      this.progress = p;
    },
    save: function () {
      this.indeterminate = this.saving = true;
      this.progress = 0;
      this.$emit("click", p => {
        this.updateProgress(p);
      });
    }
  }
};
</script>
