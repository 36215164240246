<!--
  Apex chart with cumulative travel time
-->

<template>
  <kpi-card title="Temps cumulés" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-card-actions>
      <v-row>
        <v-col cols="3">
          <direction-selector v-model="direction" prepend-icon="tune" @change="updateChart" />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card elevation="0">
      <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
        <div class="d-flex flex-column align-center">
          <h1>Chargement</h1>
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </div>
      </v-overlay>
      <apexchart height="650" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { CHART_OPTIONS_BASE, CHART_OPTIONS_CUMULATIVE_TRAVEL_TIME } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("cumulative_travel_time", {
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "cumulative_travel_time",
    colors: ["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800"],
    series: [],
    chartOptions: Object.assign({}, CHART_OPTIONS_BASE, CHART_OPTIONS_CUMULATIVE_TRAVEL_TIME)
  }),
  mounted() {
    if (0 in this.data) {
      this.updateChart();
    }
  },
  watch: {
    data() {
      this.updateChart();
    }
  },
  computed: {},
  methods: {
    updateChart() {
      if (Object.keys(this.data["0"]).length > 0) {
        let series = this.series;
        series = [];
        let colors = [];
        let dashArray = [];
        let k = 0;
        let stops = [...this.sections];
        if (this.direction == 1) {
          stops.reverse();
        }
        for (let i = stops.length; i >= 0; i--) {
          if (this.data[this.direction]["réalisé"][stops[i]] !== undefined) {
            series.push({
              name: stops[0] + "→" + stops[i] + " Réalisé",
              type: "line",
              data: this.data[this.direction]["réalisé"][stops[i]]
            });
            series.push({
              name: stops[0] + "→" + stops[i] + " Théorique",
              type: "line",
              data: this.data[this.direction]["théorique"][stops[i]]
            });
            colors.push(this.colors[k]);
            dashArray.push(0);
            colors.push("#000000");
            dashArray.push(10);
            k = k + 1;
          }
        }
        let options = this.chartOptions;
        options["labels"] = this.data[this.direction]["départ"];
        options["colors"] = colors;
        options["stroke"]["dashArray"] = dashArray;
        this.$refs.chart.updateSeries(series);
        this.$refs.chart.updateOptions(options);
      }
    }
  }
});
</script>
