<!--
Table component with only two columns, label and value
-->
<template>
  <div>
    <v-row>
      <slot name="prependActions" :loading="global_loading" :fetch-data="fetchData" />
    </v-row>

    <v-card :width="tableWidth" class="mx-auto">
      <v-card-text v-if="title" class="text-center">
        <h4>{{ title }}</h4>
      </v-card-text>
      <v-divider />
      <v-card-actions style="height: 30px" class="pa-4">
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <download-excel :fetch="getData" :name="export_filename + '.xls'">
              <v-btn icon color="primary" :loading="global_loading"><v-icon v-on="on"> save </v-icon> </v-btn>
            </download-excel>
          </template>
          <span>Exporter</span>
        </v-tooltip>
      </v-card-actions>
      <v-divider />
      <v-data-table
        :loadingText="$t('basic_dialogs.loading')"
        :loading="global_loading"
        :headers="headers"
        :items="filtered_items"
        :hideDefaultFooter="true"
        sort-by="label"
        :items-per-page="-1"
      >
        <template v-slot:item.label="{ item }">
          {{ itemLabelFormat(item) }}
        </template>
        <template v-slot:item.value="{ item }">
          {{ itemValueFormat(item) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { formatTableDataWithHeaders } from "@/functions-tools";

export default Vue.component("info-table", {
  components: {},
  props: {
    // boolean triggering compute
    compute: {
      type: Boolean,
      required: true
    },
    // function used to retrieve raw data
    dataGetter: {
      type: Function,
      required: true
    },
    // info items with { label, value } format
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // table title
    title: {
      type: String
    },
    // items filter function
    filter: {
      type: Function
    },
    // formatting function for the labels column
    labelFormatting: {
      type: Function
    },
    // formatting function for the values column
    valueFormatting: {
      type: Function
    },
    // loading boolean
    loading: {
      type: Boolean
    },
    // label sorting function
    sort: {
      type: Function
    },
    tableWidth: {
      type: String
    }
  },
  data: function () {
    return {
      nb_calls: 0,
      data_loading: false,
      export_filename: "pt_line_info"
    };
  },
  watch: {
    compute: {
      handler(value) {
        if (value) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  computed: {
    headers() {
      // headers are simply label / value columns, sorted by labels
      return [
        { text: "Label", value: "label", sort: this.sort, width: "75%" },
        { text: "Valeur", value: "value", align: "right", sortable: false }
      ];
    },
    // filter items using data items
    filtered_items() {
      if (this.filter) {
        return this.items.filter(item => {
          return this.filter(item);
        });
      } else {
        return this.items;
      }
    },
    global_loading() {
      return this.loading || this.data_loading;
    }
  },

  methods: {
    // fetch raw data
    async fetchData() {
      // start fetch state
      this.nb_calls += 1;
      this.data_loading = true;
      this.$emit("fetching", true);

      let result;
      try {
        let calls_when_fetching = this.nb_calls;
        result = await this.dataGetter();
        if (this.nb_calls == calls_when_fetching) {
          this.$emit("data_update", result);
        } else {
          console.log("Call has been overriden, but there shouldn't be concurrent calls");
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.data_loading = false;
        this.$emit("fetching", false);
      }
    },
    // format label
    itemLabelFormat(item) {
      if (this.labelFormatting) {
        return this.labelFormatting(item);
      } else {
        return item.label;
      }
    },
    // format value
    itemValueFormat(item) {
      if (this.valueFormatting) {
        return this.valueFormatting(item);
      } else {
        return item.value;
      }
    },
    // get the formated version of the table data for a file export
    getData() {
      let export_data = JSON.parse(JSON.stringify(this.filtered_items));
      if (this.sort) {
        export_data = export_data.sort((a, b) => {
          return this.sort(a.label, b.label);
        });
      }
      return formatTableDataWithHeaders(this.headers, export_data, this.exportvalueFormat);
    },
    exportvalueFormat(item, value_key) {
      if (value_key == "label") {
        return this.itemLabelFormat(item);
      } else {
        return this.itemValueFormat(item);
      }
    }
  }
});
</script>
