<!--
  Timeline of geojson trace from Starling output
  Animate geojson trace
  Configuration of this animation
  Include clock component
-->
<template>
  <div id="trace_analysis_div">
    <clock :time="currentClockTime" :display="uxDisplay.animationCommands"></clock>
    <!-- div de la timeline -->
    <div id="timeline_div" v-show="uxDisplay.animationCommands">
      <v-card class="ma-0 pa-0" style="background: rgba(255, 255, 255, 0.8)">
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-n0 mb-n0" fab dark small v-on="on" color="primary" v-on:click="resumeRun" id="run">
                    <v-icon dark>{{ play_button }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("trace.timeline.run") }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="10" class="pa-2">
              <div
                class="ma-n2"
                id="timeline"
                @mousemove="handleMouseMove"
                @mouseleave="handleMouseOut"
                @mousedown="handleMouseClick"
              ></div>
            </v-col>
            <v-col cols="1">
              <v-row class="justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" :disabled="run" text v-bind="attrs" v-on="on" @click="dialog = true">
                      <v-icon dark>settings</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("trace.timeline.parameters") }}</span>
                </v-tooltip>
              </v-row>
              <v-row class="justify-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="uxDisplay.animationCommands = false"
                    >
                      <v-icon dark>close</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("basic_dialogs.close") }}</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="headline grey lighten-2">{{ $t("trace.timeline.dialog.title") }}</v-card-title>
        <v-card-text>
          <v-row justify="start">
            <v-col>
              <h3>{{ $t("trace.timeline.dialog.speed") }}</h3>
              <v-text-field
                v-model="animSpeed"
                class="mr-n2 mb-n2"
                type="number"
                style="width: 100px"
                :disabled="run"
              ></v-text-field>
              <h3>{{ $t("trace.timeline.dialog.attribute") }}</h3>
              <v-select
                class="mr-n2 mb-n2"
                :items="movingAttributes"
                :value="selectedMovingAttribute"
                v-on:change="updateMovingAttribute"
                style="width: 100px"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import * as d3 from "d3";
import Clock from "./clock.vue";
import {
  pos2time,
  newPosition2,
  updateMovingPoints2,
  zoomToSelectedAgent,
  updateShowIDagents
} from "@/components/kite/trace/trace";

import { mapState, mapActions } from "vuex";

export default Vue.component("timeline", {
  components: {
    Clock
  },

  props: ["map"],

  data: function () {
    return {
      anim: null,
      animationStart: null,
      lastRefreshTimestamp: null,
      refreshRate: Math.round(1000 / 30),
      compareUnit: null,
      dialog: false,

      // timeline design

      // timeline start hour
      heureStart: 0,
      // number of hours in timeline
      nbTimeBar: 30,
      // timeline width in pixel,
      objectWidth: 800,
      // timeline bar height in pixel
      barHeight: 30,
      // left position of the timeline in pixel
      timeLineStart: 20
    };
  },
  computed: {
    ...mapState(["uxDisplay"]),
    ...mapState("traces", [
      "selectedAgent",
      "movingAttributes",
      "selectedMovingAttribute",
      "showMovingPoints",
      "showStoppedPoints",
      "displayOptions",
      "checkFollowAgent",
      "currentClock",
      "run",
      "pos",
      "dur",
      "mobileFeatures",
      "movingFeatures",
      "stoppedFeatures"
    ]),
    animSpeed: {
      get() {
        return this.$store.state.traces.animSpeed;
      },
      set(newValue) {
        this.$store.commit("traces/SET_ANIM_SPEED", newValue);
      }
    },
    play_button() {
      if (this.run) {
        return "pause";
      } else {
        return "play_arrow";
      }
    },
    currentClockTime() {
      // compute new clock time according to position x
      let current_clock_time = pos2time(this.x, this.timeLineStart, this.hourWidth, this.heureStart);
      // update the time indicator text
      d3.select("text#simulationStateTxt").text(current_clock_time);
      // set new clock time
      return current_clock_time;
    },
    x() {
      // compute new value of x
      let new_x = this.timeLineStart + (this.currentClock / 1000 / 3600 - this.heureStart) * this.hourWidth;
      // translate the time indicator line
      d3.select("g#simulationStateG").attr("transform", "translate(" + new_x + ", 0)");
      // set the new x
      return new_x;
    },
    hourWidth() {
      return (this.objectWidth - this.timeLineStart) / this.nbTimeBar;
    }
  },
  mounted() {
    this.drawTimeLine();
    if (this.mobileFeatures.length > 0) {
      this.restartRun();
    }
  },
  methods: {
    ...mapActions("traces", ["updateMovingPoints", "updateStoppedPoints"]),
    restartRun(startMicroSecond = 0) {
      this.stopAnimation();

      // reset the current clock and the state line position
      let restart_clock = startMicroSecond;
      this.updateCurrentClock(restart_clock);

      // réinitialisation de la position des points
      this.newStoppedMovingPoints();
      this.newMovingPoints();
    },
    // action on play / pause button
    resumeRun() {
      if (!this.run) {
        this.$store.commit("traces/SET_ANIM_RUN", true);

        this.compareUnit = Math.floor(this.getCurrentTime() / this.displayOptions.stoppedPointRefresh) + 1;
        // Start the animation.
        this.animationStart = null;
        this.anim = requestAnimationFrame(this.animateMarker);
      } else {
        this.stopAnimation();
      }
    },
    animateMarker(timestamp) {
      // if this is the first animation, evaluate a reference timestamp
      if (this.animationStart == null) {
        this.animationStart = timestamp - (this.getCurrentTime() * 1000) / this.animSpeed;
        this.lastRefreshTimestamp = timestamp;
      }

      // evaluate the time since the last frame refresh
      let refreshWait = timestamp - this.lastRefreshTimestamp;
      let vm = this;

      // if the refresh wait was under refresh rate, call for the next frame
      if (refreshWait < this.refreshRate) {
        vm.anim = requestAnimationFrame(vm.animateMarker);
        // otherwise, update the trace animation
      } else {
        // virtual time (in ms) ellapsed since animationStart
        let indice = (timestamp - this.animationStart) * this.animSpeed;
        // new virtual time (in ms)
        let current_clock = indice;
        this.updateCurrentClock(current_clock);

        // if in a new update range, update the stopped moving points
        let currentHour = Math.floor(indice / 1000 / this.displayOptions.stoppedPointRefresh);
        if (currentHour === vm.compareUnit) {
          console.log("update moving objects : current time periode => " + currentHour);
          this.newStoppedMovingPoints();
          vm.compareUnit = vm.compareUnit + 1;
        }

        // update the moving points according to the current time
        this.newMovingPoints();

        // request next frame
        this.lastRefreshTimestamp = timestamp;
        vm.anim = requestAnimationFrame(vm.animateMarker);
      }
    },
    // update attributes and cancel the animation refresh
    stopAnimation() {
      this.$store.commit("traces/SET_ANIM_RUN", false);
      cancelAnimationFrame(this.anim);
    },
    // a single method that updates both the current clock
    // and the position of the time indicator
    updateCurrentClock(new_clock) {
      // update currentClock attribute
      this.$store.commit("traces/SET_CLOCK", new_clock);
    },
    handleMouseMove(evt) {
      let vm = this;
      let e = evt || window.event;
      let x = e.offsetX; // Wonderfully
      // déplacement de la sélection
      d3.select("g#selectState").attr("transform", "translate(" + x + ", 0)");
      this.updateSelectCursorVisibility(true);
      d3.select("text#selectStateTxt").text(pos2time(x, vm.timeLineStart, vm.hourWidth, vm.heureStart));
    },
    // action à la sortie de la timeline
    handleMouseOut() {
      this.updateSelectCursorVisibility(false);
    },
    // action au click sur la timeline
    handleMouseClick(evt) {
      let vm = this;
      this.stopAnimation();

      // get the new clock from the clicked position
      let e = evt || window.event;
      let new_x = e.offsetX;
      let new_current_time = ((new_x - this.timeLineStart) / this.hourWidth + this.heureStart) * 3600;
      let new_clock = 1000 * new_current_time;
      // update the clock
      this.updateCurrentClock(new_clock);

      // make the select line disappear
      this.updateSelectCursorVisibility(false);

      // update points
      this.newStoppedMovingPoints();
      this.newMovingPoints();

      if (vm.checkFollowAgent) {
        vm.zoomToAgentTimeline(vm.selectedAgent);
      }
    },
    // evaluation of which objects are stopped or not
    updateStoppedMovingPoints(time) {
      let { movingFeatures, stoppedFeatures } = updateMovingPoints2(
        time,
        this.mobileFeatures,
        this.pos,
        this.displayOptions.useStaticLayer
      );
      this.$store.commit("traces/SET_MOVING_FEATURES", movingFeatures);
      this.updateStoppedPoints(stoppedFeatures);
    },
    // update and draw stopped moving points
    newStoppedMovingPoints() {
      let vm = this;

      let currTime = this.getCurrentTime();

      // evaluate stopped moving points
      this.updateStoppedMovingPoints(currTime);

      this.updateMovingAttribute();
    },
    // update and draw moving points
    newMovingPoints() {
      let vm = this;

      // interpolation of the new positions
      let data = newPosition2(
        1,
        vm.movingFeatures,
        vm.pos,
        vm.dur,
        0,
        vm.currentClock,
        vm.map,
        vm.selectedMovingAttribute,
        vm.checkFollowAgent,
        vm.selectedAgent,
        vm.displayOptions.activityShadow,
        vm.animSpeed
      );
      // update of the moving points' positions
      this.updateMovingPoints(data.moving_features);

      vm.previous = data.previous;
    },
    // https://github.com/fliteska/timeline-vuejs-d3js/blob/master/index.html
    drawTimeLine() {
      let vm = this;
      let data = [];
      for (let t = this.heureStart; t < Math.min(this.nbTimeBar, 24); t++) {
        data.push({ time: t });
      }
      for (let t = 0; t < this.nbTimeBar - (24 - this.heureStart); t++) {
        data.push({ time: t });
      }
      let svg = d3
        .select("div#timeline")
        .append("svg")
        .attr("width", this.objectWidth)
        .attr("height", this.barHeight + 30)
        .attr("id", "svgTimeline")
        .style("cursor", "pointer");
      let time = svg
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d, i) {
          return "translate(" + (i * vm.hourWidth + vm.timeLineStart) + ", 0)";
        });
      time
        .append("line")
        .attr("x1", "0")
        .attr("x2", "0")
        .attr("y1", "0")
        .attr("y2", this.barHeight)
        .attr("stroke-width", 2)
        .attr("stroke", "#3d6482");
      time
        .append("text")
        .attr("font-weight", "bold")
        .attr("text-anchor", "middle")
        .attr("y", this.barHeight + 15)
        .attr("x", "0")
        .attr("fill", "#3d6482")
        .attr("font-size", "12px")
        .text(function (d) {
          return ("0" + d.time).slice(-2) + "h"; // + ":00";
        });
      // position for current time
      // let d = new Date()
      // let currTime = d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds()
      let currTime = this.currentClock / 1000;
      let pos = this.timeLineStart + (currTime / 3600 - this.heureStart) * this.hourWidth;
      // dessin de la ligne à animer
      d3.select("svg#svgTimeline")
        .append("g")
        .attr("id", "simulationStateG")
        .attr("transform", "translate(" + pos + ", 0)");
      d3.select("g#simulationStateG")
        .append("line")
        .attr("x1", "0")
        .attr("x2", "0")
        .attr("y1", "0")
        .attr("y2", this.barHeight)
        .attr("stroke-width", 2)
        .attr("stroke", "#85c287")
        .attr("id", "simulationState");
      // text associé à la ligne à animer
      d3.select("g#simulationStateG")
        .append("text")
        .attr("font-weight", "bold")
        .attr("text-anchor", "start")
        .attr("x", "5")
        .attr("y", "15")
        .attr("fill", "#85c287")
        .attr("id", "simulationStateTxt")
        .attr("font-size", "12px")
        .text(this.currentClockTime);
      // groupe de sélection : ligne + texte
      d3.select("svg#svgTimeline").append("g").attr("id", "selectState").attr("opacity", "0");
      // dessin de la ligne de sélection
      d3.select("g#selectState")
        .append("line")
        .attr("x1", "0")
        .attr("x2", "0")
        .attr("y1", "0")
        .attr("y2", this.barHeight)
        .attr("stroke-width", 2)
        .attr("stroke", "#000000");
      // text associé à la ligne de sélection
      d3.select("g#selectState")
        .append("text")
        .attr("font-weight", "bold")
        .attr("text-anchor", "start")
        .attr("x", "5")
        .attr("y", "15")
        .attr("fill", "#000000")
        .attr("id", "selectStateTxt")
        .attr("font-size", "12px")
        .text("");
      // rectangle for functional click on firefox
      d3.select("svg#svgTimeline")
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", this.barHeight + 20)
        .attr("width", this.objectWidth)
        .attr("stroke-width", 0)
        .attr("stroke", "#FF0000")
        .attr("fill-opacity", 0);
    },
    // update the selection cursor visibilty
    updateSelectCursorVisibility(isVisible) {
      if (isVisible) {
        d3.select("g#selectState").attr("opacity", "0.5");
      } else {
        d3.select("g#selectState").attr("opacity", "0");
      }
    },
    // zoom to a selected agent by id
    zoomToAgentTimeline(agentId) {
      // get agent id if not known
      if (agentId === null) {
        let type = this.selectedAgentType;
        let agents = this.mobileFeatures.filter(d => d.properties.agent_type === type);
        agentId = agents[0].properties.agent_id;
        this.$store.commit("traces/SET_SELECTED_AGENT", agentId);
        this.selectedAgent = agentId;
      }
      this.updateMovingAttribute();
      zoomToSelectedAgent(agentId, this.mobileFeatures, this.map, this.currentClock, 0, this.pos, this.dur);
    },
    getCurrentTime() {
      let currTime = ((this.x - this.timeLineStart) / this.hourWidth + this.heureStart) * 3600;
      return currTime;
    },
    // show or hide agents ID
    updateMovingAttribute(selected_moving_attribute) {
      if (selected_moving_attribute !== undefined) {
        this.$store.commit("traces/SET_SELECTED_MOVING_ATTRIBUTE", selected_moving_attribute);
      }
      let { movingFeatures, stoppedFeatures } = updateShowIDagents(
        this.map,
        this.checkFollowAgent,
        this.selectedAgent,
        this.movingFeatures,
        this.stoppedFeatures,
        this.selectedMovingAttribute,
        this.getCurrentTime()
      );
      this.updateMovingPoints(movingFeatures);
      this.updateStoppedPoints(stoppedFeatures);
    }
  }
});
</script>
<style>
#clock_div {
  position: absolute;
  top: 1vh;
  left: 55%;
}
#timeline_div {
  position: relative;
  top: 80vh;
  left: 35vw;
  max-width: 1000px;
}
</style>
