<!--
  Capucine parameters as an Excel table

  Headers must contain the 'text' and 'value' keys (used as 'label' and 'field' in vue-excel-editor),
  and any other column prop from vue-excel-editor.
  Example: [{ text: 'Name', value: 'name', type: 'string' }, { text: 'Age', value: 'age', type: 'number' }]

  The table is refreshed by incrementing its key when the headers are updated, since it does not seem to be reactive.
-->

<template>
  <div>
    <h2 class="mb-1">{{ title }}</h2>
    <vue-excel-editor
      v-model="items"
      no-footer
      no-header-edit
      :key="key"
      @update="callUpdate"
      :readonly-style="readonly_cell"
    >
      <vue-excel-column
        v-for="(header, index) in headers"
        :key="100 * index"
        :label="header.text"
        :field="header.value"
        v-bind="header"
      />
    </vue-excel-editor>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions } from "vuex";
import VueExcelEditor from "vue-excel-editor";
Vue.use(VueExcelEditor);

export default Vue.component("capucine-excel-param", {
  props: ["headers", "items", "title", "update", "param"],
  data: () => ({
    key: 0,
    readonly_cell: { "background-color": "#b2dbb4 !important" }
  }),
  watch: {
    headers() {
      this.key++;
    }
  },
  methods: {
    ...mapActions("capucine_simulation", ["watchParameters"]),
    callUpdate() {
      this.watchParameters(this.param);
      if (this.update) {
        this.update();
      }
    }
  }
});
</script>

<style>
#systable thead th.first-col {
  z-index: 0;
  background-color: #86c388;
}
#systable thead th {
  background-color: #86c388;
}
#systable .first-col {
  background: #86c388;
}
</style>
