<!--
    Dialog window for uploading and reading files
-->

<template>
  <kite-drawer-content>
    <v-toolbar flat color="primary" dark>
      <v-btn :disabled="page == 'model_selection'" @click="clickPrevious" icon class="hidden-xs-only">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("simulations.pages." + page, { service_name: current_service_name }) }}</v-toolbar-title>
      <v-spacer />
      <div v-if="this.$whale.user.tellae && page == 'model_selection'">
        <menu-trace :map="map" type="button" icon="transfer_within_a_station" />
      </div>
    </v-toolbar>
    <v-tabs-items v-model="page" height="100%">
      <v-tab-item value="model_selection">
        <model-choice />
      </v-tab-item>
      <v-tab-item value="model_scenarios">
        <model-scenarios></model-scenarios>
      </v-tab-item>
      <v-tab-item value="use_case_selection">
        <model-action></model-action>
      </v-tab-item>
      <v-tab-item value="scenario_creation">
        <model-simulation></model-simulation>
      </v-tab-item>
    </v-tabs-items>
  </kite-drawer-content>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import KiteDrawerContent from "@/components/kite/base/kite_drawer_content.vue";
import ModelChoice from "./model_choice.vue";
import ModelAction from "./model_action.vue";
import ModelScenarios from "./model_scenarios.vue";
import ModelSimulation from "./model_simulation.vue";
import MenuTrace from "@/components/kite/trace/menu_trace";

export default Vue.component("simulation-page", {
  components: {
    KiteDrawerContent,
    ModelChoice,
    ModelAction,
    ModelScenarios,
    ModelSimulation,

    MenuTrace
  },
  mounted() {
    this.fetchScenario();
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("layers", ["map"]),
    ...mapState("simulations", ["current_service", "current_use_case", "page"]),
    ...mapGetters("simulations", ["current_service_name", "current_use_case_name"]),

    page: {
      get() {
        return this.$store.state.simulations.page;
      },
      set(value) {
        this.changePage(value);
      }
    }
  },
  methods: {
    ...mapActions("simulations", ["updateScenarioList", "changePage"]),

    async fetchScenario() {
      let vm = this;
      let success = await this.updateScenarioList();
      if (!success && window.location.href.startsWith("http://localhost")) {
        let message = this.$t("simulations.scenario_list.error");
        alert({ message, type: "error" });
      } else {
        const myInterval = setInterval(async function () {
          //console.log("INTERVAL??");
          if (vm.$store.state.simulations.page == "model_scenarios") {
            let success = await vm.updateScenarioList();
            if (!success) {
              let message = vm.$t("simulations.scenario_list.error");
              alert({ message, type: "error" });
              clearInterval(myInterval);
            }
          }
        }, 5000);
      }
    },
    clickPrevious() {
      if (this.page == "use_case_selection") {
        this.page = "model_scenarios";
      } else if (this.page == "model_scenarios") {
        this.page = "model_selection";
      } else if (this.page == "scenario_creation") {
        this.page = "use_case_selection";
      }
    }
  }
});
</script>
