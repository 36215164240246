<!-- 
Stepper for ff_vs new service use case 
-->

<template>
  <simulation-stepper>
    <template v-slot:environment>
      <v-form v-model="valid_step[STEPS.environment]">
        <v-row class="pb-1">
          <v-col md="6">
            <v-select
              v-model="parameters['city']"
              :items="cities"
              :rules="[mandatoryRule]"
              :label="getLabel('city')"
              required
              prepend-icon="mdi-city"
              @change="getNumberBikes()"
              return-object
            ></v-select>
          </v-col>
          <!--
          <v-col md="1">
            <v-select
              v-model="parameters['day_type']"
              :items="day_types"
              :rules="[v => !!v || $t('simulations.use_cases.SB_VS.sb_vs_service_reorganization.rules.day_type.empty')]"
              :label="$t('simulations.use_cases.SB_VS.sb_vs_service_reorganization.labels.day_type')"
              required
              prepend-icon="mdi-calendar"
              return-object
            ></v-select>
          </v-col>
          -->
          <v-col md="4">
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-x
              :nudge-right="10"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="parameters['date']"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="parameters['date']"
                locale="fr"
                min="2021-09-01"
                max="2021-12-31"
                color="primary"
                :events="functionEvents"
                elevation="3"
                :first-day-of-week="1"
                @change="getNumberBikes()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:demand>
      <v-form v-model="valid_step[STEPS.demand]">
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model="parameters['demand_percentage']"
              :rules="[mandatoryRule, ruleMaker(v => v >= 0 && v <= 1000, 'demand_percentage.range')]"
              type="number"
              :label="getLabel('demand_percentage')"
              required
              suffix="%"
              prepend-icon="mdi-label-percent-outline"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:offer>
      <v-form v-model="valid_step[STEPS.offer]">
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="parameters['number_bikes']"
              :rules="[mandatoryRule, positiveRule]"
              :loading="async.bikes > 0"
              :disabled="async.bikes > 0"
              type="number"
              :label="getLabel('number_bikes')"
              required
              prepend-icon="mdi-bike"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-select
              v-model="parameters['location']"
              :items="locations"
              :rules="[v => !!v || $t('simulations.use_cases.SB_VS.sb_vs_service_reorganization.rules.location.empty')]"
              :label="getLabel('location')"
              required
              prepend-icon="mdi-map-marker-outline"
              return-object
            ></v-select>
          </v-col>
          <v-col md="4">
            <v-checkbox
              v-model="parameters['relocation']"
              on-icon="done"
              off-icon="close"
              :label="getLabel('relocation')"
              required
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simulation-stepper>
</template>

<script lang="ts">
import Vue from "vue";
import StepperMixin from "./stepper_mixin.vue";
import i18n from "@/plugins/lang";
import { BANK_HOLIDAYS, HOLIDAYS } from "@/simulation";

export default Vue.component("sb-vs-service-reorganization", {
  mixins: [StepperMixin],
  data: () => ({
    cities: [
      { text: "Nantes", value: "nantes" },
      { text: "Cergy-Pontoise", value: "cergy-pontoise" },
      { text: "Rouen", value: "rouen" },
      { text: "Nancy", value: "nancy" },
      { text: "Marseille", value: "marseille" },
      { text: "Lyon", value: "lyon" },
      { text: "Créteil", value: "creteil" },
      { text: "Toulouse", value: "toulouse" },
      { text: "Mulhouse", value: "mulhouse" },
      { text: "Amiens", value: "amiens" },
      { text: "Besançon", value: "besancon" }
    ],
    // day_types: [
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.LAVHV"), value: "LAVHV" },
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.SAMHV"), value: "SAMHV" },
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.DIMFHV"), value: "DIMFHV" },
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.LAVVAC"), value: "LAVVAC" },
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.SAMVAC"), value: "SAMVAC" },
    //   { text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.day_types.DIMFVAC"), value: "DIMFVAC" }
    // ],
    locations: [
      {
        text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.locations.historic"),
        value: "historic"
      },
      {
        text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.locations.uniform"),
        value: "uniform"
      },
      {
        text: i18n.t("simulations.use_cases.SB_VS.sb_vs_service_reorganization.locations.weighted_by_population"),
        value: "weighted_by_population"
      }
    ]
  }),

  computed: {},

  methods: {
    async getNumberBikes() {
      if (this.parameters["city"] != null) {
        let res = await this.$whale.numberBikes(this.parameters["city"].value, this.parameters["date"]);
        this.parameters["number_bikes"] = res.number_bikes;
      }
    },
    functionEvents(date: string) {
      const colors = {
        holidays: "green",
        bank_holidays: "red"
      };

      if (HOLIDAYS.includes(date) && BANK_HOLIDAYS.includes(date)) {
        return [colors.holidays, colors.bank_holidays];
      } else if (HOLIDAYS.includes(date)) {
        return [colors.holidays];
      } else if (BANK_HOLIDAYS.includes(date)) {
        return [colors.bank_holidays];
      }
    }
  }
});
</script>
