<template>
  <kite-add-data
    :title="title"
    :read-input-data="readInputData"
    :new-data-name="newName"
    :evaluate-data-info="evaluateDataInfo"
    :new-config="newConfig"
    :config-validator="isConfigValid"
    :validate-data="validateData"
    :addData="addDataToKite"
    :close="closeDialog"
  >
    <template v-slot:import="scope">
      <slot name="networkImport" v-bind="scope" />
    </template>
  </kite-add-data>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { dateListToItems, parseDate } from "@/functions-tools";
import KiteAddData from "@/components/kite/base/kite_add_data.vue";
import { GtfsView } from "@/models";

export default Vue.component("kite-add-network", {
  components: {
    KiteAddData
  },

  props: ["title", "readInputData"],

  data: function () {
    return {};
  },

  computed: {
    ...mapState(["async", "language"])
  },

  methods: {
    ...mapActions("network", ["setInfoDialogContent"]),
    newName() {
      return "";
    },

    evaluateDataInfo(data) {
      if (data) {
        let stats = {};
        data.statistics.forEach(element => {
          stats[element.indicator] = element.value;
        });
        return stats;
      } else {
        return null;
      }
    },

    validateData(data) {
      let day_types = Object.keys(data.day_types);
      if (!data.day_types || Object.keys(data.day_types).length == 0) {
        throw new Error("No sample dates found for this GTFS");
      }
    },

    newConfig(data) {
      return null;
      // if (data) {
      //   let day_types = Object.keys(data.day_types).map(day_type => {
      //     let date_obj = new Date(data.day_types[day_type]);
      //     return {
      //       value: day_type,
      //       text: `${day_type} (${date_obj.toLocaleString(this.language, { dateStyle: "short" })})`
      //     };
      //   });
      //   return {
      //     available_day_types: day_types,
      //     day_type: day_types[0].value
      //   };
      // } else {
      //   return {
      //     available_day_types: [],
      //     day_type: null
      //   };
      // }
    },

    isConfigValid(config) {
      return true;
    },

    addDataToKite(data, name, config) {
      let network_view = new GtfsView(data, name);
      this.setInfoDialogContent(null);
      network_view.addToKite();
    },
    closeDialog() {
      this.$store.commit("network/UPDATE_DIALOG", { dialog: "add", value: false });
    }
  }
});
</script>
