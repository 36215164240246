<!--
  Control for selecting application language
-->
<template>
  <v-list-item link @click="updateLanguage">
    <v-list-item-icon>
      <v-icon>swap_horiz</v-icon>
    </v-list-item-icon>
    <v-list-item-title> {{ "FR/EN" }} </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "language",
  data() {
    return {
      langs: ["en", "fr"],
      index: 1
    };
  },
  methods: {
    updateLanguage() {
      this.index = (this.index + 1) % this.langs.length;
      this.$i18n.locale = this.langs[this.index];
      this.$store.state.language = this.$i18n.locale;
      // Update kite preferences for user
      this.$whale.user.kite.locale = this.$i18n.locale;
      this.$whale.updateUser(["kite"]);
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
</style>
