import i18n from "./plugins/lang";
import Ajv from "ajv";
import SCHEMA_TRACE from "./assets/schema_trace.json";
import SCHEMA_USERS from "./assets/schema_users.json";
import SCHEMA_FLOWS from "./assets/schema_flows.json";
import SCHEMA_LOCATIONS from "./assets/schema_locations.json";
import SCHEMA_NESTED from "./assets/schema_nested.json";
import SCHEMA_FEATURE_COLLECTION from "./assets/schemas/geojson/FeatureCollection.json";
import SCHEMA_ZONING from "./assets/schema_zoning.json";
import { FlowsView } from "@/models";
import { downloadData } from "@/io";

// ajv validation object
const ajv = new Ajv({ allowUnionTypes: true });

class ValidationError extends Error {
  schema: any;
  constructor(message: string, schema: any) {
    super(message);
    this.schema = schema;
  }
}

/**
 * Generic schema validation function
 * @param data data to validate
 * @param schema validation schema
 * @returns boolean indicating if data is valid
 */
function validateWithSchema(data: any, schema: any) {
  let validator = ajv.compile(schema);
  const valid = validator(data);
  if (!valid) {
    throw new ValidationError(errorMessage(validator), schema);
  }
}

/**
 * Create an error message from validator object.
 * @param validator ajv validator
 * @returns error message
 */
function errorMessage(validator) {
  let message = "";
  if (validator.errors.length > 0) {
    let error = validator.errors[0];
    if (error.keyword == "required") {
      message = i18n.t("schema.required") + ": " + error.params.missingProperty;
    } else if (error.keyword == "type") {
      message = error.instancePath.substring(1) + " " + i18n.t("schema.type") + " " + error.params.type;
    } else if (["minimum", "maximum"].includes(error.keyword)) {
      message =
        error.instancePath.substring(1) +
        " " +
        i18n.t("schema.constraint") +
        " " +
        error.params.comparison +
        error.params.limit;
    } else {
      message = error.instancePath.substring(1) + " " + error.message;
    }
  }

  return message;
}

// specific validation functions

/**
 * Iterate on all trace objects and check if their properties are valid
 */
function validateTrace(traceData: any) {
  for (let i = 0; i < traceData.features.length; i++) {
    validateWithSchema(traceData.features[i].properties, SCHEMA_TRACE);
  }
}

/**
 * Call relevant validation function regarding flow_view.type
 * @param flow_view
 */
function validateFlowView(flow_view: FlowsView) {
  let type = flow_view.type;
  let data = flow_view.data;
  if (type == "STARLING") {
    validateStarlingUsers(data);
  } else if (type == "FLOWMAP") {
    validateFlowmapDataFlows(data.flows);
    validateFlowmapDataLocations(data.locations);
  }
}

/**
 * Iterate on all users and check if user's properties are valid
 */
function validateStarlingUsers(rawStarlingData) {
  validateWithSchema(rawStarlingData, SCHEMA_USERS);
}

/*
 * Iterate on all rows and check if flows properties are valid
 */
function validateFlowmapDataFlows(rawFlows: Array<Object>) {
  for (let i = 0; i < rawFlows.length; i++) {
    validateWithSchema(rawFlows[i], SCHEMA_FLOWS);
  }
}

/**
 * Iterate on all rows and check if locations properties are valid
 */
function validateFlowmapDataLocations(rawLocations: Array<Object>) {
  for (let i = 0; i < rawLocations.length; i++) {
    validateWithSchema(rawLocations[i], SCHEMA_LOCATIONS);
  }
}

/**
 * Check if nested locations are valid
 */
function validateNestedLocations(rawLocations) {
  validateWithSchema(rawLocations, SCHEMA_NESTED);
}

function validateFeatureCollection(geojson) {
  validateWithSchema(geojson, SCHEMA_FEATURE_COLLECTION);
}

function validateZoningGeojson(geojson) {
  validateWithSchema(geojson, SCHEMA_ZONING);
}

// other validation procedures

/**
 * Check if one worksheet starts with 'flows' and one with 'locations'
 */
function validateFlowmapDataWorksheet(worksheet) {
  if (worksheet.SheetNames.length > 2) {
    throw new Error(<string>i18n.t("od.error.extra_worksheet"));
  }
  if (!worksheet.SheetNames[0].startsWith("flows") && !worksheet.SheetNames[1].startsWith("flows")) {
    throw new Error(i18n.t("od.error.worksheet_name") + " 'flows'");
  } else if (!worksheet.SheetNames[0].startsWith("locations") && !worksheet.SheetNames[1].startsWith("locations")) {
    throw new Error(i18n.t("od.error.worksheet_name") + " 'locations'");
  }
}

// view name rules

const name_max_size = 35;
const viewNameRegex = /^[a-zÀ-ú0-9 _-]+$/i; // deprecated
const filenameRegex = /^[0-9a-zA-ZÀ-ú\^\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~\_ ]+$/;

function nameRequired(value) {
  return !!value || i18n.t("add_dialog.errors.name_required").toString();
}

function nameLengthRule(value) {
  return value.length <= name_max_size || i18n.t("add_dialog.errors.name_length_error", { max_size: name_max_size });
}

// deprecated
function nameRegexRule(value) {
  return viewNameRegex.test(value) || i18n.t("add_dialog.errors.name_regex");
}

function filenameRegexRule(value) {
  return filenameRegex.test(value) || i18n.t("add_dialog.errors.name_regex");
}

// simple validation rules

function mandatoryRule(value) {
  return value === 0 || !!value || i18n.t("validation.mandatory");
}

function positiveRule(value) {
  return value > 0 || i18n.t("validation.strictly_positive");
}

function isNumber(value) {
  return !isNaN(parseFloat(value)) || i18n.t("validation.is_number");
}

function isBetween0And1(value) {
  return (value >= 0 && value <= 1) || i18n.t("validation.is_between_0_and_1");
}

function arrayNonEmpty(value) {
  return value.length > 0 || i18n.t("validation.non_empty_array");
}

export {
  ValidationError,
  validateWithSchema,
  validateFlowView,
  validateTrace,
  validateStarlingUsers,
  validateFlowmapDataFlows,
  validateFlowmapDataLocations,
  validateNestedLocations,
  validateFeatureCollection,
  validateFlowmapDataWorksheet,
  validateZoningGeojson,
  nameRequired,
  nameLengthRule,
  nameRegexRule,
  filenameRegexRule,
  name_max_size,
  mandatoryRule,
  positiveRule,
  isNumber,
  isBetween0And1,
  arrayNonEmpty
};
