<!--
  Data tables with robustness
-->

<template>
  <kpi-card title="Robustesse" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.robustness">
    <result-table
      :items="selectedData['robustness']"
      :chipColor="robustnessChipColor"
      :formats="{ actuel: '%', simulé: '%', écart: '-' }"
      indicator="robustness"
      :width="50"
    />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES, robustnessChipColor } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";

export default Vue.component("robustness", {
  components: { KpiCard },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  },
  methods: {
    robustnessChipColor
  }
});
</script>
