import Vue from "vue";
import Vuetify from "vuetify/lib";
import { COLORS } from "../global";
import SearchInsightsIcon from "@/components/icons/searchInsights.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      search_insights: {
        component: SearchInsightsIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: COLORS.primary,
        secondary: COLORS.secondary,
        accent: COLORS.primary,
        background: COLORS.primary
      },
      dark: {
        primary: COLORS.primary,
        secondary: COLORS.secondary,
        accent: COLORS.primary,
        background: COLORS.primary
      }
    },
    options: {
      // @ts-ignore
      themeVariations: ["primary", "secondary", "accent"],
      customProperties: true,
      extra: {
        mainToolbar: {
          color: "primary"
        },
        sideToolbar: {},
        sideNav: "primary",
        mainNav: "primary lighten-1",
        bodyBg: ""
      }
    }
  }
});
