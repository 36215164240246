<!--
  Dialog for selecting displayed section (start and end stops)
-->

<template>
  <v-dialog v-model="dialog" persistent :max-width="selection_dialog_width + 'px'" content-class="tp-config-dialog">
    <template #activator="{ on }">
      <v-btn
        large
        elevation="0"
        :loading="loading"
        color="primary"
        v-on="on"
        @click="inferLocalInterval()"
        class="pa-2 ma-2"
      >
        <v-icon class="mr-1">linear_scale</v-icon>
        Sélection du tronçon
      </v-btn>
    </template>
    <v-card>
      <kite-card-title
        title="Sélection des arrêts délimitant le ou les tronçons"
        tooltip="Choisissez les arrêts délimitant le ou les tronçons"
      ></kite-card-title>
      <v-card-text class="pa-8">
        <v-row>
          <v-col>
            <v-range-slider
              :tick-labels="stops_name_slider"
              v-model="interval_local"
              min="0"
              :max="sections.length - 1"
              ticks="always"
              tick-size="8"
              height="70px"
              :rules="[singleValueInterval]"
              @update:error="interval_error = $event"
            >
              <template v-slot:thumb-label="props">
                <v-icon dark> directions_bus </v-icon>
              </template>
            </v-range-slider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          {{ $t("basic_dialogs.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="interval_error"
          @click="
            updateSelectedSection();
            closeDialog();
          "
        >
          {{ $t("basic_dialogs.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.component("travel-time-section", {
  props: ["direction", "loading", "origin", "destination"],
  data: () => ({
    dialog: false,
    interval_local: [0, 1],
    interval_error: false
  }),
  computed: {
    ...mapGetters("capucine_analysis", ["sections", "sectionsNames"]),
    stops_slider() {
      if (this.direction == "0") {
        return this.sections;
      } else {
        return [...this.sections].reverse();
      }
    },
    stops_name_slider() {
      const max_length = 20;
      const names = this.sections.map(id => this.sectionsNames[id]);
      if (this.direction == "0") {
        return names.map(x => x.toString().substring(0, max_length));
      } else {
        return names.reverse().map(x => x.toString().substring(0, max_length));
      }
    },
    selection_dialog_width() {
      let size = Object.values(this.sectionsNames).length * 250;

      if (size < 650) {
        return 650;
      } else if (size > 1200) {
        return 1500;
      } else {
        return size;
      }
    }
  },

  methods: {
    updateSelectedSection() {
      let payload;
      if (this.direction == "0") {
        payload = {
          origin: this.stops_slider[this.interval_local[0]],
          destination: this.stops_slider[this.interval_local[1]]
        };
      } else {
        payload = {
          destination: this.stops_slider[this.interval_local[0]],
          origin: this.stops_slider[this.interval_local[1]]
        };
      }
      this.$emit("update", payload);
    },
    inferLocalInterval() {
      this.interval_local = [this.stops_slider.indexOf(this.origin), this.stops_slider.indexOf(this.destination)];
    },
    singleValueInterval(interval) {
      if (interval[0] == interval[1]) {
        return "Les arrêts doivent être distincts";
      } else {
        return true;
      }
    },
    closeDialog() {
      this.dialog = false;
    }
  }
});
</script>
