<!--
  Table containing network items
-->

<template>
  <v-card :max-height="tables_height" elevation="0" class="overflow-auto">
    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :no-data-text="$t('basic_dialogs.no_data')"
      disable-pagination
      hide-default-footer
      v-bind="$attrs"
      :loading="async.gtfsDraw > 0"
      show-select
    >
      <template v-slot:[`header.${header.value}`]="scope" v-for="header in headers">
        {{ $t(`basic_headers.${header.text}`) }}
      </template>
      <template v-slot:[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox v-bind="props" color="black" v-on="on" />
      </template>
      <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
        <v-simple-checkbox :value="isSelected" color="black" @input="select" />
      </template>
      <template v-slot:[`item.${header.value}`]="scope" v-for="header in headers">
        <slot :name="`item.${header.value}`" v-bind="scope">
          {{ scope.item[header.value] }}
        </slot>
      </template>
      <template v-slot:item.actions="{ item }">
        <kite-table-action @click="zoomOn(item)" icon="zoom_in" :tooltip="$t('basic_dialogs.zoom')" />
        <kite-table-action @click="setInfoDialogContent(item)" icon="open_in_new" :tooltip="$t('basic_dialogs.open')" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { zoomOnGeojson } from "@/functions-tools";

export default Vue.component("network-page", {
  props: ["value", "headers", "items"],

  data: function () {
    return {
      tables_height: "63vh"
    };
  },

  computed: {
    ...mapState(["async"]),
    ...mapState("layers", ["map"]),
    selectedItems: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("network", ["setInfoDialogContent"]),
    zoomOn(item) {
      zoomOnGeojson(this.map, item.geometry);
    }
  }
});
</script>
