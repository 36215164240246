/* network.store.js */
import { getInstance } from "@/api/index";
import i18n from "@/plugins/lang";
import { GtfsView } from "@/models";
import { sortFromList } from "@/functions-tools";

const GTFS_DAY_TYPES = [
  "LMaJV-HV",
  "Me-HV",
  "Sam-HV",
  "DimF-HV",
  "LMaJV-VAC",
  "Me-VAC",
  "Sam-VAC",
  "DimF-VAC",
  "LMaJV-ETE",
  "Me-ETE",
  "Sam-ETE",
  "DimF-ETE"
];

const initialState = () => ({
  dialog: {
    add: false
  },
  database_gtfs: [],
  networkViews: [],
  currentNetworkView: null,
  infoDialogContent: null,
  infoDayType: null,
  selectedRoutes: [],
  selectedStops: [],
  nbViews: 0
  /**
  networkPlotsData: {
    theoretical_travel_time: { 0: [], 1: [] },
    real_travel_time: { 0: [], 1: [] },
    headway: { 0: [], 1: [] },
    terminus: {
      0: { start_stop: null, end_stop: null },
      1: { start_stop: null, end_stop: null }
    }
  },
  plot: {
    series: [
      {
        name: "",
        type: "line",
        data: []
      }
    ],
    options: {
      title: {
        text: "",
        align: "left"
      },
      stroke: {
        width: 2
      },
      markers: {
        size: 3,
        colors: ["#000000", "#ff0000"]
      },
      colors: ["#000000", "#ff0000"],
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false
        }
      },
      legend: {
        y: 18
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          format: "HH:mm"
        }
      }
    }
  },
  */
});
const state = initialState();

export default {
  // State object
  namespaced: true,
  state,

  // Getter functions
  getters: {
    infoDialogContentType: state => {
      let content = state.infoDialogContent;
      if (!content) {
        return;
      } else if ("pt_network" in content) {
        return "gtfs";
      } else if ("route_id" in content) {
        return "route";
      } else if ("stop_id" in content) {
        return "stop";
      } else {
        throw new Error("Unknown network content type");
      }
    },
    // list of pt_networks from the database_gtfs list
    database_pt_networks: state => {
      // drop duplicates using the pt networks uuid
      let unique = [...new Map(state.database_gtfs.map(gtfs => [gtfs.pt_network.uuid, gtfs.pt_network])).values()];
      // add display_text attribute (moa and pt_network names)
      unique.forEach((pt_network: any) => {
        pt_network.display_text = `${pt_network.moa.name} (${pt_network.name})`;
      });
      // lexicographic sort
      unique = unique.sort((a: any, b: any) => {
        return a.display_text.localeCompare(b.display_text);
      });
      return unique;
    },
    dayTypes: state => {
      let day_types = state.currentNetworkView?.gtfs.day_types;
      if (!day_types) {
        return [];
      }
      let day_types_list = sortFromList(Object.keys(day_types), GTFS_DAY_TYPES).reverse();
      return day_types_list.map(day_type => {
        return {
          value: day_type,
          date: day_types[day_type],
          gtfs_date: day_types[day_type].replaceAll("-", "")
        };
      });
    },
    nbRoutes: state => {
      return state.currentNetworkView?.routes.length || 0;
    },
    nbStops: state => {
      return state.currentNetworkView?.stops.length || 0;
    }
  },
  // Actions
  actions: {
    async getDatabaseGtfs(context) {
      let whale = getInstance();
      let gtfs_list = await whale.getGtfsList();
      context.commit("SET_DATABASE_GTFS", gtfs_list);
    },
    removeNetworkView(context, network_view: GtfsView) {
      // remove view from list
      context.commit("REMOVE_NETWORK_VIEW", network_view);

      // case when selected network view is removed
      if (network_view.id == context.state.currentNetworkView.id) {
        context.dispatch("displayNetworkView", null);
      }
    },

    async displayNetworkView(context, network_view: GtfsView) {
      if (network_view != null) {
        // start async action
        context.dispatch("asyncStart", "gtfsDraw", { root: true });

        try {
          // set empty geojson to make layer visible in table while loading
          let empty_geojson = {
            type: "FeatureCollection",
            features: []
          };
          context.dispatch("layers/setLayersData", { ids: "gtfs-lines", data: empty_geojson }, { root: true });
          context.dispatch("layers/setLayersData", { ids: "gtfs-stops", data: empty_geojson }, { root: true });

          // fetch network data
          await network_view.fetch_gtfs_data();

          // setup route and stops layers
          let routes_features = network_view.routes.map(route => {
            let properties_copy = JSON.parse(JSON.stringify(route));
            let geometry_copy = properties_copy.geometry;
            delete properties_copy["geometry"];
            delete properties_copy["statistics"];

            return {
              type: "Feature",
              geometry: geometry_copy,
              properties: properties_copy
            };
          });

          let stops_features = network_view.stops.map(stop => {
            let properties_copy = JSON.parse(JSON.stringify(stop));
            let geometry_copy = properties_copy.geometry;
            delete properties_copy["geometry"];
            delete properties_copy["statistics"];

            return {
              type: "Feature",
              geometry: geometry_copy,
              properties: properties_copy
            };
          });

          context.dispatch(
            "layers/setLayersData",
            {
              ids: "gtfs-lines",
              data: {
                type: "FeatureCollection",
                features: routes_features.reverse()
              }
            },
            { root: true }
          );
          context.dispatch(
            "layers/setLayersData",
            {
              ids: "gtfs-stops",
              data: {
                type: "FeatureCollection",
                features: stops_features
              }
            },
            { root: true }
          );
          context.dispatch(
            "layers/setLayersVisibility",
            { ids: ["gtfs-lines", "gtfs-stops"], isVisible: true },
            { root: true }
          );

          // select all routes and stops
          context.commit("SET_SELECTED_ROUTES", network_view.routes);
          context.commit("SET_SELECTED_STOPS", network_view.stops);
        } catch (e) {
          console.log(e);
          let message = i18n.t("network.gtfs.error_loading");
          alert({ message, type: "error" });
        } finally {
          context.dispatch("asyncEnd", "gtfsDraw", { root: true });
        }
      } else {
        // setup route and stops layers
        context.dispatch("layers/setLayersData", { ids: ["gtfs-lines", "gtfs-stops"], data: null }, { root: true });
        context.dispatch(
          "layers/setLayersVisibility",
          { ids: ["gtfs-lines", "gtfs-stops"], isVisible: false },
          { root: true }
        );
      }

      // update selected view
      context.commit("SELECT_NETWORK_VIEW", network_view);
      console.log(context.getters.dayTypes);
      context.commit("SET_INFO_DAY_TYPE", context.getters.dayTypes[0]);
    },

    setInfoDialogContent(context, content) {
      context.commit("SET_INFO_DIALOG_CONTENT", content);
    },

    setSelectedRoutes(context, selected_routes) {
      // update state
      context.commit("SET_SELECTED_ROUTES", selected_routes);

      // update layer filter
      let filter;
      if (selected_routes.length == context.getters.nbRoutes) {
        filter = undefined;
      } else {
        filter = ["in", "route_id", ...selected_routes.map(route => route.route_id)];
      }
      context.rootGetters["layers/getLayer"]("gtfs-lines").setFilter(filter);
    },

    setSelectedStops(context, selected_stops) {
      // update state
      context.commit("SET_SELECTED_STOPS", selected_stops);

      // update layer filter
      let filter;
      if (selected_stops.length == context.getters.nbStops) {
        filter = undefined;
      } else {
        filter = ["in", "stop_id", ...selected_stops.map(stop => stop.stop_id)];
      }
      context.rootGetters["layers/getLayer"]("gtfs-stops").setFilter(filter);
    },
    // deprecated
    async addProjectGtfs(context, { data, filename, name, pt_network_uuid, dist_units }) {
      let whale = getInstance();
      // TODO check is a zip file at least
      let binary_info = whale.getBinaryInfoFromFile(data, filename, { metadata: { name } });

      let hash = binary_info.hash;

      let gtfs;
      // if gtfs exists and is public, signal and quit
      try {
        gtfs = whale.getGtfs(hash);
        if (gtfs.visibility == "public") {
          alert("This Gtfs is available in the public Gtfs database. Gtfs was not added to project files.");
          return;
        } else if (gtfs.visibility == "project") {
          alert("You have already added this Gtfs to your project");
        }
      } catch (e) {}

      // add gtfs to project
      await whale.addProjectFile("gtfs", data, filename, { metadata: { name } });

      // là il faut rechecker parce que maintenant on a les droits...

      gtfs = await whale.createProjectGtfs(hash, pt_network_uuid, dist_units);

      gtfs = await whale.fillProjectGtfs();

      if (gtfs.status != "READY") {
        throw new Error("An error occured while processing the Gtfs");
      }
      return gtfs;
    }
  },
  // Mutations
  mutations: {
    SET_DATABASE_GTFS(state, gtfs_list) {
      state.database_gtfs = gtfs_list;
    },
    UPDATE_DIALOG(state, { dialog, value }) {
      state.dialog[dialog] = value;
    },
    ADD_NETWORK_VIEW(state, added_view: GtfsView) {
      state.networkViews.push(added_view);
      state.nbViews += 1;
    },
    REMOVE_NETWORK_VIEW(state, removed_view: GtfsView) {
      state.networkViews = state.networkViews.filter(view => view !== removed_view);
    },
    SELECT_NETWORK_VIEW(state, network_view: GtfsView) {
      state.currentNetworkView = network_view;
    },
    SET_INFO_DIALOG_CONTENT(state, content) {
      state.infoDialogContent = content;
    },
    SET_INFO_DAY_TYPE(state, day_type_item) {
      console.log(day_type_item);
      state.infoDayType = day_type_item;
    },
    SET_SELECTED_ROUTES(state, selected_routes) {
      state.selectedRoutes = selected_routes;
    },
    SET_SELECTED_STOPS(state, selected_stops) {
      state.selectedStops = selected_stops;
    }
  }
};
