<!--
  Mixin containing data used by analysis KPIs  
-->

<script lang="ts">
import KpiCard from "@/components/capucine/kpi_card.vue";
import DirectionSelector from "@/components/transports/direction_selector.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";

export default {
  components: { KpiCard, DirectionSelector },
  data: function () {
    return {
      kpi_name: "",
      direction: "0",
      direction_text: {
        "0": "Aller",
        "1": "Retour"
      }
    };
  },
  watch: {
    raw_data(value) {
      if (value == null) {
        this.setTab("presentation");
      }
    }
  },

  computed: {
    ...mapState("capucine_analysis", ["lot", "day_type", "data_batch_id", "first_last", "tab"]),
    raw_data() {
      return this.$store.state.capucine_analysis.data[this.kpi_name];
    },
    data() {
      return this.raw_data || {};
    },
    loading() {
      return this.$store.state.capucine_analysis.loading[this.kpi_name];
    },
    ...mapGetters("capucine_analysis", [
      "route",
      "sections",
      "sectionsNames",
      "day_types",
      "segmentsPairs",
      "oneWayName",
      "returnName",
      "surveyDate",
      "titleSuffixWithDates"
    ]),
    subtitle() {
      return SUBTITLES[this.kpi_name];
    },
    context() {
      return {
        lot: this.lot.name,
        route: this.route.name,
        day_type: this.day_type,
        direction: this.direction_text[this.direction]
      };
    }
  },
  methods: {
    ...mapActions("capucine_analysis", ["setTab"]),
    export_table_filename() {
      return this.export_filename(this.kpi_name, this.context);
    },
    export_table_filename_with_context(ctx) {
      if ("direction" in ctx) {
        ctx.direction = this.direction_text[ctx.direction];
      }
      let enriched_ctx = {
        ...this.context,
        ...ctx
      };
      return () => {
        return this.export_filename(this.kpi_name, enriched_ctx);
      };
    },
    export_filename(kpi_name, context) {
      return (
        this.$t(`capucine.analysis.kpis.${kpi_name}.name`) +
        "_" +
        this.$t(`capucine.analysis.kpis.${kpi_name}.export_filename`, context)
      );
    }
  }
};
</script>
