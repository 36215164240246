<template>
  <div>
    <v-card-text class="justify-center">
      <v-autocomplete
        prepend-icon="chat_bubble_outline"
        :items="dataPropertiesItems"
        v-model="local_options.keys"
        :label="$t('props_mapping.tooltip.select_label')"
        :no-data-text="$t('props_mapping.tooltip.select_no_data')"
        multiple
        @change="update"
        :placeholder="$t('props_mapping.tooltip.select_placeholder')"
        persistent-placeholder
      >
      </v-autocomplete>
    </v-card-text>
  </div>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";

export default Vue.component("tooltip-mapping", {
  mixins: [LayerMappingMixin],

  data() {
    return {};
  },
  computed: {},
  methods: {}
});
</script>
