<template>
  <kite-map-action
    action="map_export"
    :title="$t('map_actions.map_export.title')"
    :get-result="exportMap"
    :on-open="init_map_export"
    :show-clear="false"
    :clear-content="onClose"
    :disable-validation="!valid"
    :width="430"
  >
    <v-form v-model="valid">
      <v-card-text>
        <!-- Format selection -->
        <v-row>
          <v-col>
            <v-select
              v-model="export_format"
              :label="$t('basic_dialogs.format')"
              :items="available_formats"
              :item-text="item => $t(`map_actions.map_export.formats.${item.value}`)"
              prepend-icon="summarize"
            />
          </v-col>
        </v-row>
        <!-- Export filename field -->
        <v-row>
          <v-col>
            <v-text-field
              prepend-icon="save_as"
              v-model="filename"
              :label="$t('basic_dialogs.filename')"
              autofocus
              :suffix="EXPORT_EXTENSIONS[export_format]"
              :rules="[nameRequired, filenameRegexRule]"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Report title -->
        <v-row v-if="export_format.endsWith('report')">
          <v-col>
            <v-text-field
              prepend-icon="title"
              v-model="title"
              :label="$t('map_actions.map_export.map_title')"
              :rules="[titleLengthRule]"
              :counter="title_max_length"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Legend layers selection -->
        <v-row v-if="export_format.endsWith('report')">
          <v-col>
            <v-select
              v-model="selected_legend_layers"
              :items="available_legend_layers"
              :label="$t('map_actions.map_export.layer_select.label')"
              :no-data-text="$t('map_actions.map_export.layer_select.no_data')"
              :item-text="layer => layer.getName()"
              :item-value="layer => layer.id"
              multiple
              return-object
              :placeholder="$t('map_actions.map_export.layer_select.placeholder')"
              persistent-placeholder
              prepend-icon="checklist"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="selected_legend_layers.length === 1">
                  <span>{{ item.getName() }}</span>
                </v-chip>
                <v-chip v-else-if="index === 0">
                  <span v-if="selected_legend_layers.length === available_legend_layers.length">{{
                    $t("map_actions.map_export.layer_select.all")
                  }}</span>
                  <span v-else>{{
                    $t("map_actions.map_export.layer_select.label", { n: selected_legend_layers.length })
                  }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- Sources selection -->
        <v-row v-if="export_format.endsWith('report')">
          <v-col>
            <v-combobox
              v-model="selected_sources"
              :items="available_sources"
              :label="$t('map_actions.map_export.sources_select.label')"
              multiple
              prepend-icon="dataset"
              :search-input.sync="source_search"
              small-chips
              :placeholder="$t('map_actions.map_export.sources_select.placeholder')"
              :hint="$t('map_actions.map_export.sources_select.hint')"
              persistent-hint
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="$t('map_actions.map_export.sources_select.search', { search: source_search })"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </kite-map-action>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import KiteMapAction from "./kite_map_action.vue";
import { MapExport, MapCapture, EXPORT_EXTENSIONS } from "@/map_export";
import { filenameRegexRule, nameRequired } from "@/validation";
import { personalDataSourceName } from "@/models";
import { BASE_LAYERS } from "@/global";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const title_max_length = 62;

export default Vue.component("kite-map-export", {
  components: {
    KiteMapAction
  },
  data: function () {
    return {
      EXPORT_EXTENSIONS,
      export_format: "pdf_report",
      filename: "map-export",
      selected_legend_layers: [],
      selected_sources: [],
      source_search: null,
      title: "",
      map_capture: null,
      map_export: null,
      main_drawer_tmp: true,
      valid: true,
      title_max_length
    };
  },
  beforeDestroy() {
    if (this.map_capture) {
      this.map_capture.removeCaptureOverlay();
    }
  },
  computed: {
    ...mapState(["mainDrawerOpen"]),
    ...mapState("layers", ["map", "baseLayer"]),
    ...mapGetters("layers", ["displayedLayers"]),
    available_legend_layers() {
      return this.displayedLayers
        .map(layer_id => {
          return this.$store.getters["layers/getLayer"](layer_id);
        })
        .filter(layer => layer.isVisible && layer.legend_export)
        .reverse();
    },
    available_sources() {
      let layer_sources = this.available_legend_layers.map(layer => {
        return layer.dataSource;
      });
      let base_layer_sources = BASE_LAYERS.filter(el => el.name == this.baseLayer)[0].sources;
      let sources = [...layer_sources, ...base_layer_sources];
      const personal_source = personalDataSourceName();
      sources = sources.filter(el => el != personal_source);
      sources = [...new Set(sources)];
      return sources;
    },
    available_formats() {
      return Object.keys(EXPORT_EXTENSIONS).map(key => {
        return { value: key };
      });
    }
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    filenameRegexRule,
    nameRequired,
    init_map_export() {
      // init map title
      this.title = this.$t("map_actions.map_export.default_map_title").toString();

      // select all layers for export
      this.selected_legend_layers = this.available_legend_layers;
      this.selected_sources = this.available_sources;

      // create new MapExport instance
      this.map_capture = new MapCapture(this.map, 240);

      // store main drawer state
      this.main_drawer_tmp = this.mainDrawerOpen;

      // hide main drawer
      this.$store.commit("UPDATE_MAIN_DRAWER", false);

      // display capture overlay
      this.map_capture.displayCaptureOverlay();
    },
    onClose() {
      // remove capture overlay
      this.map_capture.removeCaptureOverlay();

      // restore drawer state
      this.$store.commit("UPDATE_MAIN_DRAWER", this.main_drawer_tmp);
    },
    exportMap() {
      this.asyncStart({ type: "generic" });
      let map_export = new MapExport(
        this.map,
        this.map_capture,
        this.$whale.user.firstName,
        this.selected_legend_layers,
        this.selected_sources,
        this.title
      );
      map_export.exportTo(this.filename, this.export_format).finally(() => {
        this.asyncEnd({ type: "generic" });
      });
    },
    titleLengthRule(value) {
      return (
        value.length <= title_max_length ||
        this.$t("add_dialog.errors.name_length_error", { max_size: title_max_length })
      );
    }
  }
});
</script>
