<!--
  Dialog to show flows summary table
-->

<template>
  <v-dialog :value="flowsSummary != null" max-width="1000px" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline grey lighten-2">{{ $t("flows.summary.title") }}</v-card-title>
      <v-data-table :headers="headers" :items="items" :sort-by="['count']" :sort-desc="[true]" class="pa-6">
        <template v-for="header in headers" v-slot:[`header.${header.value}`]>
          {{ $t("flows.summary.headers." + header.value) }}
        </template>
        <template v-slot:[`item.origin`]="{ item }">
          {{ truncateString(item.origin, 30) }}
        </template>
        <template v-slot:[`item.dest`]="{ item }">
          {{ truncateString(item.dest, 30) }}
        </template>
        <template v-slot:[`item.percentage`]="{ item }">
          <v-chip :color="getColor(item.percentage)" text-color="black" dark>
            {{ item.percentage + "%" }}
          </v-chip>
        </template>
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-row class="px-6">
                  <!-- filter for origin -->
                  <v-text-field
                    v-model="originValue"
                    type="text"
                    :label="$t('flows.summary.headers.origin')"
                    prepend-icon="search"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row class="px-6">
                  <!-- filter for dest -->
                  <v-text-field
                    v-model="destValue"
                    type="text"
                    :label="$t('flows.summary.headers.dest')"
                    prepend-icon="search"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { truncateString } from "@/functions-tools";
export default Vue.component("dialog-table", {
  components: {},
  props: ["flowsSummary"],
  data: function () {
    return {
      originValue: "",
      destValue: "",
      headers: [
        { text: "Origin", value: "origin", align: "left", width: "250", filter: this.originFilter },
        { text: "Destination", value: "dest", align: "left", width: "250", filter: this.destFilter },
        { text: "Count", value: "count", align: "right", width: "150" },
        { text: "Percentage", value: "percentage", align: "right", width: "150" }
      ]
    };
  },
  computed: {
    items() {
      if (this.flowsSummary) {
        return this.flowsSummary;
      } else {
        return [];
      }
    }
  },
  methods: {
    truncateString,
    /**
     * Close table dialog and reopen management dialog.
     */
    closeDialog() {
      this.$emit("closeDialog");
    },
    /**
     * Filter for origin column.
     */
    originFilter(value) {
      if (!this.originValue) {
        return true;
      }
      return value.toLowerCase().includes(this.originValue.toLowerCase());
    },
    /**
     * Filter for dest column.
     */
    destFilter(value) {
      if (!this.destValue) {
        return true;
      }
      return value.toLowerCase().includes(this.destValue.toLowerCase());
    },
    /**
     * Get chip color based on flow percentage.
     */
    getColor(percentage) {
      let colors = [
        "#fdfd96",
        "#fef68e",
        "#fff087",
        "#ffe981",
        "#ffe27a",
        "#ffdb75",
        "#ffd470",
        "#ffcc6b",
        "#ffc567",
        "#ffbe64",
        "#ffb661",
        "#ffaf5e",
        "#ffa75d",
        "#ffa05c",
        "#ff985b",
        "#ff915b",
        "#ff895c",
        "#ff815c",
        "#ff795e",
        "#ff715f",
        "#ff6961"
      ];
      // change color every 5%
      return colors[Math.trunc(percentage / 5)];
    }
  }
});
</script>
