<!--
Display gtfs route information 
-->

<template>
  <div>
    <v-card-actions>
      <v-row>
        <v-col cols="5">
          <info-day-type-selector />
        </v-col>
        <v-col cols="2">
          <direction-selector v-model="direction" outlined dense />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-text>
      <kite-table
        :headers="headers"
        :items="table_items"
        :hideDefaultFooter="true"
        sort-by="num_trips"
        sort-desc
        :items-per-page="-1"
        :export-filename="export_filename"
        header-path-prefix="network.tabs.variants.headers"
      >
      </kite-table>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import NetworkInfoMixin from "./network_info_mixin.vue";

export default Vue.component("route-variants", {
  mixins: [NetworkInfoMixin],

  data() {
    return {
      headers: [
        { value: "start_stop_name" },
        { value: "end_stop_name" },
        { value: "num_stops" },
        { value: "num_trips" }
      ]
    };
  },

  computed: {
    table_items() {
      return this.getMatchingItem(
        this.content.variants,
        {
          date: this.infoDayType.gtfs_date,
          direction_id: this.direction
        },
        true
      );
    },
    export_filename() {
      return `route_${this.content.route_short_name}_variants_${this.infoDayType.value}_${this.direction}.xls`;
    }
  },

  methods: {}
});
</script>
