<!--
    Dialog window for uploading and reading files

    /!\ Missing function for validating/preprocessing read data
    before uploading it.
    To be honest this functions shouldn't be in a component.
-->
<template>
  <div>
    <v-file-input
      v-model="fileInput"
      :multiple="multipleInputs"
      :placeholder="placeholderText"
      @change="readInputFiles"
      :loading="loading"
      :error-messages="error"
      :success-messages="uploadDone"
      :hide-input="!displayed"
      :prepend-icon="fileInputIcon"
    >
      <template v-slot:progress>
        <v-progress-linear v-model="uploadProgress" :indeterminate="preflight"></v-progress-linear>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import Vue from "vue";
import { ReportingFileReader } from "@/io";
import md5 from "md5";
import axios from "axios";

export default Vue.component("whale-upload", {
  components: {},

  props: {
    // boolean indicating if the dialog box is displayed
    displayed: {
      type: Boolean,
      default: true
    },
    model: {
      type: Object,
      require: true
    },
    property: {
      type: String,
      require: true
    },
    store: {
      type: String,
      require: true
    },
    // boolean indicating if multiple files are accepted
    multipleInputs: {
      type: Boolean,
      default: false
    },
    // boolean indicating if files are added when selected
    autoUpload: {
      type: Boolean,
      default: false
    },
    // title text
    title: {
      type: String,
      default: null
    },
    // placeholder text
    placeholder: {
      type: String,
      default: null
    },
    // provide the File objects via a prop
    // avoid openning the upload dialog
    externalFileInput: {
      type: File,
      default: null
    },
    // Default endpoint to use for upload
    endpoint: {
      type: String,
      default: "/binaries"
    },
    // Verify the input, if a string is returned this is the error message
    inputValidator: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      fileInput: null,
      reportingReader: new ReportingFileReader([]),
      hash: "",
      challenge: "",
      preflight: true,
      uploadProgress: 0,
      error: undefined,
      uploadDone: undefined,
      loading: false,
      binary: undefined
    };
  },
  watch: {
    externalFileInput(value) {
      console.log("external value change");
      // when provided with an external file input
      // read it and return the file with the usual procedure
      if (value != null) {
        console.log("new external");
        this.fileInput = value;
        this.readInputFiles();
        this.returnReadResult();
      }
    }
  },
  computed: {
    titleText() {
      if (this.title == null) {
        return this.$t("file_input_dialog.title");
      } else {
        return this.title;
      }
    },
    placeholderText() {
      if (this.placeholder == null) {
        return this.$t("file_input_dialog.placeholder");
      } else {
        return this.placeholder;
      }
    },
    fileInputIcon() {
      if (this.displayed) {
        return undefined;
      } else {
        return "";
      }
    }
  },
  methods: {
    async upload() {
      let url = `${this.endpoint}/upload/${this.store}/${this.model.uuid}/${this.property}`;
      try {
        /**
         * We should be able to replace by this
        this.preflight = true;
        this.$whale.addFile(this.store, this.model.uuid, this.property, this.binary, { name: this.fileInput.name }, p => {
          this.preflight = false;
          this.uploadProgress = p;
        });
        this.uploadDone = this.$t("upload.success");
        this.$emit("uploadSuccess", info); 
         */
        let info = {
          hash: this.hash,
          challenge: this.challenge,
          name: this.fileInput.name,
          size: this.fileInput.size,
          mimetype: this.fileInput.type,
          metadata: {
            name: this.fileInput.name
          }
        };
        console.log("sending info");
        console.log(info);
        let res = await this.$whale.controller.http(url, {
          method: "PUT",
          bodyObject: info
        });

        /*
          We get an answer with
          {
            "done": boolean,
            "md5": string, // Base64 md5 to send to AWS
            "method": string, // Method to use
            "url": string // URL to send data to
          }
        */
        console.log(res);

        if (res.done) {
          // Upload is already done
          this.uploadDone = this.$t("upload.success");
          this.$emit("uploadSuccess", info);
          return;
        }

        this.preflight = false;
        await axios.request(res.url, {
          method: res.method,
          data: this.binary,
          headers: {
            "Content-MD5": res.md5,
            "Content-Type": "application/octet-stream"
          },
          onUploadProgress: progressEvent => {
            this.uploadProgress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          }
        });
        this.uploadDone = this.$t("upload.success");
        this.$emit("uploadSuccess", info);
      } catch (err) {
        console.error(err);
        this.error = this.$t("upload.failed");
      } finally {
        this.preflight = true;
        this.uploadProgress = 0;
        this.loading = false;
      }
    },
    readInputFiles() {
      try {
        this.loading = true;
        this.uploadDone = undefined;
        this.error = undefined;
        let reader = new FileReader();
        reader.onload = async evt => {
          this.binary = new Uint8Array(evt.target.result);
          this.hash = md5(this.binary);
          // TODO Optimize could update sha256 algo to handle dual calculation
          var challengeBinary = new Uint8Array(5 + this.binary.length);
          challengeBinary.set(new Uint8Array([87, 69, 66, 68, 65]));
          challengeBinary.set(this.binary, 5);
          this.challenge = md5(challengeBinary);

          if (this.autoUpload) {
            await this.upload();
          }
        };
        if (this.fileInput !== undefined) {
          if (Array.isArray(this.fileInput)) {
            console.log("multiple file input no implemented");
          } else {
            reader.readAsArrayBuffer(this.fileInput);
          }
        }
      } catch (e) {
        this.loading = false;
        let message = e.message;
        alert({ message, type: "error" });
      }
    },
    returnReadResult() {
      this.$emit("handleReturnReadResult", this.reportingReader.readResult);
    },
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    }
  }
});
</script>
