<template>
  <div :style="{ background: $vuetify.theme.themes.light.primary, width: '100%', height: '100%' }">
    <div class="d-flex flex-column align-center full">
      <v-img src="/static/logo-kite.png" max-width="100" alt="Tellae" max-height="100" />
      <h1 style="color: #ffffff; margin: 20px">KITE</h1>
      <div v-if="$whale.isAuthenticated" style="text-align: center">
        <div style="color: white; margin-bottom: 15px">{{ $t("welcome.welcome") }} {{ $whale.user.firstName }}</div>
        <v-btn @click="$emit('close')" outlined dark :disabled="!webgl">{{ $t("welcome.button") }}</v-btn>
      </div>
      <span v-else-if="$whale.authenticating" style="color: white">
        {{ $t("welcome.authenticating") }}
      </span>
      <span v-else-if="$whale.error" style="color: white">
        {{ $whale.error }}
      </span>
      <span v-else-if="!$whale.isAuthenticated" style="color: white">
        {{ $t("welcome.isAuthenticated") }}
      </span>
      <span v-else-if="key_mapbox === undefined" style="background-color: red; color: white">
        {{ $t("welcome.mapboxError") }}<br />
        VUE_APP_MAPBOXKEY=your_key
      </span>
      <span v-show="!webgl" style="background-color: red; color: white">
        {{ $t("welcome.webglError") }}
      </span>
    </div>
    <version-footer />
  </div>
</template>

<script lang="ts">
import VersionFooter from "@/components/base/version_footer.vue";

export default {
  name: "Welcome",
  components: {
    VersionFooter
  },
  data() {
    return {
      key_mapbox: process.env.VUE_APP_MAPBOXKEY, // eslint-disable-line
      webgl: false
    };
  },
  created() {
    // detect if webgl is available
    function detectWebGLContext() {
      // Create canvas element. The canvas is not added to the
      // document itself, so it is never displayed in the
      // browser window.
      var canvas = document.createElement("canvas");
      // Get WebGLRenderingContext from canvas element.
      var gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      // Report the result.
      if (gl && gl instanceof WebGLRenderingContext) {
        return true;
      } else {
        return false;
      }
    }
    this.webgl = detectWebGLContext();
  },
  methods: {}
};
</script>
<style>
.footer {
  color: white;
  text-align: center;
}

.full {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
