<!--
Route type icon
-->

<template>
  <v-tooltip slot="append" bottom :disabled="!tooltip">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" v-bind="$attrs" color="primary"> {{ ROUTE_TYPE_ICONS[routeType] }} </v-icon>
    </template>
    <span> {{ $t(`basic_transport.route_types.${routeType}`) }} </span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue";
import { ROUTE_TYPE_ICONS } from "@/global";

export default Vue.component("route-type-icon", {
  components: {},
  props: {
    routeType: {
      type: Number,
      required: true
    },
    tooltip: Boolean
  },

  data() {
    return {
      ROUTE_TYPE_ICONS
    };
  },
  methods: {}
});
</script>
