<!--
  Component representing a data table and its pagination.
-->

<template>
  <v-card>
    <v-card-title>
      {{ $t("territory.dialog.title") }}
      <v-btn icon dark color="primary"> <v-icon @click="captions = true"> help </v-icon> </v-btn>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <download-excel :fetch="getData" :name="agg_level + '.xls'">
            <v-btn large icon color="primary" :disabled="async.communes > 0"><v-icon v-on="on"> save </v-icon> </v-btn>
          </download-excel>
        </template>
        <span>{{ $t("territory.dialog.export") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-select
            v-model="agg_level"
            :items="agg_levels"
            :label="$t('territory.dialog.agg_level')"
            @change="updateCommunesList"
          />
        </v-col>
        <v-col cols="3">
          <v-btn large color="primary" @click="updateCommunesList" :disabled="async.communes > 0">
            <v-icon left>my_location</v-icon> {{ $t("map_layers.database_layers.refresh") }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('territory.dialog.search')"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      {{ $t("territory.dialog.table_comment") }}
      <v-data-table
        :headers="headers"
        :items="filteredCommunes"
        fixed-header
        :height="550"
        disable-pagination
        hide-default-footer
        class="elevation-1"
        :loading="async.communes > 0"
        :no-data-text="'Pas de données'"
      >
        <template v-slot:[header_attribute(header_obj)]="{ header }" v-for="header_obj in headers">
          {{ $t("territory.dialog.headers." + header_obj.text) }}
        </template>
        <template #body="">
          <tbody>
            <tr v-for="a in filteredCommunes.length" :key="`row-${a}`">
              <td v-for="b in headers.length" :key="`row-${a}-cell-${b}`">
                <div :style="'text-align: ' + headers[b - 1]['align']">
                  {{ filteredCommunes[a - 1][headers[b - 1]["value"]] }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
    <kite-bottom-caption
      v-model="captions"
      :items="headers"
      :text="item => $t(`territory.dialog.headers.${item.text}`)"
      :caption="item => $t(`territory.dialog.captions.${item.text}`)"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { formatTableDataWithHeaders, bbox_from_map } from "@/functions-tools";
import { TABHEADERS } from "@/global";
import KiteBottomCaption from "@/components/kite/base/kite_bottom_caption.vue";
import { create_search_list } from "@/functions-tools";

export default Vue.component("territory-kpi", {
  components: {},

  props: {
    KiteBottomCaption
  },

  data: function () {
    return {
      captions: false,
      agg_level: "commune",
      epci: false,
      search: "",
      communes: []
    };
  },

  computed: {
    ...mapState(["async"]),
    ...mapState("layers", ["map"]),
    filteredCommunes() {
      let search_list = create_search_list(this.search);

      return this.communes.filter(
        row =>
          search_list.some((s: string) => row["NOM"].toString().toLowerCase().includes(s)) ||
          search_list.some((s: string) => row["INSEE_COM"].toString().toLowerCase().includes(s)) ||
          search_list.some((s: string) => row["EPCI_NAME"].toString().toLowerCase().includes(s))
      );
    },
    agg_levels() {
      return [
        {
          text: "Commune",
          value: "commune"
        },
        {
          text: "EPCI",
          value: "epci"
        }
      ];
    },
    headers() {
      let headers = TABHEADERS.territory_kpis;
      if (this.epci) {
        headers = headers.filter(header => {
          return !header.epci_only;
        });
      }
      return headers;
    }
  },
  methods: {
    // get the formated version of the table data for a file export
    getData() {
      return formatTableDataWithHeaders(this.headers, this.filteredCommunes, this.valueFormat);
    },
    valueFormat(item, value_key) {
      let value = item[value_key];
      if (value === undefined) {
        value = "";
      }
      return value.toString().replace(".", ",");
    },
    async updateCommunesList() {
      let param = bbox_from_map(this.map);
      let epci = this.agg_level == "epci";
      let res = await this.$whale.territoryInformation(param, epci);
      this.epci = epci;
      this.communes = res?.information || [];
    },
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    }
  }
});
</script>

<style scoped>
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 9998;
  background: white;
}
table > thead > tr > th:nth-child(1) {
  z-index: 9999;
}
</style>
