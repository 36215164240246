<template>
  <div>
    <v-app>
      <kite-app-bar logo="/static/logo-capucine.png">
        <template v-slot:leftAction>
          <v-btn icon dark @click="updateDrawer" id="menu"> <v-icon>menu</v-icon></v-btn>
        </template>
        <template v-slot:title>
          CAPucine <span style="font-size: 12px"> {{ " " + version }} </span>
        </template>
        <template v-slot:midAction>
          <page-button target="analysis" text="Analyse des données" />
          <page-button
            target="simulation"
            text="Simulation de scénarios"
            :disabled="$whale.project._application != 'CAPUCINE_SIMULATION'"
            v-if="page === 'analysis' || page === 'simulation'"
          />
          <page-button
            target="results"
            text="Simulation de scénarios"
            :disabled="$whale.project._application != 'CAPUCINE_SIMULATION'"
            v-if="page === 'results'"
          />
        </template>
      </kite-app-bar>
      <v-navigation-drawer
        :value="mainDrawerOpen"
        app
        width="320px"
        class="navigation elevation-3"
        clipped
        hide-overlay
        stateless
      >
        <div v-if="page == 'analysis'">
          <navigation-drawer-analysis></navigation-drawer-analysis>
        </div>
        <div v-else-if="page == 'simulation'">
          <navigation-drawer-simulation></navigation-drawer-simulation>
        </div>
        <div v-else><navigation-drawer-results></navigation-drawer-results></div>
      </v-navigation-drawer>
      <v-main>
        <div class="navi">
          <kite-alert></kite-alert>
        </div>
        <v-tabs-items v-model="page">
          <v-tab-item eager value="analysis">
            <analysis-page />
          </v-tab-item>
          <v-tab-item eager value="simulation">
            <simulation-page v-if="$whale.project._application == 'CAPUCINE_SIMULATION'" />
          </v-tab-item>
          <v-tab-item eager value="results">
            <results-page v-if="$whale.project._application == 'CAPUCINE_SIMULATION'" />
          </v-tab-item>
        </v-tabs-items>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import AnalysisPage from "@/components/capucine/analysis/analysis_page.vue";
import SimulationPage from "@/components/capucine/simulation/simulation_page.vue";
import ResultsPage from "@/components/capucine/results/results_page.vue";
import KiteAppBar from "@/components/base/kite_app_bar.vue";
import KiteAlert from "@/components/base/kite_alert.vue";
import PageButton from "@/components/capucine/page_button.vue";
import { mapState, mapActions } from "vuex";
import NavigationDrawerAnalysis from "../components/capucine/analysis/navigation_drawer_analysis.vue";
import NavigationDrawerSimulation from "../components/capucine/simulation/navigation_drawer_simulation.vue";
import NavigationDrawerResults from "../components/capucine/results/navigation_drawer_results.vue";
export default {
  name: "Main",
  components: {
    AnalysisPage,
    SimulationPage,
    ResultsPage,
    KiteAppBar,
    KiteAlert,
    PageButton,
    NavigationDrawerAnalysis,
    NavigationDrawerSimulation,
    NavigationDrawerResults
  },
  data: () => ({
    version: "",
    mainDrawerOpen: true
  }),
  async mounted() {
    // test server availability by requesting version
    this.$whale
      .getCapucineVersion()
      .then(response => {
        // set version
        this.version = response.version;

        // get list of data batches
        this.getDataBatches();

        // get list of available lots
        this.getLotSummary().then(() => {
          // test list no empty
          if (this.lots.length == 0) {
            let message = "Vous n'avez accès à aucun réseau / lot";
            alert({ message, type: "warning" });
            throw new Error();
          }

          // init analysis context
          this.initAnalysis();

          if (this.$whale.project._application == "CAPUCINE_SIMULATION") {
            // init simulation context
            this.initSimulation();
          }
        });
      })
      .catch(e => {
        let message = "Erreur lors de l'accès au serveur. Ressayez ou contactez kite@tellae.fr";
        alert({ message, type: "error" });
      });
  },
  computed: {
    ...mapState("capucine_analysis", ["lots", "lot"]),
    page: {
      get() {
        return this.$store.state.capucine_analysis.page;
      },
      set(value) {
        this.setPage(value);
        this.$whale.updateCapucinePreferences({ analysis: { page: value } });
      }
    }
  },
  methods: {
    ...mapActions("capucine_analysis", ["setPage", "getLotSummary", "getDataBatches", "changeLot"]),
    ...mapActions("capucine_simulation", ["changeScenario", "initParamWatcher", "getScenariosList"]),
    async initAnalysis() {
      // set the analysis lot
      let success = false;
      if (Object.keys(this.lot).length > 0) {
        success = await this.changeLot(this.lot);
      }
      if (!success) {
        await this.changeLot(this.lots[0]);
      }
    },
    async initSimulation() {
      // get the list of simulation scenarios
      await this.getScenariosList();

      // initialise the param watchers
      this.initParamWatcher();

      // set the simulation scenario
      let success = await this.changeScenario(this.$store.state.capucine_simulation.scenario);
      if (!success) {
        this.changeScenario();
      }
    },

    // log the user out
    logout() {
      this.$whale.logout();
    },
    updateDrawer() {
      this.mainDrawerOpen = !this.mainDrawerOpen;
      console.log(this.mainDrawerOpen);
    }
  }
};
</script>
