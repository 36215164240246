/* simulations.store.js */

import { getInstance } from "@/api/index";
import { SERVICES, USE_CASES } from "@/simulation";
import { FlowsView } from "@/models";
import { zoomOnGeojson } from "@/functions-tools";
import { readFiles, parse_json } from "@/io";
import i18n from "@/plugins/lang";

const initialState = () => ({
  page: "model_selection",
  scenarioList: [],
  current_service: null,
  current_use_case: null,
  valid_step: {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true
  },
  step: 1,
  description: "",
  scenario_name: null,
  scenario_text: "",
  parameters: {},
  model_kpis_dialog: false,
  model_kpis: {
    ODT: [],
    OD_P_T: [],
    SB_VS: [],
    SB_VS_R: [],
    FF_VS: [],
    TT_PT: []
  },
  selectedScenario: null
});
const state = initialState();

export default {
  // State object
  namespaced: true,
  state,

  // Getter functions
  getters: {
    current_service_name: state => {
      if (state.current_service) {
        return i18n.t(`simulations.models.${state.current_service}.title`);
      }
    },
    current_use_case_name: state => {
      if (state.current_use_case) {
        return i18n.t(`simulations.use_cases.${state.current_service}.${state.current_use_case}.title`);
      }
    },
    current_service_scenarios: state => {
      if (state.current_service == null || !(state.current_service in SERVICES)) {
        return [];
      } else {
        let service = SERVICES[state.current_service];
        return state.scenarioList;
      }
    },
    current_service_use_cases: state => {
      if (state.current_service == null || !(state.current_service in SERVICES)) {
        return [];
      } else {
        let service = SERVICES[state.current_service];
        return service.use_cases.map(use_case => USE_CASES[use_case]);
      }
    },
    scenario_analysis_size: state => {
      if (state.current_service == null || !(state.current_service in state.model_kpis)) {
        return 0;
      } else {
        let model_kpis = state.model_kpis[state.current_service];
        return model_kpis.length;
      }
    },
    scenario_with_available_model_kpis: (state, getters) => {
      let scenarios_available = getters.current_service_scenarios.filter(scenario => {
        let service = scenario.service;
        let scenarios_already_added = state.model_kpis[service].map(item => item.uuid);

        let scenario_finished = scenario.status == "SUCCESS";
        let model_kpis_available = Object.keys(state.model_kpis).includes(service);
        let already_added = scenarios_already_added.includes(scenario.uuid);
        return scenario_finished && model_kpis_available && !already_added;
      });
      return scenarios_available;
    }
  },
  // Actions
  actions: {
    // global actions
    /**
     * Update the scenarioList attribute
     * @param {*} context
     */
    async updateScenarioList(context) {
      let whale = getInstance();
      try {
        let response = await whale.getScenarioList(context.state.current_service);
        context.commit("SET_SCENARIO_LIST", response);
        return true;
      } catch {
        return false;
      }
    },

    changePage(context, page) {
      if (["model_selection", "use_case_selection", "model_scenarios", "scenario_creation"].includes(page)) {
        context.commit("CHANGE_PAGE", page);
      }
    },
    changeService(context, service) {
      // reset use case if changing service
      if (context.state.current_service != service) {
        context.commit("SET_USE_CASE", null);
      }
      context.commit("SET_SERVICE", service);
    },
    async selectScenario(context, scenario) {
      let whale = getInstance();
      // update stored scenario uuid
      context.commit("SELECT_SCENARIO", scenario.uuid);

      try {
        // draw scenario ODs
        let users = await whale.downloadScenarioUsers(scenario);
        if (!users) {
          throw new Error(<string>i18n.t("simulations.data.error_users"))
        }
        let flows_view = FlowsView.newFlowsView(users, "STARLING", scenario.scenario_name + "_users");
        flows_view.addToKite(true, false);

        // draw graph
        let graph_geojson = await whale.downloadScenarioGraph(scenario);
        if (!graph_geojson) {
          throw new Error(<string>i18n.t("simulations.data.error_graph"))
        }
        context.dispatch("layers/setLayersData", { ids: "graph", data: graph_geojson }, {root: true});
        context.dispatch("layers/setLayersVisibility", { ids: ["graph"], isVisible: true }, {root: true});

        // play scenario trace
        let file = await whale.downloadScenarioTrace(scenario);
        if (!file) {
          throw new Error(<string>i18n.t("simulations.data.error_trace"))
        }
        let readResult = await readFiles(
          new File([await file.blob()], scenario.scenario_name + ".geojson.gz", { type: file.type })
        );
        let file_text = readResult[0].data;
        let geojson = parse_json(file_text);
        // setTraceData should zoom on data
        await context.dispatch("traces/setTraceData", { geojson }, {root: true});  
      } catch (e) {
        alert(e)
        throw e
      }
    },
    unselectedScenario(context) {
      context.commit("SELECT_SCENARIO", null);
      context.dispatch("flows/displayFlows", null, {root: true})
      context.dispatch("layers/setLayersData", { ids: "graph", data: null }, {root: true});
      context.dispatch("layers/setLayersVisibility", {
        ids: ["graph", "area", "staticpoint", "stoppedmovingpoint", "movingpoint"],
        isVisible: false
      }, {root: true});
    },

    // focused scenario actions
    deleteScenario(context, scenario) {
      let whale = getInstance();
      whale.deleteScenario(scenario.uuid);
      // avoid waiting for next update
      context.state.scenarioList = context.state.scenarioList.filter(item => item !== scenario);
    },

    duplicateScenario(context, scenario) {
      // set use case with copied parameters
      context.dispatch("startUseCase", {
        use_case: scenario.use_case,
        parameters: JSON.parse(JSON.stringify(scenario.parameters))
      });

      // set scenario name
      let copied_name = i18n.t("basic_dialogs.copyof") + scenario.scenario_name;
      context.commit("SET_SCENARIO_NAME", copied_name);

      // change page
      context.dispatch("changePage", "scenario_creation");
    },

    // stepper actions
    startUseCase(context, { use_case, parameters }) {
      // set use case code
      context.commit("SET_USE_CASE", use_case);

      // set step to 1
      context.commit("SET_STEP", 1);

      // set use case parameters
      context.commit("SET_PARAMETERS", parameters);
    },
    async runUseCase(context) {
      let whale = getInstance();
      if (context.state.parameters == null) {
        let message = i18n.t("simulations.error_use_case_param");
        alert({ message, type: "error" });
      }

      // create scenario
      let scenario = await whale.createScenario({
        parameters: context.state.parameters,
        scenario_name: context.state.scenario_name,
        text: context.state.scenario_text,
        service: context.state.current_service,
        use_case: context.state.current_use_case
      });

      // add scenario to scenario list
      scenario.status = "QUEUED";
      context.state.scenarioList.push(scenario);
      let message = i18n.t("simulations.new_scenario") + ": '" + context.state.scenario_name + "'";
      alert({ message, type: "success" });

      // run simulation scenario
      whale.runScenario(scenario.uuid);
    },
    resetStepper(context) {
      // set scenario name to null
      context.commit("SET_SCENARIO_NAME", null);

      context.state.scenario_text = "";

      // set parameters to empty object
      context.commit("SET_PARAMETERS", {});
    },

    // model KPIs actions
    modelKpiAvailable(context, scenario) {
      let service = scenario.service;
      let scenarios_already_added = context.state.model_kpis[service].map(item => item.uuid);

      let scenario_finished = scenario.status == "SUCCESS";
      let model_kpis_available = Object.keys(context.state.model_kpis).includes(service);
      let already_added = scenarios_already_added.includes(scenario.uuid);
      console.log(scenario);
      console.log(scenario_finished && model_kpis_available && !already_added);
      return scenario_finished && model_kpis_available && !already_added;
    },

    async addModelKpi(context, scenario) {
      let whale = getInstance();
      let model = scenario.service;
      let res = null;
      if (model in context.state.model_kpis) {
        // get scenario KPIs
        res = await whale.getModelKpis(scenario);
        // add scenario name and uuid
        res.scenario = scenario.scenario_name;
        res.uuid = scenario.uuid;
        // add KPIs to the relevant table
        context.commit("ADD_MODEL_KPI", { model, item: res });
      } else {
        let message = i18n.t("simulations.kpis.no_kpi_available");
        alert({ message, type: "warning" });
      }
    },
    removeKpi(context, payload) {
      context.commit("REMOVE_KPI", payload);
    }
  },
  // Mutations
  mutations: {
    CHANGE_PAGE(state, page) {
      state.page = page;
    },
    SELECT_SCENARIO(state, scenario_id) {
      state.selectedScenario = scenario_id;
    },
    SET_SERVICE(state, service) {
      state.current_service = service;
    },
    SET_USE_CASE(state, use_case) {
      state.current_use_case = use_case;
    },
    SET_STEP(state, step) {
      state.step = step;
    },
    SET_PARAMETERS(state, parameters) {
      state.parameters = parameters;
    },
    SET_SCENARIO_NAME(state, name) {
      state.scenario_name = name;
    },
    SET_SCENARIO_LIST(state, scenarios) {
      state.scenarioList = scenarios;
    },
    ADD_MODEL_KPI(state, payload) {
      let data = payload.item;
      // some formatting
      if (payload.model == "ODT") {
        data["vk"] = data["vk"].toFixed(1);
        //data["bulking"] = data["bulking"].toFixed(2);
      }

      // add data
      state.model_kpis[payload.model].push(data);
    },
    REMOVE_KPI(state, payload) {
      state.model_kpis[payload.model] = state.model_kpis[payload.model].filter(item => item != payload.item);
    },
    RESET_STORE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    }
  }
};
