<!--
  Data table with number of vehicles
-->

<template>
  <kpi-card title="Nombre de véhicules" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.number_vehicles">
    <result-table
      :items="selectedData['number_vehicles']"
      :formats="{ actuel: '', simulé: '', écart: '-' }"
      :width="50"
    />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("number-vehicles", {
  components: {
    KpiCard,
    ResultTable
  },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  }
});
</script>
