<template>
  <v-navigation-drawer
    :value="mainDrawerOpen"
    app
    width="560px"
    clipped
    disable-resize-watcher
    hide-overlay
    stateless
    temporary
    :style="`margin-top: ${$vuetify.application.top}px`"
  >
    <slot />
  </v-navigation-drawer>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("kite-drawer", {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["mainDrawerOpen"])
  },
  methods: {}
});
</script>
