import { render, staticRenderFns } from "./travel_time.vue?vue&type=template&id=6590c9b6"
import script from "./travel_time.vue?vue&type=script&lang=ts"
export * from "./travel_time.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-22685427/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports