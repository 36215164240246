<template>
  <div>
    <v-menu
      bottom
      left
      :offset-y="true"
      :close-on-content-click="false"
      :close-on-click="false"
      open-on-hover
      close-delay="200"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="white" outlined dark depressed v-on="on" class="pt-0 pb-0" @click="openDialog()">
          {{ project.name || $t("project.default_name") }}
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>{{ $t("project.title") }}</v-subheader>
        <div class="overflow-auto" style="max-height: 30vh">
          <v-list-item v-for="project in userProjects" :key="project.uuid" link @click="selectProject(project.uuid)">
            <v-list-item-title>
              {{ getProjectName(project) }}
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-divider />
        <v-list-item link @click="$whale.runIfHasAccess('PROJECT', openDialogProject)">
          <v-list-item-icon>
            <v-icon id="project_icon">mdi-folder-plus-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t("project.new") }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-subheader v-if="($whale.user._projects || []).length > 0">{{ $t("project.shared_projects") }}</v-subheader>
        <v-list-item
          link
          v-for="project in $whale.user._projects"
          :key="project.model"
          @click="selectProject(project.model)"
        >
          <v-list-item-title>{{ project.metadata.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <kite-dialog v-model="newDialog">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t("project.new") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="newDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form>
          <v-text-field v-model="newProject.name" label="Name" required></v-text-field>
          <v-textarea v-model="newProject.description" label="Description"></v-textarea>
        </v-form>
        <v-container>
          <v-row justify="end">
            <v-spacer></v-spacer>
            <v-col class="mr-4" style="flex-grow: 0" @click="reinitNew()">
              <v-btn>{{ $t("project.cancelSave") }}</v-btn>
            </v-col>
            <v-col class="mr-4" style="flex-grow: 0">
              <kite-action-button
                action="newProject"
                :handler="() => this.$whale.newProject(newProject)"
                @success="reinitNew()"
              >
                {{ $t("project.save") }}
              </kite-action-button>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </kite-dialog>
    <v-dialog v-model="infoDialog" min-height="400px" max-width="1000px" @click:outside="infoDialog = false">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $whale.project.name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="infoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="padding: 20px">
          {{ $whale.project.description }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <kite-dialog v-model="dialog">
      <v-tabs
        v-model="tab"
        background-color="primary"
        class="elevation-2"
        dark
        grow
        icons-and-text
        :slider-size="SLIDER_SIZE"
      >
        <v-tabs-slider :color="SLIDER_COLOR" />
        <v-tab>
          {{ $t("project.settings.info.title", project) }}
          <v-icon>mdi-file-document-outline</v-icon>
        </v-tab>

        <v-tab-item>
          <v-form v-model="valid">
            <v-text-field
              v-model="$whale.project.name"
              :label="$t('project.settings.info.name')"
              required
            ></v-text-field>
            <v-textarea
              v-model="$whale.project.description"
              :label="$t('project.settings.info.description')"
            ></v-textarea>
          </v-form>
          <v-container>
            <v-row justify="end">
              <v-spacer></v-spacer>
              <v-col class="mr-4" style="flex-grow: 0">
                <kite-action-button
                  action="updateProject"
                  :handler="() => this.$whale.updateProject(['name', 'description'])"
                  >{{ $t("project.save") }}</kite-action-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab> {{ $t("project.settings.data.title") }} <v-icon>save</v-icon> </v-tab>

        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-data-table
                class="elevation-1"
                :headers="projectDataHeaders"
                :items="projectDataItems"
                hide-default-footer
                show-group-by
                group-by="data_type"
                :items-per-page="-1"
              >
                <template v-for="header in projectDataHeaders" v-slot:[`header.${header.value}`]>
                  {{ $t("project.settings.data.headers." + header.text) }}
                </template>
                <template v-slot:group.header="item">
                  <td :colspan="projectDataHeaders.length">
                    <v-btn small icon @click="item.toggle">
                      <v-icon>{{ item.isOpen ? "mdi-minus" : "mdi-plus" }}</v-icon>
                    </v-btn>
                    {{
                      $t("project.settings.data.headers.data_type") +
                      ": " +
                      $t("project.settings.data.types." + item.group)
                    }}
                    <v-btn small icon @click="item.remove">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </td>
                </template>

                <template v-slot:item.name="{ item }">
                  <kite-edit-text
                    :text="getBinaryName(item.data, false)"
                    :rules="name_rules"
                    :counter="name_max_size"
                    @save="updateProjectDataName(item, $event)"
                  />
                </template>
                <template v-slot:item.data_type="{ item }">
                  {{ $t("project.settings.data.types." + item.data_type) }}
                </template>
                <template v-slot:item.actions="{ item, index }">
                  <kite-table-action
                    icon="file_download"
                    @click="downloadProjectData(index, item.data_type)"
                    :tooltip="$t('basic_dialogs.download')"
                  />
                  <kite-delete-button
                    @confirmDelete="deleteProjectData(index, item.data_type)"
                    :confirm-text="
                      $t('project.settings.data.delete_confirmation', {
                        name: getBinaryName(item.data, false)
                      })
                    "
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab v-if="project._permissions.includes('all')">
          {{ $t("project.settings.share.title") }}<v-icon>mdi-share</v-icon>
        </v-tab>

        <v-tab-item>
          <v-data-table :headers="aclHeaders" :items="project.__acl">
            <template v-slot:item.actions="{ item }">
              <kite-delete-button
                @click="$whale.runIfHasAccess('PROJECT', removeUser, item.uuid)"
                v-if="$whale.user.uuid !== item.uuid"
              />
            </template>
            <template v-slot:item.displayName="{ item }">
              {{ item.displayName ? item.displayName : item.uuid }}
            </template>
            <template v-slot:item.permission="{ item }">
              <v-edit-dialog @open="newFlowsName = item" v-if="$whale.user.uuid !== item.uuid">
                {{ item.permission === "all" ? "Owner" : item.permission }}
                <template v-slot:input>
                  <v-select
                    :items="['Viewer', 'Editor', 'Owner']"
                    label="Permissions"
                    v-model="item.permission"
                    :value="item.permission === 'all' ? 'Owner' : item.permission"
                    @change="$whale.runIfHasAccess('PROJECT', updateUserPermission, item)"
                  >
                  </v-select>
                </template>
              </v-edit-dialog>
              <span v-else>
                {{ item.permission === "all" ? "Owner" : item.permission }}
              </span>
            </template>
          </v-data-table>
          <v-form style="padding: 0px" v-model="valid_invite">
            <v-container>
              <v-row no-gutters>
                <v-col cols="5" md="5" class="d-flex justify-center align-center">
                  <v-text-field
                    prepend-icon="mdi-email"
                    :loading="inviteLoading"
                    :label="$t('project.settings.share.email')"
                    :rules="[rules.required, rules.email]"
                    v-model="invitation.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="4" class="d-flex justify-center align-center">
                  <v-select
                    :items="['Viewer', 'Editor', 'Owner']"
                    label="Permissions"
                    v-model="invitation.permission"
                    :value="invitation.permission"
                  >
                  </v-select>
                </v-col>
                <v-col
                  style="flex-grow: 0"
                  align-self="center"
                  cols="3"
                  md="3"
                  class="d-flex justify-center align-center"
                >
                  <v-btn
                    @click="$whale.runIfHasAccess('PROJECT', invite, null)"
                    :disabled="!valid_invite || inviteLoading"
                    >{{ $t("project.settings.share.invite") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </kite-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import { removeExtensionFromFilename, getBinaryName } from "@/functions-tools";
import { nameRequired, nameLengthRule, name_max_size } from "@/validation";

export default Vue.component("menu-project", {
  computed: {
    project: function () {
      return this.$whale.project;
    },
    projectDataItems() {
      let project_data_items = [];
      ["spatial_data", "flows", "gtfs"].forEach(data_type => {
        (this.project[data_type] || []).forEach(element => {
          project_data_items.push(this.projectDataToItem(element, data_type));
        });
      });
      return project_data_items;
    },
    userProjects() {
      let projects = [...this.$whale.user._ownedProjects];
      projects.sort((a, b) => {
        return this.getProjectName(a).localeCompare(this.getProjectName(b));
      });
      return projects;
    }
  },
  data: function () {
    return {
      SLIDER_SIZE,
      SLIDER_COLOR,
      invitation: {
        permission: "Viewer",
        email: ""
      },
      aclHeaders: [
        { text: "", value: "actions" },
        { text: "", value: "displayName" },
        { text: "Permissions", value: "permission" }
      ],
      projectDataHeaders: [
        { text: "name", value: "name", groupable: false, sortable: false },
        //{ text: "size", value: "size" },
        { text: "data_type", value: "data_type", groupable: true },
        { text: "actions", value: "actions", groupable: false, sortable: false, align: "center" }
      ],
      newProject: {
        name: "",
        description: ""
      },
      rules: {
        required: value => !!value || "Required.",
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      valid_invite: true,
      loading: false,
      inviteLoading: false,
      dialog: false,
      infoDialog: false,
      newDialog: false,
      valid: false,
      tab: 0,
      new_project_data_name: "",
      name_rules: [nameRequired, nameLengthRule],
      name_max_size
    };
  },
  methods: {
    getBinaryName,
    removeExtensionFromFilename,
    openDialogProject() {
      this.newDialog = true;
    },
    openDialog: function () {
      if (this.$whale.project._permissions.includes("all") || this.$whale.project._permissions.includes("Editor")) {
        this.dialog = true;
        if (this.$whale.project._permissions.includes("all")) {
          this.$whale.loadProjectAcl();
        }
      } else {
        this.infoDialog = true;
      }
    },
    downloadProjectData(index, data_type) {
      this.$whale.downloadProjectFile(data_type, index);
    },
    deleteProjectData: function (index, data_type) {
      this.$whale.deleteProjectFile(data_type, index);
    },
    removeUser: function (user) {
      console.log("Should remove", user);
    },
    updateUserPermission: function (user) {
      this.$whale.updateAce(user.uuid, user.permission === "Owner" ? "all" : user.permission);
    },
    invite: async function () {
      console.log("Should invite with", this.invitation);
      this.inviteLoading = true;
      await this.$whale.invite(
        this.invitation.email,
        this.invitation.permission === "Owner" ? "all" : this.invitation.permission
      );
      this.inviteLoading = false;
    },
    reinitNew: function () {
      this.newDialog = false;
      this.newProject = {
        name: "",
        description: ""
      };
    },
    selectProject: function (id) {
      // Launch the settings instead
      if (id === this.$whale.project.uuid) {
        this.openDialog();
        return;
      }
      this.$whale.setCurrentProject(id);
    },
    projectDataToItem(data, data_type) {
      return {
        data,
        data_type
      };
    },
    async updateProjectDataName(data_item, new_name) {
      // compute new name with extension
      let previous_name = getBinaryName(data_item.data);
      let new_name_with_extension = previous_name.replace(removeExtensionFromFilename(previous_name), new_name);

      // call whale to update metadatas
      await this.$whale.updateProjectBinaryMetadata(data_item.data_type, data_item.data, {
        ...data_item.data.metadata,
        name: new_name_with_extension
      });
    },
    getProjectName(project) {
      return project.name || this.$t("project.default_name");
    }
  }
});
</script>

<style scoped>
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
#project_icon {
  color: var(--v-primary-base);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.theme--light.v-list-item--disabled .theme--light.v-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.v-sheet.v-list {
  padding: 0px;
}
.v-subheader {
  height: 24px;
  font-size: 0.775rem;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
.v-form {
  padding: 40px;
}
</style>
