<!--
  Read Starling output trace file (geojson)
  Add data to mapbox layers 
-->
<template>
  <div>
    <tellae-menu :title="$t('trace.name')" icon="commute" type="button" plain>
      <v-list dense>
        <tellae-menu-item icon="cloud_upload" title="trace.file" @click="dialog = true" />
        <tellae-menu-item
          icon="moving"
          title="trace.paths.button"
          @click="paths_dialog = true"
          :disabled="agentTypeList.length == 0"
        />
        <tellae-menu-item
          icon="commute"
          title="trace.iconrules"
          @click="icons_dialog = true"
          :disabled="agentTypeList.length == 0"
        />
        <tellae-menu-item
          :icon="checkFollowAgentIcon"
          title="trace.follow"
          @click="checkFollowAgent = !checkFollowAgent"
        />
      </v-list>
    </tellae-menu>
    <kite-upload :dialog="dialog" @handleReturnReadResult="formatTraceFromFile" @handleUpdateDialog="dialog = $event">
      <template v-slot:beforeInput>
        <p>
          {{ $t("trace.dialog.intro1") }}
          <a href="https://starling.readthedocs.io/en/latest/run/io.html#visualisation-file">
            {{ $t("trace.dialog.intro2") }}
          </a>
        </p>
        <p>{{ $t("trace.dialog.intro3") }}</p>
      </template>
      <template v-slot:afterInput>
        <p>{{ $t("trace.dialog.choose") }}</p>
        <v-select
          class="mb-n2"
          :items="[3600, 9999999]"
          v-model="stoppedPointRefreshLocal"
          :label="$t('trace.dialog.interval')"
        ></v-select>
        <v-switch class="mb-n2" :label="$t('trace.dialog.users')" v-model="showUsersLocal"></v-switch>
        <v-switch class="mb-n2" :label="$t('trace.dialog.static_layer')" v-model="useStaticLayerLocal"></v-switch>
        <v-switch class="mb-n2" :label="$t('trace.activity')" v-model="activityShadowLocal"></v-switch>
      </template>
    </kite-upload>
    <paths-layers :dialog="paths_dialog" @handleUpdateDialog="paths_dialog = $event"></paths-layers>
    <relative-trace-icons
      :display="icons_dialog"
      :map="map"
      @handleUpdateDialog="icons_dialog = $event"
    ></relative-trace-icons>
  </div>
</template>

<script>
import { parse_json } from "@/io";
import KiteUpload from "../base/kite_upload.vue";
import PathsLayers from "./paths_layers.vue";
import Vue from "vue";
import { formatTester } from "@/functions-tools";
import RelativeTraceIcons from "./relative_trace_icons.vue";
import { mapState, mapActions } from "vuex";
import TellaeMenu from "@/components/base/menu.vue";
import TellaeMenuItem from "@/components/base/menu_item.vue";

export default Vue.component("menu-trace", {
  components: {
    KiteUpload,
    PathsLayers,
    RelativeTraceIcons,
    TellaeMenu,
    TellaeMenuItem
  },

  props: ["icon", "delay", "btn_class", "map"],

  data: function () {
    return {
      loading: false,
      version: {
        trace: 0
      },
      autozoom: true, // if true automatic zoom to agents on file loading,
      agent_type_count: null,
      dialog: false,
      paths_dialog: false,
      icons_dialog: false,

      // import options
      showUsersLocal: true,
      useStaticLayerLocal: true,
      stoppedPointRefreshLocal: 3600,
      activityShadowLocal: false
    };
  },
  computed: {
    ...mapState("traces", ["agentTypeList"]),
    checkFollowAgentIcon: {
      get() {
        return this.checkFollowAgent ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline";
      }
    },
    checkFollowAgent: {
      get() {
        return this.$store.state.traces.checkFollowAgent;
      },
      set(newValue) {
        this.$store.commit("traces/SET_FOLLOW_AGENT", newValue);
      }
    }
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    ...mapActions("traces", ["setTraceData"]),
    // load trace file
    async formatTraceFromFile(readResult) {
      await this.asyncStart({ type: "overlay" });
      const processing = async () => {
        try {
          readResult = await readResult;
          if (readResult.length == 0) {
            throw new Error(this.$t("trace.error.no_file"));
          } else if (readResult.length > 1) {
            throw new Error(this.$t("trace.error.multiple_file"));
          }
          let filename = readResult[0].filename;

          if (!formatTester(filename, ["geojson", "json", "gz"])) {
            throw new Error(this.$t("trace.error.format"));
          }

          let file_text = readResult[0].data;
          let allPoints = parse_json(file_text);

          await this.setTraceData({
            geojson: allPoints,
            show_users: this.showUsersLocal,
            use_static_layer: this.useStaticLayerLocal,
            stopped_point_refresh: this.stoppedPointRefreshLocal,
            activity_shadow: this.activityShadowLocal
          });
        } catch (e) {
          console.log(e);
          alert(e);
        } finally {
          await this.asyncEnd({ type: "overlay" });
        }
      };
      setTimeout(processing, 10);
      return false;
    }
  }
});
</script>
