<!--
  Show time in a div for trace animation
-->
<template>
  <div id="clock_div" v-show="display">
    <v-card class="pa-0" style="background: rgba(255, 255, 255, 0.8)">
      <v-card-text>
        <h1 style="font-size: 50px">{{ time }}</h1>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.component("clock", {
  components: {},

  props: ["time", "display"],

  data: function () {
    return {};
  },

  mounted() {},
  methods: {},
});
</script>