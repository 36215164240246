<template>
  <kite-add-flow :title="title" :read-input-data="readInputData">
    <template v-slot:flowImport="{ inputState, newInput }">
      <project-data-selector
        v-model="inputState.value"
        attribute="flows"
        @change="newInput"
        :loading="inputState.loading"
        :rules="[inputState.message]"
      />
    </template>
  </kite-add-flow>
</template>

<script lang="ts">
import Vue from "vue";
import { validateFeatureCollection } from "@/validation";
import KiteAddFlow from "./kite_add_flow.vue";
import { readFlowFromHash } from "@/io";
import ProjectDataSelector from "@/components/projects/project_data_selector.vue";
import { getBinaryName } from "@/functions-tools";

export default Vue.component("flow-from-project", {
  components: {
    KiteAddFlow,
    ProjectDataSelector
  },

  data: function () {
    return {
      title: this.$t("flows.manager.add.project.title")
    };
  },

  computed: {
    project() {
      return this.$whale.project;
    }
  },

  methods: {
    validateFeatureCollection,

    async readInputData(input, setDataName) {
      let result = await readFlowFromHash(input.hash);
      setDataName(getBinaryName(input, false));

      return {
        ...result,
        saved: input.hash
      };
    }
  }
});
</script>
