<template>
  <div>
    <v-form ref="edit_form" v-model="editionIsValid">
      <div v-if="paintType == 'color'">
        {{ getLabel() || "" }}
        <v-card-actions class="justify-center">
          <v-color-picker v-model="local_options.value" @input="update"> </v-color-picker>
        </v-card-actions>
      </div>
      <div v-if="paintType == 'size' || paintType == 'opacity'">
        <numeric-selector
          v-model="local_options.value"
          @change="update"
          :slider-constraints="constraints"
          :text-field-rules="rules"
          :label="getLabel() || ''"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";
import NumericSelector from "./numeric_selector.vue";
import { isBetween0And1 } from "@/validation";

const DEFAULT_CONSTRAINTS = {
  size: {
    min: 1,
    max: 20,
    step: 1
  },
  opacity: {
    min: 0,
    max: 1,
    step: 0.1
  }
};

export default Vue.component("constant-mapping", {
  mixins: [LayerMappingMixin],
  components: { NumericSelector },
  data() {
    return {};
  },
  computed: {
    constraints() {
      return this.options.constraints || DEFAULT_CONSTRAINTS[this.paintType] || {};
    },
    rules() {
      if (this.paintType == "size") {
        return this.size_text_field_rules;
      } else if (this.paintType == "opacity") {
        return this.opacity_text_field_rules;
      } else {
        return [];
      }
    }
  },
  methods: {
    getLabel() {
      return this.options.label || "";
    }
  }
});
</script>
