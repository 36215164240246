<!--
  Data table with kcc loss 
-->

<template>
  <kpi-card title="Pertes KCC" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.kcc_loss">
    <result-table :items="selectedData['kcc_loss']" :formats="{ actuel: '%', simulé: '%', écart: '-' }" :width="50" />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("kcc-loss", {
  components: {
    KpiCard,
    ResultTable
  },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  }
});
</script>
