<!-- 
Stepper for ff_vs new service use case 
-->

<template>
  <simulation-stepper>
    <template v-slot:environment>
      <v-form v-model="valid_step[STEPS.environment]">
        <v-row align="center">
          <v-col md="6">
            <v-autocomplete
              v-model="parameters['commune_id']"
              :items="communes"
              :rules="[mandatoryRule]"
              :disabled="async.communes > 0"
              :loading="async.communes > 0"
              :no-data-text="$t('simulations.use_cases.FF_VS.ff_vs_new_service.no_data_text.commune')"
              :label="getLabel('commune')"
              required
              prepend-icon="mdi-city"
              item-text="name"
              item-value="id"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-2" color="primary" @click="updateCommunesList"> location_searching </v-icon>
              </template>
              <span v-html="$t('simulations.use_cases.FF_VS.ff_vs_new_service.tooltip.update_communes')"></span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form> </template
    ><v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          label="Birthday date"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
    <template v-slot:demand>
      <v-form v-model="valid_step[STEPS.demand]">
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['penetration_rate']"
              :rules="penetrationRateRules"
              type="float"
              :label="getLabel('penetration_rate')"
              required
              suffix="%"
              prepend-icon="mdi-label-percent-outline"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:offer>
      <v-form v-model="valid_step[STEPS.offer]">
        <v-row>
          <v-col md="4">
            <v-text-field
              v-model="parameters['number_bikes']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('number_bikes')"
              required
              prepend-icon="mdi-bike"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <v-select
              v-model="parameters['location']"
              :items="locations"
              :rules="[mandatoryRule]"
              :label="getLabel('location')"
              required
              prepend-icon="mdi-map-marker-outline"
              return-object
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simulation-stepper>
</template>

<script lang="ts">
import Vue from "vue";
import i18n from "@/plugins/lang";
import StepperMixin from "./stepper_mixin.vue";

export default Vue.component("ff-vs-new-service", {
  mixins: [StepperMixin],
  data: () => ({
    communes: [],
    locations: [
      { text: i18n.t("simulations.use_cases.FF_VS.ff_vs_new_service.locations.random"), value: "random" },
      { text: i18n.t("simulations.use_cases.FF_VS.ff_vs_new_service.locations.grid"), value: "grid" },
      { text: i18n.t("simulations.use_cases.FF_VS.ff_vs_new_service.locations.density"), value: "density" }
    ]
  }),

  async mounted() {
    let res = await this.$whale.communeInformation();
    this.communes = res?.information || [];
  },

  computed: {},

  methods: {
    async updateCommunesList() {
      let param = this.bbox_from_map(this.map);

      let res = await this.$whale.communeInformation(param);
      this.communes = res?.information || [];
    }
  }
});
</script>
