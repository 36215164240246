<template>
  <kite-map-action
    action="draw"
    :title="$t('map_actions.draw.title')"
    :get-result="getDrawing"
    :clear-content="deleteDrawings"
    :on-open="init_geojson"
  >
    <v-card-text><span v-html="$t('map_actions.draw.desc')"></span></v-card-text>
    <v-card-text>
      <v-row>
        <v-col v-if="modes.includes('polygon')" class="d-flex">
          <v-btn
            retain-focus-on-click
            class="elevation-2 myClass"
            color="primary"
            @click="changeMode('draw_polygon')"
            :text="!(current_mode == 'draw_polygon')"
          >
            <v-icon class="outlined" left>pentagon</v-icon> {{ $t("map_actions.draw.polygon") }}
          </v-btn>
        </v-col>
        <v-col v-if="modes.includes('linestring')" class="d-flex">
          <v-btn
            class="elevation-2"
            color="primary"
            @click="changeMode('draw_line_string')"
            :text="!(current_mode == 'draw_line_string')"
          >
            <v-icon class="outlined" left>timeline</v-icon> {{ $t("map_actions.draw.linestring") }}
          </v-btn>
        </v-col>
        <v-col v-if="modes.includes('point')" class="d-flex">
          <v-btn
            class="elevation-2"
            color="primary"
            :disabled="true"
            @click="changeMode('draw_point')"
            :text="!(current_mode == 'draw_point')"
          >
            <v-icon class="outlined" left>place</v-icon> {{ $t("map_actions.draw.points") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </kite-map-action>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import KiteMapAction from "./kite_map_action.vue";

export default Vue.component("kite-draw", {
  components: {
    KiteMapAction
  },
  data: function () {
    return {
      current_mode: ""
    };
  },
  watch: {
    map(newValue) {
      if (newValue) {
        newValue.on("draw.modechange", e => {
          this.current_mode = e.mode;
        });
      }
    }
  },
  computed: {
    ...mapState(["mapActionState"]),
    ...mapState("layers", ["map", "draw"]),
    modes() {
      let modes = this.mapActionState.options.modes;
      if (modes) {
        return modes;
      } else {
        return [];
      }
    }
  },
  methods: {
    init_geojson(map_action_state) {
      let init_geojson = map_action_state.options?.geojson;
      if (init_geojson) {
        this.draw.add(init_geojson);
      }
      // move draw layers on top of others (brutal but simple)
      let draw_layers_ids = [
        "gl-draw-line.cold",
        "gl-draw-polygon-and-line-vertex-active.cold",
        "gl-draw-polygon-and-line-vertex-disactive.cold",
        "gl-draw-polygon-and-line-midpoint-active.cold",
        "gl-draw-line.hot",
        "gl-draw-polygon-and-line-vertex-active.hot",
        "gl-draw-polygon-and-line-vertex-disactive.hot",
        "gl-draw-polygon-and-line-midpoint-active.hot"
      ];
      for (let layer_id of draw_layers_ids) {
        this.map.moveLayer(layer_id);
      }
    },
    getDrawing() {
      return this.draw.getAll();
    },
    deleteDrawings() {
      this.current_mode = "";
      this.draw.deleteAll();
    },
    changeMode(mode) {
      if (mode == this.current_mode) {
        mode = "simple_select";
      }
      this.draw.changeMode(mode);
      this.current_mode = mode;
    }
  }
});
</script>

<style>
.myClass:focus::before {
  opacity: 0 !important;
}
</style>
