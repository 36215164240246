<!--
  Route selection component
-->

<template>
  <v-select
    class="mb-4"
    hide-details
    dense
    outlined
    label="Ligne"
    v-model="model"
    :items="route_items"
    item-text="name"
    item-value="id"
    v-bind="$attrs"
  ></v-select>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { ALL_ROUTES_ITEM } from "@/capucine_utils";

export default Vue.component("route-selector", {
  props: {
    value: String,
    routes: Array,
    filterSimulationAvailable: {
      type: Boolean,
      default: false
    },
    allRoutesAvailable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    route_items() {
      let routes;
      // filter routes with simulation available
      if (this.filterSimulationAvailable) {
        routes = this.routes.filter(item => {
          return item.simulation_available;
        });
      } else {
        routes = [...this.routes];
      }

      // add ALL_ROUTES_ITEM if asked
      if (this.allRoutesAvailable) {
        routes.unshift(ALL_ROUTES_ITEM);
      }

      return routes;
    }
  },

  methods: {}
});
</script>
