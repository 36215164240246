<!--
  Component representing a data table and its pagination.
-->

<template>
  <v-data-table
    :headers="headers"
    :items="items"
    class="elevation-1"
    :items-per-page="-1"
    :no-data-text="'Pas de données'"
    hide-default-footer
    :loading="loading"
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
      {{ $t("basic_headers." + header.text) }}
    </template>
    <template v-slot:item.type="{ item }">
      {{ $t("map_view.item_types." + item.type) }}
    </template>
  </v-data-table>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default Vue.component("map-view-recap", {
  components: {},

  props: ["MapView", "loading"],

  data: function () {
    return {
      headers: [
        { value: "name", text: "name" },
        { value: "type", text: "type" },
        { value: "source", text: "source" }
      ]
    };
  },
  mounted() {},

  computed: {
    items() {
      if (this.MapView) {
        return [...this.MapView.content].reverse();
      } else {
        return [];
      }
    }
  },
  methods: {}
});
</script>
