<template>
  <div>
    <kite-add-layer :title="$t('map_layers.layer_from_url.title')" :read-input-data="getGeojsonFromInput">
      <template v-slot:layerImport="{ inputState, newInput, clearInput }">
        {{ $t("map_layers.layer_from_url.intro") }}
        <v-row>
          <v-col md="8">
            <v-text-field
              v-model="inputState.value"
              prepend-icon="link"
              @change="inputState.message = true"
              :loading="inputState.loading"
              :rules="[inputState.message]"
            ></v-text-field>
          </v-col>
          <v-col md="1">
            <v-btn
              class="my-3"
              color="primary"
              icon
              :disabled="!inputState.value"
              :loading="inputState.loading"
              @click="newInput"
            >
              <v-icon> done</v-icon>
            </v-btn>
          </v-col>
          <v-col md="1">
            <v-btn class="my-3" color="primary" icon :loading="inputState.loading" @click="clearInput">
              <v-icon> close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        {{ $t("map_layers.layer_from_url.example0") }}
        <a
          href="https://data.nantesmetropole.fr/explore/?disjunctive.diffuseur&disjunctive.theme&disjunctive.features&disjunctive.publisher&disjunctive.gestionnaire&disjunctive.keyword&disjunctive.license&source=shared&sort=explore.popularity_score&geonav=world%2Fworld_fr%2Ffr_40_52%2Ffr_60_44%2Ffr_70_244400404&geonav-asc"
          target="_blank"
          >Nantes Métropole open data</a
        >
        {{ $t("map_layers.layer_from_url.example1") }}
        <a href="https://data.nantesmetropole.fr/api/v2/console#!/export_dataset/exportRecordsGEOJSON" target="_blank">
          API
        </a>
        {{ " (export dataset - geojson)" }}
      </template>
    </kite-add-layer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { validateFeatureCollection } from "@/validation";
import KiteAddLayer from "./kite_add_layer.vue";
import axios from "axios";

export default Vue.component("layer-from-url", {
  components: {
    KiteAddLayer
  },

  methods: {
    async getGeojsonFromInput(input) {
      try {
        return await axios.get(input).then(response => {
          return response.data;
        });
      } catch (error) {
        let message = "Unknown error";
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          message = "Failed with error " + error.response.status;
          if (error.response.statusText) {
            message = message + " : " + error.response.statusText;
          } else if (error.response.data.message) {
            message = message + " : " + error.response.data.message;
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          message = "No response received";
        } else {
          // Something happened in setting up the request that triggered an Error
          message = "Error: " + error.message;
        }
        throw new Error(message);
      }
    }
  }
});
</script>
