<template>
  <div>
    <v-card height="calc(100vh - 160px)" class="overflow-auto">
      <v-card-text> </v-card-text>
      <v-card-text style="position: relative; height: 60px" class="mt-n5">
        <v-row justify="center">
          <v-col>
            <v-btn color="primary" right dark large rounded @click="changePage('use_case_selection')">
              <v-icon left>mdi-plus</v-icon> {{ $t("simulations.tooltip_add") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn large rounded color="primary" @click="dialog = true">
              <v-icon left>mdi-plus</v-icon> {{ $t("simulations.tooltip_analysis") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow hide-slider>
          <v-tab :key="0" :href="`#tab-0`">
            {{ $t("simulations.simulations") }}
          </v-tab>
          <v-tab-item :key="0" :value="'tab-0'">
            <scenario-list />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <kite-dialog v-model="dialog"> <kpi-table :model="current_service" /> </kite-dialog>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import ScenarioList from "./scenarios/scenario_list.vue";
import KpiTable from "./kpis/kpi_table.vue";
import { SERVICES } from "@/simulation";
import { mapState, mapGetters, mapActions } from "vuex";

export default Vue.component("model-scenarios", {
  components: {
    ScenarioList,
    KpiTable
  },
  data: function () {
    return {
      dialog: false,
      detail: false,
      tab: 0
    };
  },
  computed: {
    ...mapState("simulations", ["current_service"]),
    ...mapGetters("simulations", ["current_service_name"])
  },
  methods: {
    ...mapActions("simulations", ["changePage"])
  }
});
</script>
