<!--
  Bottom sheet component containing captions.
-->

<template>
  <v-bottom-sheet v-model="open" hide-overlay max-width="1300px" @click:outside="open = false">
    <v-sheet>
      <v-card-title> {{ title || $t("simulations.kpis.caption") }} </v-card-title>
      <v-card elevation="0">
        <v-card-text>
          <ul v-for="(item, index) in items" :key="index">
            <li>
              {{ getText(item) + " : " + getCaption(item) }}
            </li>
          </ul>
        </v-card-text>
        <v-card-text height="100px" />
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("kite-bottom-caption", {
  components: {},

  props: {
    // dialog open boolean
    value: {
      type: Boolean,
      required: true
    },
    // items used to create captions
    items: {
      type: Array,
      required: true
    },
    // caption sheet title
    title: {
      type: String
    },
    // getter for item text (left part)
    text: {
      default: "text"
    },
    // getter from item caption (right part)
    caption: {
      default: "caption"
    }
  },

  data: function () {
    return {};
  },

  computed: {
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    getText() {
      if (typeof this.text == "string") {
        return function (item) {
          return item[this.text];
        };
      } else if (typeof this.text == "function") {
        return this.text;
      } else {
        throw new Error("Unsupported text prop for kpi-bottom-caption. Use string or function.");
      }
    },
    getCaption() {
      if (typeof this.caption == "string") {
        return function (item) {
          return item[this.caption];
        };
      } else if (typeof this.caption == "function") {
        return this.caption;
      } else {
        throw new Error("Unsupported caption prop for kpi-bottom-caption. Use string or function.");
      }
    }
  },
  methods: {}
});
</script>
