<template>
  <v-card>
    <kite-card-title title="Récapitulatif de la nouvelle carte" :tooltip="$t('map_view.add.tooltip')" />
    <v-card-text class="pt-5 pb-0">
      <v-row
        ><v-col md="6">
          <v-text-field
            v-model="newMapView.name"
            label="Nom de la carte"
            prepend-icon="drive_file_rename_outline"
            :rules="rules"
          ></v-text-field>
        </v-col>
        <v-spacer />
        <v-col md="2" v-if="newMapView?.base_layer">
          <v-list-item-title
            ><h5>{{ newMapView.base_layer }}</h5>
            <v-img
              :src="'static/baselayers/' + newMapView.base_layer + '.png'"
              :alt="newMapView.base_layer"
              height="50px"
          /></v-list-item-title>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="newMapView?.content">
      <map-view-recap :map-view="newMapView" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="closeDialog">
        {{ $t("basic_dialogs.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="
          saveMap();
          closeDialog();
        "
      >
        {{ $t("basic_dialogs.validate") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { newMapViewFromCurrentStore } from "@/map_view";
import { nameRequired, nameLengthRule } from "@/validation";
import { mapActions } from "vuex";

export default Vue.component("add-map-view", {
  props: {
    newMapView: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: function () {
    return {
      rules: [nameRequired, nameLengthRule]
    };
  },

  computed: {},
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    async saveMap() {
      this.asyncStart("mapView");
      let map_view = await newMapViewFromCurrentStore(this.newMapView.type, true);
      map_view.name = this.newMapView.name;
      let response = this.$whale.createMapView(map_view);
      response
        .then(mapView => {
          let mapped = {
            type: mapView.type,
            name: mapView.name,
            uuid: mapView.uuid,
            _creationDate: mapView._creationDate,
            _creator: mapView._creator,
            _creatorUser: {
              uuid: this.$whale.user.uuid,
              displayName: this.$whale.user.firstName + " " + this.$whale.user.lastName,
              email: this.$whale.user.email
            }
          };
          this.$whale.project._mapViews.push(mapped);
        })
        .catch(() => {
          let message = this.$t("map_view.errors.create_error");
          alert({ message, type: "error" });
        })
        .finally(() => {
          this.asyncEnd("mapView");
        });
    },
    closeDialog() {
      this.$emit("updateAddDialog", false);
    }
  }
});
</script>
