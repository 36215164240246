<!--
  Card with title and subtitle
-->

<template>
  <div class="pa-10">
    <v-card elevation="0">
      <h1 :style="{ color: $vuetify.theme.themes.light.primary }">{{ title }} {{ suffix }}</h1>
      <p v-html="subtitle" />
      <slot> Contenu du KPI </slot>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("kpi-card", {
  props: {
    title: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  }
});
</script>
