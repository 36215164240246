/**
 * Props mappings, use to describe a mapping between layer data
 * and paint props used for map display and legend creation
 * */

import { MapExport } from "./map_export";
import { have_same_keys } from "@/functions-tools";
import i18n from "./plugins/lang";

const DEFAULT_MAPPING_COLOR = "#bababa";
const DEFAULT_MAPPING_SIZE = 1;
const DEFAULT_MAPPING_OPACITY = 1;

let MAPPING_CONSTS = {
  population_densities_colors: ["#EFE3CF", "#F7C99E", "#F9AF79", "#F79465", "#E8705D", "#D4495A", "#D03568"]
};

// paint values are strings (color) or numbers (size, opacity)
type PaintValue = string | number;

const PAINT_VALUES_TYPES = {
  color: "string",
  size: "number",
  opacity: "number"
};

interface MappingOptions {
  // data property used for paint evaluation
  key: string;
  // class specific options used for paint evaluation
  // see class descriptions for detailed option specifications
  [k: string]: any;
  // size coefficient applied after paint evaluation
  // only for paint_type='size', defaults to 1
  coefficient?: number;
  // fallback paint value
  default: PaintValue;
}

abstract class PropsMapping {
  // paint layout described by the mapping
  paint_type: "color" | "size" | "opacity" | "tooltip";
  // indicates if this mapping can be modified by users
  editable: boolean;
  // mapping options
  mapping_options: MappingOptions;
  // indicates if layer paint should be set from this mapping
  paint: boolean;
  // indicates if a legend should be created from this mapping
  legend: boolean;
  // options for legend display
  legend_options: {
    // legend intro text
    text?: string;
    // layer prop unit
    unit?: string;
    // indicates if default should be displayed
    display_default?: boolean;
  };

  constructor({ paint_type, mapping_options, paint = true, legend = false, legend_options = {}, editable = true }) {
    this.paint_type = paint_type;

    this.editable = editable;

    this.legend = legend;
    this.paint = paint;

    this.legend_options = legend_options;

    // init mapping options (copy, preprocess and validate)
    let mapping_options_copy = JSON.parse(JSON.stringify(mapping_options));
    this.mapping_options = this.mappingOptionsPreprocessing(mapping_options_copy);
    this.validateOptions(this.mapping_options);
  }

  mappingOptionsPreprocessing(mapping_options: any): MappingOptions {
    if (this.paint_type == "size" && !("coefficient" in mapping_options)) {
      mapping_options.coefficient = 1;
    }
    return mapping_options;
  }

  /**
   * Get a type describing this class.
   */
  abstract getType(): string;

  /**
   * Validate the provided mapping options
   * @param _mapping_options
   */
  validateOptions(mapping_options: MappingOptions) {
    if (!(typeof mapping_options == "object") || mapping_options === undefined) {
      throw new Error("Mapping options are required and must be an object");
    }
    this.validateKey(mapping_options);
    this.validateCoefficient(mapping_options);
    this.validateDefault(mapping_options);
    this.validateTypeOptions(mapping_options);
  }

  /**
   * Validate the option descibing the propertie(s) used by the mapping.
   * @param mapping_options
   */
  validateKey(mapping_options: MappingOptions) {
    this.optionAssert(typeof mapping_options.key == "string", "Property name 'key' should be a string");
  }

  /**
   * Validate the coefficient option (used in 'size' paint type)
   * @param mapping_options
   */
  validateCoefficient(mapping_options: MappingOptions) {
    if (this.paint_type == "size") {
      this.optionAssert(typeof mapping_options.coefficient == "number", "Size coefficient should be a number");
    }
  }

  /**
   * Validate the default paint value if provided
   * @param mapping_options
   */
  validateDefault(mapping_options: MappingOptions) {
    if (mapping_options.default) {
      this.checkPaintValue(mapping_options.default);
    }
  }

  /**
   * Validate the options specific to this PropsMapping type.
   * @param _mapping_options
   */
  validateTypeOptions(_mapping_options: MappingOptions) {}

  optionAssert(assertion: boolean, message?: string) {
    if (!assertion) {
      let error_message =
        `An error occured while validating '${this.getType()}' PropsMapping options` + message ? `: ${message}` : "";
      throw new Error(error_message);
    }
  }

  checkPaintValue(value) {
    // check value type
    const expected_type = PAINT_VALUES_TYPES[this.paint_type];
    this.optionAssert(
      typeof value == expected_type,
      `Values for '${this.paint_type}' paint should be of type ${expected_type}`
    );

    // specific checks
    switch (this.paint_type) {
      case "color":
        this.optionAssert(
          value.startsWith("#") && value.length == 7,
          "Color values must use the HEX format (ex: #CD5C5C)"
        );
        break;
      case "size":
        this.optionAssert(value > 0, "Size values must be strictly positive");
        break;
      case "opacity":
        this.optionAssert(value >= 0 && value <= 1, "Opacity values must be between 0 and 1");
        break;
      default:
        throw new Error("Unknown paint type");
    }
  }

  /**
   * Get a copy of the editable values.
   * Used during paint attribute edition.
   */
  getOptionsCopy(): any {
    return JSON.parse(JSON.stringify(this.mapping_options));
  }

  /**
   * Set new mapping options (the only editable attribute of props mappings)
   * @param mapping_options
   */
  setMappingValues(mapping_options: MappingOptions): void {
    mapping_options = this.mappingOptionsPreprocessing(mapping_options);
    this.validateOptions(mapping_options);
    this.mapping_options = mapping_options;
  }

  /**
   * Convert the props mapping to a Mapbox expression.
   * Those expression are meant to be used in Mapbox layers layout descriptions.
   */
  abstract toMapboxExpression(): any;

  /**
   * Apply size coefficient option to the given expression
   * @param expression
   * @returns
   */

  /**
   * Apply a modifier on the given mapbox expression based on the paint type.
   * @param expression
   * @returns
   */
  applyExpressionModifier(expression) {
    let new_expression = expression;
    switch (this.paint_type) {
      case "color":
        // add # prefix if missing
        new_expression = ["case", ["==", "#", ["slice", expression, 0, 1]], expression, ["concat", "#", expression]];
        break;
      case "size":
        // multiply by coefficient
        if (this.mapping_options.coefficient != 1) {
          new_expression = ["to-number", ["*", expression, this.mapping_options.coefficient]];
        }
        break;
      case "opacity":
        break;
    }
    return new_expression;
  }

  /**
   * Add a legend to the map export that fits the
   * mapping described by this class.
   * @param _map_export
   * @param _layer
   */
  addToMapExport(_map_export, _layer) {
    // by default, nothing is done here
    // implement behaviour in subclasses
  }

  /**
   * Get the a default paint value regarding the mapping paint type.
   * @returns
   */
  getDefault() {
    switch (this.paint_type) {
      case "color":
        return DEFAULT_MAPPING_COLOR;
      case "size":
        return DEFAULT_MAPPING_SIZE;
      case "opacity":
        return DEFAULT_MAPPING_OPACITY;
      case "tooltip":
        return undefined;
      default:
        throw new Error(`Unsupported paint type: ${this.paint_type}`);
    }
  }

  /**
   * Convert the contents of the class to a JSON.
   * @returns
   */
  toJSON() {
    return {
      type: this.getType(),
      paint_type: this.paint_type,
      legend: this.legend,
      paint: this.paint,
      mapping_options: this.mapping_options,
      legend_options: this.legend_options
    };
  }

  /**
   * Test if the provided object is a PropsMapping instance.
   * @param obj
   * @returns
   */
  static isPropsMapping(obj): boolean {
    // check type and paint_type attribute
    return (
      typeof obj == "object" &&
      // the second part of the OR corresponds to #deprecatedEditAttributes
      (("paint_type" in obj && "mapping_options" in obj) || ("value_type" in obj && "mapping_data" in obj))
    );
  }

  /**
   * Create an object used for creating legends.
   * The returned format is [{ value: paint_value, label }]
   */
  getLegendMap(): Array<any> | void {}
}

/**
 * Map all features to a constant paint value (not a mapping really)
 *
 * Specific mapping options:
 * - value: paint value (constant for all features)
 * - label: label of the paint attribute
 * - constraints: constraints applied to value selection
 */
class ConstantMapping extends PropsMapping {
  getType() {
    return "constant";
  }

  toMapboxExpression() {
    return this.mapping_options.value;
  }

  /**
   * Layer data is not used in ConstantMapping,
   * so the key option is not required.
   * @param _mapping_options
   */
  validateKey(_mapping_options: MappingOptions): void {}

  validateTypeOptions(mapping_options: MappingOptions): void {
    this.checkPaintValue(mapping_options.value);
  }

  addToMapExport(map_export, layer) {
    // symbol is defined by layer class
    layer.addExportLegendFigure(map_export, this.mapping_options.value);
    map_export.addLegendText(layer.getLegendText());
  }
}

/**
 * Map a prop containing paint values
 *
 */
class DirectMapping extends PropsMapping {
  getType() {
    return "direct";
  }

  toMapboxExpression() {
    let key = this.mapping_options.key;
    let getter: Array<any> = ["get", key];

    // TODO : manage color formats

    getter = this.applyExpressionModifier(getter);
    return getter;
  }
}

/**
 * Mapping between categorial prop values and paint
 *
 * Specific mapping options:
 * - values_map: { value: paint } mapping between prop values and paint values
 * - values_labels: { value: label } mapping between prop values and their labels
 */
class CategoryMapping extends PropsMapping {
  getType(): string {
    return "category";
  }

  toMapboxExpression() {
    let data = this.mapping_options;
    const value_key: String = data.key;
    let prop: Array<any> = ["case"];

    // deal with null value
    // prop.push(["!", ["to-boolean", ["get", value_key]]]);
    // prop.push(this.getDefault());

    for (let key in data.values_map) {
      prop.push(["==", ["to-string", ["get", value_key]], ["to-string", key]]);
      prop.push(data.values_map[key]);
    }

    prop.push(data.default || this.getDefault());

    return this.applyExpressionModifier(prop);
  }

  getCategoryLabel(category) {
    let label;
    if (this.mapping_options.values_labels) {
      // i18n will fallback to given string if translation is not found
      label = i18n.t(this.mapping_options.values_labels[category]);
    } else {
      label = category;
    }
    return label;
  }

  validateTypeOptions(mapping_options): void {
    if (mapping_options.values_labels) {
      this.optionAssert(
        have_same_keys(mapping_options.values_map, mapping_options.values_labels),
        "'values_map' and 'values_labels' keys are not coherent"
      );
    }

    for (const key in mapping_options.values_map) {
      this.checkPaintValue(mapping_options.values_map[key]);
    }
  }

  addToMapExport(map_export, layer) {
    let layer_opacity = layer.map.getPaintProperty(layer.id, layer._opacity_prop);
    map_export.addCategorialMapping(layer.getLegendText(), this, layer_opacity);
  }

  getLegendMap() {
    let mapping_options = this.mapping_options;
    let legend_map = [];

    for (const key in mapping_options.values_map) {
      legend_map.push({
        value: mapping_options.values_map[key],
        label: this.getCategoryLabel(key)
      });
    }

    if (mapping_options.default && this.legend_options.display_default) {
      legend_map.push({
        value: mapping_options.default,
        label: i18n.t("legends.default")
      });
    }

    return legend_map;
  }
}

/**
 * Mapping between continuous prop values and paint (using intervals)
 *
 * Specific mapping options:
 * - intervals: array of numbers defining intervals ]-inf, v0[, [v1, v2[, ..., [vK, +inf[
 * - values:  paint values associated to intervals (size of intervals + 1)
 */
class ContinuousMapping extends PropsMapping {
  mappingOptionsPreprocessing(mapping_options: any): MappingOptions {
    mapping_options = PropsMapping.prototype.mappingOptionsPreprocessing.call(this, mapping_options);
    if (typeof mapping_options.values == "string") {
      mapping_options.values = MAPPING_CONSTS[mapping_options.values];
    }
    if (typeof mapping_options.intervals == "string") {
      mapping_options.intervals = MAPPING_CONSTS[mapping_options.intervals];
    }
    return mapping_options;
  }

  getType(): string {
    return "continuous";
  }

  toMapboxExpression() {
    let data = this.mapping_options;
    const value_key: String = data.key;
    let prop: Array<any> = ["case"];

    // deal with null value
    prop.push(["!", ["to-boolean", ["get", value_key]]]);
    prop.push(this.getDefault());

    for (let i = 0; i < data.intervals.length; i++) {
      prop.push(["<", ["get", value_key], data.intervals[i]]);
      prop.push(data.values[i]);
    }
    prop.push([">=", ["get", value_key], data.intervals[data.intervals.length - 1]]);
    prop.push(data.values[data.intervals.length]);

    prop.push(data.default || this.getDefault());

    return this.applyExpressionModifier(prop);
  }

  validateTypeOptions(mapping_options) {
    let intervals = mapping_options.intervals;
    let values = mapping_options.values;
    this.optionAssert(Array.isArray(intervals) && intervals.length > 0, "'intervals' option must be a non empty Array");
    this.optionAssert(typeof intervals[0] == "number", "'intervals' option must contain numbers");
    this.optionAssert(
      Array.isArray(values) && values.length == intervals.length + 1,
      "'values' option must be an array with one more element than 'intervals'"
    );
    for (let paint_value of values) {
      this.checkPaintValue(paint_value);
    }
  }

  addToMapExport(map_export, layer) {
    let layer_opacity = layer.map.getPaintProperty(layer.id, layer._opacity_prop);
    map_export.addCategorialMapping(layer.getLegendText(), this, layer_opacity);
  }

  getLegendMap() {
    let mapping_options = this.mapping_options;
    let legend_map = [];
    let nb_intervals = mapping_options.intervals.length;
    legend_map.push({
      value: mapping_options.values[0],
      label: i18n.t("props_mapping.continuous.less_than") + mapping_options.intervals[0]
    });

    for (let i = 1; i < nb_intervals; i++) {
      legend_map.push({
        value: mapping_options.values[i],
        label: mapping_options.intervals[i - 1] + i18n.t("props_mapping.continuous.to") + mapping_options.intervals[i]
      });
    }

    legend_map.push({
      value: mapping_options.values[nb_intervals],
      label: mapping_options.intervals[nb_intervals - 1] + i18n.t("props_mapping.continuous.and_more")
    });

    if (mapping_options.default && this.legend_options.display_default) {
      legend_map.push({
        value: mapping_options.default,
        label: i18n.t("props_mapping.generic.default")
      });
    }

    return legend_map;
  }
}

/**
 * Interpolation mapping of a given value.
 * See https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions for instance.
 *
 * Specific mapping options: (todo: improve)
 * - mode: interpolation mode ("linear", ..)
 * - on:  interpolated property ("zoom", ..)
 * - interpolations: interpolation values
 * - values: paint values
 */
class InterpolationMapping extends PropsMapping {
  getType(): string {
    return "interpolation";
  }

  toMapboxExpression() {
    let data = this.mapping_options;
    let prop = ["interpolate", [data.mode], [data.on]];

    for (let i = 0; i < data.interpolations.length; i++) {
      prop.push(data.interpolations[i]);
      prop.push(data.values[i]);
    }

    return prop;
  }

  setMappingValues(_values: any): void {
    throw new Error("Method not implemented");
  }
}

/**
 * Layer tooltip description. Not really a mapping,
 * but still an editable paint-like property of the layer.
 *
 * Specific mapping options:
 * - keys: list of properties selected for tooltip display
 */
class TooltipMapping extends PropsMapping {
  paint_type: "tooltip";

  constructor({ mapping_options = { keys: [] }, editable = true }) {
    super({ paint_type: "tooltip", mapping_options, paint: true, legend: false, editable });
  }

  getType(): string {
    return "tooltip";
  }

  validateKey(mapping_options: MappingOptions): void {
    this.optionAssert(Array.isArray(mapping_options.keys), "An array of keys is expected for tooltip values");
    for (const key of mapping_options.keys) {
      this.optionAssert(typeof key == "string", "Property names 'keys' should be strings");
    }
  }

  toMapboxExpression() {
    throw new Error("TooltipMapping should not be used as a layer paint");
  }
}

// instance creation

// { type: mapping_class} object
const MAPPING_CLASSES = {
  constant: ConstantMapping,
  direct: DirectMapping,
  category: CategoryMapping,
  continuous: ContinuousMapping,
  interpolation: InterpolationMapping,
  tooltip: TooltipMapping
};

function isPropsMappingInit(object: any) {
  return typeof object == "object" && "type" in object && ("mapping_options" in object || "mapping_data" in object);
}

/**
 * Create a props mapping class instance from the given props mapping.
 * @param props_mapping props mapping json
 * @returns PropsMapping subclass instance
 */
function jsonToPropsMapping(props_mapping: any) {
  // correction of old format #deprecatedEditAttributes
  let mapping = {
    type: props_mapping.type,
    editable: props_mapping.editable,
    legend: props_mapping.legend,
    paint: props_mapping.paint,
    paint_type: props_mapping.paint_type || props_mapping.value_type,
    mapping_options: props_mapping.mapping_options || props_mapping.mapping_data,
    legend_options: props_mapping.legend_options || {
      text: props_mapping.text,
      unit: props_mapping.unit,
      display_default: props_mapping.display_default
    }
  };

  // get mapping class using type prop
  let mapping_class = MAPPING_CLASSES[mapping.type];
  if (!mapping_class) {
    throw new Error(`Unknown mapping type: ${mapping.type}`);
  }

  // create mapping instance
  mapping_class = new mapping_class(mapping);

  return mapping_class;
}

/**
 * Create a props mapping json from constants
 * @param paint_type paint type
 * @param values array of paint values
 * @param edit_constraint array of paint labels
 * @returns
 */
function mappingFromConstant(paint_type, values) {
  let values_obj;
  if (typeof values == "object" && Array.isArray(values)) {
    values_obj = Object.fromEntries(
      values.map((val, idx) => {
        return [idx, val];
      })
    );
  } else {
    values_obj = values;
  }

  return {
    type: "constant",
    paint_type,
    legend: false,
    paint: true,
    mapping_options: {
      values: values_obj
    }
  };
}

//

export {
  MAPPING_CLASSES,
  PropsMapping,
  ConstantMapping,
  DirectMapping,
  CategoryMapping,
  ContinuousMapping,
  InterpolationMapping,
  TooltipMapping,
  jsonToPropsMapping,
  mappingFromConstant,
  isPropsMappingInit
};
