<template>
  <kite-dialog v-model="dialog">
    <v-card>
      <kite-card-title title="Fichiers du scénario" tooltip="Informations et actions sur les fichiers du scénario">
      </kite-card-title>
      <v-card-text />
      <v-card-text>
        <v-data-table
          v-model="selected_files"
          :items="files"
          :headers="headers"
          item-key="hash"
          dense
          show-select
          class="elevation-1"
          checkbox-color="primary"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <kite-action-button
          :disabled="selected_files.length == 0"
          :handler="downloadFiles"
          action="downloadScenariofiles"
        >
          {{ $t("basic_dialogs.download") }}
        </kite-action-button>
      </v-card-actions>
    </v-card>
  </kite-dialog>
</template>

<script>
import Vue from "vue";
import KiteCardTitle from "@/components/base/kite_card_title.vue";
import KiteActionButton from "@/components/projects/button_action.vue";
import { mapState, mapActions } from "vuex";
import { downloadData } from "@/io";
import JSZip from "jszip";

export default Vue.component("scenario-files", {
  components: {
    KiteCardTitle,
    KiteActionButton
  },
  props: ["scenario"],

  data: function () {
    return {
      selected_files: [],
      headers: [
        { text: "Nom", value: "name" },
        { text: "Format", value: "metadata.compressed-mimetype" },
        { text: "Contenu", value: "metadata.content" },
        { text: "Sujet", value: "metadata.subject" }
      ]
    };
  },
  computed: {
    ...mapState("simulations", ["scenarioList"]),
    files() {
      if (this.scenario) {
        return this.scenario._inputs.concat(this.scenario._outputs);
      } else {
        return [];
      }
    },
    dialog: {
      get() {
        return this.scenario != undefined;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      }
    }
  },
  methods: {
    ...mapActions(["asyncStart", "asyncEnd"]),
    closeDialog() {
      this.selected_files = [];
      this.$emit("closeDialog");
    },
    // zip and download selected scenario files
    async downloadFiles() {
      this.asyncStart("downloadScenariofiles");
      let zip = new JSZip();
      let file;
      let binary;

      try {
        // browse scenario files and get their binaries
        for (let i = 0; i < this.selected_files.length; i++) {
          file = this.selected_files[i];
          binary = await this.getFileBinary(file);
          zip.file(file.name, binary);
        }

        // zip binaries together
        zip = await zip.generateAsync({ type: "blob" });

        //download zip
        downloadData(zip, this.scenario.scenario_name + ".zip", "application/octet-stream");

        // close dialog
        this.closeDialog();
      } catch (e) {
        let message = this.$t("simulations.data.error_download") + ": " + e;
        alert({ message, type: "error" });
      } finally {
        this.asyncEnd("downloadScenariofiles");
      }
    },
    // get the binary (File object) of the provided scenario file
    async getFileBinary(file) {
      let index = -1;
      let type = undefined;

      // find file index in _inputs or _outputs
      const index_inputs = this.scenario._inputs.findIndex(file_info => {
        return file_info == file;
      });
      const index_outputs = this.scenario._outputs.findIndex(file_info => {
        return file_info == file;
      });
      if (index_inputs != -1) {
        type = "_inputs";
        index = index_inputs;
      } else if (index_outputs != -1) {
        type = "_outputs";
        index = index_outputs;
      } else {
        throw new Error("Error while searching index of file " + file.name);
      }

      // get file binary
      const file_url = `scenarios/${this.scenario.uuid}/${type}/${index}`;
      let file_binary = await this.$whale.downloadFromBinaries(file_url, true);
      file_binary = new File([await file_binary.blob()], file.name);
      return file_binary;
    }
  }
});
</script>
