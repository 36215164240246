<!--
  Dialog window for layer edition
-->
<template>
  <div>
    <v-dialog :value="dialog" max-width="500px" @click:outside="close" content-class="edit-dialog" hide-overlay>
      <v-tabs
        v-model="tab"
        background-color="primary"
        class="elevation-2"
        dark
        grow
        :slider-size="SLIDER_SIZE"
        :slider-color="SLIDER_COLOR"
      >
        <v-tab tab-value="color" :disabled="colorDisabled"> {{ $t("props_mapping.paint_types.color") }} </v-tab>
        <v-tab tab-value="size" :disabled="sizeDisabled"> {{ $t("props_mapping.paint_types.size") }} </v-tab>
        <v-tab tab-value="opacity" :disabled="opacityDisabled"> {{ $t("props_mapping.paint_types.opacity") }} </v-tab>
        <v-tab tab-value="tooltip" :disabled="tooltipDisabled"> {{ $t("props_mapping.paint_types.tooltip") }} </v-tab>
        <v-tab :tab-value="tab" @click="close"> <v-icon>mdi-close</v-icon> </v-tab>

        <v-tab-item eager value="color">
          <edit-tab
            :hideTypeChange="!layer.enableAttributeTypeChange"
            :edit-store="editStore"
            :edit-attributes="colorEditAttributes"
            :data-properties="dataProperties"
            :layer="layer"
            @update="updateEditAttribute($event)"
          />
        </v-tab-item>
        <v-tab-item eager value="size">
          <edit-tab
            :hideTypeChange="!layer.enableAttributeTypeChange"
            :edit-store="editStore"
            :edit-attributes="sizeEditAttributes"
            :data-properties="dataProperties"
            :layer="layer"
            @update="updateEditAttribute($event)"
          />
        </v-tab-item>
        <v-tab-item eager value="opacity">
          <edit-tab
            disableTypeChange
            :edit-store="editStore"
            :edit-attributes="opacityEditAttributes"
            :data-properties="dataProperties"
            :layer="layer"
            @update="updateEditAttribute($event)"
          />
        </v-tab-item>
        <v-tab-item eager value="tooltip">
          <v-card class="edit-card">
            <tooltip-mapping
              :props-mapping="tooltipAttribute"
              :data-properties="dataProperties"
              :layer="layer"
              @update="updateTooltipAttribute($event)"
            />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import TooltipMapping from "./mappings/tooltip_mapping.vue";
import EditTab from "./edit_tab.vue";

export default Vue.component("edit-dialog", {
  components: {
    TooltipMapping,
    EditTab
  },

  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    layer: {
      type: Object,
      required: true
    },
    refreshAttributesAtDisplay: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tab: "color",
      tooltipAttribute: {},
      editAttributes: {},
      dataProperties: [],
      editStore: {},
      SLIDER_SIZE,
      SLIDER_COLOR,
      tab_item_height: "500px"
    };
  },
  // refresh the attributes when opening the dialog
  watch: {
    dialog(value) {
      if (value && this.refreshAttributesAtDisplay) {
        this.tooltipAttribute = this.layer.tooltipAttribute;
        this.editAttributes = this.layer.editAttributes;
        this.dataProperties = this.layer.dataProperties || {};
        this.editStore = this.layer.editStore;
      }

      if (this[this.tab + "Disabled"]) {
        let enabledTab = this.findEnabledTab();
        if (enabledTab == -1) {
          let message = this.$t("props_mapping.errors.no_editable_attribute");
          alert({ message, type: "warning" });
        } else {
          this.tab = enabledTab;
        }
      }
    }
  },
  computed: {
    colorDisabled() {
      return Object.keys(this.colorEditAttributes).length == 0;
    },
    sizeDisabled() {
      return Object.keys(this.sizeEditAttributes).length == 0;
    },
    opacityDisabled() {
      return Object.keys(this.opacityEditAttributes).length == 0;
    },
    tooltipDisabled() {
      return !this.layer.enableTooltip;
    },
    sizeEditAttributes() {
      return this.getEditAttributes(this.editAttributes, "size");
    },
    colorEditAttributes() {
      return this.getEditAttributes(this.editAttributes, "color");
    },
    opacityEditAttributes() {
      return this.getEditAttributes(this.editAttributes, "opacity");
    }
  },
  methods: {
    getEditAttributes(editAttributes, paint_type) {
      let res = {};
      for (const key in editAttributes) {
        let props_mapping = editAttributes[key];
        if (props_mapping.editable && props_mapping.paint_type == paint_type) {
          res[key] = props_mapping;
        }
      }
      return res;
    },
    updateEditAttribute(payload) {
      this.layer.updateEditAttributes(payload);
    },
    updateTooltipAttribute(new_value) {
      this.layer.updateTooltipAttribute(new_value);
    },
    close() {
      this.$emit("handleUpdateDialog", false);
      this.$store.commit("layers/INCREMENT_LEGENDS_KEY");
    },
    findEnabledTab() {
      let tab = null;
      if (!this.colorDisabled) {
        return "color";
      }
      if (!this.sizeDisabled) {
        return "size";
      }
      if (!this.opacityDisabled) {
        return "opacity";
      }
      if (!this.tooltipDisabled) {
        return "tooltip";
      }
      return -1;
    }
  }
});
</script>

<style>
.edit-dialog {
  position: absolute;
  right: 0;
}
</style>
