<!--
  Main flows page
-->

<template>
  <kite-drawer-content>
    <v-row class="mt-2 ml-2" justify="center">
      <v-col col="5">
        <add-flow-dialog />
      </v-col>
    </v-row>
    <v-card class="mt-2 overflow-auto pa-2" elevation="0" height="calc(100vh - 195px)">
      <v-card-text class="pa-0">
        <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow hide-slider>
          <v-tab :key="0" :href="`#tab-0`">
            {{ $t("flows.flows") }}
          </v-tab>
          <v-tab-item :key="0" :value="'tab-0'">
            <flows-table />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-text>
        <v-divider />
      </v-card-text>
      <v-card-text class="pa-0">
        <v-expansion-panels multiple v-model="panel">
          <!-- Filters -->
          <v-expansion-panel :disabled="currentFlowsView == null">
            <v-expansion-panel-header :disable-icon-rotate="true">{{
              $t("flows.filters.title")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <flows-filters />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Plots -->
          <v-expansion-panel :disabled="getAttributes.length == 0">
            <v-expansion-panel-header :disable-icon-rotate="true">{{
              $t("flows.plots.title")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <flows-plots />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Summary -->
          <v-expansion-panel :disable-icon-rotate="true">
            <v-expansion-panel-header>{{ $t("flows.statistics.title") }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <flows-statistics />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </kite-drawer-content>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import FlowsTable from "./flows_table.vue";
import FlowsFilters from "./flows_filters.vue";
import FlowsPlots from "./flows_plots.vue";
import FlowsStatistics from "./flows_statistics.vue";
import KiteDrawerContent from "@/components/kite/base/kite_drawer_content.vue";
import AddFlowDialog from "./add/add_flow_dialog.vue";

export default Vue.component("flows-page", {
  components: {
    AddFlowDialog,
    FlowsTable,
    FlowsFilters,
    FlowsPlots,
    FlowsStatistics,
    KiteDrawerContent
  },

  data: function () {
    return { tab: 0, panel: [2] };
  },

  watch: {
    currentFlowsView(view) {
      if (!view) {
        const index = this.panel.indexOf(0);
        if (index > -1) {
          // only splice array when item is found
          this.panel.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  },

  computed: {
    ...mapState(["apiAvailable"]),
    ...mapState("flows", ["dialog", "currentFlowsView", "plot"]),
    ...mapGetters("flows", ["getAttributes"])
  },

  methods: {}
});
</script>

<style>
.tooltip-inner {
  text-align: justify;
}
</style>
