<!--
  Data table with kcc reduction
-->

<template>
  <kpi-card title="Réfaction kilométrique" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.kcc_reduction">
    <result-table
      :items="selectedData['kcc_reduction']"
      :formats="{ actuel: '€', simulé: '€', écart: '-' }"
      :width="60"
    />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("kcc-reduction", {
  components: {
    KpiCard,
    ResultTable
  },
  data: () => ({
    SUBTITLES
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  }
});
</script>
