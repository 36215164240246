<template>
  <kite-left-dialog :value="isOpen">
    <kite-draw v-show="mapActionState.action == 'draw'" />
    <kite-zone-selection v-show="mapActionState.action == 'zone_selection'" />
    <kite-point-selection v-show="mapActionState.action == 'point_selection'" />
    <kite-map-export v-show="mapActionState.action == 'map_export'" />
  </kite-left-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import KiteLeftDialog from "@/components/kite/base/kite_left_dialog.vue";
import KiteDraw from "./kite_draw.vue";
import KiteZoneSelection from "./kite_zone_selection.vue";
import KitePointSelection from "./kite_point_selection.vue";
import KiteMapExport from "./kite_map_export.vue";

export default Vue.component("kite-map-action-dialog", {
  components: {
    KiteLeftDialog,
    KiteDraw,
    KiteZoneSelection,
    KitePointSelection,
    KiteMapExport
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["mapActionState"]),
    isOpen() {
      return this.mapActionState.action != null;
    }
  },
  methods: {}
});
</script>
