<template>
  <v-snackbar v-model="alertDisplayed" :timeout="alertState.timeout" style="z-index: 20001">
    <v-icon left class="pr-1" large :color="TYPE_COLORS[alertState.type]"> {{ TYPE_ICONS[alertState.type] }} </v-icon>
    <span>{{ alertState.message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn v-if="alertState.action" @click="clickAction(alertState.action.handler)" text>
        {{ alertState.action.text }}
      </v-btn>
      <v-btn icon v-bind="attrs" @click="updateAlertDisplay(false)">
        <v-icon> clear </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { downloadData } from "@/io";

const TYPE_ICONS = {
  success: "task_alt",
  info: "info_outline",
  warning: "warning_amber",
  error: "error_outline"
};

const TYPE_COLORS = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error"
};

export default Vue.component("kite-alert", {
  data: function () {
    return {
      TYPE_COLORS,
      TYPE_ICONS
    };
  },
  watch: {
    alertDisplayed(value) {
      // when alert ends, display next alert if there is one
      if (!value && this.alertStack.length > 0) {
        this.$store.commit("SHIFT_ALERT_STACK");
        this.$nextTick(() => this.$store.commit("UPDATE_ALERT_DISPLAY", true));
      }
    }
  },
  computed: {
    ...mapState(["alertState", "alertStack"]),

    alertDisplayed: {
      get() {
        return this.$store.state.alertDisplayed;
      },
      set(value) {
        this.updateAlertDisplay(value);
      }
    }
  },
  methods: {
    ...mapActions(["closeAlert"]),
    downloadSchema() {
      if (this.alertState.schema === undefined || this.alertState.schema === null) {
        console.log("Alert schema shouldn't be null when calling download");
        return;
      }
      let schema_str = JSON.stringify(this.alertState.schema);
      let filename = this.alertState.schema.title + ".json";
      downloadData(schema_str, filename);
    },
    updateAlertDisplay(value) {
      this.$store.commit("UPDATE_ALERT_DISPLAY", value);
    },
    clickAction(handler) {
      // close alert
      this.updateAlertDisplay(false);
      // call handler function
      if (typeof handler == "function") {
        handler();
      }
    }
  }
});
</script>
