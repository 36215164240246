<template>
  <v-form ref="edit_form" v-model="editionIsValid">
    <v-card-text>
      <div v-if="paintType == 'color'">
        <div v-for="i in options.intervals.length + 1" :key="i" style="height: 60px">
          <v-row>
            <v-col cols="2">
              <v-menu offset-x left :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    retain-focus-on-click
                    :color="options.values[i - 1]"
                    fab
                    tile
                    x-small
                    class="ma-3"
                    ><v-icon color="white">edit</v-icon>
                  </v-btn>
                </template>
                <v-color-picker v-model="local_options.values[i - 1]" @input="update" mode="rgba"> </v-color-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-if="i > 1"
                hide-spin-buttons
                class="pt-2"
                outlined
                type="number"
                v-model.number="local_options.intervals[i - 2]"
                dense
                @input="update"
                :rules="[isNumber, mandatoryRule]"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col class="text-center" cols="3" align-self="center" style="font-size: 17px">
              <div v-if="1 < i && i < options.intervals.length + 1">{{ $t("props_mapping.continuous.to") }}</div>
              <div v-if="i == 1">{{ $t("props_mapping.continuous.less_than") }}</div>
              <div v-if="i == options.intervals.length + 1">{{ $t("props_mapping.continuous.and_more") }}</div>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-if="i < options.intervals.length + 1"
                hide-spin-buttons
                class="pt-2"
                outlined
                type="number"
                v-model.number="local_options.intervals[i - 1]"
                dense
                @input="update"
                :rules="[isNumber, mandatoryRule]"
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-text>
      <div v-if="paintType == 'size'">
        <v-text-field
          v-model.number="local_options.coefficient"
          :label="$t('props_mapping.generic.coefficient')"
          type="number"
          :rules="size_text_field_rules"
          @input="update"
        />
      </div>
    </v-card-text>
  </v-form>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";

export default Vue.component("continuous-mapping", {
  mixins: [LayerMappingMixin],

  data() {
    return {};
  },
  computed: {},
  methods: {}
});
</script>
