<!--
  Data tables with robustness per direction
-->

<template>
  <kpi-card title="Robustesse" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-row class="mt-5" v-if="data !== undefined">
      <v-col align="center" md="6">
        <capucine-table
          :headers="headers"
          :items="data['0']"
          :loading="loading"
          :title="oneWayName"
          card-width="40vh"
          indicator="robustness"
          :exportFileName="export_table_filename_with_context({ direction: '0' })"
        >
        </capucine-table>
      </v-col>
      <v-col align="center" md="6">
        <capucine-table
          :headers="headers"
          :items="data['1']"
          :loading="loading"
          :title="returnName"
          card-width="40vh"
          indicator="robustness"
          :exportFileName="export_table_filename_with_context({ direction: '1' })"
        >
        </capucine-table>
      </v-col>
    </v-row>
    <div v-else>
      <b>Pas de données</b>
    </div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import KpiCard from "@/components/capucine/kpi_card.vue";
import { robustnessChipColor, robustnessWarningBadge } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("robustness", {
  components: { KpiCard },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "robustness",
    headers: [
      { text: "Tranche horaire", value: "période", align: "left", badge: robustnessWarningBadge },
      { text: "Robustesse", value: "battement_suffisant", align: "right", color: robustnessChipColor, format: "%" }
    ]
  }),
  computed: {},
  methods: {}
});
</script>
