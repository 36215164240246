<template>
  <div
    :style="{
      background: $vuetify.theme.themes.light.primary,
      width: '100%',
      height: '90vh',
      'text-align': 'center',
      color: 'white'
    }"
  >
    <v-card
      class="overflow-auto"
      elevation="0"
      height="80vh"
      :style="{ background: $vuetify.theme.themes.light.primary, color: 'white' }"
    >
      <div class="flex-column header">
        <v-img src="/static/logo-kite.png" max-width="100" alt="Tellae" max-height="100" />
        <version-footer />
        <div v-if="$whale.isAuthenticated">
          <div style="margin-bottom: 15px">{{ $t("welcome.welcome") }} {{ $whale.user.firstName }}</div>
        </div>
      </div>
      <div style="text-align: left; margin-left: 20px; margin-right: 20px">
        {{ $t("welcome.use_intro") }}
        <ul>
          <br />
          <li>
            <b>{{ $t("map_layers.name") }} : </b> {{ $t("map_layers.desc") }}
          </li>
          <br />

          <li>
            <b>{{ $t("flows.name") }} : </b> {{ $t("flows.desc") }}
          </li>
          <br />

          <li>
            <b>{{ $t("network.name") }} : </b> {{ $t("network.desc") }}
          </li>
          <br />

          <li>
            <b>{{ $t("simulations.name") }} : </b> {{ $t("simulations.desc") }}
          </li>
        </ul>
      </div>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="white" rounded large left @click="open_doc()">
              {{ $t("welcome.help.button") }}<v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("welcome.help.tooltip") }}</span>
        </v-tooltip>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import VersionFooter from "@/components/base/version_footer.vue";
export default {
  name: "welcome-tab",
  components: {
    VersionFooter
  },
  methods: {
    open_doc() {
      const url = "https://tellae.fr/#/blog/" + this.$i18n.locale + "/kite_guides_step_by_step";
      window.open(url, "_blank").focus();
    }
  }
};
</script>
<style>
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40%;
}
</style>
