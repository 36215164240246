<template>
  <v-card-title class="headline grey lighten-2">
    <!-- title -->
    {{ title }}
    <!-- information tooltip -->
    <v-tooltip color="primary" right content-class="tooltip-inner" max-width="500">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" class="ml-2" color="primary"> info </v-icon>
      </template>
      <span v-html="tooltip"></span>
    </v-tooltip>
  </v-card-title>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("kite-card-title", {
  props: ["title", "tooltip"]
});
</script>
