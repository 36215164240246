<!--
  Data tables with conformity score per direction
-->

<template>
  <kpi-card title="Ponctualité / Régularité" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
      <div class="d-flex flex-column align-center">
        <h1>Chargement</h1>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </div>
    </v-overlay>

    <div v-if="data === undefined"><b>Pas de données</b></div>
    <div v-else>
      <v-card-actions class="mb-5">
        <v-row>
          <v-col cols="3">
            <v-select prepend-icon="tune" label="Régulation" v-model="content" :items="contents"></v-select>
          </v-col>
          <v-col cols="3">
            <first-last-switch />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-row>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers['0']"
            :items="data[content]['0']"
            :loading="loading"
            :title="oneWayName"
            :card-width="table_width"
            :table-props="{ 'fixed-header': true, height: '430' }"
            :small-chips="true"
            indicator="conformity"
            :exportFileName="export_table_filename_with_context({ direction: '0', content })"
          >
          </capucine-table>
        </v-col>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers['1']"
            :items="data[content]['1']"
            :loading="loading"
            :title="returnName"
            :card-width="table_width"
            :table-props="{ 'fixed-header': true, height: '430' }"
            :small-chips="true"
            indicator="conformity"
            :exportFileName="export_table_filename_with_context({ direction: '1', content })"
          >
          </capucine-table>
        </v-col>
      </v-row>
    </div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { conformityChipColor } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import FirstLastSwitch from "./first_last_switch.vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("conformity", {
  components: { KpiCard, FirstLastSwitch },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "conformity",
    content: "ponctualité",
    contents: [
      { text: "Ponctualité", value: "ponctualité" },
      { text: "Régularité", value: "régularité" },
      { text: "Hybride", value: "hybride" }
    ]
  }),
  computed: {
    raw_data() {
      if (this.first_last) {
        return this.$store.state.capucine_analysis.data.conformity_first_last_only;
      } else {
        return this.$store.state.capucine_analysis.data.conformity;
      }
    },
    data() {
      return this.raw_data || { ponctualité: {}, régularité: {} };
    },
    loading() {
      if (this.first_last) {
        return this.$store.state.capucine_analysis.loading.conformity_first_last_only;
      } else {
        return this.$store.state.capucine_analysis.loading.conformity;
      }
    },
    headers() {
      return {
        0: this.getHeaders(this.sections, this.segmentsPairs, 0, this.first_last),
        1: this.getHeaders(this.sections, this.segmentsPairs, 1, this.first_last)
      };
    },
    table_width() {
      // change table width depending on number of headers
      const n_headers = this.headers[0].length;
      let table_width = "40vh";
      switch (n_headers) {
        case 2:
          table_width = "40vh";
          break;
        case 3:
          table_width = "50vh";
          break;
        case 4:
          table_width = "60vh";
          break;
        case 5:
          table_width = "70vh";
          break;
        default:
          table_width = "80vh";
      }
      return table_width;
    }
  },
  methods: {
    getHeaders(sections, segmentsPairs, direction, first_last) {
      if (direction == 1) {
        sections = [...sections].reverse();
      }
      let headers = [];
      if (!first_last) {
        headers = sections.slice(0, sections.length - 1).map((element, index) => {
          return {
            text: segmentsPairs[direction][index],
            value: element,
            align: "left",
            color: conformityChipColor,
            format: "%"
          };
        });
      }
      headers.unshift({ text: "Tranche horaire", value: "période", align: "left" });
      headers.push({ text: "Total", value: "total", align: "left", color: conformityChipColor, format: "%" });
      return headers;
    }
  }
});
</script>
