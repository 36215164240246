<template>
  <div>
    <kite-add-layer
      :title="$t('map_layers.layer_from_file.title')"
      :read-input-data="getGeojsonFromInput"
      :add-layer-function="addLayerFromProject"
    >
      <template v-slot:layerImport="{ inputState, newInput }">
        <project-data-selector
          v-model="inputState.value"
          attribute="spatial_data"
          @change="newInput"
          :loading="inputState.loading"
          :rules="[inputState.message]"
        />
      </template>
    </kite-add-layer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { parse_json } from "@/io";
import { removeExtensionFromFilename } from "@/functions-tools";
import { addCustomLayer } from "@/kite_layers";
import KiteAddLayer from "./kite_add_layer.vue";
import ProjectDataSelector from "@/components/projects/project_data_selector.vue";

export default Vue.component("layer-from-project", {
  components: {
    KiteAddLayer,
    ProjectDataSelector
  },

  computed: {},

  methods: {
    async getGeojsonFromInput(input, setDataName) {
      let geojson = await this.$whale.downloadFromBinaries(
        `projects/${this.$whale.project.uuid}/spatial_data/${input.index}`,
        true
      );
      geojson = parse_json(await geojson.text());
      geojson["hash"] = input.hash;
      setDataName(input.display_name);
      return geojson;
    },

    addLayerFromProject(data, name, config) {
      let layer_class_name = config.type;
      if (layer_class_name == "Multiple") {
        layer_class_name = "KiteGeojsonLayer";
      }
      addCustomLayer({
        layer_class_name,
        data,
        name,
        saved: data.hash,
        additionalProperties: config.additional_properties
      });
    }
  }
});
</script>
