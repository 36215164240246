<template>
  <v-card-text class="justify-center">
    <info-table
      @data_update="dataUpdateHandler"
      @fetching="computing = $event"
      :items="selected_data"
      :compute="computing"
      :data-getter="getLineInfo"
      :label-formatting="formatText"
      :loading="loading"
      :sort="getSort()"
      :title="$t('network.draw_pt_line.data.desc', { buffer: buffer_size })"
      table-width="300px"
    >
      <template v-slot:prependActions="{ loading, fetchData }">
        <v-card-text>
          <v-row align="center">
            <v-col cols="5">
              <v-select
                v-model="selected_item"
                @change="updateSelectedData"
                label="Donnée affichée"
                :items="content_items"
              >
              </v-select>
            </v-col>
            <v-col cols="7">
              <v-slider
                class="mt-4"
                :label="'Distance (m)'"
                v-model="buffer_size"
                @change="bufferUpdateHandler($event, fetchData)"
                min="100"
                max="1000"
                step="50"
                ticks
                thumb-label="always"
                :thumb-size="25"
                :readonly="loading"
              />
            </v-col> </v-row
        ></v-card-text>
      </template>
    </info-table>
  </v-card-text>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import InfoTable from "@/components/base/info_table.vue";

export default Vue.component("pt-line-stats", {
  components: {},
  props: {
    lineGeojson: {
      type: Object,
      required: true
    },
    contentKeys: {
      type: Array,
      default: () => {
        return ["poptot", "comp", "stat", "sch", "itinerary"];
      }
    },
    loading: {
      type: Boolean
    }
  },
  data: function () {
    return {
      computing: false,
      data: {},
      selected_data: [],
      buffer_size: 300,
      selected_item: null,
      content_items: []
    };
  },
  mounted() {
    this.content_items = this.contentKeys.map(key => {
      return { value: key, text: this.contentKeyText(key) };
    });
    this.selected_item = this.content_items[0].value;
  },
  watch: {
    lineGeojson: {
      handler() {
        this.computing = true;
      },
      immediate: true
    }
  },
  computed: {
    // currently selected data name
    current_content_text() {
      if (this.content_items.length > 0 && this.selected_item != null) {
        return this.contentKeyText(this.selected_item);
      } else {
        return "";
      }
    }
  },

  methods: {
    getLineInfo() {
      // ignore empty geojson or with wrong geometry types
      if (this.lineGeojson.features.length == 0) {
        return;
      }
      if (!["LineString", "MultiLineString"].includes(this.lineGeojson.features[0].geometry.type)) {
        let message = this.$t("map_layers.layer_from_file.json_error");
        alert({ message, type: "error" });
        return;
      }

      return this.$whale.transportLinePopulation(this.lineGeojson, this.buffer_size);
    },
    dataUpdateHandler(data) {
      this.data = data;
      this.updateSelectedData();
      this.$emit("data_update", data);
    },
    bufferUpdateHandler(buffer_size, fetchData) {
      this.$emit("buffer_update", buffer_size);
      fetchData();
    },
    updateSelectedData() {
      if (this.selected_item in this.data) {
        this.selected_data = this.data[this.selected_item];
      } else {
        this.selected_data = [];
      }
    },
    contentKeyText(content_key) {
      return this.$t("network.draw_pt_line.data." + content_key + ".label");
    },
    formatText(item) {
      return this.$t("network.draw_pt_line.data." + this.selected_item + "." + item.label);
    },
    getSort() {
      if (this.selected_item == "comp") {
        return (a, b) => {
          if (a == "total") {
            return -1;
          } else if (b == "total") {
            return 1;
          }
          a = parseInt(a.split("_")[0]);
          b = parseInt(b.split("_")[0]);
          return a < b ? 1 : -1;
        };
      } else {
        return undefined;
      }
    },
    valueFormat(value) {
      if (value === undefined) {
        value = "";
      }
      return value.toString().replace(".", ",");
    }
  }
});
</script>
