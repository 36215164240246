import Vue from "vue";
import Router from "vue-router";
import Welcome from "../pages/Welcome.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "*",
      meta: {
        public: true
      },
      redirect: {
        path: "/"
      }
    },
    {
      path: "/",
      name: "Accueil",
      component: Welcome
    }
  ]
});
