<template>
  <base-aggregation v-on="$listeners" :agg-options="options" v-slot="{ attributes }" hide-attributes>
    {{ $t("flows.aggregation.centered.text1") }}
    <br />
    <i>{{ $t("flows.aggregation.centered.recommendation") }}</i>

    <zoning-selector
      v-model="options.zoning"
      class="mt-3 mb-n4"
      :label="$t('flows.aggregation.admin.title')"
      solo
      :rules="[mandatoryRule]"
    />
    {{ $t("flows.aggregation.centered.text2") }}
    <v-autocomplete
      v-model="options.location"
      :items="locations"
      item-text="name"
      item-value="id"
      :label="$t('flows.aggregation.centered.location')"
      prepend-icon="mdi-select-place"
      :rules="[mandatoryRule]"
    ></v-autocomplete>
  </base-aggregation>
</template>

<script lang="ts">
import Vue from "vue";
import { mandatoryRule } from "@/validation";
import { mapState } from "vuex";
import BaseAggregation from "./base_aggregation.vue";
import ZoningSelector from "@/components/kite/base/zoning_selector.vue";

export default Vue.component("attributes-aggregation", {
  components: {
    BaseAggregation,
    ZoningSelector
  },

  data: function () {
    return {
      locations: [],
      options: {
        zoning: null,
        location: null
      }
    };
  },

  watch: {
    methods(value) {
      this.method = value[0];
    },
    currentFlowsView: {
      handler: function () {
        if (this.currentFlowsView != null) {
          this.locations = this.currentFlowsView.data.locations;
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    ...mapState("flows", ["currentFlowsView"])
  },

  methods: {
    mandatoryRule
  }
});
</script>
