<!--
  Analysis navigation drawer
-->

<template>
  <div class="px-5">
    <v-row align="center">
      <v-col md="11"><h3 align="center" class="my-4 text-uppercase">Analyse des données</h3></v-col>
      <v-col md="1" class="ml-n9">
        <glossary></glossary>
      </v-col>
    </v-row>

    <data-setup></data-setup>
    <v-divider class="mb-1" />
    <v-list nav dense>
      <v-list-item-group mandatory v-model="tab" active-class="border">
        <v-list-item color="primary" key="presentation" value="presentation">
          <v-list-item-icon>
            <v-icon> map </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Présentation </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          color="primary"
          key="analysis_synthesis"
          :disabled="day_type !== ALL_DAYS"
          value="analysis_synthesis"
        >
          <v-list-item-icon>
            <v-icon> leaderboard </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Synthèse </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-3" />
        <v-list-item
          class="mt-1"
          color="primary"
          v-for="(item, i) in ANALYSIS_KPIS"
          :key="i"
          :value="item.key"
          :disabled="day_type == ALL_DAYS || data[item.key] == null"
        >
          <v-list-item-icon>
            <v-progress-circular size="20" width="2" indeterminate v-if="loading[item.key]" />
            <v-icon v-else v-text="item.icon"></v-icon
          ></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import DataSetup from "./data_setup.vue";
import Glossary from "../glossary.vue";
import { ANALYSIS_KPIS, ALL_DAYS, ALL_ROUTES } from "@/capucine_utils";

import { mapState, mapActions } from "vuex";

export default Vue.component("navigation-drawer-analysis", {
  components: { DataSetup, Glossary },
  data: () => ({
    ANALYSIS_KPIS,
    ALL_DAYS,
    ALL_ROUTES
  }),
  watch: {
    day_type(value) {
      if (value == ALL_DAYS && this.tab != "synthesis") {
        this.tab = "presentation";
      }
      if (value !== ALL_DAYS && this.tab == "synthesis") {
        this.tab = "travel_time";
      }
    }
  },
  computed: {
    ...mapState("capucine_analysis", ["tab", "day_type", "data", "route_id", "loading"]),
    tab: {
      get() {
        return this.$store.state.capucine_analysis.tab;
      },
      set(value) {
        this.setTab(value);
      }
    }
    // disable items if no data
    /*
    disabled() {
      let disabled = {};

      for (let item of this.drawer) {
        let i = item.key;
        disabled[i] = false;
      }
      disabled["synthesis"] = false;

      // for each indicator
      // for (let item of this.drawer) {
      //   let i = item.key;
      //   disabled[i] = false;
      //   if (!Object.keys(this.data).includes(i)) {
      //     disabled[i] = true;
      //   } else if (this.data[i] === undefined) {
      //     // for buffer time
      //     disabled[i] = true;
      //   } else if (Object.keys(this.data[i]).length === 0) {
      //     disabled[i] = true;
      //   } else if (Object.keys(this.data[i]).includes("0")) {
      //     if (this.data[i]["0"].length === 0) {
      //       // for vehicle load
      //       disabled[i] = true;
      //     } else if (Object.keys(this.data[i]["0"]).includes("HPM")) {
      //       // for serpentary
      //       if (this.data[i]["0"]["HPM"]["down"].length === 0) {
      //         disabled[i] = true;
      //       }
      //     }
      //   }
      // }

      // for synthesis
      disabled["synthesis"] = false;
      if (Object.keys(this.data["analysis_synthesis"]).length > 0) {
        if (this.route_id == ALL_ROUTES && this.data["analysis_synthesis"][ALL_ROUTES][0]["ponctualité"] === "") {
          disabled["synthesis"] = true;
        }
      }

      return disabled;
    }
    */
  },
  methods: {
    ...mapActions("capucine_analysis", ["setTab"])
  }
});
</script>

<style scoped>
.border {
  border: 2px solid rgb(133, 194, 135);
}
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.theme--light.v-icon {
  color: var(--v-primary-base);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.theme--light.v-list-item--disabled .theme--light.v-icon {
  color: rgba(0, 0, 0, 0.38);
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

h3 {
  color: var(--v-primary-base);
}
</style>
