<template>
  <div>
    <v-dialog v-model="dialog_analyse">
      <template #activator="dialogScope">
        <v-tooltip bottom>
          <template v-slot:activator="tooltipScope">
            <v-btn icon v-on="dialogScope.on">
              <v-icon v-on="tooltipScope.on">$vuetify.icons.search_insights</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tools.tooltip") }}</span>
        </v-tooltip>
      </template>
      <v-card height="845px">
        <v-toolbar color="primary" dark dense class="elevation-0">
          <v-toolbar-title>{{ $t("tools.title") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn large icon @click="dialog_analyse = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-tabs v-model="tab" vertical>
          <v-tab v-for="(item, i) in tabs" :key="i" :tab-value="item.key">
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ $t(item.text) }}
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item value="territory">
              <territory-kpi />
            </v-tab-item>
            <v-tab-item value="draw_pt_line">
              <draw-pt-line :dialog-updater="updateToolsDialog" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import TerritoryKpi from "@/components/kite/territory/territory_kpi.vue";
import DrawPtLine from "@/components/kite/network/draw_pt_line.vue";

export default Vue.component("tools-dialog", {
  components: { TerritoryKpi, DrawPtLine },

  data: function () {
    return {
      tab: "none",
      dialog_analyse: false,
      tabs: [
        { icon: "query_stats", key: "territory", text: "territory.dialog.title" },
        { icon: "draw", key: "draw_pt_line", text: "network.draw_pt_line.short_title" }
      ]
    };
  },

  computed: {},
  methods: {
    updateToolsDialog(value) {
      this.dialog_analyse = value;
    }
  }
});
</script>

<style scoped>
.v-tab {
  justify-content: left;
}
</style>
