<!--
  Layer legend
-->

<template>
  <div v-show="uxDisplay.legends" id="legend_div" :key="legendsKey">
    <v-expansion-panels accordion>
      <v-expansion-panel
        v-for="(layer, i) in legendLayers"
        :key="i"
        style="max-width: 250px; background-color: #ffffffcc"
      >
        <v-expansion-panel-header
          ><b>{{ layer.getName() }}</b></v-expansion-panel-header
        >
        <v-expansion-panel-content eager>
          <layer-legend :layer="layer" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import LayerLegend from "./layer_legend.vue";

export default Vue.component("legends", {
  components: {},

  data: function () {
    return {};
  },

  computed: {
    ...mapState(["uxDisplay"]),
    ...mapState("layers", ["zList", "allLayers", "visibleLayers", "legendsKey"]),
    legendLayers() {
      return this.zList
        .filter(id => this.visibleLayers.includes(id))
        .map(id => this.allLayers[id])
        .filter(layer => layer.legend != undefined && layer.legend.getLegendMap() != undefined)
        .reverse();
    }
  },
  methods: {}
});
</script>

<style scoped>
#legend_div {
  position: fixed;
  bottom: 17vh;
  right: 10px;
  width: 250px;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
}

@media (max-width: 780px) {
  #legend_div {
    display: none;
  }
}
</style>
