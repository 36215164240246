<template>
  <kite-add-flow :title="title" :read-input-data="readInputData">
    <template v-slot:flowImport="{ inputState, newInput }">
      <v-row>
        <v-col md="8">
          <v-file-input
            v-model="inputState.value"
            :placeholder="$t('add_dialog.basics.file_input_placeholder')"
            :loading="inputState.loading"
            @change="newInput"
            :rules="[inputState.message]"
            :truncate-length="34"
            multiple
          ></v-file-input>
        </v-col>
      </v-row>
      <span v-html="$t('flows.manager.add.file.intro')"></span>
    </template>
  </kite-add-flow>
</template>

<script lang="ts">
import Vue from "vue";
import { validateFeatureCollection } from "@/validation";
import { flowsViewFromFileInput } from "@/io";
import { formatTester, removeExtensionFromFilename } from "@/functions-tools";

import KiteAddFlow from "./kite_add_flow.vue";

export default Vue.component("flow-from-file", {
  components: {
    KiteAddFlow
  },

  data: function () {
    return {
      title: this.$t("flows.manager.add.file.title")
    };
  },

  computed: {},

  methods: {
    validateFeatureCollection,

    async readInputData(input, setDataName) {
      let result = await flowsViewFromFileInput(input);
      setDataName(result.name);
      return result;
    }
  }
});
</script>
