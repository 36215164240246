/* capucine_results.store.js */

import { getInstance } from "@/api/index";
import {
  DAY_TYPES,
  ALL_DAYS,
  ALL_ROUTES,
  computeSectionsPairs,
  resultTabDisabled,
  get_route_item,
  routeTitleSuffixWithDates,
  routeSurveyDate
} from "@/capucine_utils";

export default {
  // State object
  namespaced: true,
  state: {
    tab: "synthesis",
    summary: [],
    route_id: ALL_ROUTES,
    day_type: ALL_DAYS,
    data: null,
    result_loading_count: 0
  },

  // Getter functions
  getters: {
    route: (state, _, rootState) => {
      return get_route_item(
        rootState.capucine_simulation.routes,
        state.route_id,
        rootState.capucine_simulation.scenario_data_batch
      );
    },
    sections: (_, getters) => {
      return getters.route.sections.order;
    },
    day_types: (_, getters) => {
      return getters.route.day_types || DAY_TYPES;
    },
    loading: (state, _, __, rootGetters) => {
      return state.result_loading_count !== 0 || rootGetters["capucine_simulation/simulation_loading"];
    },
    segmentsPairs: (_, getters) => {
      return {
        0: computeSectionsPairs(getters.sections, 0),
        1: computeSectionsPairs(getters.sections, 1)
      };
    },
    selectedLevel: (state, getters) => {
      if (state.day_type == ALL_DAYS && getters.route.isAllRoutes) {
        return "lot";
      } else if (state.day_type == ALL_DAYS) {
        return "route";
      } else {
        return "day_type";
      }
    },
    selectedData: state => {
      if (state.data === null) {
        return {
          drive_duration: [],
          kcc_loss: [],
          kcc_reduction: [],
          number_vehicle: [],
          robustness: [],
          bonus_malus_punctuality: [],
          bonus_malus_regularity: [],
          punctuality: [],
          regularity: [],
          vehicle_load: []
        };
      } else {
        return state.data[state.route_id][state.day_type];
      }
    },
    lotSynthesisData: state => {
      if (state.data === null) {
        return [];
      } else {
        return state.data[ALL_ROUTES][ALL_DAYS]["synthesis"];
      }
    },
    surveyDate: (_, getters) => {
      return routeSurveyDate(getters.route);
    },
    titleSuffixWithDates: (_, getters) => {
      return routeTitleSuffixWithDates(getters.route);
    }
  },

  // Actions
  actions: {
    /**
     * Change the current route (this is cp from analysis)
     * @param context
     * @param route_id
     */
    async changeRoute(context, route_id) {
      // update store variables
      context.commit("CHANGE_ROUTE", route_id);

      // if the route is ALL_ROUTES, set the day_type to ALL_DAYS
      if (route_id == ALL_ROUTES) {
        context.commit("CHANGE_DAY_TYPE", { day_type: ALL_DAYS });
      } else {
        // check if current day type available for new route, otherwise set to ALL_DAYS
        let day_types = context.getters.day_types;
        if (!day_types.includes(context.state.day_type)) {
          context.commit("CHANGE_DAY_TYPE", { day_type: ALL_DAYS });
        }
      }

      context.dispatch("checkTabDisabled");
    },

    /**
     * Change current day type
     * @param context
     * @param day_type
     */
    changeDayType(context, day_type) {
      context.commit("CHANGE_DAY_TYPE", { day_type });
      context.dispatch("checkTabDisabled");
    },

    /**
     * Get the simulation results of the selected scenario
     * @param context
     */
    async getSimulationResults(context) {
      let whale = getInstance();
      let scenario = context.rootState.capucine_simulation.scenario;

      console.log("Get simulation results of scenario " + scenario);
      context.commit("ADD_RESULT_LOADER");
      try {
        let results = whale.getCapucineSimulationResults(scenario);
        results
          .then(result => {
            context.commit("UDPATE_RESULT_DATA", result);
          })
          .catch(() => {
            let message = "Erreur lors du chargement des résultats de simulation";
            alert({ message, type: "error" });
          })
          .finally(() => {
            context.commit("REMOVE_RESULT_LOADER");
          });
      } catch (e) {
        console.log(e);
      }
    },

    checkTabDisabled(context) {
      let tab = context.state.tab;
      let level = context.getters.selectedLevel;
      if (resultTabDisabled(tab, level)) {
        context.commit("SET_TAB", "robustness");
      }
    },

    setTab(context, tab) {
      context.commit("SET_TAB", tab);
    }
  },

  // Mutations
  mutations: {
    ADD_RESULT_LOADER(state) {
      state.result_loading_count += 1;
    },
    REMOVE_RESULT_LOADER(state) {
      state.result_loading_count -= 1;
    },
    CHANGE_ROUTE(state, route_id) {
      state.route_id = route_id;
    },
    CHANGE_DAY_TYPE(state, { day_type }) {
      state.day_type = day_type;
    },
    SET_TAB(state, tab) {
      state.tab = tab;
    },
    UDPATE_RESULT_DATA(state, result_data) {
      state.data = result_data;
    }
  }
};
