<!--
  Dialog to setup data
-->

<template>
  <div>
    <v-dialog v-model="dialog" width="1200px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :disabled="simulation_loading" color="primary" x-large> Sauvegarder et lancer </v-btn>
      </template>
      <v-card>
        <kite-card-title title="Lancement de la simulation" tooltip="Vérifiez les paramétrages et lancez la simulation">
        </kite-card-title>
        <v-card-text class="pa-5">
          <v-data-table :headers="headers" :items="param_watcher" hide-default-footer disablePagination>
            <template v-slot:item="{ item }">
              <tr>
                <td v-for="column in headers" :key="column.id">
                  <v-chip small :color="getChipColor(item[column.value])">
                    {{ format(item[column.value]) }}
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t("basic_dialogs.cancel") }}
          </v-btn>
          <v-btn color="primary" text @click="launch">
            {{ $t("basic_dialogs.validate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import KiteCardTitle from "@/components/base/kite_card_title.vue";

export default Vue.component("launch-simulation-dialog", {
  components: {
    KiteCardTitle
  },
  data: function () {
    return {
      dialog: false,
      headers: [
        { text: "Temps de parcours - Aller", value: "travel_time_params_aller" },
        { text: "Temps de parcours - Retour", value: "travel_time_params_retour" },
        { text: "Temps de battement - Aller", value: "buffer_time_params_aller" },
        { text: "Temps de battement - Retour", value: "buffer_time_params_retour" },
        { text: "Intervalles - Aller", value: "headway_params_aller" },
        { text: "Intervalles - Retour", value: "headway_params_retour" },
        { text: "Capacité", value: "capacity_param" },
        { text: "Neutralisation", value: "neutralisation_param" }
      ],
      texts: {
        changed: "Modifié",
        unchanged: "Non Modifié",
        error: "Erreur"
      },
      colors: {
        changed: "green",
        unchanged: "gray",
        error: "red"
      }
    };
  },
  computed: {
    ...mapState("capucine_simulation", ["param_watcher"]),
    ...mapGetters("capucine_simulation", ["simulation_loading"])
  },
  methods: {
    ...mapActions("capucine_simulation", ["saveParamsRunSimulation", "getScenarioSummary"]),
    ...mapActions("capucine_results", ["getSimulationResults"]),
    format(item) {
      return this.texts[item];
    },
    getChipColor(item) {
      return this.colors[item];
    },
    async launch() {
      this.dialog = false;
      await this.saveParamsRunSimulation();
      this.getScenarioSummary();
      this.getSimulationResults();
    }
  }
});
</script>
