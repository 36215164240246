<template>
  <div>
    <v-form ref="edit_form" v-model="editionIsValid">
      <v-card-text>
        <div v-for="(val, k) in propsMapping.mapping_options.values_map" :key="k" style="height: 60px">
          <v-row>
            <v-col cols="2" v-if="paintType == 'color'">
              <v-menu offset-x left :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" retain-focus-on-click :color="val" fab tile x-small class="ma-3"
                    ><v-icon color="white">edit</v-icon>
                  </v-btn>
                </template>
                <v-color-picker v-model="local_options.values_map[k]" @input="update" mode="rgba"> </v-color-picker>
              </v-menu>
            </v-col>
            <v-col align-self="center">
              <div style="font-size: 17px">
                {{ propsMapping.getCategoryLabel(k) }}
              </div>
            </v-col>
            <v-col cols="2" v-if="paintType == 'size'">
              <v-text-field
                v-model="local_options.values_map[k]"
                type="number"
                @input="update"
                :rules="size_text_field_rules"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text>
        <div v-if="paintType == 'size'">
          <v-text-field
            v-model.number="local_options.coefficient"
            label="Coefficient multiplicateur"
            type="number"
            :rules="size_text_field_rules"
            @input="update"
          />
        </div>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";

export default Vue.component("category-mapping", {
  mixins: [LayerMappingMixin],

  data() {
    return {};
  },
  computed: {},
  methods: {
    getUpdatedPropsMapping() {
      let props_mapping = this.propsMapping;
      if (this.paintType == "size") {
        for (const key in this.local_options.values_map) {
          this.local_options.values_map[key] = parseFloat(this.local_options.values_map[key]);
        }
      }
      props_mapping.setMappingValues(this.local_options);
      return props_mapping;
    }
  }
});
</script>
