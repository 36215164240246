<!--
  Data tables with commercial speed per stop per direction
-->

<template>
  <kpi-card title="Vitesse commerciale" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
      <div class="d-flex flex-column align-center">
        <h1>Chargement</h1>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </div>
    </v-overlay>

    <div v-if="data === undefined"><b>Pas de données</b></div>
    <div v-else>
      <v-card-actions>
        <v-row>
          <v-col cols="3">
            <direction-selector v-model="direction" prepend-icon="tune" />
          </v-col>
          <v-col>
            <h2 class="mt-3 secondary--text">{{ title }}</h2>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-row>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers[direction]"
            :items="data_theo[direction]"
            :loading="loading"
            title="Théorique"
            :card-width="table_width"
            :table-props="{ 'fixed-header': true, height: '493', dense: true }"
            indicator="commercial_speed"
            title_color="primary"
            :exportFileName="export_table_filename_with_context({ type: 'théorique' })"
          >
          </capucine-table>
        </v-col>
        <v-col align="center" md="6">
          <capucine-table
            :headers="headers[direction]"
            :items="data[direction]"
            :loading="loading"
            title="Réalisé"
            :card-width="table_width"
            :table-props="{ 'fixed-header': true, height: '493', dense: true }"
            indicator="commercial_speed"
            title_color="primary"
            :exportFileName="export_table_filename_with_context({ type: 'réalisé' })"
          >
          </capucine-table>
        </v-col>
      </v-row>
    </div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { commercialSpeedChipColor } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("commercial_speed", {
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "commercial_speed"
  }),
  computed: {
    data_theo() {
      return this.$store.state.capucine_analysis.data.commercial_speed_theoretical;
    },
    headers() {
      return {
        0: this.getHeaders(this.sections, this.segmentsPairs, 0),
        1: this.getHeaders(this.sections, this.segmentsPairs, 1)
      };
    },
    table_width() {
      // change table width depending on number of headers
      const n_headers = this.headers[0].length;
      let table_width = "40vh";
      switch (n_headers) {
        case 2:
          table_width = "40vh";
          break;
        case 3:
          table_width = "50vh";
          break;
        case 4:
          table_width = "60vh";
          break;
        case 5:
          table_width = "70vh";
          break;
        default:
          table_width = "80vh";
      }
      return table_width;
    },
    title() {
      let title = this.direction == "0" ? this.oneWayName : this.returnName;
      return title;
    }
  },
  methods: {
    getHeaders(sections, segmentsPairs, direction) {
      if (direction == 1) {
        sections = [...sections].reverse();
      }

      let headers = sections.slice(0, sections.length - 1).map((element, index) => {
        return {
          text: segmentsPairs[direction][index],
          value: element,
          align: "left",
          color: commercialSpeedChipColor,
          format: ""
        };
      });
      headers.unshift({ text: "Tranche horaire", value: "période", align: "left" });
      headers.push({ text: "Total", value: "total", align: "left", color: commercialSpeedChipColor, format: "" });
      return headers;
    }
  }
});
</script>
