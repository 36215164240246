<!--
  Results navigation drawer
-->

<template>
  <div class="px-5">
    <div align="center">
      <h3 class="mt-4 mb-1 text-uppercase">Simulation de scénarios</h3>
      <simulation-page-button class="mb-2" />
      <simulation-scenario-interface />
      <v-divider class="mt-1 mb-3" />
      <lot-selector :value="lot" disabled />
      <route-selector v-model="route_id" :routes="routes" :filter-simulation-available="true" />
      <data-batch-selector :value="scenario_data_batch" disabled />
      <day-type-selector v-model="day_type" :day-types="day_types" :disabled="route.isAllRoutes" />
    </div>
    <v-divider class="mt-n1 mb-1" />
    <v-list nav dense>
      <v-list-item-group mandatory v-model="tab" active-class="border">
        <v-list-item
          color="primary"
          key="synthesis"
          :disabled="resultTabDisabled('synthesis', selectedLevel)"
          value="synthesis"
        >
          <v-list-item-icon> <v-icon>leaderboard</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Synthèse</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-1" />
        <v-list-item
          class="mt-1"
          color="primary"
          v-for="(item, i) in results"
          :key="i"
          :value="item.key"
          :disabled="resultTabDisabled(item.key, selectedLevel)"
        >
          <v-list-item-icon> <v-icon v-text="item.icon"></v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { resultTabDisabled } from "@/capucine_utils";
import { mapState, mapActions, mapGetters } from "vuex";
import LotSelector from "../lot_selector.vue";
import RouteSelector from "../route_selector.vue";
import DataBatchSelector from "../data_batch_selector.vue";
import DayTypeSelector from "@/components/transports/day_type_selector.vue";
import SimulationPageButton from "../simulation_page_button.vue";

export default Vue.component("navigation-drawer-results", {
  components: {
    LotSelector,
    RouteSelector,
    DataBatchSelector,
    DayTypeSelector,
    SimulationPageButton
  },
  data: () => ({
    results: [
      { name: "Temps de parcours", key: "section_travel_time", icon: "update" },
      { name: "Robustesse", key: "robustness", icon: "thumb_up_off_alt" },
      { name: "Ponctualité / Régularité", key: "conformity", icon: "departure_board" },
      { name: "Pertes KCC", key: "kcc_loss", icon: "trending_down" },
      { name: "Charge", key: "vehicle_load", icon: "mdi-account-multiple" },
      { name: "Nombre de véhicules", key: "number_vehicles", icon: "mdi-bus-multiple" },
      { name: "Heures de conduite", key: "drive_duration", icon: "timelapse" },
      { name: "Réfaction kilométrique", key: "kcc_reduction", icon: "mdi-currency-eur-off" },
      { name: "Bonus / Malus", key: "bonus_malus", icon: "price_change" },
      { name: "Indicateurs à moyen constant", key: "iso_indicators", icon: "mdi-timeline-clock-outline" }
    ]
  }),
  computed: {
    ...mapState("capucine_simulation", ["lot", "routes", "scenario_data_batch"]),
    ...mapGetters("capucine_results", ["route", "day_types", "selectedLevel"]),
    route_id: {
      get() {
        return this.$store.state.capucine_results.route_id;
      },
      async set(value) {
        await this.changeRoute(value);
      }
    },
    day_type: {
      get() {
        return this.$store.state.capucine_results.day_type;
      },
      set(value) {
        this.changeDayType(value);
      }
    },
    tab: {
      get() {
        return this.$store.state.capucine_results.tab;
      },
      set(value) {
        this.setTab(value);
      }
    }
  },
  methods: {
    ...mapActions("capucine_results", ["setTab", "changeDayType", "changeRoute"]),
    resultTabDisabled
  }
});
</script>

<style scoped>
.border {
  border: 2px solid rgb(133, 194, 135);
}
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.theme--light.v-icon {
  color: var(--v-primary-base);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.theme--light.v-list-item--disabled .theme--light.v-icon {
  color: rgba(0, 0, 0, 0.38);
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

h3 {
  color: var(--v-primary-base);
}
</style>
