<!--
  Lot selection component.
  Lots are chosen among the global lots list.
-->

<template>
  <v-select
    v-model="model"
    class="mb-4"
    hide-details
    :dense="dense"
    :outlined="outlined"
    :label="label"
    :items="items"
    :return-object="returnObject"
    item-text="name"
    v-bind="$attrs"
  ></v-select>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("lot-selector", {
  // TODO : remove v-select props when using Vue 3 (https://v3-migration.vuejs.org/breaking-changes/v-bind.html)
  props: {
    value: {
      required: true
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: "Réseau / Lot"
    },
    dense: {
      type: Boolean,
      default: true
    },
    filterSimulationAvailable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapState("capucine_analysis", ["lots"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    items() {
      if (this.filterSimulationAvailable) {
        return this.lots.filter(item => {
          return item.simulation_available;
        });
      } else {
        return this.lots;
      }
    }
  },

  methods: {}
});
</script>
