<!--
Demand step proposing to get demand from user
-->

<template>
  <div>
    <v-btn @click="dialog = true" color="primary">
      {{ $t("simulations.speeds.configure") }}
    </v-btn>
    <kite-dialog v-model="dialog" :title="$t('simulations.speeds.title')" :tooltip="$t('simulations.speeds.tooltip')">
      <v-card>
        <v-card-text>
          <v-data-table :items="speedItems" :headers="headers" sort-by="highway" disable-pagination hide-default-footer>
            <template v-slot:[`item.speed`]="props">
              <kite-edit-text
                :text="props.item.speed"
                :rules="[arrayNonEmpty, isInteger, speedMinMax]"
                @save="props.item.speed = $event"
              >
                {{ props.item.speed + " km/h" }}
              </kite-edit-text>
            </template>
            <template v-slot:[`item.highway`]="props">
              {{ $t("osm.highway." + props.item.highway) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </kite-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { arrayNonEmpty } from "@/validation";

const highway_order = [
  "motorway",
  "trunk",
  "primary",
  "secondary",
  "tertiary",
  "residential",
  "living_street",
  "other"
];

export default Vue.component("simulation-speeds-param", {
  props: ["speedItems"],
  components: {},
  data: () => ({
    dialog: false
  }),

  computed: {
    headers() {
      return [
        { text: "Highway type", value: "highway", sort: this.sortHighway },
        { text: "Speed", value: "speed" }
      ];
    }
  },

  methods: {
    sortHighway(a, b) {
      let index_a = highway_order.indexOf(a);
      let index_b = highway_order.indexOf(b);
      if (index_a == index_b) {
        return 0;
      } else if (index_a > index_b) {
        return 1;
      } else {
        return -1;
      }
    },
    arrayNonEmpty,
    isInteger(v) {
      return (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || "Speed must be integer";
    },
    speedMinMax(v) {
      return (v >= 5 && v <= 130) || "Speed must be between 5 and 130 km/h";
    }
  }
});
</script>
