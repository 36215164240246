import pako from "pako";
import tinytar from "tinytar-node10";
import Papa from "papaparse";
import XLSX from "xlsx";
import i18n from "./plugins/lang";
import { formatTester, removeExtensionFromFilename, getBinaryName } from "./functions-tools";
import { validateFlowmapDataWorksheet } from "./validation";
import JSZip from "jszip";
import { getInstance } from "./api";

async function readFiles(files) {
  // make files an Array
  if (!Array.isArray(files)) {
    files = [files];
  }

  // return a global promise that resolve when all promises have been resolved
  return Promise.all(files.map(f => loadFile(f)))
    .then(res => res.flat())
    .catch(res => {
      let message = "An error occured while reading the file " + res.filename + " : " + res.error;
      alert({ message, type: "error" });
      return [];
    });
}

function loadFile(file) {
  let extension = file.name.match(/\.[0-9a-z]+$/i)[0];

  if (extension == ".gz") {
    if (file.name.substring(file.name.length - 6) == "tar.gz") {
      return loadTarGz(file);
    } else {
      return loadGz(file);
    }
  } else if (extension == ".xlsx") {
    return loadXlsx(file);
  } else if (extension == ".zip") {
    return loadZip(file);
  } else {
    return loadText(file);
  }
}

function readPromise(file, readAs, load_process) {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    // resolve the promise when the file is loaded
    // return an object containing the file name, data and an eventual error
    fr.onload = e => {
      try {
        let data = load_process(e.target.result);
        resolve({ filename: file.name, data, error: null });
      } catch (e) {
        console.log(e);
        reject({ filename: file.name, data: null, error: e });
      }
    };
    fr.onerror = function (e) {
      console.log(e);
      reject({ filename: file.name, data: null, error: e });
    };
    readAs(fr, file);
  });
}

function loadText(file) {
  return readPromise(
    file,
    function (fr, file) {
      fr.readAsText(file);
    },
    res => res
  );
}

function loadXlsx(file) {
  return readPromise(
    file,
    function (fr, file) {
      fr.readAsBinaryString(file);
    },
    function (res) {
      return XLSX.read(res, { type: "binary" });
    }
  );
}

function loadGz(file) {
  return readPromise(
    file,
    function (fr, file) {
      fr.readAsArrayBuffer(file);
    },
    function (res) {
      let binData = new Uint8Array(res);
      let data = pako.inflate(binData);
      let strData = new TextDecoder("utf-8").decode(data);
      return strData;
    }
  );
}

function loadTarGz(file) {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = e => {
      let readResults = [];
      let unpacked_files = tinytar.untar(pako.inflate(e.target.result));
      for (let i = 0; i < unpacked_files.length; i++) {
        let unpacked_file = unpacked_files[i];
        let data = new TextDecoder("utf-8").decode(unpacked_file.data);
        readResults.push({ filename: unpacked_file.name, data, error: null });
      }
      resolve(readResults);
    };
    fr.onerror = function (e) {
      reject({ filename: file.name, data: null, error: e });
    };
    fr.readAsArrayBuffer(file);
  });
}

function loadZip(file) {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = async e => {
      let jszip = new JSZip();
      let zip = await jszip.loadAsync(e.target.result);
      let readResults = [];
      for (const filename in zip.files) {
        let file_content = await zip.files[filename].async("string");
        readResults.push({ filename, data: file_content, error: null });
      }
      resolve(readResults);
    };
    fr.onerror = function (e) {
      reject({ filename: file.name, data: null, error: e });
    };
    fr.readAsArrayBuffer(file);
  });
}

// this class is not used anymore

export class ReportingFileReader {
  files: any;
  fr: any;
  loading: number;
  readResult: {};
  constructor(files) {
    // files read
    this.files = files;
    if (!Array.isArray(files)) {
      this.files = [files];
    }

    // file reader object
    this.fr = this.files.map(() => new FileReader());

    // attribute that indicates if the file reader is in loading state
    this.loading = 0;

    // attribute that contains the
    this.readResult = {};

    // the file reader reports its current state to this class
    for (let i = 0; i < this.fr.length; i++) {
      this.fr[i].onloadstart = () => (this.loading += 1);
      this.fr[i].onloadend = () => (this.loading -= 1);
    }

    // display errors
    this.fr.onerror = e => {
      let message = "Error while reading file: " + e.message;
      alert({ message, type: "error" });
    };
  }

  async load() {
    for (let i = 0; i < this.files.length; i++) {
      this.loadFile(this.files[i], this.fr[i]);
      console.log();
    }
  }

  loadFile(file, fr) {
    let extension = file.name.match(/\.[0-9a-z]+$/i)[0];

    if (extension == ".gz") {
      if (file.name.substring(file.name.length - 6) == "tar.gz") {
        this.loadTarGz(file, fr);
      } else {
        this.loadGz(file, fr);
      }
    } else if (extension == ".xlsx") {
      this.loadXlsx(file, fr);
    } else {
      this.loadText(file, fr);
    }
  }

  loadText(file, fr) {
    fr.onload = e => {
      this.readResult[file.name] = e.target.result;
    };
    fr.readAsText(file);
  }

  loadXlsx(file, fr) {
    fr.onload = e => {
      this.readResult[file.name] = XLSX.read(e.target.result, { type: "binary" });
    };
    fr.readAsBinaryString(file);
  }

  loadGz(file, fr) {
    fr.onload = e => {
      let binData = new Uint8Array(e.target.result);
      let data = pako.inflate(binData);
      let strData = new TextDecoder("utf-8").decode(data);
      this.readResult[file.name] = strData;
    };
    fr.readAsArrayBuffer(file);
  }

  loadTarGz(file, fr) {
    fr.onload = e => {
      let unpacked_files = tinytar.untar(pako.inflate(e.target.result));
      for (let i = 0; i < unpacked_files.length; i++) {
        let unpacked_file = unpacked_files[i];
        this.readResult[unpacked_file.name] = new TextDecoder("utf-8").decode(unpacked_file.data);
      }
    };
    fr.readAsArrayBuffer(file);
  }
}

/**
const loaderWrap = fct => {
    return e => {
      try {
        fct(e);
      } catch (err) {
        alert(err.message);
        this.loading = false;
      }
    };
  };
*/

// function for parsing json from text
function parse_json(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    throw new Error("Error while parsing json: " + e.message);
  }
}

function parse_csv(data) {
  return Papa.parse(data, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  });
}

function downloadData(data, filename, type = "text/plain;charset=utf-8;") {
  // Could check file-saver library as it was previously used
  let blob = new Blob([data], { type });
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

async function readFlowFromHash(hash) {
  let whale = getInstance();

  // get binary data
  let response = await whale.getProjectBinaryFromHash(hash, "flows", true);

  // get binary info
  let index = whale.getBinaryIndexFromHash(hash, "flows");
  let info = whale.project.flows[index];

  // create file object
  let file = new File([await response.arrayBuffer()], getBinaryName(info));

  if (!file) {
    throw new Error("Error while creating virtual file from data. Check file integrity and file name.");
  }

  // read flows from binary data
  let result = await flowsViewFromFileInput(file);

  // check flows type against stored metadata
  if (info.metadata.type != undefined && info.metadata.type != result.type) {
    throw new Error("Flow type does not correspond to the expected from mimetype");
  }

  return result;
}

async function flowsViewFromFileInput(input): Promise<{ name; type; data }> {
  let readResult = await readFiles(input);
  let name = input.length == 1 ? input[0].name : undefined;
  let result;

  // case on number of resulting files
  if (readResult.length == 0) {
    return;
  } else if (readResult.length == 1) {
    let file = readResult[0];
    result = await loadSingleFileFlows(file.data, file.filename);
  } else if (readResult.length == 2) {
    result = loadTwoFilesFlows(readResult, name);
  } else {
    throw new Error(this.$t("od.error.extra"));
  }

  // remove extension from filename
  result.name = removeExtensionFromFilename(result.name);

  return result;
}

async function loadSingleFileFlows(result_data, filename) {
  let data = {};
  let type;
  if (formatTester(filename, ["zip"])) {
    type = "FLOWMAP";
    let jszip = new JSZip();
    let zip = await jszip.loadAsync(result_data);
    if (!zip.files["flows.csv"] || !zip.files["locations.csv"]) {
      return undefined;
    }
    data["flows"] = parse_csv(await zip.files["flows.csv"].async("string")).data;
    data["locations"] = parse_csv(await zip.files["locations.csv"].async("string")).data;
    return {
      data,
      type
    };
  } else if (formatTester(filename, ["geojson", "json", "gz"])) {
    type = "STARLING";
    data = parse_json(result_data);
  } else if (formatTester(filename, ["xlsx"])) {
    const wb = result_data;
    validateFlowmapDataWorksheet(wb);
    type = "FLOWMAP";
    if (wb.SheetNames[0].startsWith("flows")) {
      data["flows"] = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      data["locations"] = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]]);
    } else {
      data["flows"] = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[1]]);
      data["locations"] = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    }
  } else {
    throw new Error("Unsupported file extension for flows : " + filename);
  }
  return { name: filename, data, type };
}

function loadTwoFilesFlows(files, name?) {
  let filenames = files.map(item => item.filename);
  if (!(formatTester(filenames[0], ["csv"]) && formatTester(filenames[1], ["csv"]))) {
    throw new Error(i18n.t("od.error.extension").toString());
  }

  let data = {};
  let filename_flows = null;
  let flows_data = null;
  let locations_data = null;

  if (filenames[0].startsWith("flows") && filenames[1].startsWith("locations")) {
    filename_flows = filenames[0];
    flows_data = files[0].data;
    locations_data = files[1].data;
  } else if (filenames[0].startsWith("locations") && filenames[1].startsWith("flows")) {
    filename_flows = filenames[1];
    flows_data = files[1].data;
    locations_data = files[0].data;
  } else {
    throw new Error(i18n.t("od.error.name").toString());
  }

  data["flows"] = parse_csv(flows_data).data;
  data["locations"] = parse_csv(locations_data).data;

  return { name: name || filename_flows, type: "FLOWMAP", data };
}

export { parse_json, parse_csv, downloadData, readFiles, flowsViewFromFileInput, readFlowFromHash };
