<!--
  Simulation navigation drawer
-->

<template>
  <div class="px-5">
    <div align="center">
      <h3 class="mt-4 mb-1 text-uppercase">Simulation de scénarios</h3>
      <simulation-page-button class="mb-2" />
      <simulation-scenario-interface />
      <v-divider class="mt-1 mb-3" />
      <lot-selector :value="lot" disabled />
      <v-row>
        <v-col md="10">
          <route-selector
            v-model="route_id"
            :routes="routes"
            :filter-simulation-available="true"
            :all-routes-available="false"
            :disabled="simulation_loading"
          />
        </v-col>
        <v-col md="2" class="ml-n3">
          <section-legend :route="route" :data-batch="scenario_data_batch"></section-legend>
        </v-col>
      </v-row>
      <data-batch-selector :value="scenario_data_batch" disabled />
      <day-type-selector
        v-model="day_type"
        :day-types="day_types"
        :disabled="simulation_loading"
        :all-available="false"
      />
      <v-divider class="mb-4" />
      <v-text-field
        :value="scenario_qs"
        :rules="[v => v > 0 || 'La part variable doit être supérieure à 0.']"
        color="secondary"
        suffix="€"
        type="number"
        hide-spin-buttons
        label="Part variable de la qualité de service"
        filled
        :disabled="simulation_loading"
        @change="updateScenarioQS"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SimulationScenarioInterface from "../simulation_scenario_interface.vue";
import LotSelector from "../lot_selector.vue";
import RouteSelector from "../route_selector.vue";
import DataBatchSelector from "../data_batch_selector.vue";
import DayTypeSelector from "@/components/transports/day_type_selector.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import SimulationPageButton from "../simulation_page_button.vue";

export default Vue.component("analysis-page", {
  components: { SimulationScenarioInterface, LotSelector, RouteSelector, DataBatchSelector, DayTypeSelector },
  data: function () {
    return {
      tab: "parameters"
    };
  },
  computed: {
    ...mapState("capucine_simulation", ["lot", "routes", "scenario_qs", "scenario_data_batch"]),
    ...mapGetters("capucine_simulation", ["route", "day_types", "simulation_loading"]),
    route_id: {
      get() {
        return this.$store.state.capucine_simulation.route_id;
      },
      async set(value) {
        this.changeRoute(value);
      }
    },
    day_type: {
      get() {
        return this.$store.state.capucine_simulation.day_type;
      },
      async set(value) {
        this.changeDayType(value);
      }
    }
  },
  methods: {
    ...mapActions("capucine_simulation", ["updateParamsContents", "updateScenarioQS", "changeRoute", "changeDayType"])
  }
});
</script>

<style scoped>
h3 {
  color: var(--v-primary-base);
}
</style>
