var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('kpi-card',{attrs:{"title":_vm.title,"subtitle":_vm.SUBTITLES.lot_synthesis}},[_c('v-row',[_c('v-col',{attrs:{"align":"center","md":"12"}},[_c('capucine-table',{attrs:{"headers":_vm.headers,"items":_vm.lotSynthesisData,"loading":_vm.loading,"card-width":"1300","tableProps":{
          'hide-default-footer': false,
          'disable-pagination': false,
          'footer-props': {
            'items-per-page-text': 'Lignes par page',
            'disable-items-per-page': true
          },
          'disable-sort': false,
          'must-sort': true,
          'sort-by': 'route',
          'custom-sort': _vm.sortLotTable
        },"networkType":_vm.lot.type}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }