<!--
Demand step proposing to get demand from user
-->

<template>
  <project-data-selector
    v-model="model"
    attribute="flows"
    :loading="loading"
    :rules="[message, ...rules]"
    :filter="
      data => {
        return data.metadata.type == 'STARLING';
      }
    "
  />
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import ProjectDataSelector from "@/components/projects/project_data_selector.vue";
import { validateStarlingUsers } from "@/validation";
import { readFlowFromHash } from "@/io";
import { FlowsView } from "@/models";
import { zoomOnGeojson } from "@/functions-tools";

export default Vue.component("demand-from-project", {
  props: ["value", "rules"],
  components: {
    ProjectDataSelector
  },
  data: () => ({
    loading: false,
    message: true
  }),

  computed: {
    ...mapState("layers", ["map"]),
    ...mapState("simulations", ["page"]),
    ...mapState(["mainDrawerTab"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  watch: {
    model: {
      async handler(value) {
        if (value) {
          await this.selectProjectDemand(value);
        }
        this.updateLayerVisibility();
      },
      immediate: true
    },
    page() {
      this.updateLayerVisibility();
    },
    mainDrawerTab() {
      this.updateLayerVisibility();
    }
  },
  methods: {
    ...mapActions("flows", ["displayFlows"]),
    ...mapActions("layers", ["setLayersData", "setLayersVisibility"]),
    async selectProjectDemand(data) {
      this.message = true;
      this.loading = true;
      try {
        // check hash (index is likely to change over time)
        let index_hash = this.$whale.project.flows[data.index]?.hash;
        if (index_hash != data.hash) {
          // find new index of hash
          let index = this.$whale.project.flows.map(binary => binary.hash).indexOf(data.hash);
          if (index !== -1) {
            data.index = index;
          } else {
            throw new Error("Scenario demand could not be found in project. Maybe data has been removed from project.");
          }
        }

        // fetch geojson
        let result = await readFlowFromHash(data.hash);
        if (result.type != "STARLING") {
          throw new Error("Error in flow in type");
        }
        // validate (no need since already validated when adding flows)
        // validateStarlingUsers(result.data);

        // set dedicated layer data and display it
        this.setDemandData(result.data.features);
        zoomOnGeojson(this.map, result.data);
      } catch (e) {
        console.log(e);
        // reset model and display an error message
        this.model = null;
        this.message = e.message;
      } finally {
        this.loading = false;
      }
    },
    updateLayerVisibility() {
      if (this.page == "scenario_creation" && this.mainDrawerTab == "simulation" && this.model) {
        this.updateDemandDisplay(true);
      } else {
        this.updateDemandDisplay(false);
      }
    },
    updateDemandDisplay(display) {
      if (display) {
        if (this.model) {
          this.removeFlowsViewDisplay();
          this.setLayersVisibility({ ids: ["users-arclayer-simulation"], isVisible: true });
        }
      } else {
        this.setLayersVisibility({ ids: ["users-arclayer-simulation"], isVisible: false });
      }
    },
    removeFlowsViewDisplay() {
      this.displayFlows(null);
    },
    setDemandData(data) {
      this.setLayersData({ ids: ["users-arclayer-simulation"], data });
      if (!data) {
        this.model = null;
      }
    }
  }
});
</script>
