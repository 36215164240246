<template>
  <v-card>
    <v-card-text>
      <!-- TODO : dynamic step management (much to refactor), maybe with Vue3 ? -->
      <v-stepper v-model="step" vertical outlined height="665px">
        <v-stepper-step :complete="step > STEPS.intro" :step="STEPS.intro">
          {{ $t("simulations.stepper.step.scenario", { use_case: current_use_case_name }) }}
        </v-stepper-step>
        <!-- common stepper content for scenario -->
        <v-stepper-content :step="STEPS.intro">
          <v-container>
            <p v-html="getStepDescription('scenario')"></p>
          </v-container>
        </v-stepper-content>
        <v-stepper-step :complete="step > STEPS.environment" :step="STEPS.environment">
          {{ $t("simulations.stepper.step.environment") }}
        </v-stepper-step>
        <v-stepper-content :step="STEPS.environment">
          <p v-html="getStepDescription('environment')"></p>
          <slot name="environment" />
        </v-stepper-content>
        <v-stepper-step :complete="step > STEPS.demand" :step="STEPS.demand">
          {{ $t("simulations.stepper.step.demand") }}
        </v-stepper-step>
        <v-stepper-content :step="STEPS.demand">
          <p v-html="getStepDescription('demand')"></p>
          <slot name="demand" />
        </v-stepper-content>
        <v-stepper-step :complete="step > STEPS.offer" :step="STEPS.offer">
          {{ $t("simulations.stepper.step.offer") }}
        </v-stepper-step>
        <v-stepper-content :step="STEPS.offer">
          <p v-html="getStepDescription('offer')"></p>
          <slot name="offer" />
        </v-stepper-content>
        <v-stepper-step :complete="step > STEPS.info" :step="STEPS.info">
          {{ $t("simulations.stepper.step.info", { use_case: current_use_case_name }) }}
        </v-stepper-step>
        <!-- common stepper content for scenario -->
        <v-stepper-content :step="STEPS.info">
          <v-form @submit.prevent v-model="valid_step[STEPS.info]">
            <v-text-field
              v-model="scenario_name"
              :counter="50"
              :rules="[
                v => !!v || $t('simulations.stepper.rules.scenario_name.empty'),
                v => (v && v.length <= 50) || $t('simulations.stepper.rules.scenario_name.length'),
                v => /^[a-zA-Z0-9éèêà_ ]+$/.test(v) || $t('simulations.stepper.rules.scenario_name.special_characters')
              ]"
              :label="$t('simulations.stepper.labels.scenario_name')"
              prepend-icon="mdi-text-short"
              required
            >
            </v-text-field>
            <v-textarea
              class="pt-3"
              :counter="description_max_length"
              filled
              rows="3"
              no-resize
              :label="$t('simulations.stepper.labels.comment')"
              :rules="[
                v =>
                  !v ||
                  v.length <= description_max_length ||
                  $t('simulations.stepper.rules.comment.length', { max_length: description_max_length })
              ]"
              v-model="$store.state.scenario_text"
            ></v-textarea>
          </v-form>
        </v-stepper-content>
        <v-stepper-step :complete="step > STEPS.validation" :step="STEPS.validation">
          {{ $t("simulations.stepper.step.validation") }}
        </v-stepper-step>
        <v-stepper-content :step="STEPS.validation">
          <slot name="validation">
            <v-card class="overflow-auto" height="205px">
              <stepper-recap-table
                :service="current_service"
                :use-case="current_use_case"
                :scenario-name="scenario_name"
                :parameters="parameters"
              />
            </v-card>
          </slot>
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="px-4" style="padding: 0">
      <v-spacer></v-spacer>
      <v-btn text color="primary" :disabled="step == 1" @click="clickPrevious">
        {{ $t("simulations.stepper.buttons.previous") }}
      </v-btn>
      <v-btn class="mx-2" :disabled="!valid_step[step] || step == maxStep" color="primary" @click="clickNext">
        {{ $t("simulations.stepper.buttons.next") }}
      </v-btn>
      <v-btn :disabled="step != maxStep || !valid_step[step]" color="secondary" @click="clickRunSimulation">
        {{ $t("simulations.stepper.buttons.run_simulation") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { USE_CASES, STEPS } from "@/simulation";
import StepperRecapTable from "./stepper_recap_table.vue";

export default Vue.component("simulation-stepper", {
  components: {
    StepperRecapTable
  },

  props: {
    onValidation: {
      type: Function
    }
  },

  data: () => ({
    description_max_length: 1000,
    STEPS,
    USE_CASES
  }),

  computed: {
    ...mapState("simulations", ["valid_step", "current_service", "current_use_case", "parameters"]),
    ...mapGetters("simulations", ["current_service_name", "current_use_case_name"]),
    step: {
      get() {
        return this.$store.state.simulations.step;
      },
      set(value) {
        this.$store.commit("simulations/SET_STEP", value);
      }
    },
    scenario_name: {
      get() {
        return this.$store.state.simulations.scenario_name;
      },
      set(value) {
        this.$store.commit("simulations/SET_SCENARIO_NAME", value);
      }
    },
    maxStep() {
      return Math.max.apply(null, Object.values(STEPS));
    }
  },

  methods: {
    ...mapActions("simulations", ["runUseCase", "resetStepper", "changePage"]),
    clickNext() {
      if (this.valid_step[this.step]) {
        this.step += 1;
      }
    },
    clickPrevious() {
      if (this.step > 1) {
        this.step += -1;
      }
    },
    async clickRunSimulation() {
      // return to scenario list page
      this.changePage("model_scenarios");

      // create new scenario and run it
      await this.runUseCase();

      // call onValidation function
      if (this.onValidation) {
        this.onValidation();
      }

      // reset stepper contents
      this.resetStepper();
    },
    getStepDescription(step) {
      return this.$t(`simulations.use_cases.${this.current_service}.${this.current_use_case}.description.${step}`);
    }
  }
});
</script>

<style scoped>
p {
  text-align: justify;
}
</style>
