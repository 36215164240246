<template>
  <tellae-menu :title="$t('welcome.settings')" icon="account_circle" type="button">
    <div style="background-color: white; padding-top: 10px">
      <v-subheader v-if="userName.length > 1"> {{ userName }} </v-subheader>
      <v-list dense>
        <language />
        <tellae-menu-item
          title="welcome.settings"
          icon="mdi-tune"
          @click="$emit('my-settings')"
          v-if="$whale.project._application != 'CONTEMPLATIVE'"
        />
        <tellae-menu-item
          title="welcome.disconnect"
          icon="logout"
          @click="logout"
          v-if="$whale.project._application != 'CONTEMPLATIVE'"
        />
      </v-list>
      <v-subheader>Kite {{ version }}</v-subheader>
      <v-list dense>
        <tellae-menu-item title="about.button" icon="mdi-information-variant" @click="$emit('about')" />
      </v-list>
    </div>
  </tellae-menu>
</template>

<script lang="ts">
import Language from "./language.vue";
import Vue from "vue";
import TellaeMenu from "@/components/base/menu.vue";
import TellaeMenuItem from "@/components/base/menu_item.vue";
import { mapState } from "vuex";
import router from "@/router/index";

export default Vue.component("menu-user", {
  components: {
    Language,
    TellaeMenu,
    TellaeMenuItem
  },
  computed: {
    ...mapState(["version"]),
    userName() {
      return `${this.$whale.user.firstName} ${this.$whale.user.lastName}`;
    }
  },
  methods: {
    async logout() {
      await this.$whale.logout();
      this.$router.go();
    }
  }
});
</script>
<style scoped>
.v-list-item {
  color: var(--v-primary-base) !important;
}
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.v-sheet.v-list {
  padding: 0px;
}
.v-subheader {
  height: 24px;
  font-size: 0.775rem;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
</style>
