<!-- 
Table model KPI with captions bottom sheet. 
Headers are fetched in the KPI_HEADERS const of global.ts (see headersModel prop).
KPI contents are fetched in simulation store : model_kpis.
-->

<template>
  <model-kpi :model="model" ref="model_kpi">
    <template v-slot:kpi="slotProps">
      <v-data-table
        :headers="fullHeaders"
        :items="model_kpis[model]"
        :items-per-page="5"
        :no-data-text="$t('kpi.analysis.no_data')"
        disable-sort
        :footer-props="{
          'items-per-page-text': $t('simulations.scenario_list.items_per_page')
        }"
      >
        <template v-slot:[header_attribute(header_obj)]="{ header }" v-for="header_obj in fullHeaders">
          {{ $t("simulations.kpis.headers." + header_obj.text + ".0") }}
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} {{ $t("simulations.scenario_list.of") }}
          {{ items.itemsLength }}
        </template>
        <template v-if="model_kpis[model].length > 0" v-slot:body="props">
          <draggable :list="props.items" tag="tbody">
            <tr v-for="(item, index) in props.items" :key="index">
              <td v-for="(header, index2) in fullHeaders" :key="100 * index + index2" :align="header.align">
                <div v-if="header.value != 'actions'">
                  {{ item[header.value] }}
                </div>
                <div v-else>
                  <kite-table-action icon="clear" @click="removeItem(item)" :tooltip="$t('basic_dialogs.remove')" />
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
      <v-card-actions>
        <kite-bottom-caption
          :value="slotProps.help"
          @input="closeHelp"
          :items="headers"
          :text="item => $t(`simulations.kpis.headers.${item.text}.0`)"
          :caption="item => $t(`simulations.kpis.headers.${item.text}.1`)"
        />
      </v-card-actions>
    </template>
  </model-kpi>
</template>

<script>
import Vue from "vue";
import ModelKpi from "./model_kpi.vue";
import { mapState, mapActions } from "vuex";
import { KPI_HEADERS } from "@/simulation";
import draggable from "vuedraggable";
import KiteBottomCaption from "@/components/kite/base/kite_bottom_caption.vue";

export default Vue.component("kpi-table", {
  components: {
    ModelKpi,
    draggable,
    KiteBottomCaption
  },
  props: {
    // model code
    model: {
      type: String,
      required: true
    },
    // if the model uses the table headers of another model
    // used to fetch the headers in KPI_HEADERS and the translations using i18n
    headersModel: {
      type: String
    }
  },

  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      model_kpis: state => state.simulations.model_kpis
    }),
    headers() {
      return KPI_HEADERS[this.headers_model];
    },
    fullHeaders() {
      let full_headers = [...this.headers];
      full_headers.unshift({ text: "generic.scenario", value: "scenario", width: "20%", align: "left" });
      full_headers.push({ text: "generic.actions", value: "actions", align: "center", width: "9%" });
      return full_headers;
    },
    headers_model() {
      if (this.headersModel == undefined) {
        return this.model;
      } else {
        return this.headersModel;
      }
    }
  },
  methods: {
    ...mapActions("simulations", ["removeKpi"]),
    removeItem(item) {
      this.removeKpi({ model: this.model, item });
    },
    closeHelp() {
      this.$refs.model_kpi.help = false;
    },
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    }
  }
});
</script>
