<!-- 
Display the relevant stepper according to the selected use case 
-->

<template>
  <component :is="componentName" />
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import OdtBasic from "./run/use_case_steppers/odt_basic.vue";
import OdtRailwayStation from "./run/use_case_steppers/odt_railway_station.vue";
import FfVsNewService from "./run/use_case_steppers/ff_vs_new_service.vue";
import TtPtDeleteBusRoutes from "./run/use_case_steppers/tt_pt_delete_bus_routes.vue";
import SbVsServiceReorganization from "./run/use_case_steppers/sb_vs_service_reorganization.vue";

export default Vue.component("model-simulation", {
  components: {
    OdtBasic,
    OdtRailwayStation,
    FfVsNewService,
    TtPtDeleteBusRoutes,
    SbVsServiceReorganization
  },
  computed: {
    ...mapState("simulations", ["current_use_case"]),
    componentName() {
      return this.current_use_case?.replace(/_/g, "-");
    }
  }
});
</script>
