<!--
  Bottom sheet to display section legend
-->

<template>
  <div>
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="disabled" color="primary" icon v-bind="attrs" v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
            </template>
            <span>Afficher le nom des tronçons</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-sheet class="text-center">
        <v-btn class="my-3" text color="primary" @click="sheet = !sheet"> Fermer </v-btn>
        <div>
          {{ route.comment }}
          <div v-if="timetableUrls[timetable_key]">
            <a :href="timetableUrls[timetable_key]" target="_blank">Horaires de la ligne</a>
          </div>
          <v-row>
            <v-col align="center" md="12">
              <v-card max-width="700" elevation="0" class="pa-5">
                <v-data-table
                  dense
                  :items="convertSectionsNameDictToRecords(route.sections)"
                  :headers="headers"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import axios from "axios";
import { mapState } from "vuex";

export default Vue.component("section-legend", {
  components: {},

  props: ["disabled", "route", "dataBatch"],

  data: function () {
    return {
      sheet: false,
      headers: [
        { text: "Code", value: "code", width: "30%" },
        { text: "Nom", value: "name", width: "70%" }
      ]
    };
  },
  computed: {
    ...mapState("capucine_analysis", {
      planUrls: (state: any) => state.lot_description.plan_urls,
      timetableUrls: (state: any) => state.lot_description.timetable_urls
    }),
    timetable_key() {
      return this.route.id + "-" + this.dataBatch;
    }
  },
  methods: {
    convertDescRecordsToMap(records: Array<any>) {
      return Object.fromEntries(
        records.map(item => {
          return [item.id_line, item.url];
        })
      );
    },
    convertSectionsNameDictToRecords(sections) {
      var ans = [];
      for (const key of sections.order) {
        ans.push({ code: key, name: sections.names[key] });
      }
      return ans;
    }
  }
});
</script>
