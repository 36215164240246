<template>
  <v-card height="751px">
    <v-card-text> </v-card-text>
    <v-card-text>
      <v-row align="center" justify="center" class="px-5" v-if="current_service_use_cases.length > 0">
        <v-col v-for="(use_case_item, code) in current_service_use_cases" :key="code" cols="6">
          <v-hover v-slot="{ hover }">
            <v-card
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 12 : 2"
              @click="clickUseCase(use_case_item)"
              :disabled="!use_case_item.available"
            >
              <v-img
                :src="getImg(use_case_item)"
                class="white--text align-end"
                height="300px"
                gradient="to bottom, rgba(50,50,50,0.1), rgba(50,50,50,0.5)"
              >
                <v-card-title>{{
                  $t(`simulations.use_cases.${current_service}.${use_case_item.code}.title`)
                }}</v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
//let images = require.context("@/../public/static/", true, /\.jpg$/);

export default Vue.component("model-action", {
  computed: {
    ...mapState("simulations", ["current_service", "current_use_case", "parameters"]),
    ...mapGetters("simulations", ["current_service_use_cases", "current_service_name"])
  },

  methods: {
    ...mapActions("simulations", ["startUseCase", "changePage"]),
    clickUseCase(use_case) {
      // if we change the use case or if there is no stepper started, start a new use case
      // otherwise do nothing except changing page
      if (use_case.code != this.current_use_case || Object.keys(this.parameters).length == 0) {
        let parameters = JSON.parse(JSON.stringify(use_case.parameters));
        this.startUseCase({
          use_case: use_case.code,
          parameters
        });
      }
      // go to scenario creation page
      this.changePage("scenario_creation");
    },
    getImg(use_case_item) {
      try {
        return use_case_item.img || `./static/simulation/use_cases/${use_case_item.code}.jpg`;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    }
  }
});
</script>

<style>
.v-card__title {
  word-break: normal;
}
</style>
