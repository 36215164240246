<template>
  <div>
    <kite-add-layer :title="$t('map_layers.layer_from_file.title')" :read-input-data="getGeojsonFromInput">
      <template v-slot:layerImport="{ inputState, newInput }">
        <p align="justify">{{ $t("map_layers.layer_from_file.description") }}</p>
        <v-row>
          <v-col md="8">
            <v-file-input
              v-model="inputState.value"
              :placeholder="$t('add_dialog.basics.file_input_placeholder')"
              :loading="inputState.loading"
              @change="newInput"
              :rules="[inputState.message]"
              :truncate-length="34"
            ></v-file-input>
          </v-col>
        </v-row>
      </template>
    </kite-add-layer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { readFiles, parse_json } from "@/io";
import { removeExtensionFromFilename } from "@/functions-tools";
import KiteAddLayer from "./kite_add_layer.vue";

export default Vue.component("layer-from-file", {
  components: {
    KiteAddLayer
  },

  methods: {
    async getGeojsonFromInput(input, setDataName) {
      let result = await readFiles(input);
      if (result.length > 1) {
        throw new Error(this.$t("map_layers.layer_from_file.multiple_file_error"));
      }
      try {
        let geojson = parse_json(result[0].data);
        setDataName(removeExtensionFromFilename(result[0].filename));
        return geojson;
      } catch (e) {
        throw new Error(this.$t("map_layers.layer_from_file.json_error"));
      }
    }
  }
});
</script>
