<template>
  <v-menu
    bottom
    :offset-y="true"
    :close-on-content-click="false"
    :close-on-click="false"
    open-on-hover
    close-delay="200"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on" class="pt-0 pb-0" v-if="type != 'button'" v-bind="$attrs">
        {{ title }}
      </v-btn>
      <v-btn depressed icon v-on="on" class="pt-0 pb-0" v-if="type == 'button'" v-bind="$attrs">
        <v-icon dark>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("tellae-menu", {
  props: ["title", "type", "icon"] /* use type='button' for having a button instead of a text */
});
</script>

<style scoped>
.v-list-item {
  color: var(--v-primary-base) !important;
}
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.v-sheet.v-list {
  padding: 0px;
}
.v-subheader {
  height: 24px;
  font-size: 0.775rem;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
</style>
