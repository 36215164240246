<template>
  <base-aggregation v-on="$listeners" :agg-options="options" v-slot="{ attributes }">
    <p>
      {{ $t("flows.aggregation.attributes.text") }}
    </p>
    <v-select
      prepend-icon="mdi-ray-start-arrow"
      v-model="options.origin_attribute"
      :items="attributes"
      :label="$t('flows.aggregation.attributes.origin')"
      :rules="[mandatoryRule]"
    ></v-select>
    <v-select
      prepend-icon="mdi-ray-end"
      v-model="options.destination_attribute"
      :items="attributes"
      :label="$t('flows.aggregation.attributes.destination')"
      :rules="[mandatoryRule]"
    ></v-select>
  </base-aggregation>
</template>

<script lang="ts">
import Vue from "vue";
import { mandatoryRule } from "@/validation";
import BaseAggregation from "./base_aggregation.vue";

export default Vue.component("attributes-aggregation", {
  components: {
    BaseAggregation
  },

  data: function () {
    return {
      options: {
        origin_attribute: null,
        destination_attribute: null
      }
    };
  },

  computed: {},

  methods: {
    mandatoryRule
  }
});
</script>
