<template>
  <div>
    <kite-add-layer :title="$t('map_actions.draw.title')" :read-input-data="getGeojsonFromInput">
      <template v-slot:layerImport="{ inputState, newInput }">
        <span v-html="$t('map_actions.draw.desc')"></span>
        <v-row>
          <v-col md="7"> </v-col>
          <v-col md="2">
            <v-btn text color="primary" @click="draw(inputState, newInput)"> {{ $t("basic_dialogs.start") }} </v-btn>
          </v-col>
        </v-row>
      </template>
    </kite-add-layer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import KiteAddLayer from "./kite_add_layer.vue";
import { validateFeatureCollection } from "@/validation";
import { mapState, mapActions } from "vuex";

export default Vue.component("layer-from-draw", {
  components: {
    KiteAddLayer
  },

  data: function () {
    return {};
  },

  computed: {},

  methods: {
    ...mapActions(["startMapAction"]),
    async draw(inputState, newInput) {
      // start a drawing

      let handleDraw = fc => {
        inputState.value = fc;
        newInput();
      };

      let success = await this.startMapAction({
        action: "draw",
        options: {
          modes: ["polygon", "linestring", "point"]
        },
        validationHandler: handleDraw,
        closeHandler: this.handleClose
      });

      // if the draw dialog opened correctly, close
      if (success) {
        this.$store.commit("layers/UPDATE_ADD_DIALOG", false);
      }
    },
    handleClose() {
      this.$store.commit("layers/UPDATE_ADD_DIALOG", true);
    },
    async getGeojsonFromInput(input) {
      return input;
    }
  }
});
</script>
