<!--
  Route selection component
-->

<template>
  <v-select
    class="mb-4"
    hide-details
    :dense="dense"
    :outlined="outlined"
    label="Période"
    v-model="model"
    :items="filtered_items"
    v-bind="$attrs"
    item-text="name"
    item-value="id"
    :no-data-text="'TODO'"
  ></v-select>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("data_batch-selector", {
  props: {
    value: {
      required: true
    },
    items: {
      type: Array
    },
    outlined: {
      default: true
    },
    filterSimulationAvailable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({}),
  computed: {
    ...mapState("capucine_analysis", ["data_batches_table"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    filtered_items() {
      let filtered_items = this.items;
      if (!filtered_items) {
        filtered_items = Object.values(this.data_batches_table);
      }

      if (this.filterSimulationAvailable) {
        return filtered_items.filter(item => {
          return item.simulation_available;
        });
      } else {
        return filtered_items;
      }
    }
  },

  methods: {}
});
</script>
