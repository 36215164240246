import { render, staticRenderFns } from "./menu_user.vue?vue&type=template&id=cb1f174c&scoped=true"
import script from "./menu_user.vue?vue&type=script&lang=ts"
export * from "./menu_user.vue?vue&type=script&lang=ts"
import style0 from "./menu_user.vue?vue&type=style&index=0&id=cb1f174c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-22685427/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb1f174c",
  null
  
)

export default component.exports