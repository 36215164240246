<!--
  Day type selection component
-->

<template>
  <v-select
    class="mb-4"
    hide-details
    dense
    outlined
    :label="$t('basic_transport.day_type')"
    v-model="model"
    :items="day_type_items"
    :disabled="disabled"
    v-bind="$attrs"
  ></v-select>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { DAY_TYPES, ALL_DAYS } from "@/capucine_utils";

export default Vue.component("day-type-selector", {
  props: ["value", "dayTypes", "disabled", "allAvailable"],
  data: () => ({}),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    day_type_items() {
      let day_types = !!this.dayTypes ? this.dayTypes : DAY_TYPES;
      if (this.allAvailable == undefined || this.allAvailable) {
        return [ALL_DAYS, ...day_types];
      } else {
        return day_types;
      }
    }
  },

  methods: {}
});
</script>
