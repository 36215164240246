<!--
  Data table with synthesis (lot or line)
-->

<template>
  <kpi-card :title="title" :suffix="titleSuffixWithDates" :subtitle="subtitle">
    <v-row class="ma-n4 pa-n4">
      <v-col md="5" class="ma-n4 pa-n4">
        <first-last-switch />
      </v-col>
    </v-row>
    <v-row class="mt-n2">
      <v-col align="center" md="12" class="mt-n0 pt-n0">
        <capucine-table
          :headers="headers"
          :items="synthesis_table"
          :loading="loading"
          :title="title"
          card-width="800px"
          :tableProps="table_props"
          :exportFileName="export_table_filename"
        >
        </capucine-table>
      </v-col>
    </v-row>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import KpiCard from "@/components/capucine/kpi_card.vue";
import FirstLastSwitch from "./first_last_switch.vue";
import CapucineTable from "@/components/capucine/capucine_table.vue";
import { routeChipColor, routeChipTextColor } from "@/capucine_utils";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("analysis-synthesis", {
  components: {
    KpiCard,
    CapucineTable,
    FirstLastSwitch
  },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "analysis_synthesis"
  }),
  computed: {
    ...mapState("capucine_analysis", ["type"]),
    ...mapGetters("capucine_analysis", ["routeNames"]),
    synthesis_table() {
      let synthesis_obj = this.data[this.data_batch_id];
      if (!synthesis_obj) {
        return [];
      } else {
        return synthesis_obj[this.route.id];
      }
    },
    lot_loading() {
      return this.$store.state.capucine_analysis.loading.lot;
    },
    title() {
      if (this.route.isAllRoutes) {
        let lot = this.lot.name;
        let type = this.lot.type;
        if (lot == undefined) {
          lot = "";
          type = "lot";
        }
        return `Synthèse du ${type.toLowerCase()} ${lot}`;
      } else {
        return "Synthèse de la ligne " + this.route.name;
      }
    },
    day_type_filter() {
      let day_types = ["Année", ...this.day_types];
      return v => {
        return day_types.includes(v);
      };
    },
    headers() {
      let first_header = null;
      if (this.route.isAllRoutes) {
        first_header = {
          text: "Ligne",
          value: "route",
          align: "left",
          color: routeChipColor,
          "chip-text-color": routeChipTextColor,
          format: val => this.routeNames[val]
        };
      } else {
        first_header = { text: "Type de jour", value: "day_type", align: "left", filter: this.day_type_filter };
      }
      let first_last_suffix = "";
      if (this.first_last) {
        first_last_suffix = "_first_last_only";
      }
      let headers = [
        first_header,
        { text: "Pertes KCC", value: "kcc_loss_percentage", align: "right", format: "%" },
        { text: "Robustesse", value: "robustness", align: "right", format: "%" },
        { text: "Ponctualité", value: "ponctualité" + first_last_suffix, align: "right", format: "%" },
        { text: "Régularité", value: "régularité" + first_last_suffix, align: "right", format: "%" },
        { text: "Hybride", value: "hybride" + first_last_suffix, align: "right", format: "%" },
        { text: "Production", value: "production" + first_last_suffix, align: "right", format: "%" }
      ];
      if (this.route.isAllRoutes) {
        headers.push({ text: "Charge (Max)", value: "vehicle_load", align: "right", format: "%" });
      }
      return headers;
    },
    table_props() {
      if (this.route.isAllRoutes) {
        return {
          "hide-default-footer": false,
          "disable-pagination": false,
          "footer-props": {
            "items-per-page-text": "Lignes par page",
            "disable-items-per-page": true
          },
          "disable-sort": false,
          "must-sort": true,
          "sort-by": "route",
          "no-data-text": "Synthèse manquante pour cette période"
        };
      } else {
        return undefined;
      }
    }
  },
  methods: {
    export_table_filename() {
      if (this.route.isAllRoutes) {
        return this.export_filename("lot_synthesis", this.context);
      } else {
        return this.export_filename("route_synthesis", this.context);
      }
    }
  }
});
</script>
