<!--
  Button for selecting Simulation page
-->

<template>
  <div>
    <v-btn outlined @click="setPage('simulation')" :color="setColor('simulation')" class="ma-1 pa-2 white--text">
      Paramètres
    </v-btn>
    <v-btn outlined @click="setPage('results')" :color="setColor('results')" class="ma-1 pa-2"> Résultats </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.component("simulation-page-button", {
  data: () => ({}),
  computed: {
    ...mapState("capucine_analysis", ["page"])
  },
  methods: {
    ...mapActions("capucine_analysis", ["setPage"]),
    setColor(page) {
      if (page === this.page) {
        return "primary";
      } else {
        return "primary lighten-4";
      }
    }
  }
});
</script>
