<!--
    Dialog window for uploading and reading files
-->

<template>
  <div>
    <v-dialog :value="dialog" max-width="400px" @click:outside="closeDialog()" @keydown="closeDialog()">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ titleText }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <slot name="beforeInput"></slot>
          <v-file-input
            v-model="fileInput"
            :multiple="multipleInputs"
            :placeholder="placeholderText"
            @change="readInputFiles"
            :loading="loading"
          ></v-file-input>
          <slot name="afterInput"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            {{ $t("basic_dialogs.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="loading"
            @click="
              returnReadResult();
              closeDialog();
            "
          >
            {{ $t("basic_dialogs.validate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { readFiles } from "@/io";

export default Vue.component("kite-upload", {
  components: {},
  props: {
    // boolean indicating if the dialog box is displayed
    dialog: {
      type: Boolean,
      required: true
    },
    // boolean indicating if multiple files are accepted
    multipleInputs: {
      type: Boolean,
      default: false
    },
    // title text
    title: {
      type: String,
      default: null
    },
    // placeholder text
    placeholder: {
      type: String,
      default: null
    },
    // provide the File objects via a prop
    // avoid openning the upload dialog
    externalFileInput: {
      type: Array,
      default: null
    }
  },
  data: function () {
    return {
      fileInput: null,
      readResults: null,
      loading: false
    };
  },
  watch: {
    dialog(value) {
      this.dialog = value;
    },
    externalFileInput(value) {
      // when provided with an external file input
      // read it and return the file with the usual procedure
      if (value != null && value.length > 0) {
        this.fileInput = value;
        this.readInputFiles();
        this.returnReadResult();
      }
    }
  },
  computed: {
    titleText() {
      if (this.title == null) {
        return this.$t("file_input_dialog.title");
      } else {
        return this.title;
      }
    },
    placeholderText() {
      if (this.placeholder == null) {
        return this.$t("file_input_dialog.placeholder");
      } else {
        return this.placeholder;
      }
    }
  },
  methods: {
    readInputFiles() {
      this.loading = true;
      this.readResults = readFiles(this.fileInput).finally(res => {
        this.loading = false;
      });
    },
    returnReadResult() {
      this.$emit("handleReturnReadResult", this.readResults);
      this.fileInput = null;
    },
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    }
  }
});
</script>
