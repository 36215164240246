<!--
  Table summarizing comparison between simulated and actual data
-->

<template>
  <v-row class="mt-5">
    <v-col align="center" md="12">
      <capucine-table
        :title="title"
        :headers="headers"
        :items="items"
        :loading="loading"
        :card-width="width + 'vh'"
        :table-props="table_props"
        :indicator="indicator"
        :exportFileName="exportFileName"
        :networkType="lot.type"
      >
      </capucine-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { ALL_DAYS, routeChipColor, routeChipTextColor, sortLotTable } from "@/capucine_utils";

export default Vue.component("result-table", {
  props: {
    items: {
      type: Array
    },
    chipColor: {
      required: false
    },
    formats: {
      required: true
    },
    title: {
      required: false
    },
    indicator: {
      type: String
    },
    width: {
      type: Number
    },
    exportFileName: {
      type: Function
    }
  },
  data: () => ({}),
  computed: {
    ...mapState("capucine_results", ["day_type"]),
    ...mapState("capucine_simulation", ["lot"]),
    ...mapGetters("capucine_simulation", ["routeNames"]),
    ...mapGetters("capucine_results", ["route", "day_types", "loading"]),
    day_type_filter() {
      let day_types = ["Année", ...this.day_types];
      return v => {
        return day_types.includes(v);
      };
    },
    headers() {
      let first_header = null;
      if (this.route.isAllRoutes && this.day_type == ALL_DAYS) {
        first_header = {
          text: "Ligne",
          value: "route",
          align: "left",
          width: "30%",
          color: routeChipColor,
          "chip-text-color": routeChipTextColor,
          format: val => this.routeNames[val]
        };
      } else if (this.day_type == ALL_DAYS) {
        first_header = {
          text: "Type de jour",
          value: "day_type",
          align: "left",
          width: "30%",
          filter: this.day_type_filter
        };
      } else {
        first_header = { text: "Tranche horaire", value: "période", align: "left", width: "30%" };
      }
      let headers = [
        first_header,
        { text: "Actuel", value: "actuel", align: "right", width: "23.3%", color: this.chipColor },
        {
          text: "Simulation",
          value: "simulé",
          align: "right",
          width: "23.3%",
          color: this.chipColor
        },
        { text: "Simulation - Actuel", value: "écart", align: "right", width: "23.3%" }
      ];
      headers.forEach(this.addHeaderFormat);
      return headers;
    },
    table_props() {
      if (this.route.isAllRoutes) {
        let nb_items = 10;
        if (this.indicator == "conformity") {
          nb_items = 9;
        }
        return {
          "hide-default-footer": false,
          "disable-pagination": false,
          "footer-props": {
            "items-per-page-text": "Lignes par page",
            "disable-items-per-page": true,
            "items-per-page-options": [9, 10]
          },
          "items-per-page": nb_items,
          "disable-sort": false,
          "must-sort": true,
          "sort-by": "route",
          "custom-sort": sortLotTable
        };
      } else {
        return undefined;
      }
    }
  },
  methods: {
    addHeaderFormat(header, index) {
      if (this.formats && header.value in this.formats && header.value != "route") {
        header.format = this.formats[header.value];
      }
    }
  }
});
</script>
