/* traces.store.js */

import {} from "@/functions-tools";
import { zoomOnTrace, MAX_MOBILE_AGENTS, read_trace, add_icons_to_map } from "@/components/kite/trace/trace";
import { validateTrace } from "@/validation";

export default {
  // State object
  namespaced: true,
  state: {
    rawTraceData: null,
    mobileFeatures: [],
    movingFeatures: [],
    stoppedFeatures: [],
    pos: [],
    dur: [],
    agentTypeList: [],
    selectedAgent: null,
    movingAttributes: [],
    selectedMovingAttribute: "--",
    checkFollowAgent: false,
    displayOptions: {
      useStaticLayer: true,
      stoppedPointRefresh: 3600,
      activityShadow: false
    },
    currentClock: 0,
    animSpeed: 60,
    run: false,
    mapIcons: [],
    icon_rules: {},
    timeline_key: 0
  },

  // Getter functions
  getters: {},
  // Actions
  actions: {
    async setTraceData(
      context,
      { geojson, show_users = true, use_static_layer = true, stopped_point_refresh = 3600, activity_shadow = false }
    ) {
      let map = context.rootState.layers.map;

      // data validation
      validateTrace(geojson);

      // backup the raw trace data
      let raw_trace_data = JSON.parse(JSON.stringify(geojson));

      // read the various types of features from trace
      let {
        colored_icons,
        mobile_features,
        static_features,
        area_features,
        positions,
        durations,
        mobile_attributes,
        mobile_agent_types
      } = read_trace(geojson, use_static_layer, stopped_point_refresh, show_users);

      // check number of mobile agents
      if (mobile_features.length > MAX_MOBILE_AGENTS) {
        throw new Error(`Trace cannot exceed ${MAX_MOBILE_AGENTS} mobile agents`);
      }

      // add the colored icons to the map
      await add_icons_to_map(map, colored_icons);

      // set layers data
      if (use_static_layer) {
        context.dispatch(
          "layers/setLayersData",
          {
            ids: "staticpoint",
            data: {
              type: "FeatureCollection",
              features: static_features
            }
          },
          { root: true }
        );
      }
      context.dispatch(
        "layers/setLayersData",
        {
          ids: "area",
          data: {
            type: "FeatureCollection",
            features: area_features
          }
        },
        { root: true }
      );
      context.dispatch(
        "layers/setLayersVisibility",
        {
          ids: ["area", "staticpoint", "stoppedmovingpoint", "movingpoint"],
          isVisible: true
        },
        { root: true }
      );

      // update store
      context.commit("UPDATE_FROM_READ_TRACE", {
        raw_trace_data,
        mobile_features,
        positions,
        durations,
        display_options: {
          useStaticLayer: use_static_layer,
          stoppedPointRefresh: stopped_point_refresh,
          activityShadow: activity_shadow
        },
        agent_types: mobile_agent_types,
        moving_attributes: mobile_attributes
      });

      // reset timeline and display trace commands, zoom on trace
      context.commit("INCREMENT_TIMELINE_KEY");
      context.commit("UPDATE_UX_DISPLAY", { ux: "animationCommands", value: true }, { root: true });
      zoomOnTrace(map, mobile_features);
    },

    updateMovingPoints(context, moving_features) {
      context.commit("SET_MOVING_FEATURES", moving_features);
      context.dispatch(
        "layers/setLayersData",
        {
          ids: "movingpoint",
          data: {
            type: "FeatureCollection",
            features: moving_features
          }
        },
        { root: true }
      );
    },

    updateStoppedPoints(context, stopped_features) {
      context.commit("SET_STOPPED_FEATURES", stopped_features);
      context.dispatch(
        "layers/setLayersData",
        {
          ids: "stoppedmovingpoint",
          data: {
            type: "FeatureCollection",
            features: stopped_features
          }
        },
        { root: true }
      );
    },

    addMapIcon(context, icon_id) {
      context.commit("ADD_MAP_ICON", icon_id);
    },

    setIconRule(context, payload) {
      context.commit("SET_ICON_RULE", payload);
    }
  },

  // Mutations
  mutations: {
    UPDATE_FROM_READ_TRACE(
      state,
      { raw_trace_data, mobile_features, positions, durations, display_options, agent_types, moving_attributes }
    ) {
      state.rawTraceData = raw_trace_data;
      state.mobileFeatures = mobile_features;
      state.pos = positions;
      state.dur = durations;
      state.displayOptions = display_options;
      state.agentTypeList = agent_types;
      state.movingAttributes = moving_attributes;
    },
    SET_SELECTED_MOVING_ATTRIBUTE(state, value: string) {
      state.selectedMovingAttribute = value;
    },
    SET_SELECTED_AGENT(state, value) {
      state.selectedAgent = value;
    },
    SET_FOLLOW_AGENT(state, value) {
      state.checkFollowAgent = value;
    },
    SET_ANIM_RUN(state, run: boolean) {
      state.run = run;
    },
    SET_CLOCK(state, value) {
      state.currentClock = value;
    },
    SET_ANIM_SPEED(state, value) {
      state.animSpeed = value;
    },
    SET_MOVING_FEATURES(state, moving_features) {
      state.movingFeatures = moving_features;
    },
    SET_STOPPED_FEATURES(state, stopped_features) {
      state.stoppedFeatures = stopped_features;
    },
    ADD_MAP_ICON(state, icon_id) {
      state.mapIcons.push(icon_id);
    },
    SET_ICON_RULE(state, payload) {
      state.icon_rules[payload.key] = payload.value;
    },
    INCREMENT_TIMELINE_KEY(state) {
      state.timeline_key = state.timeline_key + 1;
    }
  }
};
