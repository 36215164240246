<!--
Stepper for basic odt use case 
-->

<template>
  <simulation-stepper :on-validation="clearStepperLocals">
    <template v-slot:environment>
      <v-form v-model="valid_step[STEPS.environment]">
        <simulation-speeds-param :speed-items="parameters['speeds']" />
      </v-form>
    </template>
    <template v-slot:demand>
      <v-form ref="demand_form" v-model="valid_step[STEPS.demand]">
        <demand-from-user v-model="parameters['demand']" :rules="[mandatoryRule]" :displayed="step == 3" />
      </v-form>
    </template>
    <template v-slot:offer>
      <v-form ref="offer_form" v-model="valid_step[STEPS.offer]">
        <!-- Refactor this part ? -->
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['number_vehicles']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('number_vehicles')"
              required
              prepend-icon="mdi-bus"
            ></v-text-field>
          </v-col>
          <v-col md="5">
            <v-text-field
              v-model="parameters['capacity']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('capacity')"
              required
              prepend-icon="groups"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['max_waiting_time']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('max_waiting_time')"
              required
              suffix="minutes"
              prepend-icon="mdi-timer-outline"
            />
          </v-col>
          <v-col md="5">
            <point-selector
              v-model="parameters.depot_point"
              :label="getLabel('depot')"
              :rules="[mandatoryRule]"
              @selection:update="dialog = !$event"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simulation-stepper>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import StepperMixin from "./stepper_mixin.vue";
import DemandFromUser from "./demand_from_user.vue";
import PointSelector from "@/components/kite/base/point_selector.vue";
import SimulationSpeedsParam from "./simulation_speeds_param.vue";

export default Vue.component("odt-basic", {
  mixins: [StepperMixin],
  components: {
    DemandFromUser,
    PointSelector,
    SimulationSpeedsParam
  },
  data: () => ({}),
  computed: {},
  methods: {
    clearStepperLocals() {
      this.$refs.demand_form.reset();
      this.$refs.offer_form.reset();
    }
  }
});
</script>
