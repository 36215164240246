/**
 * This should be part of a generic module to be reused
 */
import { ProjectPermission } from "@/api";
import { User } from "../api/models";
import i18n from "@/plugins/lang";

interface CustomRequestInit extends RequestInit {
  bodyObject?: object;
  loader?: "generic" | "overlay" | string;
  errorAlert?: boolean;
}

class HttpError extends Error {
  http: Response;
  constructor(http: Response, message?: string) {
    super(message);
    this.http = http;
  }
}
export default class Controller {
  static singleton: Controller;
  store: any = undefined;

  static getInstance(): Controller {
    Controller.singleton ??= new Controller(undefined, "whale");
    return Controller.singleton;
  }

  static getBaseUrl(hostname: string, apiName: string = "api") {
    return hostname.replace(/^(beta\.|pr\d+\.)?.*(\.[a-zA-Z0-9_-]+\.[a-z]+)$/, `$1${apiName}$2`);
  }

  baseUrl: string;
  constructor(baseUrl: string, apiName = "api") {
    if (baseUrl) {
      this.baseUrl = baseUrl;
    } else {
      if (window.location.protocol !== "https:") {
        this.baseUrl = `http://${window.location.hostname}:18080`;
      } else {
        // Use api by default
        this.baseUrl = `https://${Controller.getBaseUrl(window.location.hostname, apiName)}`;
      }
    }

    if (this.baseUrl.endsWith("/")) {
      this.baseUrl = this.baseUrl.substr(0, this.baseUrl.length - 1);
    }
  }

  setStore(store) {
    this.store = store;
  }

  async http(url: string, options: CustomRequestInit = {}, raw: boolean = false) {
    const headers: any = options.headers ?? {};

    if (options.bodyObject) {
      options.body = JSON.stringify(options.bodyObject);
      headers["content-type"] = "application/json";
    }

    if (options.loader && this.store) {
      this.store.dispatch("asyncStart", { type: options.loader });
    }
    options.headers = headers;

    const res = await fetch(this.getUrl(url), {
      credentials: "include",
      ...options
    });
    try {
      if (raw) {
        return res;
      } else {
        if (res.url.includes("/users/")) {
          // remove error message if users service api
          return {};
        } else if (res.status === 204) {
          return {};
        } else if (res.status === 200) {
          try {
            return await res.json();
          } catch (err) {
            // Should be a 204
            return {};
          }
        } else if ([401, 403, 404, 413, 504].includes(res.status)) {
          throw new Error(<string>i18n.t("errors.api." + res.status));
        } else if (res.status >= 300) {
          throw new HttpError(res, <string>i18n.t("errors.api.server_error"));
        } else {
          throw new Error(<string>i18n.t("errors.api.unknown_error"));
        }
      }
    } catch (e) {
      if (options.errorAlert === undefined || options.errorAlert) {
        let message = e.message;
        alert({ message, type: "error" });
        throw e;
      } else {
        throw e;
      }
    } finally {
      if (options.loader && this.store) {
        this.store.dispatch("asyncEnd", { type: options.loader });
      }
    }
  }

  /**
   * Get current user
   * @returns
   */
  async me(): Promise<User> {
    try {
      return await this.http("/auth/me");
    } catch (err) {
      return undefined;
    }
  }

  async getUsers() {
    try {
      return await this.http("/admin/users");
    } catch (err) {
      return [];
    }
  }

  authRedirect() {
    // @ts-ignore
    window.location = this.getUrl("/auth/redirect") + `?url=${window.location.toString()}`;
  }

  /**
   * Log the user out
   * @returns
   */
  async logout(callback: () => void) {
    const promise = this.http("/auth", { method: "DELETE" });

    await promise;

    return callback ? callback() : "";
  }

  /**
   * Get an url for the controller
   *
   * If the url is absolute will just return the url back
   * @param url
   * @returns
   */
  getUrl(url: string) {
    // Add url
    if (!url.startsWith("http")) {
      url = `${this.baseUrl}/${url.startsWith("/") ? url.substr(1) : url}`;
    }

    return url;
  }

  /**
   * Update a user
   *
   * @param uuid of the user
   * @param updates to send
   * @returns
   */
  updateUser(uuid: string, updates: any) {
    return this.http(`/users/${uuid}`, { method: "PATCH", bodyObject: updates });
  }

  /**
   * Update a user
   *
   * @param uuid of the user
   * @param updates to send
   * @returns
   */
  updateProject(uuid: string, updates: any) {
    return this.http(`/projects/${uuid}`, { method: "PATCH", bodyObject: updates });
  }

  /**
   * Retrieve project or return undefined if issue
   * @param uuid
   * @returns
   */
  async getProject(uuid: string) {
    return this.http(`/projects/${uuid}`);
  }

  /**
   * Retrieve project or return undefined if issue
   * @param uuid
   * @returns
   */
  async createProject(project: any) {
    await this.http(`/projects`, { method: "POST", bodyObject: project });
  }

  /**
   * Load project ACL and invitations
   * @param uuid
   */
  async loadProjectAcl(uuid: string) {
    let p = await Promise.all([this.http(`/projects/${uuid}/acl`), this.http(`/projects/${uuid}/invitations`)]);
    return [
      ...p[0].resolved.map(e => {
        return {
          ...e.actor,
          permission: e.permission,
          aceType: "ace"
        };
      }),
      ...Object.keys(p[1]).map(e => {
        return {
          uuid: e.substr(0, e.length - 6),
          permission: p[1][e],
          aceType: "invitation"
        };
      })
    ];
  }

  async updateAce(uuid: string, user: string, permissions: ProjectPermission) {
    return this.http(`/projects/${uuid}/invitations`, {
      method: "POST",
      bodyObject: {
        users: [user],
        metadata: permissions
      }
    });
  }

  async inviteOnProject(uuid: string, email: string, permissions: ProjectPermission) {
    return this.http(`/projects/${uuid}/invitations`, {
      method: "POST",
      bodyObject: {
        idents: [`${email}_email`],
        metadata: permissions
      }
    });
  }
}
