<template>
  <div style="color: white; width: 100%; text-align: center">
    <span style="font-size: 30px; font-weight: bold">KITE</span> {{ version }}
    <!--<span v-if="version.includes('-')">({{ buildDate }})</span>-->
  </div>
</template>
<script lang="ts">
import { mapState } from "vuex";
export default {
  name: "VersionFooter",
  data() {
    return {
      buildDate: process.env.BUILD_DATE || "1970-01-01T00:00:00Z" // eslint-disable-line
    };
  },
  computed: {
    ...mapState(["version"]),
    getYear() {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear;
    }
  }
};
</script>
