<template>
  <v-card-text class="elevation-0">
    <info-table
      @data_update="dataUpdateHandler"
      @fetching="computing = $event"
      :items="chosen_variant_features"
      :filter="items_filter"
      :compute="computing"
      :data-getter="getLineInfo"
      :value-formatting="formatValue"
      :label-formatting="formatText"
      :loading="loading"
      :title="selected_variant"
      table-width="350px"
      :sort="sort()"
    >
      <template v-slot:prependActions="{ loading }">
        <v-card-text>
          <v-select label="Mission" :items="variant_choices" v-model="selected_variant" />
        </v-card-text>
      </template>
    </info-table>
  </v-card-text>
</template>

<script lang="ts">
import Vue from "vue";
import { formatTableContent } from "@/capucine_utils";
import InfoTable from "@/components/base/info_table.vue";
import * as turf from "@turf/turf";
import { ALL_ROUTES } from "@/capucine_utils";

const vertical_headers = [
  {
    value: "distance",
    text: "Longueur (m)"
  },
  {
    value: "agg_psv",
    text: "% voie réservée bus",
    format: "100%"
  },
  {
    value: "agg_cycleway_on_busway",
    text: "% piste cyclable sur voie réservée bus",
    format: "100%"
  },
  {
    value: "agg_traffic_signals",
    text: "Nombre de feux de signalisation"
  },
  {
    value: "agg_give_way",
    text: "Nombre de cédez-le-passage"
  },
  {
    value: "agg_roudabout",
    text: "Nombre de rond-point"
  },
  {
    value: "agg_stop",
    text: "Nombre de stop"
  },
  {
    value: "agg_without_traffic_signals_crossing",
    text: "Nombre passages piétons (hors feux de signalisation)"
  },
  {
    value: "agg_highway_motorway",
    text: "% réseau autoroutier",
    format: "100%"
  },
  {
    value: "agg_highway_primary",
    text: "% routes principales",
    format: "100%"
  },
  {
    value: "agg_highway_secondary",
    text: "% routes secondaires",
    format: "100%"
  },
  {
    value: "agg_highway_other",
    text: "% autres routes",
    format: "100%"
  },
  {
    value: "agg_lanes_only1",
    text: "% une seule voie de circulation",
    format: "100%"
  },
  {
    value: "agg_maxspeed30",
    text: "% vitesse limitée à 30km/h",
    format: "100%"
  }
];

export default Vue.component("pt-line-osm", {
  components: {
    InfoTable
  },
  props: ["lineId", "lineGeojson", "loading"],
  data: function () {
    return {
      computing: false,
      data: {},
      vertical_headers,
      variant_choices: [],
      selected_variant: null,
      data_loading: false
    };
  },
  watch: {
    lineId: {
      handler(value) {
        this.computing = true;
      },
      immediate: true
    }
  },
  computed: {
    chosen_variant_features() {
      if (this.selected_variant) {
        return this.data[this.selected_variant];
      } else {
        return [];
      }
    }
  },

  methods: {
    async getLineInfo() {
      if (this.lineId == ALL_ROUTES) {
        return {};
      }

      let bbox = turf.bbox(this.lineGeojson);
      let result = await this.$whale.transportLineOsm(bbox, this.lineId);

      let features = {};
      let properties;
      let title;
      for (let i = 0; i < result.features.length; i++) {
        properties = result.features[i].properties;
        title = properties.direction;
        let items = [];
        for (let key in properties) {
          items.push({ label: key, value: properties[key] });
        }
        features[title] = items;
      }

      return features;
    },
    dataUpdateHandler(data) {
      this.data = data;
      this.variant_choices = Object.keys(this.data);
      this.selected_variant = this.variant_choices[0];
    },
    formatText(item) {
      let header = this.getLabelHeader(item);
      if (header?.text) {
        return header.text;
      } else {
        return item.label;
      }
    },
    formatValue(item) {
      let header = this.getLabelHeader(item);
      if (header?.format) {
        return formatTableContent(item.value, header.format);
      } else {
        return item.value;
      }
    },
    getLabelHeader(item) {
      let filtered = this.vertical_headers.filter(header => {
        return header.value == item.label;
      });
      if (filtered.length == 0) {
        return undefined;
      } else {
        return filtered[0];
      }
    },
    items_filter(item) {
      return this.getLabelHeader(item) !== undefined;
    },
    sort() {
      return (a, b) => {
        let ordered_values = this.vertical_headers.map(header => header.value);
        let a_index = ordered_values.indexOf(a);
        let b_index = ordered_values.indexOf(b);
        if (a_index > b_index) {
          return 1;
        } else if (b_index > a_index) {
          return -1;
        } else {
          return 0;
        }
      };
    }
  }
});
</script>
