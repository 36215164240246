<template>
  <div>
    <v-card-title>
      {{ $t("network.draw_pt_line.title") }} <v-spacer />
      <v-btn color="primary" @click="$whale.runIfHasAccess('NETWORK', draw)" :disabled="loading" class="mx-4">
        <v-icon left> draw </v-icon>
        {{ $t("network.draw_pt_line.draw") }}
      </v-btn>

      <v-btn color="primary" @click="document.getElementById('fileUpload').click()" :disabled="loading" class="mx-4">
        <v-icon left> upload </v-icon>
        {{ $t("network.draw_pt_line.import") }}
      </v-btn>
      <v-btn color="primary" :disabled="loading" @click="export_layer()" class="mx-4"
        ><v-icon left> download </v-icon>{{ $t("network.draw_pt_line.export_trace") }}</v-btn
      >
    </v-card-title>
    <v-card-text>
      {{ $t("network.draw_pt_line.desc") }}
      <v-file-input
        id="fileUpload"
        accept=".geojson"
        hide-input
        prepend-icon=""
        @change="$whale.runIfHasAccess('NETWORK', getGeojsonFromInput, $event)"
      />
    </v-card-text>
    <v-card-text class="d-flex flex-grow-1 align-center justify-center">
      <v-card width="500px" elevation="0">
        <div v-if="line_geojson != null">
          <v-divider />
          <v-tabs v-model="tab" background-color="primary" class="elevation-2" dark grow slider-size="6">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab :key="0" :href="`#tab-0`"> {{ $t("network.draw_pt_line.tab.statistics") }} </v-tab>
            <v-tab :key="1" :href="`#tab-1`"> {{ $t("network.draw_pt_line.tab.costs") }} </v-tab>
            <v-tab-item :key="0" :value="'tab-0'">
              <!-- Statistics -->
              <pt-line-stats :line-geojson="line_geojson" @data_update="data = $event" @buffer_update="updateBuffer" />
            </v-tab-item>
            <v-tab-item :key="1" :value="'tab-1'" class="pa-2">
              <v-overlay opacity="0.5" color="grey lighten-1" absolute :value="loading">
                <div class="d-flex flex-column align-center">
                  <h1>{{ $t("basic_dialogs.loading") }}</h1>
                  <v-progress-linear color="primary" indeterminate></v-progress-linear>
                </div>
              </v-overlay>
              <v-row class="mb-n0">
                <v-col>
                  <p>
                    <span class="v-label theme--light">{{ $t("network.draw_pt_line.data.distance_text") }}</span>
                    {{ line_length }} m
                  </p>
                </v-col>
              </v-row>
              <v-row class="ml-n2 mb-6 mt-2">
                <v-card-text>
                  <v-expansion-panels accordion multiple v-model="panel">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="v-label theme--light">{{ $t("network.draw_pt_line.cost_model.round_trip") }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="px-n4 mx-n4">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left pa-0">{{ $t("network.draw_pt_line.cost_model.periods") }}</th>
                                <th class="text-left pa-0" v-for="day in cost_model.days[0].values" :key="day.day">
                                  {{ day.day }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="day_type in cost_model.days" :key="day_type.day">
                                <td class="pa-0">{{ day_type.period }}</td>
                                <td class="pl-0" v-for="item in day_type.values" :key="item.day">
                                  <v-text-field v-model="item.round_trip" type="number" min="0"></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="v-label theme--light">{{ $t("network.draw_pt_line.cost_model.activation") }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="px-n4 mx-n4">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left pa-0">{{ $t("network.draw_pt_line.cost_model.periods") }}</th>
                                <th class="text-left pa-0" v-for="day in cost_model.days[0].values" :key="day.day">
                                  {{ day.day }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="day_type in cost_model.days" :key="day_type.day">
                                <td class="pa-0">{{ day_type.period }}</td>
                                <td class="pl-0" v-for="item in day_type.values" :key="item.day">
                                  <v-text-field v-model="item.activation" type="number" min="0"></v-text-field>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider
                    v-model="cost_model.cost_per_km"
                    thumb-label="always"
                    ticks
                    step="0.1"
                    min="0"
                    max="6"
                    :label="$t('network.draw_pt_line.cost_model.cost_km')"
                  ></v-slider>
                  <p>
                    {{ $t("network.draw_pt_line.cost_model.result") }}
                    <b>{{ cost_model_result }} {{ $t("network.draw_pt_line.cost_model.result_unit") }}</b
                    >.
                  </p>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card>
    </v-card-text>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { downloadData, readFiles, parse_json } from "@/io";
import PtLineStats from "./pt_line_stats.vue";
import * as turf from "@turf/turf";

const export_filename = "drawn_pt_line.geojson";

export default Vue.component("draw-pt-line", {
  components: { PtLineStats },
  props: ["dialogUpdater"],

  data: function () {
    return {
      panel: [0],
      buffer_size: 300, // meters
      document,
      data: {},
      line_geojson: null,
      tab: 0,
      cost_model: {
        cost_per_km: 3,
        days: [
          {
            period: "Hors vacances",
            values: [
              { day: "lu", value: true, round_trip: 10, activation: 100, weight: 179 / 5 },
              { day: "ma", value: true, round_trip: 10, activation: 100, weight: 179 / 5 },
              { day: "me", value: true, round_trip: 10, activation: 100, weight: 179 / 5 },
              { day: "je", value: true, round_trip: 10, activation: 100, weight: 179 / 5 },
              { day: "ve", value: true, round_trip: 10, activation: 100, weight: 179 / 5 },
              { day: "sa", value: true, round_trip: 10, activation: 100, weight: 36 },
              { day: "di", value: true, round_trip: 10, activation: 100, weight: 43 }
            ]
          },
          {
            period: "Petites vacances",
            values: [
              { day: "lu", value: true, round_trip: 10, activation: 100, weight: 39 / 5 },
              { day: "ma", value: true, round_trip: 10, activation: 100, weight: 39 / 5 },
              { day: "me", value: true, round_trip: 10, activation: 100, weight: 39 / 5 },
              { day: "je", value: true, round_trip: 10, activation: 100, weight: 39 / 5 },
              { day: "ve", value: true, round_trip: 10, activation: 100, weight: 39 / 5 },
              { day: "sa", value: true, round_trip: 10, activation: 100, weight: 8 },
              { day: "di", value: true, round_trip: 10, activation: 100, weight: 11 }
            ]
          },
          {
            period: "Vacances d'été",
            values: [
              { day: "lu", value: true, round_trip: 10, activation: 100, weight: 33 / 5 },
              { day: "ma", value: true, round_trip: 10, activation: 100, weight: 33 / 5 },
              { day: "me", value: true, round_trip: 10, activation: 100, weight: 33 / 5 },
              { day: "je", value: true, round_trip: 10, activation: 100, weight: 33 / 5 },
              { day: "ve", value: true, round_trip: 10, activation: 100, weight: 33 / 5 },
              { day: "sa", value: true, round_trip: 10, activation: 100, weight: 7 },
              { day: "di", value: true, round_trip: 10, activation: 100, weight: 9 }
            ]
          }
        ]
      }
    };
  },

  computed: {
    ...mapState(["async"]),
    ...mapState("network", ["dialog"]),
    line_length() {
      if ("itinerary" in this.data) {
        return this.data.itinerary[0].value;
      } else {
        return 0;
      }
    },
    cost_model_result() {
      let result = 0;
      for (let day = 0; day < this.cost_model.days.length; day++) {
        for (let value = 0; value < this.cost_model.days[day].values.length; value++) {
          let day_value = this.cost_model.days[day].values[value];
          result = result + day_value.round_trip * (day_value.activation / 100) * day_value.weight;
        }
      }
      return 100 * Math.round((result * (this.line_length / 1000) * 2 * this.cost_model.cost_per_km) / 100);
    },
    loading() {
      return this.async.draw_pt_line > 0;
    }
  },
  methods: {
    ...mapActions(["startMapAction"]),
    ...mapActions("layers", ["setLayersVisibility", "setLayersData"]),
    draw() {
      // hide layers during draw
      if (this.line_geojson) {
        this.setLayersVisibility({ ids: ["drawn-pt-line", "drawn-pt-line-buffer"], isVisible: false });
      }
      // start draw action (edition if a geojson is already set)
      this.startMapAction({
        action: "draw",
        options: { modes: ["linestring"], geojson: this.line_geojson },
        validationHandler: this.setPtLineGeometry,
        closeHandler: this.displayLayers
      });
      this.dialogUpdater(false);
    },
    // set the data of the pt-line layers
    setPtLineGeometry(fc) {
      if (fc.features.length > 0) {
        if (!["LineString", "MultiLineString"].includes(fc.features[0].geometry.type)) {
          let message = this.$t("map_layers.layer_from_file.json_error");
          alert({ message, type: "error" });
          return;
        }
        // set the new shape of the line
        this.line_geojson = fc;
        this.setLayersData({ ids: "drawn-pt-line", data: fc });

        // update the buffer around the new line shape
        this.evaluateBuffer();
      }
    },
    // update the buffer size
    updateBuffer(value) {
      this.buffer_size = value;
      this.evaluateBuffer();
    },
    // compute buffer around the line shape
    evaluateBuffer() {
      let buffer = turf.buffer(this.line_geojson, this.buffer_size / 1000, { units: "kilometers" });
      this.setLayersData({ ids: "drawn-pt-line-buffer", data: buffer });
    },
    // display both layers
    displayLayers() {
      this.dialogUpdater(true);
      if (this.line_geojson) {
        this.setLayersVisibility({ ids: ["drawn-pt-line", "drawn-pt-line-buffer"], isVisible: true });
      }
    },
    // get geojson from file input
    async getGeojsonFromInput(file) {
      let result = await readFiles(file);
      if (result.length > 1) {
        throw new Error(this.$t("map_layers.layer_from_file.multiple_file_error"));
      }
      try {
        let geojson = parse_json(result[0].data);
        this.setPtLineGeometry(geojson);
        this.displayLayers();
      } catch (e) {
        throw new Error(this.$t("map_layers.layer_from_file.json_error"));
      }
    },
    // export geojson to file
    export_layer() {
      downloadData(JSON.stringify(this.line_geojson), export_filename, "application/octet-stream");
    },
    closeDialog() {
      this.dialog.draw = false;
    }
  }
});
</script>

<style>
.draw-dialog {
  position: absolute;
  right: 0;
}
</style>
