<template>
  <div>
    <v-form ref="edit_form" v-model="editionIsValid">
      <v-card-text class="justify-center">
        <div>
          <v-select
            :label="$t('props_mapping.direct.select_label')"
            :items="dataPropertiesItems"
            v-model="local_options.key"
            :rules="[mandatoryRule]"
            @change="autoEvaluateCoefficentAndUpdate"
          />
        </div>
        <div v-if="paintType == 'size'">
          <v-text-field
            v-model.number="local_options.coefficient"
            label="Coefficient multiplicateur"
            type="number"
            :rules="size_text_field_rules"
            @input="update"
          />
        </div>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import LayerMappingMixin from "./layer_mapping_mixin.vue";

export default Vue.component("direct-mapping", {
  mixins: [LayerMappingMixin],

  data() {
    return {};
  },
  computed: {},
  methods: {
    async autoEvaluateCoefficentAndUpdate() {
      this.local_options.coefficient = await this.layer.evaluateSizeCoefficient(this.local_options.key);
      this.update();
    }
  }
});
</script>
