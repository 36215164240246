<!--
  Dialog to manage flows
-->

<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      :headers="headers"
      :items="flowsTable"
      :no-data-text="$t('flows.manager.table.no_data_text')"
      disable-pagination
      hide-default-footer
      disable-sort
      class="elevation-3"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        {{ $t("flows.manager.table.headers." + header.value) }}
      </template>
      <template v-slot:[`item.name`]="props">
        <kite-edit-text
          :text="props.item.name"
          :rules="[nameRequired, nameLengthRule, nameTakenRule]"
          @save="props.item.rename($event)"
        >
          {{ truncateString(props.item.name, 26) }}
        </kite-edit-text>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ $t("flows.type." + item.type) }}
      </template>
      <template v-slot:[`item.displayed`]="{ item }">
        <v-simple-checkbox
          :value="item.isDisplayed()"
          color="black"
          on-icon="radio_button_checked"
          off-icon="radio_button_unchecked"
          :ripple="false"
          @click="displayItem(item)"
        ></v-simple-checkbox>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <kite-save-button
          @click="$whale.runIfHasAccess('FLOWS', e => item.saveToProject(e), $event)"
          :disabled="!item.canBeSaved()"
        ></kite-save-button>
        <kite-table-action
          icon="content_copy"
          @click="$whale.runIfHasAccess('FLOWS', copyFlowsView, item)"
          :tooltip="$t('flows.manager.table.tooltip.copy')"
        />
        <kite-table-action
          icon="file_download"
          :disabled="!item.canBeDownloaded()"
          @click="item.downloadView()"
          :tooltip="$t('flows.manager.table.tooltip.download')"
        />
        <kite-table-action icon="clear" @click="item.removeFromStore()" :tooltip="$t('basic_dialogs.remove')" />
      </template>
      <template v-slot:[`item.tools`]="{ item }">
        <kite-table-action
          icon="call_merge"
          :disabled="!item.isDisplayed()"
          @click="$whale.runIfHasAccess('FLOWS', openAggregationDialog, item)"
          :tooltip="$t('flows.manager.table.tooltip.aggregate')"
        />
        <kite-table-action
          icon="table_view"
          :disabled="!(item.isDisplayed() && getType == 'FLOWMAP')"
          @click="openTableDialog(item)"
          :tooltip="$t('flows.manager.table.tooltip.summary')"
        />
      </template>
    </v-data-table>
    <aggregation-dialog :flows="aggregatedFlows" @closeDialog="aggregatedFlows = null" />
    <dialog-summary :flows-summary="summarisedFlows" @closeDialog="summarisedFlows = null" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { nameRequired, nameLengthRule } from "@/validation";
import { truncateString } from "@/functions-tools";
import { computeFlowMapSummaryTable } from "@/flows";
import KiteSaveButton from "@/components/projects/button_save.vue";
import AggregationDialog from "./aggregation/aggregation_dialog.vue";
import DialogSummary from "./dialog_summary.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { FlowsView } from "@/models";

export default Vue.component("flows-page", {
  components: {
    KiteSaveButton,
    AggregationDialog,
    DialogSummary
  },

  data: function () {
    return {
      newFlowsName: "",
      aggregatedFlows: null,
      summarisedFlows: null,
      headers: [
        { text: "Displayed", value: "displayed", align: "center", width: "10%" },
        { text: "Name", value: "name", width: "35%" },
        { text: "Type", value: "type", align: "center" },
        { text: "Actions", value: "actions", align: "center", width: "130" },
        { text: "Tools", value: "tools", align: "center", width: "110" }
      ]
    };
  },

  computed: {
    ...mapGetters("flows", ["allNames", "getType"]),
    ...mapState(["apiAvailable"]),
    ...mapState("flows", ["dialog", "flowsTable", "viewFilteredData"])
  },

  methods: {
    truncateString,
    isSaved(item) {
      return (
        item.saved ||
        !this.$whale.user._features.includes("FLOWS") ||
        // if we do not have the permission to save it
        !(this.$whale.project._permissions.includes("all") || this.$whale.project._permissions.includes("Editor"))
      );
    },
    /**
     * Create a copy of the given FlowsView.
     * The resulting copy contains the filtered data of the original view.
     * This is more like an extraction of the current state of the view as a new one.
     */
    copyFlowsView(item) {
      let data = JSON.parse(JSON.stringify(this.viewFilteredData));
      let view = FlowsView.newFlowsView(data, item.type, this.$t("basic_dialogs.copyof") + item.name);
      view.addToKite();
    },
    displayItem(item) {
      if (!item.isDisplayed()) {
        item.updateViewDisplay(true);
      } else {
        item.updateViewDisplay(false);
      }
    },
    nameRequired,
    nameLengthRule,
    nameTakenRule(name) {
      let taken = this.allNames.includes(name);
      let response = !taken || this.$t("flows.manager.table.edit_taken");
      return response;
    },
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    },
    /**
     * Aggregation button click event.
     */
    openAggregationDialog(item) {
      this.aggregatedFlows = item;
    },
    /**
     * Open table dialog.
     */
    openTableDialog(item) {
      this.summarisedFlows = computeFlowMapSummaryTable(item.aggregated_filtered_data);
    }
  }
});
</script>

<style>
.tooltip-inner {
  text-align: justify;
}
</style>
