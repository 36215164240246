<template>
  <v-card min-height="450px">
    <v-card-title>
      {{ $t("simulations.kpis.title") }}
      <v-btn icon dark color="primary"> <v-icon @click="help = true"> help </v-icon> </v-btn>
    </v-card-title>
    <v-card-subtitle style="position: relative">
      <v-menu offset-y max-height="50vh">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="scenario_with_available_model_kpis.length == 0"
            color="primary"
            dark
            right
            bottom
            absolute
            rounded
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-2">add</v-icon>{{ $t("simulations.kpis.add_scenario") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in scenario_with_available_model_kpis"
            :key="index"
            link
            @click="addScenario(item)"
          >
            <v-list-item-title>{{ item.scenario_name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-subtitle>
    <v-card-text>
      <slot :help="help" name="kpi" />
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default Vue.component("model-kpi", {
  props: ["model"],

  data: function () {
    return {
      help: false
    };
  },
  computed: {
    ...mapGetters("simulations", ["scenario_with_available_model_kpis"])
  },
  methods: {
    ...mapActions("simulations", ["addModelKpi"]),
    addScenario(scenarioItem) {
      this.addModelKpi(scenarioItem);
    },
    updateHelp(value) {
      this.help = value;
    }
  }
});
</script>
