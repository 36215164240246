<!--
  Bottom sheet to display color legend
-->

<template>
  <div>
    <v-bottom-sheet v-model="sheet" attach=".navigation" hide-overlay width="99.5%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="disabled" color="primary" icon v-bind="attrs" v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-label-multiple-outline </v-icon>
            </template>
            <span>Légende</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-sheet class="text-center">
        <v-btn class="my-3" text color="primary" @click="sheet = !sheet"> Fermer </v-btn>
        <div class="color_legend">
          <div v-for="(item, index) in colors" :key="item.id">
            <i :style="'background:' + item + ' ; opacity: 1'"></i>
            <p>{{ text[index] }}</p>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { COLOR_LEGEND, getColorLegendText } from "@/capucine_utils";

export default Vue.component("color-legend", {
  components: {},

  props: ["disabled", "indicator"],

  data: function () {
    return {
      sheet: false
    };
  },
  computed: {
    text() {
      if (this.indicator != undefined) {
        return getColorLegendText(this.indicator);
      } else {
        return [];
      }
    },
    colors() {
      if (this.indicator != undefined) {
        return COLOR_LEGEND[this.indicator].colors;
      } else {
        return [];
      }
    }
  },

  methods: { getColorLegendText }
});
</script>

<style scoped>
.color_legend i {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid;
  opacity: 0.75;
}
</style>
