// Kite mail contact
const KITE_CONTACT = "kite@tellae.fr";

// main colors
const COLORS = {
  primary: "#3d6482",
  secondary: "#85c287",
  grey: "#aaaaaa"
};

// zoning year for layers
const ZONING_YEAR = 2023;
const IRIS_ZONING_YEAR = 2022;

// default map zoom
const MAPZOOM = 5;
// default map pitch
const MAPPITCH = 0;
// city centers
const MAPCENTER = [2.571, 46.815];

// header for table of scenarios
const TABHEADERS = {
  agent_planning: [
    {
      text: "Heure",
      value: "hour",
      width: "25%",
      align: "start",
      sortable: true
    },
    {
      text: "Événement",
      value: "status",
      width: "75%",
      align: "end",
      sortable: true
    }
  ],
  territory_kpis: [
    {
      text: "city",
      align: "start",
      value: "NOM",
      width: "150px",
      sortable: false,
      epci_only: true
    },
    {
      text: "code",
      align: "start",
      value: "INSEE_COM",
      width: "100px",
      sortable: false,
      epci_only: true
    },
    {
      text: "cityGroup",
      align: "start",
      value: "EPCI_NAME",
      width: "200px",
      sortable: false
    },
    {
      text: "population",
      align: "end",
      value: "P21_POP",
      width: "100px",
      sortable: false
    },
    {
      text: "density_population",
      align: "end",
      value: "P21_POP_DENSITY",
      width: "100px",
      sortable: false
    },
    {
      text: "population_previous",
      align: "end",
      value: "P15_POP",
      width: "100px",
      sortable: false
    },
    {
      text: "population_growth",
      align: "end",
      value: "Tx_ACC_ANNUEL_MOY_POP1521",
      width: "100px",
      sortable: false
    },
    {
      text: "unemployment",
      align: "end",
      value: "PercP21_CHOMEUR1564",
      width: "100px",
      sortable: false
    },
    {
      text: "occupied_worker",
      align: "end",
      value: "PercP21_ACTOCC1564",
      width: "100px",
      sortable: false
    },
    {
      text: "non_occupied_worker",
      align: "end",
      value: "PercP21_INACT1564",
      width: "100px",
      sortable: false
    },
    {
      text: "less15yrs",
      align: "end",
      value: "PercP21_POP0014",
      width: "100px",
      sortable: false
    },
    {
      text: "more64yrs",
      align: "end",
      value: "PercP21_POP6499",
      width: "100px",
      sortable: false
    },
    {
      text: "poverty",
      align: "end",
      value: "TP6021",
      width: "100px",
      sortable: false
    },
    {
      text: "employment",
      align: "end",
      value: "P21_EMPLT",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_previous",
      align: "end",
      value: "P15_EMPLT",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_growth",
      align: "end",
      value: "Tx_ACC_ANNUEL_MOY_EMPL1521",
      width: "100px",
      sortable: false
    },
    {
      text: "employment_rate_worker",
      align: "end",
      value: "Tx_EMPL_ACT",
      width: "100px",
      sortable: false
    },
    {
      text: "companies10p",
      align: "end",
      value: "SIRENE_10P",
      width: "100px",
      sortable: false
    },
    {
      text: "companies50p",
      align: "end",
      value: "SIRENE_50P",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_pt",
      align: "end",
      value: "MODE21_PT",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_car",
      align: "end",
      value: "MODE21_CAR",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_bike",
      align: "end",
      value: "MODE21_BIKE",
      width: "100px",
      sortable: false
    },
    {
      text: "modeshare_dt_walk",
      align: "end",
      value: "MODE21_WALK",
      width: "100px",
      sortable: false
    },
    {
      text: "household",
      align: "end",
      value: "P21_MEN",
      width: "100px",
      sortable: false
    },
    {
      text: "household_size",
      align: "end",
      value: "P21_PMEN",
      width: "100px",
      sortable: false
    },
    {
      text: "household_park",
      align: "end",
      value: "PercP21_RP_GARL",
      width: "100px",
      sortable: false
    },
    {
      text: "household_0car",
      align: "end",
      value: "PercP21_RP_VOIT0",
      width: "100px",
      sortable: false
    },
    {
      text: "household_1car",
      align: "end",
      value: "PercP21_RP_VOIT1",
      width: "100px",
      sortable: false
    },
    {
      text: "household_2pcar",
      align: "end",
      value: "PercP21_RP_VOIT2P",
      width: "100px",
      sortable: false
    },
    {
      text: "worker_in_zone",
      align: "end",
      value: "PercP21_ACTOCC_ZONE",
      width: "100px",
      sortable: false,
      epci_only: true
    }
  ]
};

// list of icons for objects in map
/**const ICONS = [
  ["user-dark.svg.png", "t-user"],
  ["user-01.svg.png", "t-user-01"],
  ["user-02.svg.png", "t-user-02"],
  ["user-03.svg.png", "t-user-03"],
  ["user-04.svg.png", "t-user-04"],
  ["user-05.svg.png", "t-user-05"],
  ["user-06.svg.png", "t-user-06"],
  ["user-07.svg.png", "t-user-07"],
  ["user-08.svg.png", "t-user-08"],
  ["user-09.svg.png", "t-user-09"],
  ["user-10.svg.png", "t-user-10"],
  ["truck.svg.png", "t-truck"],
  ["odt.svg.png", "t-odt"],
  ["odt-blue.svg.png", "t-odt-blue"],
  ["odt-green.svg.png", "t-odt-green"],
  ["odt-grey.svg.png", "t-odt-grey"],
  ["odt-orange.svg.png", "t-odt-orange"],
  ["odt-red.svg.png", "t-odt-red"],
  ["bike.svg.png", "t-bike"],
  ["station.svg.png", "t-station"],
  ["station-circle.svg.png", "t-station-circle"],
  ["station-circle-black.svg.png", "t-station-circle-black"],
  ["ufo.svg.png", "t-ufo"],
  ["car.svg.png", "t-car"],
  ["car-grey.svg.png", "t-car-grey"],
  ["car-green.svg.png", "t-car-green"],
  ["car-blue.svg.png", "t-car-blue"],
  ["kick-scooter-dark.svg.png", "t-kick-scooter"],
  ["scooter.svg.png", "t-scooter"],
  ["bus-dark.svg.png", "t-bus"],
  ["tram-dark.svg.png", "t-tram"],
  ["subway.svg.png", "t-subway"],
  ["train.svg.png", "t-train"],
  ["stop_point.svg.png", "t-stop_point"],
  ["school-backpack-green.svg.png", "t-school-backback-green"]
];*/

const DECK_PT_COLORS = {
  "t-user": {
    color: [61, 100, 130],
    radius: 3
  },
  "t-bus": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-tram": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-subway": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-train": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-odt": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-bike": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-kick-scooter": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-scooter": {
    color: [133, 194, 135],
    radius: 20
  },
  "t-car": {
    color: [255, 0, 0],
    radius: 20
  },
  "t-station": {
    color: [97, 163, 188],
    radius: 20
  },
  "t-stop_point": {
    color: [97, 163, 188],
    radius: 20
  },
  "t-truck": {
    color: [0, 0, 0],
    radius: 20
  }
};

// tabs slider
const SLIDER_COLOR = "secondary";
const SLIDER_SIZE = "6";

// source names
const OSM_SOURCE = "OpenStreetMap";
const IGN_SOURCE = "IGN";

// layers for menu
const BASE_LAYERS = [
  {
    label: "map_layers.mapnik",
    name: "osm-classic",
    tiles: "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    sources: [OSM_SOURCE]
  },
  {
    label: "ign.plan",
    name: "ign-plan",
    display: false,
    visibility: "none",
    tiles:
      "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png" +
      "&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: "IGN-F/Geoportail",
    sources: [IGN_SOURCE]
  },
  {
    label: "ign.ortho",
    name: "ign-orthophotos",
    tiles:
      "https://data.geopf.fr/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg" +
      "&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
    attribution: "IGN-F/Geoportail",
    sources: [IGN_SOURCE]
  },
  {
    label: "map_layers.transports",
    name: "osm-transport",
    tiles: "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=c1dc9caf209d4339bc5edf6a6b288574",
    attribution:
      "&trade; <a href='http://www.thunderforest.com/'>Thunderforest</a>, &trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    sources: [OSM_SOURCE]
  },
  {
    label: "map_layers.cyclosm",
    name: "osm-cycling",
    tiles: "https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    attribution: "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    sources: [OSM_SOURCE]
  },
  {
    label: "map_layers.maptiler",
    name: "maptiler-dataviz",
    tiles: "https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=ezj7sarQ0SUnnHeyIblg",
    attribution:
      '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
    tileSize: 512,
    sources: [OSM_SOURCE]
  },
  {
    label: "map_layers.dark_map",
    name: "carto-dark",
    tiles: "https://basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
    attribution:
      "&trade; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &trade; <a href='https://carto.com/attributions'>CARTO</a>",
    sources: [OSM_SOURCE]
  }
];

// buttons options for plotly plots
const MODE_BAR_BUTTONS = [["toImage"]];

// data layers properties labels

const DATA_LAYERS_PROP_LABELS = {
  insee_bpe: {
    SDOM_LIBELLE: "Sous-domaine appartenance équipement",
    TYPEQU_LIBELLE: "Type équipement"
  },
  insee_rp_voit_iris: {
    NOM_COM: "Nom de commune",
    P21_MEN: "Nombre de ménages",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercP21_RP_VOIT0: "% 0 voiture",
    PercP21_RP_VOIT1: "% 1 voiture",
    PercP21_RP_VOIT1P: "% 1 voiture et +",
    PercP21_RP_VOIT2P: "% 2 voitures et +"
  },
  insee_rp_voit_commune: {
    NOM: "Nom de commune",
    P21_MEN: "Nombre de ménages",
    INSEE_COM: "Code INSEE",
    PercP21_RP_VOIT0: "% 0 voiture",
    PercP21_RP_VOIT1: "% 1 voiture",
    PercP21_RP_VOIT1P: "% 1 voiture et +",
    PercP21_RP_VOIT2P: "% 2 voitures et +"
  },
  insee_rp_age_iris: {
    NOM_COM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercP21_POP75P: "Population 75 ans et +",
    PercP21_POP0014: "Population 0-14 ans",
    PercP21_POP1529: "Population 15-29 ans",
    PercP21_POP3044: "Population 30-44 ans",
    PercP21_POP4559: "Population 45-59 ans",
    PercP21_POP6074: "Population 60-74 ans"
  },
  insee_rp_age_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    PercP21_POP90P: "Population 90 ans et +",
    PercP21_POP0014: "Population 0-14 ans",
    PercP21_POP1529: "Population 15-29 ans",
    PercP21_POP3044: "Population 30-44 ans",
    PercP21_POP4559: "Population 45-59 ans",
    PercP21_POP6074: "Population 60-74 ans",
    PercP21_POP7589: "Population 75-89 ans"
  },
  insee_rp_activity_iris: {
    NOM_COM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    PercC21_POP15P_CS1: "% agriculteurs",
    PercC21_POP15P_CS2: "% artisants",
    PercC21_POP15P_CS3: "% cadres",
    PercC21_POP15P_CS4: "% prof. inter.",
    PercC21_POP15P_CS5: "% employés",
    PercC21_POP15P_CS6: "% ouvriers",
    PercC21_POP15P_CS7: "% retraités"
  },
  insee_rp_activity_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    PercC21_POP15P_CS1: "% agriculteurs",
    PercC21_POP15P_CS2: "% artisants",
    PercC21_POP15P_CS3: "% cadres",
    PercC21_POP15P_CS4: "% prof. inter.",
    PercC21_POP15P_CS5: "% employés",
    PercC21_POP15P_CS6: "% ouvriers",
    PercC21_POP15P_CS7: "% retraités"
  },
  insee_rp_population_iris: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    NOM_IRIS: "Nom IRIS",
    CODE_IRIS: "Code IRIS",
    INSEE_COM: "Code INSEE",
    P21_POP_DENSITY: "Densité de population (hab./km²)"
  },
  insee_rp_population_commune: {
    NOM: "Nom de commune",
    P21_POP: "Population totale",
    INSEE_COM: "Code INSEE",
    P21_POP_DENSITY: "Densité de population (hab./km²)"
  },
  sirene: {
    siren: "SIREN",
    siret: "SIRET",
    nafLabelLevel2: "Activité",
    nafLabelDetail: "Activité détaillée",
    labelTrancheEffectifs: "Effectifs",
    denominationUniteLegale: "Dénomination"
  }
};

const ROUTE_TYPE_ICONS = {
  0: "tram",
  1: "directions_subway",
  2: "directions_railway",
  3: "directions_bus",
  4: "directions_boat",
  5: "tramway_cable",
  6: "cable_car",
  7: "funicular",
  11: "trolley_cable_car",
  12: "monorail"
};

export {
  KITE_CONTACT,
  COLORS,
  MAPCENTER,
  MAPZOOM,
  MAPPITCH,
  TABHEADERS,
  BASE_LAYERS,
  MODE_BAR_BUTTONS,
  DECK_PT_COLORS,
  SLIDER_COLOR,
  SLIDER_SIZE,
  ZONING_YEAR,
  IRIS_ZONING_YEAR,
  DATA_LAYERS_PROP_LABELS,
  ROUTE_TYPE_ICONS
};
