import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "../translations/fr";
import en from "../translations/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "fr", // set locale
  messages: {
    fr,
    en
  }
});

export default i18n;
