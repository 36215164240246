<!--
Field for choosing a point localisation
-->

<template>
  <v-text-field
    v-bind="$attrs"
    :value="depot_point_str"
    :placeholder="$t('basic_dialogs.undefined')"
    persistent-placeholder
    readonly
    prepend-icon="edit_location_alt"
    @click="selectPoint"
    @click:prepend="selectPoint"
  >
    <v-icon color="primary" @click="selectPoint"> edit_location_alt </v-icon>
  </v-text-field>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { coordinatesToText } from "@/functions-tools";

export default Vue.component("point-selector", {
  props: {
    value: {
      required: true
    }
  },
  data: () => ({}),

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    depot_point_str() {
      if (this.model) {
        return coordinatesToText(this.model.coordinates);
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions(["startMapAction"]),
    selectPoint() {
      this.$emit("selection:update", true);
      this.startMapAction({
        action: "point_selection",
        validationHandler: this.pointSelected,
        closeHandler: this.onClose,
        options: {
          point: this.value?.coordinates
        }
      });
    },
    pointSelected(point) {
      this.model = point;
    },
    onClose() {
      this.$emit("selection:update", false);
    }
  }
});
</script>
