<!--
Display gtfs route information 
-->

<template>
  <v-chip
    v-bind="$attrs"
    style="font-weight: bold"
    :color="`#${routeColor(route)}`"
    :text-color="`#${routeTextColor(route)}`"
    v-on="$listeners"
  >
    {{ routeText(route) }}
  </v-chip>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("route-chip", {
  components: {},
  props: {
    route: { required: true },
    text: {
      default: "route_short_name"
    },
    color: {
      default: "route_color"
    },
    textColor: {
      default: "route_text_color"
    }
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    routeText(route) {
      if (typeof this.text == "function") {
        return this.text(route);
      } else if (typeof this.text == "string") {
        return route[this.text];
      } else {
        return undefined;
      }
    },
    routeColor(route) {
      if (typeof this.color == "function") {
        return this.color(route);
      } else if (typeof this.color == "string") {
        return route[this.color];
      } else {
        return undefined;
      }
    },
    routeTextColor(route) {
      if (typeof this.textColor == "function") {
        return this.textColor(route);
      } else if (typeof this.textColor == "string") {
        return route[this.textColor];
      } else {
        return undefined;
      }
    }
  }
});
</script>
