<!--
  Mixin containing data used by analysis KPIs  
-->

<script lang="ts">
import { mapState, mapActions, mapGetters } from "vuex";
import SimulationStepper from "./simulation_stepper.vue";
import { bbox_from_map } from "@/functions-tools";
import { mandatoryRule, positiveRule } from "@/validation";
import { STEPS } from "@/simulation";
import i18n from "@/plugins/lang";

export default {
  components: { SimulationStepper },
  data: function () {
    return {
      STEPS,
      // predefined rules for penetration rate
      penetrationRateRules: [
        v => !!v || i18n.t("simulations.stepper.rules.penetration_rate.empty"),
        v => (v >= 0 && v <= 100) || i18n.t("simulations.stepper.rules.penetration_rate.range")
      ]
    };
  },

  computed: {
    ...mapState(["async"]),
    ...mapState("simulations", ["step", "valid_step", "parameters", "current_service", "current_use_case"]),
    ...mapState("layers", ["map"])
  },
  methods: {
    ruleMaker(rule_function, i18n_end_path) {
      return value => {
        return (
          rule_function(value) ||
          i18n.t(`simulations.use_cases.${this.current_service}.${this.current_use_case}.rules.${i18n_end_path}`)
        );
      };
    },
    getLabel(label_key) {
      let use_case_path = this.getUSeCaseTranslationPath(`labels.${label_key}`);
      let basics_path = `simulations.stepper.labels.${label_key}`;
      if (i18n.te(use_case_path)) {
        return this.getUSeCaseTranslation(`labels.${label_key}`);
      } else if (i18n.te(basics_path)) {
        return i18n.t(basics_path);
      } else {
        return undefined;
      }
    },
    getUSeCaseTranslation(end_path) {
      return i18n.t(this.getUSeCaseTranslationPath(end_path));
    },
    getUSeCaseTranslationPath(end_path) {
      return `simulations.use_cases.${this.current_service}.${this.current_use_case}.${end_path}`;
    },
    mandatoryRule,
    positiveRule,
    bbox_from_map
  }
};
</script>
