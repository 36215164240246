<template>
  <v-list-item link @click="$emit('click')" :disabled="disabled">
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>
      <div v-if="no_translate">
        {{ title }}
      </div>
      <div v-else>
        {{ $t(title) }}
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("tellae-menu-item", {
  props: ["title", "icon", "disabled", "no_translate"]
});
</script>

<style scoped>
.v-list-item--link::before {
  background-color: var(--v-primary-base) !important;
}
.theme--light.v-icon {
  color: var(--v-primary-base);
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-primary-base) !important;
}
.theme--light.v-list-item--disabled .theme--light.v-icon {
  color: rgba(0, 0, 0, 0.38);
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
</style>
