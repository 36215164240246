<template>
  <div>
    <kite-app-bar logo="/static/logo-kite.png" hide-project>
      <template v-slot:leftAction>
        <v-btn icon dark @click="mainDrawerOpen = !mainDrawerOpen" id="menu"> <v-icon>menu</v-icon></v-btn>
      </template>
      <template v-slot:title>
        <span class="hidden-sm-and-down"> KITE </span>
      </template>
    </kite-app-bar>
    <kite-drawer v-if="appPreset" id="navigation-drawer">
      <kite-drawer-content>
        <map-view-preset v-if="appPreset.type == 'MapView'" :map-view="appPresetObject" />
      </kite-drawer-content>
    </kite-drawer>
    <v-main>
      <v-progress-linear
        :color="$vuetify.theme.themes.light.secondary"
        indeterminate
        height="7px"
        v-if="async.generic"
      ></v-progress-linear>
      <v-container class="pa-0 ma-0 .noscroll" fluid>
        <KiteMap :loading="async.overlay > 0" @map:loaded="mapLoaded" pitch-control />
        <!-- div map -->
        <div class="navi">
          <legends />
          <kite-alert />
          <kite-map-action-dialog />
        </div>
      </v-container>
      <v-progress-linear
        style="position: fixed; bottom: 0px; left: 0px; right: 0px"
        :color="$vuetify.theme.themes.light.secondary"
        indeterminate
        height="7px"
        v-if="async.generic"
      ></v-progress-linear>
    </v-main>
  </div>
</template>

<script>
import KiteMap from "@/components/base/kite_map.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import Legends from "@/components/kite/legends/legends.vue";
import KiteAppBar from "@/components/base/kite_app_bar.vue";
import KiteAlert from "@/components/base/kite_alert.vue";
import { setup_kite_layers } from "@/kite_layers";
import { loadAppPreset, truncateString } from "@/functions-tools";
import KiteDrawer from "@/components/kite/base/kite_drawer.vue";
import KiteDrawerContent from "@/components/kite/base/kite_drawer_content.vue";
import MapViewPreset from "@/components/kite/presets/map_view_preset.vue";

export default {
  name: "Main",
  components: {
    KiteMap,
    KiteAppBar,
    KiteAlert,
    Legends,
    KiteDrawer,
    KiteDrawerContent,
    MapViewPreset
  },
  data: () => ({}),
  computed: {
    ...mapState(["async", "appPreset", "appPresetObject"]),
    ...mapGetters(["appPresetLoading"]),
    mainDrawerOpen: {
      get() {
        return this.$store.state.mainDrawerOpen;
      },
      set(value) {
        console.log(value);
        this.$store.commit("UPDATE_MAIN_DRAWER", value);
      }
    }
  },
  methods: {
    ...mapActions(["asyncEnd"]),
    // on map loaded
    async mapLoaded(map) {
      setup_kite_layers(map);

      // switch on preset type and load content
      await loadAppPreset(this.appPreset);

      this.asyncEnd({ type: "overlay" });
    },
    truncateString
  }
};
</script>

<style scoped>
@media (max-width: 780px) {
  #menu,
  #navigation-drawer {
    display: none;
  }
}
</style>
