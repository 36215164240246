<!--
  Data tables with traffic per direction
-->

<template>
  <kpi-card title="Trafic" :suffix="surveyDate" :subtitle="subtitle">
    <div align="center">
      <capucine-table
        :headers="headers"
        :items="data"
        :loading="loading"
        card-width="40vh"
        :exportFileName="export_table_filename"
      >
      </capucine-table>
    </div>
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import KpiCard from "@/components/capucine/kpi_card.vue";
import AnalysisKpiMixin from "./analysis_kpi_mixin.vue";

export default Vue.component("traffic", {
  components: { KpiCard },
  mixins: [AnalysisKpiMixin],
  data: () => ({
    kpi_name: "traffic",
    headers: [
      { text: "Période", value: "période", align: "left" },
      { text: "V/C", value: "voyageurs_par_course", align: "left" },
      { text: "V/K", value: "voyageurs_par_kilomètre", align: "left" }
    ]
  }),
  computed: {}
});
</script>
