<!--
  Route direction selector component
-->

<template>
  <v-select
    :label="$t('basic_transport.direction')"
    v-model="model"
    :items="direction_items"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-select>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.component("direction-selector", {
  props: ["value"],
  data: () => ({}),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    direction_items() {
      return [
        { text: this.$t("basic_transport.directions.0"), value: "0" },
        { text: this.$t("basic_transport.directions.1"), value: "1" }
      ];
    }
  },

  methods: {}
});
</script>
