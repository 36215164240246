<template>
  <kite-table-action icon="delete_outline" @click="askDelete" :tooltip="$t('basic_dialogs.delete')" />
</template>

<script>
import i18n from "@/plugins/lang";

export default {
  props: {
    askConfirmation: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: i18n.t("basic_dialogs.confirm_delete")
    }
  },
  data() {
    return {};
  },
  methods: {
    askDelete() {
      if (this.askConfirmation) {
        alert({
          message: this.confirmText,
          action: {
            text: this.$t("boolean.true"),
            handler: () => {
              this.$emit("confirmDelete");
            }
          }
        });
      } else {
        this.$emit("confirmDelete");
      }
    }
  }
};
</script>
