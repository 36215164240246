<!--
  Component representing a data table and its pagination.
-->

<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      :headers="headers"
      :items="items"
      :disablePagination="disablePagination"
      :items-per-page="itemsPerPage"
      dense
      hide-default-footer
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <template slot="no-data"> {{ noDataMessage }} </template>
      <template v-slot:[header_attribute(header_obj)]="{ header }" v-for="header_obj in headers">
        {{ headerLangText[header_obj.value] }}
      </template>
    </v-data-table>
    <div v-if="!disablePagination" class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        next-icon="chevron_right"
        prev-icon="chevron_left"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.component("kite-table-old", {
  component: {},

  props: {
    // array containing the table headers
    // example : [{value: "word"}, {value: "number"}]
    headers: {
      type: Array,
      required: true
    },

    // array containing the table items
    // example : [{word: "One", number: 1}, {word: "Two", number: 2}, {word: "Three", number: 3}]
    items: {
      type: Array,
      required: true
    },

    // object containing the translation for the headers
    // example : {word: "Mot", number: "Nombre"}
    headerLangText: {
      type: Object,
      required: true
    },

    // message displayed when no data is available
    noDataMessage: {
      type: String,
      default: "No data"
    },

    // number of items per page
    itemsPerPage: {
      type: Number,
      default: 5
    },

    // disable the table pagination
    disablePagination: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      page: 1,
      pageCount: null
    };
  },

  methods: {
    // create a string used to fetch the right header in the dynamic slot of v-data-table
    header_attribute(header_obj) {
      return "header." + header_obj.value;
    }
  }
});
</script>
