import { render, staticRenderFns } from "./odt_basic.vue?vue&type=template&id=1aef9dd3"
import script from "./odt_basic.vue?vue&type=script&lang=ts"
export * from "./odt_basic.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_-22685427/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports