var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticStyle:{"height":"75vh","width":"100%"}},[_c('KiteMap',{directives:[{name:"show",rawName:"v-show",value:(_vm.non_empty),expression:"non_empty"}],attrs:{"map-css":"height: inherit; width: inherit;","loading":_vm.loading,"map-props":{
      style: {
        version: 8,
        sources: {},
        layers: [
          {
            id: 'background',
            type: 'background',
            paint: {
              'background-color': 'white'
            }
          }
        ]
      },
      antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
    },"overlay-props":{ style: 'position: absolute' }},on:{"map:initialised":_vm.mapInitialized,"map:loaded":_vm.mapLoaded}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.non_empty),expression:"!non_empty"}]},[_vm._v("Pas de tracé pour cette ligne")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }