<!--
Stepper for odt railway station use case 
-->

<template>
  <simulation-stepper>
    <template v-slot:environment>
      <v-form v-model="valid_step[STEPS.environment]">
        <v-row align="center">
          <v-col md="6">
            <v-autocomplete
              v-model="parameters['railway_station']"
              :items="railway_stations"
              :rules="[mandatoryRule]"
              :loading="async.railway_stations > 0"
              :disabled="async.railway_stations > 0"
              :label="getLabel('railway_station')"
              required
              prepend-icon="train"
              item-text="railway_station_name"
              item-value="uic_code"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col md="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="ml-2" color="primary" @click="updateStationsList"> location_searching </v-icon>
              </template>
              <span v-html="$t('simulations.use_cases.ODT.odt_railway_station.tooltip.update_station')"></span>
            </v-tooltip>
          </v-col>
          <v-col md="5">
            <v-text-field
              v-model="parameters['radius']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('radius')"
              required
              :suffix="$t('simulations.use_cases.ODT.odt_railway_station.suffix.radius')"
              prepend-icon="mdi-radius-outline"
            ></v-text-field>
          </v-col>
        </v-row>
        <simulation-speeds-param :speed-items="parameters['speeds']" />
      </v-form>
    </template>
    <template v-slot:demand>
      <v-form v-model="valid_step[STEPS.demand]">
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['penetration_rate']"
              :rules="penetrationRateRules"
              type="number"
              :label="getLabel('penetration_rate')"
              required
              suffix="%"
              prepend-icon="mdi-label-percent-outline"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header hide-actions>
                <template v-slot:default="{ open }">
                  <div>
                    <v-icon>
                      <template v-if="open">mdi-chevron-up</template>
                      <template v-else>mdi-chevron-down</template>
                    </v-icon>
                    {{ getLabel("options") }}
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-html="$t('simulations.use_cases.ODT.odt_railway_station.description.demand_options')"></span>
                <v-col md="4">
                  <v-text-field
                    v-model="parameters['random_seed']"
                    :rules="[mandatoryRule]"
                    type="number"
                    :label="getLabel('random_seed')"
                    required
                    prepend-icon="mdi-dice-6-outline"
                  ></v-text-field>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-form>
    </template>
    <template v-slot:offer>
      <v-form v-model="valid_step[STEPS.offer]">
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['number_vehicles']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('number_vehicles')"
              required
              prepend-icon="mdi-bus"
            ></v-text-field>
          </v-col>
          <v-col md="5">
            <v-text-field
              v-model="parameters['capacity']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('capacity')"
              required
              prepend-icon="groups"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="5">
            <v-text-field
              v-model="parameters['max_waiting_time']"
              :rules="[mandatoryRule, positiveRule]"
              type="number"
              :label="getLabel('max_waiting_time')"
              required
              suffix="minutes"
              prepend-icon="mdi-timer-outline"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simulation-stepper>
</template>

<script lang="ts">
import Vue from "vue";
import StepperMixin from "./stepper_mixin.vue";
import SimulationSpeedsParam from "./simulation_speeds_param.vue";

export default Vue.component("odt-railway-station", {
  mixins: [StepperMixin],
  components: {
    SimulationSpeedsParam
  },
  data: () => ({
    railway_stations: []
  }),

  async mounted() {
    let res = await this.$whale.railwayStationInformation();
    this.railway_stations = res?.information || [];
  },

  computed: {},

  methods: {
    async updateStationsList() {
      let param = this.bbox_from_map(this.map);

      let res = await this.$whale.railwayStationInformation(param);
      this.railway_stations = res?.information || [];
    }
  }
});
</script>
