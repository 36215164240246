<!--
  Data table with bonus malus
-->

<template>
  <kpi-card title="Bonus / Malus" :suffix="titleSuffixWithDates" :subtitle="SUBTITLES.bonus_malus">
    <v-card-actions class="mb-n10">
      <v-row>
        <v-col cols="3">
          <v-select prepend-icon="tune" label="Régulation" v-model="content" :items="contents"></v-select>
        </v-col>
      </v-row>
    </v-card-actions>
    <result-table
      :items="selectedData['bonus_malus_' + content]"
      :formats="{ actuel: '€', simulé: '€', écart: '€' }"
      :width="60"
    />
  </kpi-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { SUBTITLES } from "@/capucine_utils";
import KpiCard from "@/components/capucine/kpi_card.vue";
import ResultTable from "./result_table.vue";

export default Vue.component("bonus-malus", {
  components: {
    KpiCard,
    ResultTable
  },
  data: () => ({
    SUBTITLES,
    content: "punctuality",
    contents: [
      { text: "Ponctualité", value: "punctuality" },
      { text: "Régularité", value: "regularity" }
    ]
  }),
  computed: {
    ...mapGetters("capucine_results", ["selectedData", "titleSuffixWithDates"])
  }
});
</script>
