<!--
  Changed log
-->
<template>
  <div>
    <v-dialog :value="dialog" max-width="700px" scrollable @click:outside="closeDialog">
      <v-card max-height="500px">
        <v-card-title class="headline grey lighten-2"
          >{{ $t("mySettings.title") }}
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="$whale.user.firstName"
                    :label="$t('mySettings.firstName')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="$whale.user.lastName"
                    :label="$t('mySettings.lastName')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="$whale.user.jobTitle"
                    :label="$t('mySettings.jobTitle')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="$whale.updateUser(['firstName', 'lastName', 'jobTitle', 'kite'])"
            >
              <v-progress-circular size="20" width="2" indeterminate v-if="$whale.async.updateUser === true" />
              {{ $t("mySettings.save") }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("my-settings", {
  components: {},

  props: ["dialog"],

  computed: {
    ...mapState(["buildDate", "version"])
  },
  data: function () {
    return {
      reverse: false,
      valid: false
    };
  },
  watch: {
    dialog(value) {
      this.dialog = value;
    }
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("handleUpdateDialog", false);
    }
  }
});
</script>
