<!--
  Capucine data table base component
-->

<template>
  <v-card elevation="0" :max-height="height">
    <div id="tp_plots_div">
      <Plotly
        :data="formatSeries(series)"
        :layout="plotly_layout"
        :config="plotly_config"
        id="plotly_tp"
        :responsive="true"
        :autosizable="true"
        :scrollZoom="false"
        :displaylogo="false"
        :displayModeBar="true"
        :toImageButtonOptions="plotly_config['toImageButtonOptions']"
      ></Plotly>
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { Plotly } from "@wellcaffeinated/vue-plotly";

export default Vue.component("capucine-sim-tp-plot", {
  components: {
    Plotly
  },
  props: {
    height: String,
    series: Array
  },
  data: () => ({
    plotly_config: {
      toImageButtonOptions: {
        format: "png",
        filename: "temps_parcours_simulation",
        height: 1000,
        width: 1500
      }
    }
  }),
  computed: {
    plotly_layout() {
      return {
        height: this.height,
        margin: { b: 20, l: 20, r: 5, t: 30 },
        title: { text: "", font: { size: 25 } },
        legend: { orientation: "h" },
        modebar: {
          add: ["togglespikelines", "hoverclosest", "hovercompare"]
        },
        hovermode: "x",
        xaxis: {
          zeroline: false,
          hoverformat: ".1f",
          dtick: 1,
          gridcolor: "#eeeeee",
          linecolor: "#eeeeee",
          linewidth: 2,
          mirror: "ticks"
        },
        yaxis: {
          zeroline: false,
          hoverformat: ".0f",
          dtick: 1,
          gridcolor: "#eeeeee",
          linecolor: "#eeeeee",
          linewidth: 2,
          mirror: "ticks"
        }
      };
    }
  },
  methods: {
    formatSeries(series) {
      const labels = [
        4, 5, 6, 6.75, 7.25, 7.75, 8.25, 8.75, 9.25, 10, 11, 12, 13, 14, 15, 16, 16.75, 17.25, 17.75, 18.25, 18.75,
        19.25, 19.75, 20.5, 21.5, 22.5, 23.5, 24.5, 25.5, 26.5, 27.5, 28.5, 29.5
      ];

      return [
        {
          x: labels,
          y: series[0]["data"],
          mode: "lines",
          name: series[0]["name"],
          line: {
            color: "#ff0000",
            width: 3
          }
        },
        {
          x: labels,
          y: series[1]["data"],
          mode: "lines",
          name: series[1]["name"],
          line: {
            color: "#85c287",
            width: 3
          }
        },
        {
          x: labels,
          y: series[2]["data"],
          mode: "lines",
          name: series[2]["name"],
          line: {
            color: "#000000",
            width: 3,
            dash: "dot"
          }
        }
      ];
    }
  }
});
</script>
