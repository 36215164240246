<!--
  Bottom sheet to display glossary
-->

<template>
  <div>
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon v-bind="attrs" v-on="on">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
            </template>
            <span>Afficher le glossaire</span>
          </v-tooltip>
        </v-btn>
      </template>
      <v-sheet class="text-center">
        <v-btn class="my-3" text color="primary" @click="sheet = !sheet"> Fermer </v-btn>
        <div>
          <h2>Glossaire</h2>
          <v-row>
            <v-col align="center" md="6">
              <v-card elevation="0" class="px-5 pb-5">
                <v-card-title>Indicateurs</v-card-title>
                <v-data-table
                  :items="GLOSSARY.indicators"
                  :headers="headers_indicators"
                  fixed-header
                  hide-default-footer
                  height="70vh"
                  :items-per-page="-1"
                  disable-sort
                >
                  <template v-slot:item.description="{ item }"> <span v-html="item.description"></span> </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col align="center" md="6">
              <v-card elevation="0" class="px-5 pb-5">
                <v-card-title>Définitions générales</v-card-title>
                <v-data-table
                  :items="GLOSSARY.generic_definition"
                  :headers="headers_definitions"
                  fixed-header
                  hide-default-footer
                  height="70vh"
                  :items-per-page="-1"
                  disable-sort
                >
                  <template v-slot:item.indicator="{ item }"> <span v-html="item.indicator"></span> </template>
                  <template v-slot:item.description="{ item }"> <span v-html="item.description"></span> </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { GLOSSARY } from "@/capucine_utils";

export default Vue.component("glossary", {
  data: function () {
    return {
      GLOSSARY,
      sheet: false,
      headers_indicators: [
        { text: "Indicateur", value: "indicator", width: "20%" },
        { text: "Description", value: "description", width: "80%" }
      ],
      headers_definitions: [
        { text: "Définition", value: "indicator", width: "20%" },
        { text: "Description", value: "description", width: "80%" }
      ]
    };
  }
});
</script>
