<template>
  <base-aggregation v-on="$listeners" :agg-options="options">
    <p>
      {{ $t("flows.aggregation.admin.text") }}
    </p>
    <zoning-selector
      v-model="options.zoning"
      :rules="[mandatoryRule]"
      class="mt-3 mb-n4"
      :label="$t('flows.aggregation.admin.title')"
      solo
    />
  </base-aggregation>
</template>

<script lang="ts">
import Vue from "vue";
import { mandatoryRule } from "@/validation";
import BaseAggregation from "./base_aggregation.vue";
import ZoningSelector from "@/components/kite/base/zoning_selector.vue";

export default Vue.component("admin-aggregation", {
  components: {
    BaseAggregation,
    ZoningSelector
  },

  data: function () {
    return {
      options: {
        zoning: null
      }
    };
  },

  computed: {},

  methods: {
    mandatoryRule
  }
});
</script>
