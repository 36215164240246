<!--
  Main simulation page
-->

<template>
  <div>
    <v-overlay opacity="0.5" z-index="10" color="grey lighten-1" absolute :value="simulation_loading">
      <div class="d-flex flex-column align-center">
        <h1>Chargement</h1>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </div>
    </v-overlay>
    <simulation-parameters />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SimulationParameters from "./simulation_parameters.vue";

import { mapGetters } from "vuex";

export default Vue.component("analysis-page", {
  components: { SimulationParameters },
  computed: {
    ...mapGetters("capucine_simulation", ["simulation_loading"])
  }
});
</script>
