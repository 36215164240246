<!--
  Component representing a data table and its pagination.
-->

<template>
  <v-card min-height="90vh">
    <kite-card-title :title="$t('map_view.manager.title')" :tooltip="$t('map_view.manager.tooltip')" />
    <v-card-text class="pt-5">
      <v-row>
        <v-col cols="4">
          <v-btn color="primary" rounded large right dark @click="newMapView('map')">
            <v-icon left>map</v-icon>{{ $t("map_view.manager.save_map") }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" rounded large right dark @click="newMapView('zoom')">
            <v-icon left>pin_drop</v-icon>{{ $t("map_view.manager.save_zoom") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card class="mt-2 mx-1" elevation="0" tile>
      <v-tabs
        v-model="tab"
        background-color="primary"
        class="elevation-2"
        dark
        grow
        :slider-size="SLIDER_SIZE"
        icons-and-text
      >
        <v-tabs-slider :color="SLIDER_COLOR" />
        <v-tab :key="0" :href="`#tab-0`"> {{ $t("map_view.manager.tabs.map") }} <v-icon> map</v-icon> </v-tab>
        <v-tab-item :key="0" :value="'tab-0'">
          <map-view-table :items="mapsTable" focusable sharable @closeDialog="closeDialog" />
        </v-tab-item>
        <v-tab :key="1" :href="`#tab-1`"> {{ $t("map_view.manager.tabs.zoom") }} <v-icon> pin_drop</v-icon> </v-tab>
        <v-tab-item :key="1" :value="'tab-1'">
          <map-view-table :items="zoomsTable" @closeDialog="closeDialog" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { newMapViewFromCurrentStore } from "@/map_view";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";
import MapViewTable from "./map_view_table.vue";

export default Vue.component("map-manager", {
  components: {
    MapViewTable
  },

  props: {},

  data: function () {
    return {
      tab: 0,
      SLIDER_SIZE,
      SLIDER_COLOR
    };
  },
  computed: {
    mapsTable() {
      return this.$whale.project._mapViews.filter(map_view => {
        return map_view.type == "map";
      });
    },
    zoomsTable() {
      return this.$whale.project._mapViews.filter(map_view => {
        return map_view.type == "zoom";
      });
    }
  },
  methods: {
    async newMapView(type) {
      let new_map_view = await newMapViewFromCurrentStore(type, false);
      this.$emit("newMapView", new_map_view);
    },
    closeDialog() {
      this.$emit("updateMenuDialog", false);
    }
  }
});
</script>
