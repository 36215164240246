/* This module contains utils fonctions and variables for Capucine */

import { COLORS } from "./global";
import i18n from "./plugins/lang";
import store from "./store";

// Constants

const ANALYSIS_KPIS = [
  { name: "Temps de parcours", key: "travel_time", icon: "update" },
  // { name: "Temps cumulés", key: "cumulative_travel_time", icon: "mdi-chart-bell-curve-cumulative" },
  { name: "Ponctualité / Régularité", key: "conformity", icon: "departure_board" },
  { name: "Production", key: "production", icon: "timer" },
  { name: "Vitesse commerciale", key: "commercial_speed", icon: "speed" },
  { name: "Battement", key: "buffer_time", icon: "u_turn_right" },
  { name: "Robustesse", key: "robustness", icon: "thumb_up_off_alt" },
  { name: "Pertes KCC", key: "kcc_loss", icon: "trending_down" },
  { name: "Charge", key: "vehicle_load", icon: "mdi-account-multiple" },
  { name: "Serpentaire", key: "serpentary", icon: "bar_chart" },
  { name: "Trafic", key: "traffic", icon: "transfer_within_a_station" }
];

const DAY_TYPES = ["LAVHV", "SAMHV", "DIMFHV", "LAVVAC", "SAMVAC", "DIMFVAC", "LAVETE", "SAMETE", "DIMFETE"];

// dictionnary with kpi description
const SUBTITLES = {
  bonus_malus:
    "Les <b>bonus / malus</b> de qualité de service sont évalués à partir des indicateurs de ponctualité et de régularité.",
  buffer_time:
    "Le <b>battement</b> est mesuré par direction, que ce soit au terminus ou à un arrêt de retournement. Le <b>taux de battement</b> est égal à <i>(temps de battement) / (temps de roulage précédent)</i>. <br> La valeur affichée est le battement moyen par course.",
  commercial_speed:
    "L'indicateur détaille les <b>vitesses moyennes</b> pour chaque tronçon. Un filtre préalable retire les valeurs anormales.",
  conformity:
    "L'indicateur évalue la <b>conformité</b> des courses par tronçon et par période par rapport\
    au tableau de marche théorique. La conformité d'un tronçon est la moyenne des conformités\
    de ses arrêts. <br> Le calcul de la <b>régularité</b> se base sur les intervalles de\
    passages aux arrêts. La <b>ponctualité</b> dépend de l'écart avec les horaires théoriques.\
    En <b>hybride</b>, la régulation peut être en régularité à certaines tranches horaires\
    et en ponctualité à d'autres tranches horaires.",
  cumulative_travel_time:
    "L'indicateur détaille les <b>temps de parcours cumulés</b> théoriques et réalisés pour tous les tronçons.",
  drive_duration: "Les <b>heures de conduites</b> prennent en compte le temps de roulage et le temps de battement.",
  iso_indicators:
    "Les <b>indicateurs à moyen constant</b> donnent une estimation de l'impact sur les intervalles et les taux de charge, à nombre de véhicules inchangé par rapport au théorique, et en prenant en compte vos ajustements de temps de parcours et de taux de battement.",
  kcc_loss:
    "L'indicateur synthétise les <b>pertes de kilomètres commerciaux</b> par rapport à l'engagement contractuel du théorique.",
  kcc_reduction:
    "La <b>réfaction kilométrique</b> est une estimation des pénalités financières en lien avec les pertes KCC.",
  analysis_synthesis:
    "Synthèse des <b>indicateurs annualisés</b> par ligne ou par type de jour quand une ligne est sélectionnée.",
  lot_synthesis: "Synthèse des <b>indicateurs simulés annualisés</b> par ligne.",
  number_vehicles:
    "Le <b>nombre de véhicules</b> est estimé à partir du temps de révolution, des temps de battement et des intervalles.",
  robustness:
    "L'indicateur quantifie le nombre de courses réalisées pour lesquelles le retard est couvert par le temps de battement. <br> La condition suivante doit alors être respectée <i>(temps réalisé) ≤ (temps théorique) + (temps de battement)</i>.",
  production:
    "Pour chaque (jour, tranche horaire, point d'arrêt), l'indicateur calcule\
    le nombre de passage théorique k et réalisé k'. Il est ensuite décompté le\
    nombre de fois où k < k' -1 (situations inacceptables). Le résultat final\
    est la proportion de situation inacceptables.",
  section_travel_time:
    "L'indicateur détaille les <b>temps de parcours</b> théoriques, réalisés et ajustés pour tous les tronçons",
  serpentary: "L'indicateur détaille la <b>charge</b> sur la ligne et les <b>montées / descentes</b> aux arrêts.",
  traffic:
    "L'indicateur détaille pour chaque période les indicateurs <b>V/C</b> <i>(nombre de voyages / nombre de courses)</i> et <b>V/K</b> <i>(nombre de voyages / kilomètres commerciaux)</i>.",
  travel_time:
    "L'indicateur détaille les <b>temps de parcours</b> théoriques et réalisés pour chaque tronçon et l'ensemble de la ligne. Un filtre préalable retire les valeurs anormales.",
  vehicle_load: {
    analysis:
      "L'indicateur détaille les <b>taux de charge maximaux</b> atteints par tronçon. <br/> Hypothèse de <b>capacité des véhicules</b> : ",
    results:
      "Le <b>taux de charge maximal</b> atteint dans les véhicules.<br/> La <b>capacité des véhicules</b> est celle spécifiée dans les paramètres de la simulation."
  }
};
const ALL_DAYS = "Tous types de jours";
const ALL_ROUTES = "Toutes les lignes";

const ALL_ROUTES_ITEM = {
  id: ALL_ROUTES,
  route_data_id: null,
  lot: "",
  name: ALL_ROUTES,
  sections: {
    order: [],
    names: {}
  },
  capacity: 0,
  color: "",
  text_color: "",
  start_date: "",
  end_date: "",
  id_line: undefined,
  type: null,
  isAllRoutes: true
};

const CAPUCINE_FILTER_TP_PARAMS = {
  below_median: {
    value: 5,
    activate: false
  },
  above_median: {
    value: 10,
    activate: false
  },
  number_std: {
    value: 2,
    activate: true
  }
};

const CAPUCINE_BUFFER_PARAMS = {
  percentage: 10,
  percentage_theoretical: 10,
  pX: 95,
  std_3_6: 2,
  std_6_10: 1.5,
  std_10_more: 1
};

// dictionnary to map long and short periods when updating travel time dynamically in simulation
// key 0 = "03:30 → 04:30" value 0 = 03:30 → 06:30 ...
const MAP_PERIODS = {
  0: 0,
  1: 0,
  2: 0,
  3: 1,
  4: 1,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
  9: 2,
  10: 2,
  11: 2,
  12: 3,
  13: 3,
  14: 3,
  15: 3,
  16: 4,
  17: 4,
  18: 4,
  19: 4,
  20: 4,
  21: 4,
  22: 4,
  23: 5,
  24: 5,
  25: 5,
  26: 5,
  27: 5,
  28: 5,
  29: 5
};

// chart labels
const LABELS = [
  "1970-01-01T03:30:00",
  "1970-01-01T04:30:00",
  "1970-01-01T05:30:00",
  "1970-01-01T06:30:00",
  "1970-01-01T07:00:00",
  "1970-01-01T07:30:00",
  "1970-01-01T08:00:00",
  "1970-01-01T08:30:00",
  "1970-01-01T09:00:00",
  "1970-01-01T09:30:00",
  "1970-01-01T10:30:00",
  "1970-01-01T11:30:00",
  "1970-01-01T12:30:00",
  "1970-01-01T13:30:00",
  "1970-01-01T14:30:00",
  "1970-01-01T15:30:00",
  "1970-01-01T16:30:00",
  "1970-01-01T17:00:00",
  "1970-01-01T17:30:00",
  "1970-01-01T18:00:00",
  "1970-01-01T18:30:00",
  "1970-01-01T19:00:00",
  "1970-01-01T19:30:00",
  "1970-01-01T20:00:00",
  "1970-01-01T21:00:00",
  "1970-01-01T22:00:00",
  "1970-01-01T23:00:00",
  "1970-01-02T00:00:00",
  "1970-01-02T01:00:00",
  "1970-01-02T02:00:00",
  "1970-01-02T03:00:00",
  "1970-01-02T04:00:00",
  "1970-01-02T05:00:00"
];
// chart labels moved to middle of slice for better plot and alignment with points
const LABELS_MIDDLE = [
  "1970-01-01T04:00:00",
  "1970-01-01T05:00:00",
  "1970-01-01T06:00:00",
  "1970-01-01T06:45:00",
  "1970-01-01T07:15:00",
  "1970-01-01T07:45:00",
  "1970-01-01T08:15:00",
  "1970-01-01T08:45:00",
  "1970-01-01T09:15:00",
  "1970-01-01T10:00:00",
  "1970-01-01T11:00:00",
  "1970-01-01T12:00:00",
  "1970-01-01T13:00:00",
  "1970-01-01T14:00:00",
  "1970-01-01T15:00:00",
  "1970-01-01T16:00:00",
  "1970-01-01T16:45:00",
  "1970-01-01T17:15:00",
  "1970-01-01T17:45:00",
  "1970-01-01T18:15:00",
  "1970-01-01T18:45:00",
  "1970-01-01T19:15:00",
  "1970-01-01T19:45:00",
  "1970-01-01T20:30:00",
  "1970-01-01T21:30:00",
  "1970-01-01T22:30:00",
  "1970-01-01T23:30:00",
  "1970-01-02T00:30:00",
  "1970-01-02T01:30:00",
  "1970-01-02T02:30:00",
  "1970-01-02T03:30:00",
  "1970-01-02T04:30:00",
  "1970-01-02T05:30:00"
];

// chart options for apex chart
var CHART_OPTIONS_BASE = {
  chart: {
    type: "line",
    toolbar: {
      show: true
    }
  },
  labels: LABELS,
  xaxis: {
    type: "datetime",
    tooltip: {
      enabled: false
    },
    labels: {
      datetimeFormatter: {
        day: "J+1",
        hour: "HH:mm"
      },
      datetimeUTC: false
    }
  },
  title: {
    align: "center",
    offsetY: 15,
    style: {
      fontSize: "25px"
    }
  },
  yaxis: {
    labels: {
      formatter: function (value: any) {
        return value.toFixed(0);
      }
    }
  },
  tooltip: {
    shared: true,
    intersect: false,
    x: {
      format: "HH:mm"
    }
  }
};

// chart options for apex chart travel time graph in analysis
var CHART_OPTIONS_TRAVEL_TIME = {
  stroke: {
    curve: "smooth",
    width: 2,
    dashArray: [10, 0]
  },
  colors: ["#000000", "#ff0000"],
  legend: {
    position: "bottom"
  }
};

// chart options for apex chart cumulative travel time graph in analysis
var CHART_OPTIONS_CUMULATIVE_TRAVEL_TIME = {
  stroke: {
    curve: "smooth",
    width: 3
  },
  theme: {
    palette: "palette2"
  },
  legend: {
    position: "right"
  }
};

// chart options for apex chart section travel time graph in simulation and results
var CHART_OPTIONS_SECTION_TRAVEL_TIME = {
  stroke: {
    curve: "smooth",
    width: 2,
    dashArray: [0, 0, 10]
  },
  colors: ["#ff0000", COLORS.secondary, "#000000"],
  legend: {
    position: "bottom"
  }
};

// select levels at which the result page is disabled (in {'lot", "route", "day_type" })
const DISABLED_RESULT_PAGES = {
  section_travel_time: ["route", "lot"],
  iso_indicators: ["route", "lot"],
  robustness: [],
  kcc_loss: ["day_type"],
  vehicle_load: [],
  conformity: [],
  number_vehicles: ["day_type"],
  drive_duration: ["day_type"],
  kcc_reduction: ["day_type"],
  bonus_malus: ["day_type", "route"],
  synthesis: ["day_type", "route"]
};

const COLOR_LEGEND = {
  robustness: {
    colors: ["#ef5350", "#ffffbf", "#99d594"],
    values: [0, 85, 95],
    unit: "%"
  },
  conformity: {
    colors: ["#ef5350", "#fc8d59", "#ffffbf", "#99d594"],
    values: [0, 80, 84, 88],
    unit: "%"
  },
  commercial_speed: {
    colors: [
      "#ef5350",
      "#f46760",
      "#f87970",
      "#fc8b81",
      "#ff9c92",
      "#ffada4",
      "#ffbdb5",
      "#ffcec7",
      "#ffdeda",
      "#ffefec",
      "#ffffff"
    ],
    values: [0, 2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25],
    unit: "km/h"
  },
  vehicle_load: {
    colors: ["#99d594", "#ffffbf", "#ef5350"],
    values: [0, 60, 80],
    unit: "%"
  }
};

const GLOSSARY = {
  indicators: [
    {
      indicator: "Synthèse",
      description:
        "<ul><li>Au niveau du réseau / lot : Indicateurs annualisés de chaque ligne de bus et taux charge maximum en LAVHV</li><li>Au niveau de la ligne : Indicateurs par type de jour et annualisés</li></ul>"
    },
    {
      indicator: "Temps de parcours",
      description:
        "<ul><li>Temps de parcours théoriques et réalisés après filtrage des valeurs anormales</li><li>Valeurs par tronçon ou pour l'ensemble de la ligne</li></ul>"
    },
    {
      indicator: "Temps cumulés",
      description:
        "<ul><li>Similaire à temps de parcours</li><li>Restitution des temps de parcours cumulés à partir du terminus de départ de la ligne</li></ul>"
    },
    {
      indicator: "Vitesse commerciale",
      description:
        "<ul><li>Filtrage similaire aux temps de parcours</li><li>À partir des temps de parcours et des distances, calcul des vitesses commerciales théoriques et réalisées pour chaque tronçon</li></ul>"
    },
    {
      indicator: "Battement",
      description:
        "<ul><li>Filtrage des temps de battements excessifs ou négatifs</li><li>Temps de battement (temps non commercial entre deux courses d'un même service voiture), mesuré à l'arrêt de fin de la course (terminus de la ligne ou arrêt de retournement / raccordement)</li><li>Taux de battement égal à <i>(temps de battement) / (temps de roulage précédent)</i></li><li>Pour chaque période horaire, temps de battement moyen des courses de la période horaire</li></ul>"
    },
    {
      indicator: "Robustesse",
      description:
        "<ul><li>Proportion de courses réalisées pour lesquelles le retard est couvert par le temps de battement</li><li>Une course est robuste si <i>(temps réalisé) ≤ (temps théorique) + (temps de battement théorique)</i></li></ul>"
    },
    {
      indicator: "Ponctualité",
      description:
        "<ul><li>Application du mode de calcul IdFM</li><li>À chaque point SAE, calcul de l'écart entre l'heure de passage théorique et l'heure réalisée, puis application d'un score</li><li>Distinction entre Haute et Basse Fréquence</li><li>Le score d'un tronçon est la moyenne des scores des points SAE du tronçon, à l'exception du dernier point SAE qui n'est pas pris en compte</li></ul>"
    },
    {
      indicator: "Régularité",
      description:
        "<ul><li>Application du mode de calcul IdFM</li><li>À chaque pointe SAE, calcul de l'écart entre l'intervalle théorique et l'intervalle réalisé, puis application d'un score</li><li>Le score d'un tronçon est la moyenne des scores des points SAE du tronçon, à l'exception du dernier point SAE qui n'est pas pris en compte</li></ul>"
    },
    {
      indicator: "Hybride",
      description:
        "<ul><li>Application du mode de calcul IdFM</li><li>Pour un même type de jour, une ligne peut\
        être régulée en régularité (si intervalle moyen inférieur ou égal à 10 minutes) à certaines\
        tranches horaires ou en ponctualité à d'autres tranches horaires.</li></ul>"
    },
    {
      indicator: "Production",
      description:
        "<ul><li>Comparaison entre le nombre de passage théorique k et réalisé k'</li>\
        <li>Calcul pour chaque jour / tranche horaire / point d'arrêt</li>\
        <li>Calcul final de la proportion de situations inacceptables où k < k' - 1</li></ul>"
    },
    {
      indicator: "Pertes KCC",
      description:
        "<ul><li>Comparaison entre les kilomètres commerciaux réalisés et les kilomètres commerciaux contractuel du théorique</li><li>Restitution des valeurs moyennes journalières</li></ul>"
    },
    {
      indicator: "Charge",
      description:
        "<ul><li>Calcul des taux de charges pour chaque inter-arrêt et par direction</li><li>Affichage du taux de charge maximum par tronçon</li><li>Hypothèse de capacité par véhicule de 71 places</li></ul>"
    },
    {
      indicator: "Serpentaire",
      description: "<ul><li>Profil de charge et montées / descentes aux arrêts</li></ul>"
    },
    {
      indicator: "Trafic",
      description: "<ul><li>Indicateurs de trafic</li></ul>"
    }
  ],
  generic_definition: [
    {
      indicator: "Filtrage des temps de parcours",
      description:
        "<ul><li>Appliqué uniquement au LAVHV car la quantité de données est suffisante</li><li>Au niveau des tronçons, calcul du temps moyen réalisé par pas de 30 minutes en HP et 1h en HC</li><li>Conservation des valeurs comprises dans l'intervalle [moyenne - 2 x écart type, moyenne + 2 x écart type] ce qui correspond à environ 95% des valeurs</li></ul>"
    },
    {
      indicator: "Annualisation des indicateurs",
      description:
        "<ul><li>Pondération des valeurs journalières par le nombre moyen de mesures du type de jour</li><li>Puis pondération par le nombre de jours de chaque type de jours au cours de l'année 2021</li></ul>"
    },
    {
      indicator: "Basse et haute fréquence",
      description:
        "<ul><li>Basse fréquence : intervalle moyen supérieur à 12 minutes</li><li>Haute fréquence : intervalle moyen inférieur ou égal à 12 minutes</li></ul>"
    },
    {
      indicator: "Charges",
      description:
        "<ul><li>Données de l'enquête OD</li><li>Utilisation du niveau d'offre réalisé le jour de l'enquête</li></ul>"
    },
    {
      indicator: "Temps de parcours &#x22;théoriques&#x22;",
      description:
        "Les temps de parcours &#x22;théoriques&#x22; sont estimés à partir des données SAE de la période d'analyse."
    },
    {
      indicator: "Temps de parcours &#x22;contractuel&#x22;",
      description: "Les temps de parcours &#x22;contractuels&#x22; sont extraits du logiciel HASTUS."
    }
  ]
};

// Functions

function resultTabDisabled(tab, level) {
  return DISABLED_RESULT_PAGES[tab].includes(level);
}

/**
 * Custom sort function for lot synthesis tables.
 * Copy of sortItems from https://github.com/vuetifyjs/vuetify/blob/51635832b795abeb2e16d4b2701c41e9d9413dd7/packages/vuetify/src/util/helpers.ts
 * @param items
 * @param sortBy
 * @param sortDesc
 * @param locale
 * @param customSorters
 * @returns
 */
function sortLotTable(items: any[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?) {
  if (sortBy === null || !sortBy.length) return items;
  const stringCollator = new Intl.Collator(locale, { sensitivity: "accent", usage: "sort" });

  return items.sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      // special case when route == "Lot"
      if (a.route == "Lot") {
        return -1;
      } else if (b.route == "Lot") {
        return 1;
      }

      const sortKey = sortBy[i];

      // this part is simplified, doesn't support nested access, etc
      let sortA = a[sortKey];
      let sortB = b[sortKey];

      if (sortDesc[i]) {
        [sortA, sortB] = [sortB, sortA];
      }

      if (customSorters && customSorters[sortKey]) {
        const customResult = customSorters[sortKey](sortA, sortB);

        if (!customResult) continue;

        return customResult;
      }

      // Check if both cannot be evaluated
      if (sortA === null && sortB === null) {
        continue;
      }

      // Dates should be compared numerically
      if (sortA instanceof Date && sortB instanceof Date) {
        return sortA.getTime() - sortB.getTime();
      }

      [sortA, sortB] = [sortA, sortB].map(s => (s || "").toString().toLocaleLowerCase());

      if (sortA !== sortB) {
        if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB);
        return stringCollator.compare(sortA, sortB);
      }
    }

    return 0;
  });
}

/**
 * Compute a list of section pairs from the raw list of sections
 * @param stops
 * @param direction
 * @param cumulative
 * @returns
 */
function computeSectionsPairs(stops, direction, cumulative = false) {
  if (stops.length == 0) {
    return [];
  }

  let ordered_stops = null;
  if (direction == 0) {
    ordered_stops = stops;
  } else {
    ordered_stops = [...stops].reverse();
  }

  let pairs = [];
  let current_stop = ordered_stops[0];
  let pair = null;
  for (let i = 1; i < ordered_stops.length; i++) {
    if (cumulative) {
      pair = ordered_stops[0] + "→" + ordered_stops[i];
    } else {
      pair = current_stop + "→" + ordered_stops[i];
    }
    pairs.push(pair);
    current_stop = ordered_stops[i];
  }
  return pairs;
}

function computeSectionPairsItems(sections: Array<String>, section_pairs: any, direction, add_total: Boolean) {
  if (direction == 1) {
    sections = [...sections].reverse();
  }
  let pairsItems = section_pairs[direction].map((element, index) => {
    return { text: element, value: sections[index] };
  });
  if (add_total) {
    pairsItems.unshift({ text: "Total", value: "total" });
  }
  return pairsItems;
}

/** DEPRECATED/UNUSED : keep for eventual use for apex charts
 * Create apex chart series to plot travel time for selected direction
 * @param direction direction
 * @param travel_time dictionnary with travel time by direction
 */
function updateTravelTimeSeries(direction: string, travel_time: any) {
  let series = [];
  let parameters = travel_time[direction];

  // filter labels where both values are NaN
  let theo = [];
  let real = [];
  let labels = [];
  let current_theo;
  let current_real;
  for (let i = 0; i < LABELS.length; i++) {
    current_theo = parameters["théorique"][i];
    current_real = parameters["réalisé"][i];
    if (current_theo != "" || current_real != "") {
      theo.push(current_theo);
      real.push(current_real);
      labels.push(LABELS[i]);
    }
  }

  // add theoretical values
  series.push({
    name: "Théorique",
    type: "line",
    data: theo
  });
  // add real values
  series.push({
    name: "Réalisé",
    type: "line",
    data: real
  });
  return { series, labels };
}

function filterEmptyXAndAddLabels(travel_time_data): any {
  let keys = Object.keys(travel_time_data).filter(val => val != "départ");
  if (keys.includes("labels")) {
    throw new Error("Reserved key 'labels' in travel time data");
  }

  // create a result dict with labels and an array for each key
  let values = {
    labels: []
  };
  for (let i = 0; i < keys.length; i++) {
    values[keys[i]] = [];
  }

  for (let j = 0; j < LABELS_MIDDLE.length; j++) {
    let key;
    let temp = {};
    let all_values_empty = true;

    // get travel time values for the current index
    for (let k = 0; k < keys.length; k++) {
      key = keys[k];
      temp[key] = travel_time_data[key][j];
      if (temp[key] != "") {
        all_values_empty = false;
      }
    }

    // add values if one is non empty
    if (!all_values_empty) {
      for (let h = 0; h < keys.length; h++) {
        key = keys[h];
        values[key].push(temp[key]);
      }

      // add corresponding label, converted to hours
      values.labels.push(convert_hours(LABELS_MIDDLE[j]));
    }
  }

  return values;
}

/**
 * Create apex chart series to plot section travel time for selected direction and section
 * @param direction direction
 * @param section section
 * @param section_travel_time dictionnary with section travel time by direction
 */
function updateSectionTravelTimeSeries(direction: string, section: string, section_travel_time: any) {
  let series = [];
  let parameters = section_travel_time[direction];
  // add real values
  series.push({
    name: "Réalisé",
    type: "line",
    data: parameters["réalisé"][section]
  });
  // add adjusted values
  series.push({
    name: "Ajusté",
    type: "line",
    data: parameters["ajusté"][section]
  });
  // add theoretical values
  series.push({
    name: "Théorique",
    type: "line",
    data: parameters["théorique"][section]
  });

  return series;
}

// table content functions

function numberWithSpaces(x) {
  /**
   * Add one space between groups of 3 digits.
   */
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

function getDefaultTravelTimeFilter(day_type: string) {
  let new_filters = JSON.parse(JSON.stringify(CAPUCINE_FILTER_TP_PARAMS));
  if (day_type !== "LAVHV") {
    new_filters.number_std.activate = false;
  }
  return new_filters;
}

function getDefaultTravelTimeBuffers() {
  return JSON.parse(JSON.stringify(CAPUCINE_BUFFER_PARAMS));
}

function formatTableContent(value, type) {
  if (typeof type == "function") {
    return type(value);
  }
  if (value === "" || value == undefined) {
    return "";
  } else
    switch (type) {
      case "%":
        if (value == 100) {
          return value + " %";
        } else {
          return value.toFixed(1) + " %";
        }
      case "100%":
        let percentage = value * 100;
        if (percentage == 100) {
          return percentage + " %";
        } else {
          return percentage.toFixed(1) + " %";
        }
      case "€":
        return numberWithSpaces(value.toFixed(1)) + " €";
      case "-":
        if (value > 0) {
          return "+" + numberWithSpaces(value.toFixed(1));
        } else if (value == 0) {
          return value.toFixed(1);
        } else {
          return numberWithSpaces(value.toFixed(1));
        }
      case "boolean":
        let boolean_value = !!value;
        return i18n.t(`boolean.${boolean_value}`);
      default:
        return numberWithSpaces(value.toFixed(1));
    }
}

function routeChipColor(item, value_prop) {
  if (store.getters["capucine_analysis/routeColors"][item[value_prop]]) {
    return store.getters["capucine_analysis/routeColors"][item[value_prop]]["color"];
  }
}

function routeChipTextColor(item, value_prop) {
  if (store.getters["capucine_analysis/routeColors"][item[value_prop]]) {
    return store.getters["capucine_analysis/routeColors"][item[value_prop]]["text_color"];
  }
}

function robustnessWarningBadge(item, value_prop) {
  if (item.count <= 10 && item[value_prop] != "") {
    return item.count;
  } else {
    return;
  }
}

function indicatorChipColor(item, value_prop, indicator) {
  let n = COLOR_LEGEND[indicator].values.length;
  let i = 0;
  while (item[value_prop] >= COLOR_LEGEND[indicator].values[i + 1] && i < n) {
    i += 1;
  }
  return COLOR_LEGEND[indicator].colors[i];
}

function robustnessChipColor(item, value_prop) {
  return indicatorChipColor(item, value_prop, "robustness");
}

function conformityChipColor(item, value_prop) {
  return indicatorChipColor(item, value_prop, "conformity");
}

function commercialSpeedChipColor(item, value_prop) {
  return indicatorChipColor(item, value_prop, "commercial_speed");
}

function vehicleLoadChipColor(item, value_prop) {
  return indicatorChipColor(item, value_prop, "vehicle_load");
}

function getColorLegendText(indicator) {
  if (indicator != undefined) {
    let values = COLOR_LEGEND[indicator].values;
    let unit = COLOR_LEGEND[indicator].unit;
    let text = [];
    text.push("< " + values[1] + unit);
    for (let i = 1; i < values.length - 1; i++) {
      text.push(values[i].toString().padStart(2) + unit + " - " + values[i + 1].toString().padStart(2) + unit);
    }
    text.push("≥ " + values[values.length - 1] + unit);
    return text;
  } else {
    return [];
  }
}

function get_route_item(route_items: Array<any>, route_id: string, data_batch_id: number) {
  if (route_id == ALL_ROUTES) {
    return ALL_ROUTES_ITEM;
  }

  let select = route_items.filter(item => item.id == route_id && item.data_batch == data_batch_id);
  if (select.length == 1) {
    return select[0];
  } else if (select.length == 0) {
    return {
      id: "",
      name: "",
      sections: { order: [], names: {} },
      capacity: 0,
      color: "",
      text_color: "",
      start_date: "",
      end_date: "",
      id_line: undefined,
      type: null
    };
  } else {
    throw new Error("Unsuported case of several routes with same id");
  }
}

/**
 * Get the list of data batch items associated to the given route id,
 * fetched in the given routes records.
 * @param routes records of existing route_data
 * @param route_id id of the examined route
 * @param lot_data_batches data batch ids listed in the network
 * @returns
 */
function get_route_data_batch_items(routes: Array<any>, route_id: string, network_data_batches: Array<number>) {
  let data_batch_ids;
  if (route_id == ALL_ROUTES) {
    // get all possible data batches
    data_batch_ids = network_data_batches;
  } else {
    // get route data batches
    data_batch_ids = routes.filter(item => item.id == route_id).map(item => item.data_batch);
    // keep data data batches listed in the network
    data_batch_ids = data_batch_ids.filter(id => network_data_batches.includes(id));
  }

  return get_data_batch_items(data_batch_ids);
}

/**
 * Convert list of data batch ids into a list of unique items,
 * sorted by decreasing start date.
 * @param data_batch_ids
 * @returns
 */
function get_data_batch_items(data_batch_ids: Array<number>) {
  // remove duplicates and map to data batch items
  let data_batches = data_batch_ids
    .filter((element, i) => i === data_batch_ids.indexOf(element))
    .map(id => store.state.capucine_analysis.data_batches_table[id]);

  // sort by decreasing date
  data_batches = data_batches.sort((a, b) => {
    let date_a = a.date_range[0];
    let date_b = b.date_range[0];
    if (date_a > date_b) {
      return -1;
    } else if (date_a < date_b) {
      return 1;
    } else {
      return 0;
    }
  });

  return data_batches;
}

function routeOneWayName(route) {
  if (route.isAllRoutes) {
    return "";
  }
  let sections = route.sections;
  return sections.names[sections.order[0]] + " → " + sections.names[sections.order[sections.order.length - 1]];
}

function routeReturnName(route) {
  if (route.isAllRoutes) {
    return "";
  }
  let sections = route.sections;
  return sections.names[sections.order[sections.order.length - 1]] + " → " + sections.names[sections.order[0]];
}

function routeTitleSuffixWithDates(route) {
  let text = "";
  if (!route.isAllRoutes && route.start_date != undefined && route.end_date != undefined) {
    text = " (" + route.start_date + " au " + route.end_date + ")";
  }
  return text;
}

function routeSurveyDate(route) {
  let text = "";
  if (!route.isAllRoutes && route.survey_date != undefined && route.survey_date != "") {
    text = " (enquête OD du " + route.survey_date + ")";
  }
  return text;
}

// travel time plots management

function createTravelTimePlots(plots_data, plots_visibility) {
  let plot;
  for (let plot_key of Object.keys(plots_descriptors)) {
    switch (plot_key) {
      case "mesures":
        plot = createPlot("mesures", plots_data["mesures_x"], plots_data["mesures_y"], plots_visibility[plot_key]);
        break;
      case "mesures_non":
        plot = createPlot(
          "mesures_non",
          plots_data["mesures_non_x"],
          plots_data["mesures_non_y"],
          plots_visibility[plot_key]
        );
        break;
      default:
        plot = createPlot(plot_key, plots_data.labels, plots_data[plot_key], plots_visibility[plot_key]);
    }
  }
}

function createPlot(plot_key, x, y, visible, update_store = true) {
  if (!y) {
    return;
  }

  let plot_object = {
    ...plots_descriptors[plot_key],
    x,
    y,
    name: getPlotName(plot_key),
    visible
  };

  if (update_store) {
    store.commit("capucine_analysis/SET_TRAVEL_TIME_PLOT", { [plot_key]: plot_object });
  }

  return plot_object;
}

const scatter_plot_base = {
  type: "scattergl",
  mode: "markers",
  marker: { color: "#000000", size: 3 },
  hoverinfo: "none",
  opacity: 0.7
};

const lines_plot_base = {
  type: "scattergl",
  mode: "lines",
  connectgaps: true,
  line: { color: "#000000", width: 3 },
  hovertemplate: "%{y:.1f}",
  opacity: 1
};

const plots_descriptors = {
  mesures: {
    ...scatter_plot_base,
    marker: { color: "#85c287", size: 3 }
  },
  mesures_non: {
    ...scatter_plot_base,
    marker: { color: "#cccccc", size: 3 }
  },
  theo_contractuel: {
    ...lines_plot_base,
    line: {
      color: "#000000",
      width: 3,
      dash: "dashdot"
    }
  },
  theo: {
    ...lines_plot_base,
    line: {
      color: "#000000",
      width: 3
    }
  },
  realise: {
    ...lines_plot_base,
    line: {
      color: "#ff0000",
      width: 3
    }
  },
  realise_median: {
    ...lines_plot_base,
    line: {
      color: "#ff0000",
      width: 2,
      dash: "dot"
    },
    opacity: 0.7
  },
  buffered_std: {
    ...lines_plot_base,
    line: {
      color: "#be49ff",
      width: 2,
      dash: "dashdot"
    },
    opacity: 0.7
  },
  buffered_pX: {
    ...lines_plot_base,
    line: {
      color: "#be49ff",
      width: 2,
      dash: "dot"
    },
    opacity: 0.7
  },
  buffered_realise: {
    ...lines_plot_base,
    line: {
      color: "#be49ff",
      width: 2,
      dash: "dash"
    },
    opacity: 0.7
  },
  buffered_theo: {
    ...lines_plot_base,
    line: {
      color: "#03224c",
      width: 2,
      dash: "dash"
    },
    opacity: 0.7
  }
};

const plot_names = {
  mesures: "Mesures",
  mesures_non: "Mesures (non retenues)",
  theo_contractuel: "Théorique contractuel",
  theo: "Théorique",
  realise: "Réalisé",
  realise_median: "Réalisé (médiane)",
  buffered_std: "Battement (écart type)"
};

function getPlotName(plot_key, travel_time_buffers?) {
  let buffers = travel_time_buffers || store.state.capucine_analysis.travel_time.buffers;
  if (plot_key == "buffered_pX") {
    return `Battement (${buffers.pX}% des mesures)`;
  } else if (plot_key == "buffered_realise") {
    return `Battement (réalisé +${buffers.percentage}%)`;
  } else if (plot_key == "buffered_theo") {
    return `Battement (théorique +${buffers.percentage_theoretical}%)`;
  } else {
    return plot_names[plot_key];
  }
}

function buffered_travel_time(x, buffer) {
  return x === "" ? x : x * (1 + buffer / 100);
}

function convert_hours(x: string) {
  // convert time to hours in float
  // if day is 2 then it is the next day
  let day = parseInt(x.substring(8, 10));
  let time = parseInt(x.substring(11, 13)) + parseInt(x.substring(14, 16)) / 60;

  if (day == 2) {
    time = time + 24;
  }
  return time;
}

function transformTableKeys(table_data: Array<any>, buffers) {
  let new_table_data = [];
  for (let item of table_data) {
    let { count, intervalle, période, temps_réalisé, temps_réalisé_median, temps_théorique, écart, buffer_std } = item;
    let new_item = {
      count,
      intervalle,
      période,
      écart,
      theo: temps_théorique,
      realise: temps_réalisé,
      realise_median: temps_réalisé_median,
      buffered_std: buffer_std
    };

    new_item["buffered_pX"] = item["temps_réalisé_p" + buffers.pX];
    if ("contractuel" in item) {
      new_item["theo_contractuel"] = item["contractuel"];
      new_item["écart_contractuel"] = item["écart_contractuel"];
    }

    new_table_data.push(new_item);
  }
  return new_table_data;
}

function transformPlotKeys(plots_data, buffers) {
  let { count, départ, réalisé, réalisé_median, buffer_std, théorique } = plots_data;
  let new_data = {
    count,
    départ,
    realise: réalisé,
    realise_median: réalisé_median,
    buffered_std: buffer_std,
    theo: théorique
  };
  new_data["buffered_pX"] = plots_data["réalisé_p" + buffers.pX];
  if ("contractuel" in plots_data) {
    new_data["theo_contractuel"] = plots_data["contractuel"];
  }
  return new_data;
}

function removeFewCountsTableValues(table_data: Array<any>, mean_ratio = 2, min_value = 20) {
  if ("count" in table_data[0]) {
    const counts = table_data.map(x => x["count"]).filter(x => x !== "");
    const mean = counts.reduce((a, b) => a + b, 0) / counts.length;

    for (let i = 0; i < table_data.length; i++) {
      if ("count" in table_data[i]) {
        if (table_data[i]["count"] < mean / mean_ratio && table_data[i]["count"] < min_value) {
          for (const key in table_data[i]) {
            if (!["count", "période"].includes(key)) {
              table_data[i][key] = "";
            }
          }
        }
      }
    }
  }
}

/**
 * Removes values computed from too few mesures.
 * Remove if (count < mean / mean_ratio and count < min_value)
 * @param plots_data
 * @param mean_ratio
 * @param min_value
 */
function removeFewCountsGraphicValues(plots_data, mean_ratio = 2, min_value = 20) {
  if ("count" in plots_data) {
    const counts = plots_data["count"];
    const mean = counts.reduce((a, b) => a + b, 0) / counts.length;
    for (let i = 0; i < plots_data["count"].length; i++) {
      if (plots_data["count"][i] < mean / mean_ratio && plots_data["count"][i] < min_value) {
        for (const key in plots_data) {
          if (!["count", "départ"].includes(key)) {
            plots_data[key][i] = "";
          }
        }
      }
    }
  }
}

export {
  ANALYSIS_KPIS,
  DAY_TYPES,
  ALL_DAYS,
  ALL_ROUTES,
  ALL_ROUTES_ITEM,
  MAP_PERIODS,
  DISABLED_RESULT_PAGES,
  SUBTITLES,
  COLOR_LEGEND,
  GLOSSARY,
  LABELS,
  CHART_OPTIONS_BASE,
  CHART_OPTIONS_TRAVEL_TIME,
  CHART_OPTIONS_CUMULATIVE_TRAVEL_TIME,
  CHART_OPTIONS_SECTION_TRAVEL_TIME,
  formatTableContent,
  computeSectionsPairs,
  computeSectionPairsItems,
  updateTravelTimeSeries,
  updateSectionTravelTimeSeries,
  routeChipColor,
  routeChipTextColor,
  robustnessChipColor,
  robustnessWarningBadge,
  conformityChipColor,
  commercialSpeedChipColor,
  vehicleLoadChipColor,
  getColorLegendText,
  resultTabDisabled,
  sortLotTable,
  filterEmptyXAndAddLabels,
  get_route_item,
  get_route_data_batch_items,
  get_data_batch_items,
  routeOneWayName,
  routeReturnName,
  routeTitleSuffixWithDates,
  routeSurveyDate,
  createTravelTimePlots,
  buffered_travel_time,
  getPlotName,
  transformTableKeys,
  transformPlotKeys,
  removeFewCountsTableValues,
  removeFewCountsGraphicValues,
  getDefaultTravelTimeFilter,
  getDefaultTravelTimeBuffers
};
