/**
 * Create a new PDF export from mapbox Map.
 * See https://github.com/parallax/jsPDF and http://raw.githack.com/MrRio/jsPDF/master/docs/index.html
 * Inspired from https://github.com/watergis/mapbox-gl-export/blob/master/lib/map-generator.ts#L266
 */

import { jsPDF, ShadingPattern } from "jspdf";
import { hexToRGB, sortFromList } from "@/functions-tools";
import store from "./store";
import i18n from "./plugins/lang";
import { KiteFlowMapLayer, KiteScatterPlot, OdArcLayer, KITE_LAYERS } from "./kite_layers";
import { personalDataSourceName } from "@/models";
import { Map as MapboxMap } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { PropsMapping } from "./props_mapping";
import { getInstance } from "@/api/index";
import { getDocument } from "pdfjs-dist/webpack.mjs";

export const EXPORT_EXTENSIONS = {
  pdf_report: ".pdf",
  png_report: ".png",
  png_image: ".png"
};

export class MapExport {
  user_layout: {
    // layout positions in mm
    margin_h: number;
    margin_w: number;
    leg_fig_margins: number;
    leg_fig_width: number;
    leg_text_line_margin: number;
    font: string;
  };

  map: any;
  render_map: any;
  dpi: number;

  overlay: MapCapture;

  doc: jsPDF;

  author: string;

  title: string;

  legend_layers: Array<any>;

  sources: Array<string>;

  total_width: number;
  total_height: number;

  map_container: any;
  map_start_x: number;
  map_end_x: number;
  map_start_y: number;
  map_end_y: number;
  map_width: number;
  map_height: number;

  margin_w: number;
  margin_h: number;

  legend_start_y: number;
  current_leg_text_y: number;
  leg_text_line_margin: number;

  leg_fig_margins: number;
  leg_fig_start_x: number;
  leg_fig_width: number;
  leg_fig_end_x: number;

  leg_text_start_x: number;
  leg_text_width: number;
  leg_text_end_x: number;

  scale_end_x: number;
  scale_end_y: number;
  scale_height: number;

  mapping_leg_line_margin: number;
  mapping_leg_indent: number;

  font: string;
  title_size: number;
  leg_title_size: number;
  text_size: number;
  scale_size: number;

  constructor(
    map,
    overlay: MapCapture,
    author,
    legend_layers,
    sources,
    title?,
    layout = {
      // layout positions in mm
      margin_h: 10,
      margin_w: 5,
      leg_fig_margins: 2,
      leg_fig_width: 10,
      leg_text_line_margin: 5,
      font: "Helvetica"
    }
  ) {
    // save provided layout
    this.user_layout = layout;

    // capture overlay
    this.overlay = overlay;

    this.dpi = 300;

    // mapbox Map
    this.map = map;

    this.render_map = null;

    // author name
    this.author = author;

    // export title
    this.title = title;

    // evaluate list of eligible layers
    this.legend_layers = sortFromList(legend_layers, store.state.layers.zList).reverse();

    // layers' sources
    this.sources = sources;

    // pdf export layout

    // for now only A4 is supported
    this.total_width = 297; // millimeters
    this.total_height = 210; // millimeters

    // global margins
    this.margin_w = layout.margin_w;
    this.margin_h = layout.margin_h;

    // map is A4, so we only decide width
    // get map canvas and get its dimensions
    this.map_width = overlay.capture_width;
    this.map_height = overlay.capture_height;
    this.map_start_x = this.margin_w;
    this.map_end_x = this.map_start_x + this.map_width;
    this.map_start_y = this.margin_h + 5;
    this.map_end_y = this.map_start_y + this.map_height;

    // legend
    this.legend_start_y = this.map_start_y + 5;
    this.current_leg_text_y = this.legend_start_y + 10;
    this.leg_text_line_margin = layout.leg_text_line_margin;
    this.leg_text_line_margin;

    // legend figures
    this.leg_fig_margins = layout.leg_fig_margins;
    this.leg_fig_start_x = this.map_end_x + this.leg_fig_margins;
    this.leg_fig_width = layout.leg_fig_width;
    this.leg_fig_end_x = this.leg_fig_start_x + this.leg_fig_width;

    // legend texts
    this.leg_text_start_x = this.leg_fig_end_x + this.leg_fig_margins;
    this.leg_text_end_x = this.total_width - this.margin_w;
    this.leg_text_width = this.leg_text_end_x - this.leg_text_start_x;

    // mapping legend texts
    this.mapping_leg_line_margin = 2;
    this.mapping_leg_indent = 3;

    // scale position
    this.scale_end_x = this.map_end_x - 2;
    this.scale_end_y = this.map_end_y - 2;
    this.scale_height = 5;

    // create jsPDF document
    this.doc = new jsPDF({
      orientation: "l",
      unit: "mm",
      format: "a4",
      compress: true
    });

    // font
    this.font = layout.font;
    this.doc.setFont(this.font);
    this.title_size = 24;
    this.leg_title_size = 17;
    this.text_size = 11;
    this.scale_size = 7;
  }

  /**
   * Export the captured map to the given format and filename.
   * @param filename
   * @param format
   */
  async exportTo(filename, format) {
    let full_filename = filename + EXPORT_EXTENSIONS[format];
    alert({
      message: i18n.t("map_export.success_message"),
      type: "success"
    });
    switch (format) {
      case "pdf_report":
        await this.toPdfReport(full_filename);
        break;
      case "png_report":
        await this.toPngReport(full_filename);
        break;
      case "png_image":
        await this.toPngImage(full_filename);
        break;
      default:
        throw new Error("Unknown map export format");
    }
  }

  mm2pixels(value_px) {
    return Math.round((value_px * 96) / 25.4);
  }

  /**
   * Export the map as a PNG image.
   * @param filename
   */
  async toPngImage(filename: string) {
    const map_canvas = await this.createRenderMap();
    var link = document.createElement("a");
    link.download = filename;
    link.href = map_canvas.toDataURL();
    link.click();
    this.removeRenderMap();
  }

  /**
   * Export the report as a PDF document
   */
  async toPdfReport(filename: string) {
    // generate report
    await this.makeReport();

    // write pdf file
    this.doc.save(filename);
  }

  async toPngReport(filename: string) {
    // generate report
    await this.makeReport();

    // convert pdf to buffer
    let mypdf = await this.doc.output("arraybuffer");

    // pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.5.136/build/pdf.worker.min.mjs"; // seems unused
    getDocument(mypdf).promise.then(pdf => {
      pdf.getPage(1).then(page => {
        var scale = 300 / 96;
        var viewport = page.getViewport({ scale });

        // Prepare canvas using PDF page dimensions
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "pdf-canvas");
        let context = canvas.getContext("2d");
        canvas.width = Math.floor(viewport.width);
        canvas.height = Math.floor(viewport.height);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";

        // Render PDF page into canvas context
        // And save to image file
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);
        renderTask.promise.then(function () {
          var link = document.createElement("a");
          link.download = filename;
          link.href = canvas.toDataURL("image/png");
          link.click();
        });
      });
    });
  }

  /**
   * Create a report containing the map with additional content description (legend, sources, etc)
   */
  async makeReport() {
    this.addTitle();
    await this.addMapImage();
    this.addScale();
    this.removeRenderMap();
    this.addLegend();
    this.addSources();
    this.addMetadata();
  }

  /**
   * Add left aligned title at the top.
   */
  addTitle() {
    this.doc.setFontSize(this.title_size);
    this.doc.text(this.title, this.margin_w, this.margin_h, {
      align: "left"
    });
  }

  private toPixels(length: number, conversionFactor = 96) {
    conversionFactor /= 25.4;
    return `${conversionFactor * length}px`;
  }

  async createRenderMap(): Promise<any> {
    // change render pixel ratio
    const new_pixel_ratio = 300 / 96;
    const actualPixelRatio: number = window.devicePixelRatio;
    Object.defineProperty(window, "devicePixelRatio", {
      get() {
        return new_pixel_ratio;
      }
    });

    // get delimitation of of map capture
    const { startX, endX, startY, endY } = this.overlay.captureCoords();
    const width = endX - startX;
    const height = endY - startY;

    // create a hidden container for the map
    this.map_container = document.createElement("div");
    this.map_container.className = "hidden-map";
    document.body.appendChild(this.map_container);
    const container = document.createElement("div");
    container.style.width = `${width}px`;
    container.style.height = `${height}px`;
    this.map_container.appendChild(container);

    // replicate map style (deckgl layers are added later)
    const style = this.map.getStyle();
    if (style && style.sources) {
      const sources = style.sources;
      Object.keys(sources).forEach(name => {
        const src = sources[name];
        Object.keys(src).forEach(key => {
          // delete properties if value is undefined.
          // for instance, raster-dem might has undefined value in "url" and "bounds"
          // if (!src[key]) delete src[key];
        });
      });
    }

    // replicate the map
    const renderMap = new MapboxMap({
      container,
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      bearing: this.map.getBearing(),
      pitch: this.map.getPitch(),
      interactive: false,
      preserveDrawingBuffer: true,
      fadeDuration: 0,
      attributionControl: false,
      antialias: true,
      style,
      transformRequest: (this.map as any)._requestManager._transformRequestFn
    });
    this.render_map = renderMap;

    let promise = new Promise(resolve => {
      // manually add deckgl layers to the map
      let layers_ids = this.legend_layers.map(layer => layer.id);
      renderMap.once("idle", () => {
        let original_layer;
        let new_layer;
        let deckgl_added = false;
        for (let layer_id of layers_ids) {
          // list of relevant layers ("origin-scatterplot", "destination-scatterplot" don't work for some reason)
          if (["users-flowmap", "users-arclayer", "origin-scatterplot", "destination-scatterplot"].includes(layer_id)) {
            original_layer = store.getters["layers/getLayer"](layer_id);
            // TODO : instead of this, we should be able to call original_layer.addToMap(renderMap)
            // and it would do all we need (adding the layer to the map, with correct data, visibility and editAttributes)
            // But my try at implementing this was unsuccessful, so here's something that works
            new_layer = KITE_LAYERS.filter(layer_info => layer_info.id == layer_id)[0];
            new_layer = new new_layer.layer_class(new_layer);
            new_layer.setBeforeId(original_layer.beforeId);
            new_layer.addToMap(renderMap);
            new_layer.setData(original_layer.data);
            new_layer.updateVisibility(true);
            new_layer.updateEditAttributes(original_layer.editAttributes);
            deckgl_added = true;
          }
        }

        // "idle" event is not triggered again if no additional layer was added
        if (deckgl_added) {
          renderMap.once("idle", () => {
            resolve(renderMap.getCanvas());
            Object.defineProperty(window, "devicePixelRatio", {
              get() {
                return actualPixelRatio;
              }
            });
          });
        } else {
          resolve(renderMap.getCanvas());
          Object.defineProperty(window, "devicePixelRatio", {
            get() {
              return actualPixelRatio;
            }
          });
        }
      });
    });

    return promise;
  }

  removeRenderMap() {
    this.render_map.remove();
    this.map_container.parentNode?.removeChild(this.map_container);
  }

  /**
   * Add map capture on the left.
   */
  async addMapImage() {
    const map_canvas = await this.createRenderMap();

    this.doc.addImage(
      map_canvas.toDataURL("image/jpeg", 1.0),
      "JPEG",
      this.map_start_x,
      this.map_start_y,
      this.map_width,
      this.map_height,
      undefined,
      "NONE"
    );

    // add frame around map
    this.doc.setLineWidth(0.5);
    this.setColor("draw");
    this.doc.rect(this.map_start_x, this.map_start_y, this.map_width, this.map_height);
  }

  /**
   * Add legend title on the right of the map.
   */
  addLegendTitle() {
    this.doc.setFontSize(this.leg_title_size);
    this.doc.text(
      i18n.t("map_export.texts.legend_title").toString(),
      (this.map_end_x + this.total_width) / 2,
      this.legend_start_y,
      {
        align: "center"
      }
    );
  }

  /**
   * Add legend contents under the legend title.
   */
  addLegend() {
    // if list is not empty, add legend
    if (this.legend_layers.length == 0) {
      return;
    }
    // legend title
    this.addLegendTitle();

    // layers' legend
    this.doc.setFontSize(this.text_size);
    for (let layer of this.legend_layers) {
      if (layer.legend_export) {
        layer.addLegendToExport(this);
      }
    }

    // restore opacity to 1
    this.setOpacity();
  }

  /**
   * Add layers' sources under the map capture.
   */
  addSources() {
    if (this.sources.length == 0) {
      return;
    }
    this.doc.setFontSize(this.text_size);
    this.doc.text(
      i18n.t("map_export.texts.sources").toString() + ": " + this.sources.sort().join("; "),
      this.margin_w,
      this.map_end_y + 10,
      {
        maxWidth: this.map_width
      }
    );
  }

  /**
   * Add map scale to export
   * Read scale control object in order to get scale value and width
   */
  addScale() {
    // get scale value and width
    let element = document.getElementsByClassName("mapboxgl-ctrl mapboxgl-ctrl-scale");
    let scale_value = element[0].textContent;
    let scale_width_string = element[0].getAttribute("style");
    let scale_width = parseFloat(scale_width_string.substring(7, scale_width_string.length - 3)); // in px

    // compute scale positions in mm
    let line_length = (scale_width * 25.4) / 96; // convert in mm
    line_length = (line_length * this.map_width) / this.total_width; // apply map size reduction
    let scale_end_x = this.scale_end_x;
    let scale_start_x = scale_end_x - line_length;
    let scale_end_y = this.scale_end_y;
    let scale_start_y = scale_end_y - this.scale_height;

    // draw brackground rect
    this.setOpacity(0.8);
    this.setColor("fill", "#F9F9F9");
    this.doc.rect(scale_start_x, scale_start_y, line_length, this.scale_height, "F");

    // draw lines
    this.setOpacity(1);
    this.doc.setLineWidth(0.4);
    this.setColor("draw", "#000000");
    this.doc.path([
      { op: "m", c: [scale_start_x, scale_start_y] },
      { op: "l", c: [scale_start_x, scale_end_y] },
      { op: "l", c: [scale_end_x, scale_end_y] },
      { op: "l", c: [scale_end_x, scale_start_y] }
    ]);
    this.doc.stroke();

    // add text
    this.doc.setFontSize(this.scale_size);
    this.doc.text(scale_value, scale_start_x + 1, scale_end_y - 1.5);
  }

  /**
   * Add metadata to the PDF document.
   */
  addMetadata() {
    const { lng, lat } = this.map.getCenter();
    this.doc.setProperties({
      title: this.title,
      subject: `center: [${lng}, ${lat}], zoom: ${this.map.getZoom()}`,
      creator: "(c)Kite, Tellae",
      author: this.author
    });
  }

  // legend figures/text utils

  addCategorialMapping(title: string, props_mapping: PropsMapping, layer_opacity: number) {
    // get categorial mapping
    let legend_map = <Array<any>>props_mapping.getLegendMap();

    // set a thin line margin for mapping legend
    this.setLegTextLineMargin(this.mapping_leg_line_margin);

    // add layer name without figure
    let title_width = this.leg_text_end_x - this.leg_fig_start_x;
    this.addLegendText(title, this.leg_fig_start_x, title_width, undefined);

    // indent mappings legend
    this.indentLegFigStartX(this.mapping_leg_indent);

    if (props_mapping.legend_options.unit) {
      this.addLegendText(`(${props_mapping.legend_options.unit})`, this.leg_fig_start_x);
    }

    // add mappings legend
    for (let item of legend_map) {
      this.addLegendRect(item.value, layer_opacity);
      this.addLegendText(item.label);
    }

    // restore default indent and margin
    this.indentLegFigStartX();
    this.setLegTextLineMargin();

    // add linespace
    this.current_leg_text_y += this.leg_text_line_margin;
  }

  addLegendLine(color?: string, opacity?: number) {
    // set stroke opacity
    this.setOpacity(opacity);

    // set draw color
    this.setColor("draw", color);

    // draw line
    this.doc.setLineWidth(1);
    this.doc.line(this.leg_fig_start_x + 1, this.current_leg_text_y, this.leg_fig_end_x - 1, this.current_leg_text_y);
  }

  addLegendRect(color?: string, opacity?: number) {
    // set fill opacity
    this.setOpacity(opacity);

    // set fill color
    this.setColor("fill", color);

    // draw rectangle
    this.doc.rect(this.leg_fig_start_x + 1, this.current_leg_text_y - 1, this.leg_fig_width - 4, 3, "F");
  }

  addLegendCircle(color?: string, opacity?: number) {
    // set fill opacity
    this.setOpacity(opacity);

    // set fill color
    this.setColor("fill", color);

    // draw circle
    this.doc.circle((this.leg_fig_start_x + this.leg_fig_end_x) / 2, this.current_leg_text_y, 2, "F");
  }

  addLegendHalfArrow(color?: string, height?: number) {
    // set fill color
    this.setColor("fill", color);

    // draw half arrow  (rectange + half square)
    let rect_width = this.leg_fig_width - 5;
    let rect_height = height || 3;
    let rect_x_start = this.leg_fig_start_x + 1;
    let rect_x_end = rect_x_start + rect_width;
    let rect_y_start = this.current_leg_text_y;
    let rect_y_end = rect_y_start + rect_height;
    let triangle_width = this.leg_fig_width - rect_width;
    let triangle_height = 4.5;
    let triangle_y_start = rect_y_end - triangle_height;

    this.doc.path([
      { op: "m", c: [rect_x_start, rect_y_start] },
      { op: "l", c: [rect_x_end, rect_y_start] },
      { op: "l", c: [rect_x_end, triangle_y_start] },
      { op: "l", c: [rect_x_end + triangle_width, rect_y_end] },
      { op: "l", c: [rect_x_start, rect_y_end] },
      { op: "l", c: [rect_x_start, rect_y_start] }
    ]);

    this.doc.fill();
  }

  // this method does not work, dunno why (see https://stackoverflow.com/questions/71130340/jspdf-is-there-a-way-to-use-shading-partterns-to-fill-a-rect-wiht-a-color-gradi)
  // something with the matrix (changing values makes the pattern proc but at wrong place)
  addGradientRect(left_color: string, right_color: string) {
    this.doc.advancedAPI(pdf => {
      const key = `test`;
      pdf.addShadingPattern(
        key,
        new ShadingPattern(
          "axial",
          [this.leg_fig_start_x + 1, this.current_leg_text_y, this.leg_fig_width - 4, this.current_leg_text_y],
          [
            {
              offset: 0,
              color: [0, 0, 0] //RGB as an array
            },
            {
              offset: 1,
              color: [255, 255, 255]
            }
          ]
        )
      );
      pdf
        .rect(this.leg_fig_start_x + 1, this.current_leg_text_y - 1, this.leg_fig_width - 4, 3)
        .fill({ key, matrix: pdf.Matrix(0, 0, 0, 0, 0, 0) });
    });
  }

  // see supra
  addGradientCircle(inner_color: string, outer_color: string) {
    this.doc.advancedAPI(pdf => {
      const key = `test`;
      pdf.addShadingPattern(
        key,
        new ShadingPattern(
          "radial",
          [
            (this.leg_fig_start_x + this.leg_fig_end_x) / 2,
            this.current_leg_text_y,
            1,
            (this.leg_fig_start_x + this.leg_fig_end_x) / 2,
            this.current_leg_text_y,
            2
          ],
          [
            {
              offset: 0,
              color: [0, 0, 0] //RGB as an array
            },
            {
              offset: 1,
              color: [255, 255, 255]
            }
          ]
        )
      );
      pdf
        .circle((this.leg_fig_start_x + this.leg_fig_end_x) / 2, this.current_leg_text_y, 2)
        .fill({ key, matrix: pdf.Matrix(0, 0, 0, 0, 0, 0) });
    });
  }

  /**
   * Set mode color from HEX string.
   * @param mode 'draw' or 'fill'
   * @param color color in HEX format
   */
  setColor(mode, color?: string) {
    // define setter to be called
    let setter_function;
    if (mode == "draw") {
      setter_function = this.doc.setDrawColor;
    } else if (mode == "fill") {
      setter_function = this.doc.setFillColor;
    } else {
      throw new Error("Unsupported mode in setFillColor");
    }

    // evaluate color
    if (color) {
      let rgb_color = hexToRGB(color);
      setter_function(rgb_color[0], rgb_color[1], rgb_color[2]);
    } else {
      setter_function("black");
    }
  }

  setOpacity(opacity: number = 1) {
    this.doc.setGState(this.doc.GState({ opacity, "stroke-opacity": opacity }));
  }

  /**
   * Add a new legend text.
   * @param text text string
   * @param x text position. Defaults to this.leg_text_start_x
   * @param maxWidth text max width. Defaults to this.leg_text_width
   * @param line_margin line margin between texts. Defaults to this.leg_text_line_margin
   */
  addLegendText(text: string, x?: number, maxWidth?: number, line_margin?: number) {
    // restore opacity
    this.setOpacity(1);

    // add layer name
    this.doc.text(text, x || this.leg_text_start_x, this.current_leg_text_y + 2, {
      maxWidth: maxWidth || this.leg_text_width
    });

    // update current y
    let text_height = this.doc.getTextDimensions(text, { maxWidth: maxWidth || this.leg_text_width }).h;
    this.current_leg_text_y += Math.ceil(text_height) + (line_margin || this.leg_text_line_margin);
  }

  /**
   * Figure is moved but its width does not change,
   * so its the text width which changes.
   * @param indent indent added to the legend figure start x coordinate
   */
  indentLegFigStartX(indent: number = 0) {
    this.leg_fig_start_x = this.map_end_x + this.leg_fig_margins + indent;
    this.leg_fig_end_x = this.leg_fig_start_x + this.leg_fig_width;

    this.leg_text_start_x = this.leg_fig_end_x + this.leg_fig_margins;
    this.leg_text_end_x = this.total_width - this.margin_w;
    this.leg_text_width = this.leg_text_end_x - this.leg_text_start_x;
  }

  /**
   * Change margin between lines.
   * If no arg is provided, return to user provided margin.
   * @param line_margin
   */
  setLegTextLineMargin(line_margin?: number) {
    this.leg_text_line_margin = line_margin || this.user_layout.leg_text_line_margin;
  }
}

export class MapCapture {
  map: any;
  capture_width: number;
  capture_height: number;

  svgCanvas: any;
  svgPath: any;

  constructor(map, capture_width) {
    this.map = map;

    this.capture_width = capture_width;
    this.capture_height = (capture_width / 297) * 210; // for now only A4 is supported
    capture_width;
    this.mapResize = this.mapResize.bind(this);
    this.map.on("resize", this.mapResize);
    const clientWidth = this.map?.getCanvas().clientWidth;
    const clientHeight = this.map?.getCanvas().clientHeight;
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.style.position = "absolute";
    svg.style.top = "0px";
    svg.style.left = "0px";
    svg.setAttribute("width", `${clientWidth}px`);
    svg.setAttribute("height", `${clientHeight}px`);
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("style", "fill:#888888;stroke-width:0");
    path.setAttribute("fill-opacity", "0.5");
    svg.append(path);
    this.map?.getCanvasContainer().appendChild(svg);
    this.svgCanvas = svg;
    this.svgPath = path;
  }

  public getMapCanvas() {
    let mapCanvas = webglToCanvas2d(this.map.getCanvas().getContext("webgl"));

    const { startX, endX, startY, endY } = this.captureCoords();

    var canvas = document.createElement("canvas");

    const width = endX - startX;
    const height = endY - startY;

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");

    ctx.drawImage(mapCanvas, startX, startY, width, height, 0, 0, width, height);

    return canvas;
  }

  /**
   * Display capture overlay
   * @returns
   */
  public displayCaptureOverlay() {
    if (this.map === undefined || this.svgCanvas === undefined || this.svgPath === undefined) {
      return;
    }

    const { startX, endX, startY, endY, clientWidth, clientHeight } = this.captureCoords();

    this.svgCanvas.setAttribute("width", `${clientWidth}px`);
    this.svgCanvas.setAttribute("height", `${clientHeight}px`);
    this.svgPath.setAttribute(
      "d",
      `M 0 0 L ${clientWidth} 0 L ${clientWidth} ${clientHeight} L 0 ${clientHeight} M ${startX} ${startY} L ${startX} ${endY} L ${endX} ${endY} L ${endX} ${startY}`
    );
  }

  public removeCaptureOverlay() {
    if (this.svgCanvas !== undefined) {
      this.svgCanvas.remove();
      this.svgCanvas = undefined;
    }

    if (this.map !== undefined) {
      this.map = undefined;
    }
  }

  private mapResize() {
    this.displayCaptureOverlay();
  }

  /**
   * Evaluate the coordinates of the capture
   * @returns object containing overlay geometry { startX, endX, startY, endY, clientWidth, clientHeight }
   */
  public captureCoords() {
    const width = Math.round(toPixels(this.capture_width));
    const height = Math.round(toPixels(this.capture_height));
    const clientWidth = this.map?.getCanvas().clientWidth;
    const clientHeight = this.map?.getCanvas().clientHeight;
    //const startX = 0.35 * clientWidth;
    const startX = Math.round(clientWidth / 2 - width / 2);
    const endX = startX + width;
    const startY = Math.round(clientHeight / 2 - height / 2);
    const endY = startY + height;
    return { startX, endX, startY, endY, clientWidth, clientHeight };
  }
}

export class MapExportControl {
  map: MapboxMap;
  container: HTMLElement;
  exportContainer: HTMLElement;
  exportButton: HTMLButtonElement;

  public getDefaultPosition(): string {
    const defaultPosition = "top-right";
    return defaultPosition;
  }

  public onAdd(map: MapboxMap) {
    let whale = getInstance();
    this.map = map;
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this.container.addEventListener("contextmenu", e => e.preventDefault());
    this.container.addEventListener("click", e => {
      if (store.state.mapActionState.action != "map_export" && store.state.async.generic == 0) {
        whale.runIfHasAccess("LAYERS", () => {
          store.dispatch("startMapAction", {
            action: "map_export"
          });
        });
      }
    });

    this.container.innerHTML =
      '<div class="tools-box">' +
      "<button>" +
      `<span class="mapboxgl-ctrl-icon mapboxgl-export-control" aria-hidden="true" title="${i18n.t(
        "map_actions.map_export.menu_tooltip"
      )}"></span>` +
      "</button>" +
      "</div>";

    return this.container;
  }

  public onRemove() {
    if (!this.container || !this.container.parentNode || !this.map || !this.exportButton) {
      return;
    }
    this.container.parentNode.removeChild(this.container);

    this.map = undefined;
  }
}

/**
 * Convert mm/inch to pixel
 * @param length mm/inch length
 * @param conversionFactor DPI value. default is 96.
 */
function toPixels(length: number, conversionFactor = 96) {
  conversionFactor /= 25.4;
  return conversionFactor * length;
}

function getPixels(ctx) {
  return ctx.readPixels ? getPixels3d(ctx) : getPixels2d(ctx);
}

function getPixels3d(gl) {
  var canvas = gl.canvas;
  var height = canvas.height;
  var width = canvas.width;
  var buffer = new Uint8Array(width * height * 4);

  gl.readPixels(0, 0, canvas.width, canvas.height, gl.RGBA, gl.UNSIGNED_BYTE, buffer);

  return buffer;
}

function getPixels2d(ctx) {
  var canvas = ctx.canvas;
  var height = canvas.height;
  var width = canvas.width;

  return ctx.getImageData(0, 0, width, height).data;
}

/**
 * Convert a webgl canvas context in a 2D canvas
 * @param webgl
 * @returns
 */
function webglToCanvas2d(webgl) {
  var outCanvas = document.createElement("canvas");
  var outContext = outCanvas.getContext("2d");
  var outImageData;

  webgl =
    webgl instanceof WebGLRenderingContext
      ? webgl
      : webgl.getContext("webgl") || webgl.getContext("experimental-webgl");

  outCanvas.width = webgl.canvas.width;
  outCanvas.height = webgl.canvas.height;
  outImageData = outContext.getImageData(0, 0, outCanvas.width, outCanvas.height);

  outImageData.data.set(new Uint8ClampedArray(getPixels3d(webgl).buffer));
  outContext.putImageData(outImageData, 0, 0);
  outContext.translate(0, outCanvas.height);
  outContext.scale(1, -1);
  outContext.drawImage(outCanvas, 0, 0);
  outContext.setTransform(1, 0, 0, 1, 0, 0);

  return outCanvas;
}
