<!--
  Capucine data table base component
-->

<template>
  <v-card :max-width="cardWidth">
    <v-card-title
      v-if="title"
      :class="'headline justify-center ' + card_title_color"
      :style="'color: ' + title_text_color"
    >
      {{ title }}
    </v-card-title>
    <v-card-actions style="height: 30px" class="pa-4">
      <v-spacer />
      <color-legend :disabled="indicator === undefined" :indicator="indicator"></color-legend>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <download-excel :fetch="getData" :name="export_filename + '.xls'">
            <v-btn icon color="primary"><v-icon v-on="on"> save </v-icon> </v-btn>
          </download-excel>
        </template>
        <span>Exporter</span>
      </v-tooltip>
    </v-card-actions>
    <v-divider />
    <v-card-text>
      <v-data-table v-bind="table_properties" :headers="headers" :items="items" :loading="loading">
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="(header, index) in headers" :key="index" :align="header.align">
              <div v-if="itemChipColor(header, item)">
                <v-chip
                  :small="smallChips"
                  :color="itemChipColor(header, item)"
                  :text-color="itemChipTextColor(header, item)"
                >
                  {{ itemValueFormat(header, item) }}
                </v-chip>
              </div>
              <div v-else-if="itemBadge(header, item)">
                <v-badge color="error" inline :content="itemBadge(header, item)">
                  {{ itemValueFormat(header, item) }}
                </v-badge>
              </div>

              <div v-else>
                {{ itemValueFormat(header, item) }}
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} {{ $t("simulations.scenario_list.of") }}
          {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { formatTableDataWithHeaders } from "@/functions-tools";
import { formatTableContent } from "@/capucine_utils";
import colorLegend from "@/components/capucine/color_legend.vue";

export default Vue.component("capucine-table", {
  components: {
    colorLegend
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: Array,
    title: String,
    cardWidth: String,
    loading: Boolean,
    tableProps: {
      type: Object
    },
    smallChips: Boolean,
    indicator: String,
    title_color: String,
    exportFileName: {
      type: Function
    },
    networkType: String
  },
  data: function () {
    return {
      export_filename: ""
    };
  },
  computed: {
    table_properties() {
      return {
        "loading-text": "Chargement",
        "no-data-text": "Pas de données",
        "disable-sort": true,
        "disable-pagination": true,
        "hide-default-footer": true,
        ...this.tableProps
      };
    },
    card_title_color() {
      let color;
      // background title color
      if (this.title_color === undefined) {
        color = "secondary";
      } else if (["secondary", "primary"].includes(this.title_color)) {
        color = this.title_color;
      } else {
        color = "secondary";
      }
      return color;
    },
    title_text_color() {
      // text color
      if (this.card_title_color == "secondary") {
        return "black";
      } else {
        return "white";
      }
    }
  },
  methods: {
    // get the formated version of the table data for a file export
    getData() {
      if (this.exportFileName != undefined) {
        this.export_filename = this.exportFileName();
      } else {
        this.export_filename = "data";
      }
      return formatTableDataWithHeaders(this.headers, this.items, this.valueFormat);
    },
    valueFormat(item, value_key) {
      let value = item[value_key];
      if (value === undefined) {
        value = "";
      }
      return value.toString().replace(".", ",");
    },
    // get the color of the cell chip
    itemChipColor(header, item) {
      if (header.color != undefined && item[header.value] != "") {
        if (item[header.value] === undefined) {
          return;
        }
        return header.color(item, header.value);
      } else {
        return;
      }
    },
    // get the text color of the cell chip
    itemChipTextColor(header, item) {
      if (header["chip-text-color"] != undefined) {
        return header["chip-text-color"](item, header.value);
      } else {
        return;
      }
    },
    // get the content of the cell badge
    itemBadge(header, item) {
      if ("badge" in header) {
        return header.badge(item, header.value);
      } else {
        return;
      }
    },
    // get the formated value of the cell content
    itemValueFormat(header, item) {
      let value;
      if ("format" in header) {
        value = formatTableContent(item[header.value], header.format);
      } else {
        value = item[header.value];
      }
      return value;
    }
  }
});
</script>
