<!--
  Button for selecting Capucine pages
-->

<template>
  <v-btn :plain="page != target" :outlined="page == target" @click="page = target" v-bind="$attrs">
    {{ text }}
  </v-btn>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.component("page-button", {
  props: {
    target: String,
    text: String
  },
  data: () => ({}),
  computed: {
    page: {
      get() {
        return this.$store.state.capucine_analysis.page;
      },
      set(value) {
        this.setPage(value);
        this.$whale.updateCapucinePreferences({ analysis: { page: value } });
      }
    }
  },

  methods: {
    ...mapActions("capucine_analysis", ["setPage"])
  }
});
</script>
