var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    background: _vm.$vuetify.theme.themes.light.primary,
    width: '100%',
    height: '90vh',
    'text-align': 'center',
    color: 'white'
  })},[_c('v-card',{staticClass:"overflow-auto",style:({ background: _vm.$vuetify.theme.themes.light.primary, color: 'white' }),attrs:{"elevation":"0","height":"80vh"}},[_c('div',{staticClass:"flex-column header"},[_c('v-img',{attrs:{"src":"/static/logo-kite.png","max-width":"100","alt":"Tellae","max-height":"100"}}),_c('version-footer'),(_vm.$whale.isAuthenticated)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px"}},[_vm._v(_vm._s(_vm.$t("welcome.welcome"))+" "+_vm._s(_vm.$whale.user.firstName))])]):_vm._e()],1),_c('div',{staticStyle:{"text-align":"left","margin-left":"20px","margin-right":"20px"}},[_vm._v(" "+_vm._s(_vm.$t("welcome.use_intro"))+" "),_c('ul',[_c('br'),_c('li',[_c('b',[_vm._v(_vm._s(_vm.$t("map_layers.name"))+" : ")]),_vm._v(" "+_vm._s(_vm.$t("map_layers.desc"))+" ")]),_c('br'),_c('li',[_c('b',[_vm._v(_vm._s(_vm.$t("flows.name"))+" : ")]),_vm._v(" "+_vm._s(_vm.$t("flows.desc"))+" ")]),_c('br'),_c('li',[_c('b',[_vm._v(_vm._s(_vm.$t("network.name"))+" : ")]),_vm._v(" "+_vm._s(_vm.$t("network.desc"))+" ")]),_c('br'),_c('li',[_c('b',[_vm._v(_vm._s(_vm.$t("simulations.name"))+" : ")]),_vm._v(" "+_vm._s(_vm.$t("simulations.desc"))+" ")])])]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"white","rounded":"","large":"","left":""},on:{"click":function($event){return _vm.open_doc()}}},on),[_vm._v(" "+_vm._s(_vm.$t("welcome.help.button"))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-open-in-new")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("welcome.help.tooltip")))])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }