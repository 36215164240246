<template>
  <kite-add-data
    :title="title"
    :read-input-data="readInputData"
    :validate-data="validateFlowView"
    :new-data-name="newName"
    :evaluate-data-info="evaluateDataInfo"
    :new-config="newConfig"
    :config-validator="isConfigValid"
    :addData="addDataToKite"
    :close="closeDialog"
  >
    <template v-slot:import="scope">
      <slot name="flowImport" v-bind="scope" />
    </template>
    <template v-slot:stats="{ dataInfo }">
      <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.stats") }}</h3>
      <p v-for="(value, key) in dataInfo" :key="key">{{ $t("statistics.flows." + key) + " : " + (value || "") }}</p>
    </template>
    <template v-slot:config="{ addConfig }">
      <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.config") }}</h3>
      <v-select
        :value="addConfig.type"
        prepend-icon="multiple_stop"
        :label="$t('flows.manager.add.config.type')"
        :items="addConfig.available_types"
        :no-data-text="$t('add_dialog.basics.no_data')"
        :disabled="true"
      >
      </v-select>
    </template>
  </kite-add-data>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { starlingStats, flowmapStats } from "@/flows";
import { validateFlowView } from "@/validation";
import { FlowsView } from "@/models";

import KiteAddData from "@/components/kite/base/kite_add_data.vue";

export default Vue.component("flow-from-file", {
  components: {
    KiteAddData
  },
  props: ["title", "readInputData"],

  data: function () {
    return {};
  },

  computed: {},

  methods: {
    validateFlowView,
    newName() {
      return "Flux";
    },
    evaluateDataInfo(data) {
      if (data) {
        let stats;
        if (data.type == "STARLING") {
          stats = starlingStats(data.data);
        } else if (data.type == "FLOWMAP") {
          stats = flowmapStats(data.data);
        } else {
          throw new Error("Unknown flow type");
        }
        return stats;
      } else {
        return {};
      }
    },

    newConfig(data) {
      if (data) {
        return {
          available_types: [{ value: data.type, text: this.$t("flows.type." + data.type) }],
          type: data.type
        };
      } else {
        return {
          available_types: [],
          type: null
        };
      }
    },

    isConfigValid(config) {
      return !!config.type;
    },

    addDataToKite(data, name, config) {
      data.name = name;
      let new_flows_view = FlowsView.newFlowsView(data.data, data.type, name, data.saved);
      new_flows_view.addToKite();
    },
    closeDialog() {
      this.$store.commit("flows/UPDATE_DIALOG", { dialog: "add", value: false });
    }
  }
});
</script>
