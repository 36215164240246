<!--
  Dialog for managing scenarios
-->

<template>
  <v-dialog v-model="dialog" width="900px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="primary" :loading="simulation_loading">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon dark v-bind="attrs" v-on="on">mdi-clipboard-text-search-outline </v-icon>
          </template>
          <span>Résumé des simulations</span>
        </v-tooltip>
      </v-btn>
    </template>
    <v-card>
      <kite-card-title
        :title="'Résumé des simulations du scénario : ' + scenario_name"
        tooltip="Tableau récapitulatif des simulations lancées pour chaque ligne et chaque type de jour"
      ></kite-card-title>
      <v-card-text> </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="summary_headers"
          :items="scenario_summary"
          :loading="simulation_loading"
          loading-text="Chargement"
          class="elevation-3"
          no-data-text="Pas de données disponibles"
          sort-by="route"
          :must-sort="true"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="(column, index) in summary_headers" :key="column.id" :align="column.align">
                <div v-if="index > 0 && item[column.value] != ''" class="justify-center">
                  <v-chip
                    v-if="item[column.value] != 'day_type_not_available'"
                    small
                    :color="getChipColor(item[column.value])"
                  >
                    {{ format(item[column.value]) }}
                  </v-chip>
                </div>
                <div v-else>
                  <div v-if="column.value == 'route'">
                    <v-chip
                      :color="routeChipColor(item, column.value)"
                      :text-color="routeChipTextColor(item, column.value)"
                    >
                      {{ itemValueFormat(column, item) }}
                    </v-chip>
                  </div>
                  <div v-else>{{ item[column.value] }}</div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { DAY_TYPES, routeChipColor, routeChipTextColor, formatTableContent } from "@/capucine_utils";

export default Vue.component("scenario-summary", {
  data: function () {
    return {
      dialog: false,
      colors: {
        changed: "green",
        unchanged: "gray",
        error: "red"
      },
      texts: {
        changed: " ",
        unchanged: " ",
        error: "Erreur"
      }
    };
  },
  computed: {
    ...mapState("capucine_simulation", ["scenario_name", "scenario_summary"]),
    ...mapGetters("capucine_simulation", ["simulation_loading", "routeNames"]),
    summary_headers() {
      let headers = DAY_TYPES.map(day_type => {
        return { text: day_type, value: day_type, align: "center", sortable: false, format: val => val };
      });
      headers.unshift({
        text: "Ligne",
        value: "route",
        align: "center",
        sortable: true,
        format: val => this.routeNames[val]
      });
      return headers;
    }
  },
  methods: {
    routeChipColor,
    routeChipTextColor,
    getChipColor(item) {
      return this.colors[item];
    },
    format(item) {
      return this.texts[item];
    },
    itemValueFormat(header, item) {
      let value;
      if ("format" in header) {
        value = formatTableContent(item[header.value], header.format);
      } else {
        value = item[header.value];
      }
      return value;
    }
  }
});
</script>

<style scoped>
h2 {
  color: var(--v-primary-base);
}
</style>
