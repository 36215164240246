<!--
  Dialog for managing scenarios
-->

<template>
  <div class="mx-5">
    <v-row align="center" justify="center">
      <v-col>
        <v-dialog v-model="dialog" width="800px">
          <template v-slot:activator="{ on }">
            <v-btn class="mb-n3" v-on="on" color="primary" :loading="simulation_loading"> Gérer les scénarios </v-btn>
          </template>
          <v-card>
            <kite-card-title
              title="Scénarios de simulation"
              tooltip="Gestion des scénarios de simulation"
            ></kite-card-title>
            <v-card-text />
            <v-card-text style="position: relative">
              <v-row>
                <v-col cols="3">
                  <v-select
                    label="Filtrer par réseau / lot"
                    v-model="lot_filter"
                    :items="simulable_lots"
                    item-text="name"
                    item-value="id"
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item.name }}
                      <v-spacer></v-spacer>
                      <v-icon v-if="data.item.id == lot.id"> radio_button_checked </v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-spacer />
                </v-col>
                <v-col cols="5" class="align: right">
                  <v-btn color="primary" dark @click="creation_dialog = true">
                    <v-icon class="mr-2">add</v-icon>{{ $t("simulations.kpis.add_scenario") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-radio-group v-model="scenario">
                <v-data-table
                  :headers="headers"
                  :items="scenarios"
                  :loading="simulation_loading"
                  item-key="scenario_id"
                  loading-text="Chargement"
                  class="elevation-3"
                  disable-pagination
                  hide-default-footer
                  show-select
                  single-select
                >
                  <template v-slot:item.data-table-select="{ item }">
                    <v-simple-checkbox
                      :value="item.scenario_id == scenario"
                      color="black"
                      on-icon="radio_button_checked"
                      off-icon="radio_button_unchecked"
                      :ripple="false"
                      @click="changeScenario(item.scenario_id)"
                      :disabled="simulation_loading"
                    ></v-simple-checkbox> </template
                  >data_batch
                  <template v-slot:item.data_batch="{ item }">
                    {{ data_batches_table[item.data_batch].name }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip slot="append" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="tryCopy(item)"> content_copy </v-icon>
                      </template>
                      <span> {{ $t("flows.manager.table.tooltip.copy") }} </span>
                    </v-tooltip>
                    <v-tooltip slot="append" bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :disabled="item.scenario_id == scenario" @click="delete_scenario = item">
                          delete_outline
                        </v-icon>
                      </template>
                      <span> {{ $t("flows.manager.table.tooltip.delete") }} </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.date_creation="{ item }">
                    {{ new Date(item.date_creation).toLocaleDateString("fr") }}
                  </template>
                  <template v-slot:item.scenario_params.first_last_stop="{ item }">
                    <v-simple-checkbox
                      v-model="item.scenario_params.first_last_stop"
                      color="black"
                      on-icon="check_box"
                      off-icon="check_box_outline_blank"
                      :ripple="false"
                      :disabled="true"
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>
              </v-radio-group>
            </v-card-text>
          </v-card>
          <!-- New scenario dialog -->
          <v-dialog v-model="creation_dialog" width="400px">
            <v-card>
              <kite-card-title
                title="Nouveau scénario"
                tooltip="Création d'un nouveau scénario de simulation"
              ></kite-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid_creation">
                  <v-text-field
                    v-model="new_scenario.scenario_name"
                    required
                    :counter="max_name_length"
                    label="Nom du scénario"
                    :rules="[scenarioNameRule]"
                  >
                  </v-text-field>
                  <v-row>
                    <v-col cols="6">
                      <lot-selector
                        :outlined="false"
                        v-model="new_scenario.lot"
                        item-value="id"
                        :dense="false"
                        :disabled="copied_scenario !== null"
                        :filter-simulation-available="true"
                        @input="new_scenario.data_batch = null"
                      />
                    </v-col>
                    <v-col cols="6">
                      <data-batch-selector
                        v-model="new_scenario.data_batch"
                        :outlined="false"
                        :dense="false"
                        :items="lot_data_batches"
                        :disabled="!new_scenario.lot || copied_scenario !== null"
                        :filter-simulation-available="true"
                      />
                    </v-col>
                  </v-row>
                  <v-switch
                    v-model="new_scenario.first_last"
                    :disabled="copied_scenario !== null"
                    label="Premier / dernier arrêt uniquement"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeCreationDialog">
                  {{ $t("basic_dialogs.cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="addScenario"
                  :disabled="!valid_creation || !new_scenario.lot || !new_scenario.data_batch"
                >
                  {{ $t("basic_dialogs.validate") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- TODO : use kite alert with callback -->
          <v-snackbar :value="delete_scenario != null" timeout="-1" style="z-index: 20005">
            Voulez-vous vraiment supprimer le scénario <i>{{ delete_scenario_info.name }}</i> du réseau / lot&#8239;
            {{ delete_scenario_info.lot }} ?
            <template v-slot:action="{ attrs }">
              <v-btn @click="delete_scenario = null" text color="secondary"> Non </v-btn>
              <v-btn @click="archiveScenario" color="secondary"> Oui </v-btn>
            </template>
          </v-snackbar>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col md="10">
        <h2>{{ cut_scenario_name }}</h2>
      </v-col>
      <v-col md="2">
        <scenario-summary />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import KiteCardTitle from "@/components/base/kite_card_title.vue";
import LotSelector from "./lot_selector.vue";
import DataBatchSelector from "./data_batch_selector.vue";
import ScenarioSummary from "./scenario_summary.vue";
import { get_data_batch_items } from "@/capucine_utils";

export default Vue.component("simulation-scenario-interface", {
  components: {
    KiteCardTitle,
    LotSelector,
    DataBatchSelector,
    ScenarioSummary
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.delete_scenario = null;
      }
    }
  },
  data: function () {
    return {
      dialog: false,
      valid_creation: false,
      creation_dialog: false,
      new_scenario: {
        scenario_name: "",
        lot: null,
        first_last: false,
        data_batch: null
      },
      copied_scenario: null,
      delete_scenario: null,
      max_name_length: 100
    };
  },
  computed: {
    ...mapState("capucine_analysis", ["lots", "data_batches_table"]),
    ...mapState("capucine_simulation", ["lot", "scenarios", "scenario", "scenario_name"]),
    ...mapGetters("capucine_simulation", ["simulation_loading"]),
    headers() {
      return [
        { text: "Nom", value: "scenario_name" },
        {
          text: "Réseau / Lot",
          value: "lot",
          filter: value => {
            return value == null || value == this.lot_filter;
          }
        },
        { text: "Période", value: "data_batch" },
        { text: "Date de création", value: "date_creation", align: "center" },
        {
          text: "Premier à dernier arrêt",
          value: "scenario_params.first_last_stop",
          align: "center",
          width: "80",
          sortable: false
        },
        { text: "Actions", value: "actions", align: "center", sortable: false }
      ];
    },
    simulable_lots() {
      return this.lots.filter(item => {
        return item.simulation_available;
      });
    },
    lot_filter: {
      get() {
        return this.$store.state.capucine_simulation.lot_filter;
      },
      set(value) {
        this.$store.commit("capucine_simulation/SET_LOT_FILTER", value);
      }
    },
    scenario_names() {
      return this.scenarios.map(item => item.scenario_name);
    },
    cut_scenario_name() {
      if (this.scenario_name.length > 15) {
        return this.scenario_name.substring(0, 15) + "...";
      } else {
        return this.scenario_name;
      }
    },
    delete_scenario_info() {
      if (this.delete_scenario === null) {
        return { name: "", lot: "" };
      } else {
        return { name: this.delete_scenario.scenario_name, lot: this.delete_scenario.lot };
      }
    },
    lot_data_batches() {
      if (this.new_scenario.lot) {
        return this.get_data_batch_items(this.new_scenario.lot.data_batches);
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions("capucine_simulation", [
      "changeScenario",
      "getScenariosList",
      "createScenario",
      "copyScenario",
      "deleteScenario"
    ]),
    get_data_batch_items,
    scenarioNameRule(scenario_name) {
      if (scenario_name === "" || scenario_name === null) {
        return false;
      } else if (scenario_name.length > this.max_name_length) {
        return "Ce nom de scénario est trop long";
      } else if (this.scenario_names.includes(scenario_name)) {
        return "Ce nom de scénario est déjà pris";
      } else {
        return true;
      }
    },
    tryCopy(scenario) {
      // set the scenario creation info from the copied scenario
      this.copied_scenario = scenario.scenario_id;
      this.new_scenario = {
        scenario_name: "Copie de " + scenario.scenario_name,
        lot: scenario.lot,
        first_last: scenario.scenario_params.first_last_stop || false,
        data_batch: scenario.data_batch
      };
      this.creation_dialog = true;
    },
    addScenario() {
      // check form validation
      if (!this.$refs.form.validate()) {
        return;
      }

      // get the scenario information and close the dialogs
      let new_scenario = this.new_scenario;
      new_scenario.lot = new_scenario.lot.id;
      let copied_scenario = this.copied_scenario;
      this.closeCreationDialog();
      this.dialog = false;

      // call scenario creation action or the scenario copy action
      // and get the new scenario id
      // the scenarios list is then refreshed and the current scenario changed
      let scenario_info = null;
      let response = null;
      if (copied_scenario === null) {
        scenario_info = {
          ...new_scenario,
          creator_uuid: this.$whale.user.uuid
        };
        this.createScenario(scenario_info);
      } else {
        scenario_info = {
          copied_scenario_id: copied_scenario,
          scenario_name: new_scenario.scenario_name,
          creator_uuid: this.$whale.user.uuid
        };
        this.copyScenario(scenario_info);
      }
    },
    async archiveScenario() {
      // call scenario archive action and refresh the list
      this.deleteScenario(this.delete_scenario.scenario_id);

      // reset delete_scenario attribute
      this.delete_scenario = null;
    },
    closeCreationDialog() {
      // reset the scenario creation information
      this.new_scenario = {
        scenario_name: "",
        lot: null,
        first_last: false,
        data_batch: null
      };
      this.copied_scenario = null;
      this.creation_dialog = false;
      this.$refs.form.reset();
    }
  }
});
</script>

<style scoped>
h2 {
  color: var(--v-primary-base);
}
</style>
