<template>
  <div>
    <v-card class="edit-card">
      <v-card-text v-for="(props_mapping, key, index) in local_edit_attributes" :key="index">
        <v-card-actions>
          <v-select
            :value="props_mapping.getType()"
            :items="getAvailableChangeTypes(key, props_mapping)"
            @change="changeMapping(key, $event, props_mapping)"
            outlined
            :disabled="disableTypeChange"
          >
            <template slot="item" slot-scope="{ item }"> {{ $t(`props_mapping.${item}.name`) }} </template>
            <template slot="selection" slot-scope="{ item }"> {{ $t(`props_mapping.${item}.name`) }} </template>
          </v-select>
        </v-card-actions>
        <component
          :ref="key"
          :is="getMappingComponent(props_mapping)"
          :props-mapping="props_mapping"
          :data-properties="dataProperties"
          :layer="layer"
          @update="updateMapping(key, $event)"
        />
        <v-divider class="my-1" v-if="index < edit_attributes_size - 1" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import ConstantMapping from "./mappings/constant_mapping.vue";
import CategoryMapping from "./mappings/category_mapping.vue";
import ContinuousMapping from "./mappings/continuous_mapping.vue";
import DirectMapping from "./mappings/direct_mapping.vue";
import TooltipMapping from "./mappings/tooltip_mapping.vue";
import { MAPPING_CLASSES } from "@/props_mapping";

export default Vue.component("edit-tab", {
  components: {
    ConstantMapping,
    DirectMapping,
    CategoryMapping,
    ContinuousMapping
  },

  props: {
    editAttributes: {
      required: true
    },
    dataProperties: {
      required: true
    },
    editStore: {},
    disableTypeChange: {
      type: Boolean,
      default: false
    },
    layer: {
      type: Object
    }
  },

  data() {
    return {
      local_edit_attributes: []
    };
  },
  watch: {
    editAttributes: {
      handler: function (new_val) {
        this.local_edit_attributes = {
          ...this.editAttributes
        };
      },
      immediate: true
    }
  },
  computed: {
    edit_attributes_size() {
      return Object.keys(this.local_edit_attributes).length;
    }
  },
  methods: {
    getAvailableChangeTypes(key, props_mapping) {
      let stored_edits = this.editStore?.[key];
      if (!stored_edits) {
        return [];
      }

      let types_set = new Set(Object.keys(stored_edits));
      types_set.add("constant");
      types_set.add("direct");
      return [...types_set];
    },
    getMappingComponent(props_mapping) {
      return props_mapping.getType() + "-mapping";
    },
    updateMapping(key, props_mapping) {
      this.$emit("update", {
        [key]: props_mapping
      });
    },
    changeMapping(key, mapping_type, current_props_mapping) {
      let stored_mapping = this.editStore?.[key]?.[mapping_type];
      let new_mapping;
      if (stored_mapping) {
        new_mapping = stored_mapping;
      } else {
        new_mapping = this.newMapping(key, mapping_type, current_props_mapping);
      }

      this.local_edit_attributes[key] = new_mapping;

      // update layer paint if mapping is valid
      this.$nextTick(() => {
        let mapping_component = this.$refs[key][0];
        if (mapping_component.$refs.edit_form) {
          if (!mapping_component.$refs.edit_form.validate()) {
            mapping_component.$refs.edit_form.resetValidation();
            return;
          }
        }
        this.updateMapping(key, new_mapping);
      });
    },
    newMapping(key, mapping_type, current_props_mapping) {
      let paint_type = current_props_mapping.paint_type;
      let mapping_options;

      switch (mapping_type) {
        case "direct":
          mapping_options = {
            key: "",
            coefficient: paint_type == "size" ? 1 : undefined
          };
          break;
        case "constant":
          mapping_options = {
            value: paint_type == "size" ? 5 : "#000000"
          };
          break;
        default:
          let message = this.$t("props_mapping.errors.not_available");
          alert({ message, type: "error" });
          return;
      }

      let mapping_class = MAPPING_CLASSES[mapping_type];

      return new mapping_class({
        paint_type: current_props_mapping.paint_type,
        mapping_options,
        paint: current_props_mapping.paint,
        legend: current_props_mapping.legend,
        legend_options: current_props_mapping.legend_options,
        editable: current_props_mapping.editable
      });
    }
  }
});
</script>

<style>
.edit-card {
  height: 410px;
  overflow: auto;
}
</style>
