<template>
  <v-btn
    v-bind="{ ...$attrs }"
    @click="click"
    :color="$attrs.color || 'primary'"
    :disabled="($attrs.disabled !== undefined ? $attrs.disabled : false) || async[action] > 0"
  >
    <v-progress-circular size="20" width="2" indeterminate v-if="async[action] > 0" />
    <slot></slot>
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    handler: Function,
    action: String
  },
  computed: mapState(["async"]),
  data: function () {
    return {
      disabled: false
    };
  },
  methods: {
    click: async function () {
      try {
        await this.handler();
        this.$emit("success");
      } catch (err) {
        console.error(err);
        this.$emit("failure");
      }
    }
  }
};
</script>
