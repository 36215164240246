/* capucine_simulation.store.js */

import { getInstance } from "@/api/index";
import {
  DAY_TYPES,
  ALL_ROUTES,
  MAP_PERIODS,
  computeSectionsPairs,
  updateSectionTravelTimeSeries,
  get_route_item,
  routeOneWayName,
  routeReturnName,
  routeTitleSuffixWithDates
} from "../../capucine_utils";

function watchHeadwayParams(item) {
  for (let key in item) {
    if (key.endsWith("_adjusted")) {
      let theo_key = key.substring(0, key.length - 8) + "theoretical";
      if (item[theo_key] !== item[key]) {
        return "changed";
      }
    }
  }
  return "unchanged";
}

function watchTravelTimeParams(sections, item) {
  for (let key in item) {
    if (sections.includes(key) && item[key] !== 0) {
      return "changed";
    }
  }
  return "unchanged";
}

function watchBufferTimeParams(item) {
  if (item.theoretical !== item.adjusted) {
    return "changed";
  }
  return "unchanged";
}

function watchCapacityParam(item) {
  if (item.theoretical !== item.adjusted) {
    return "changed";
  }
  return "unchanged";
}

const DEFAULT_SCENARIO = 23;

export default {
  // State object
  namespaced: true,
  state: {
    summary: [],
    routes: [],
    lot: "",
    route_id: "",
    day_type: "LAVHV",
    scenarios: [],
    lot_filter: null,
    scenario: DEFAULT_SCENARIO,
    scenario_name: "",
    scenario_summary: [],
    scenario_qs: null,
    scenario_data_batch: null,
    first_last: true,
    simulation_loading_count: 0,
    simulation_loading: false,
    base_values: null,
    direction_param: 0,
    headway_params: [],
    travel_time_params: [],
    buffer_time_params: [],
    capacity_param: [],
    neutralisation_param: 0,
    param_watcher: {},
    series_param: [],
    section_param: "total"
  },

  // Getter functions
  getters: {
    route: state => {
      return get_route_item(state.routes, state.route_id, state.scenario_data_batch);
    },
    sections: (_, getters) => {
      return getters.route.sections.order;
    },
    day_types: (_, getters) => {
      return getters.route.day_types || DAY_TYPES;
    },
    simulation_loading: state => {
      return state.simulation_loading_count !== 0;
    },
    segmentsPairs: (_, getters) => {
      return {
        0: computeSectionsPairs(getters.sections, 0),
        1: computeSectionsPairs(getters.sections, 1)
      };
    },
    cumulativeSegmentPairs: (_, getters) => {
      return {
        0: computeSectionsPairs(getters.sections, 0, true),
        1: computeSectionsPairs(getters.sections, 1, true)
      };
    },
    oneWayName: (_, getters) => {
      return routeOneWayName(getters.route);
    },
    returnName: (_, getters) => {
      return routeReturnName(getters.route);
    },
    titleSuffixWithDates: (_, getters) => {
      return routeTitleSuffixWithDates(getters.route);
    },
    routeNames: state => {
      let route_names = Object.fromEntries(
        state.routes.map(route_item => {
          return [route_item.id, route_item.name];
        })
      );
      route_names.Lot = state.lot.type;
      return route_names;
    }
  },
  // Actions
  actions: {
    /**
     * Get the list of the available scenarios (non archived)
     * @param context
     */
    async getScenariosList(context) {
      let whale = getInstance();

      context.commit("ADD_SIMULATION_LOADER");
      let scenarios = await whale.getCapucineScenariosList();
      context.commit("CHANGE_SCENARIO_LIST", scenarios.scenarios);
      context.commit("REMOVE_SIMULATION_LOADER");
    },

    /**
     * Create a new simulation scenario, refresh the scenarios list and change selected scenario
     * @param context
     * @param scenario_data
     */
    async createScenario(context, scenario_data) {
      let whale = getInstance();

      // ask for scenario creation and get the new id
      let response = await whale.createCapucineScenario(scenario_data);

      // update the scenarios list
      await context.dispatch("getScenariosList");

      // change scenario
      context.dispatch("changeScenario", response.scenario_id);
    },

    /**
     * Copy a simulation scenario (params and results), refresh the scenarios list and change selected scenario
     * @param context
     * @param scenario_data
     */
    async copyScenario(context, scenario_data) {
      let whale = getInstance();

      // ask for scenario copy and get the new id
      let response = await whale.copyCapucineScenario(scenario_data);

      // update the scenarios list
      await context.dispatch("getScenariosList");

      // change scenario
      context.dispatch("changeScenario", response.scenario_id);
    },

    /**
     * Archive a scenario and refresh the scenarios list
     * @param context
     * @param scenario_id
     */
    async deleteScenario(context, scenario_id) {
      let whale = getInstance();

      // ask for scenario deletion
      await whale.deleteCapucineScenario(scenario_id);

      // update the scenarios list
      context.dispatch("getScenariosList");
    },

    /**
     * Update the scenario value of the quality of service share
     * @param context
     */
    async updateScenarioQS(context, scenario_qs) {
      let whale = getInstance();
      let scenario = context.state.scenario;
      try {
        await whale.updateCapucineScenarioQS(scenario, scenario_qs);
        context.commit("SET_SCENARIO_QS", scenario_qs);
      } catch {
        let message = "Erreur lors de la mise à jour du scénario";
        alert({ message, type: "error" });
      }
    },

    /**
     * Update the simulation parameters and results when the scenario changes
     * @param context
     * @param scenario_id
     * @returns boolean indicating change success
     */
    async changeScenario(context, scenario_id = DEFAULT_SCENARIO) {
      let whale = getInstance();

      // set loader
      context.commit("ADD_SIMULATION_LOADER");

      // check that the scenario exists and is unique
      let matching_scenarios = context.state.scenarios.filter(scenario => scenario.scenario_id == scenario_id);
      if (matching_scenarios.length != 1) {
        let message = "Erreur lors de la sélection du scénario";
        alert({ message, type: "error" });
        context.commit("REMOVE_SIMULATION_LOADER");
        return false;
      }
      let scenario_info = matching_scenarios[0];

      // check that the scenario lot exists
      let lot;
      try {
        lot = context.rootGetters["capucine_analysis/getLotItem"](scenario_info.lot);
      } catch {
        let message =
          "Réseau / Lot inconnu pour le scénario " + scenario_info.scenario_name + " : " + scenario_info.lot;
        alert({ message, type: "error" });
        context.commit("REMOVE_SIMULATION_LOADER");
        return false;
      }

      // update store variables
      context.commit("CHANGE_SCENARIO", { scenario_id, scenario_info });

      // set lot filter to scenario lot
      context.commit("SET_LOT_FILTER", scenario_info.lot);

      // save scenario in user preferences
      whale.updateCapucinePreferences({ simulation: { scenario: scenario_id } });

      // update the current lot
      await context.dispatch("changeLot", lot);

      // get scenario summary
      context.dispatch("getScenarioSummary");

      // end loader
      context.commit("REMOVE_SIMULATION_LOADER");

      // get the simulation results of the new scenario
      context.dispatch("capucine_results/getSimulationResults", undefined, { root: true });

      return true;
    },

    /**
     * Change the current lot (in simulation, only with scenario change)
     * @param context
     * @param lot
     */
    async changeLot(context, lot) {
      let whale = getInstance();

      let lot_information;
      try {
        // get general information
        lot_information = await whale.getCapucineLotInfo(lot.id);
      } catch {
        let message = "Erreur lors de la sélection du réseau / lot";
        alert({ message, type: "error" });
        return;
      }

      // update store variables
      context.commit("CHANGE_LOT", {
        lot,
        routes: lot_information.routes
      });

      // change the result page route to ALL_ROUTES
      context.dispatch("capucine_results/changeRoute", ALL_ROUTES, { root: true });

      // change the simulation page route to the first route of the lot if necessary
      let sim_routes_ids = lot_information.routes.filter(route => route.simulation_available).map(route => route.id);
      if (sim_routes_ids.includes(context.state.route_id)) {
        await context.dispatch("changeRoute", context.state.route_id);
      } else {
        await context.dispatch("changeRoute", sim_routes_ids[0]);
      }
    },

    /**
     * Change the current route
     * @param context
     * @param route new route id
     */
    async changeRoute(context, route_id) {
      let whale = getInstance();

      // update store variables
      context.commit("CHANGE_ROUTE", route_id);

      // reset section param to global option
      context.commit("SET_SECTION", "total");

      // check if current day type available for new route, otherwise set to first day type
      let day_types = context.getters.day_types;
      if (!day_types.includes(context.state.day_type)) {
        context.commit("CHANGE_DAY_TYPE", { day_type: day_types[0] });
      }

      // save simulation route in user preferences
      whale.updateCapucinePreferences({ simulation: { route_id } });

      // get the simulation parameters of the selected route
      context.dispatch("updateParamsContents");
    },

    /**
     * Change the current day type
     * @param context
     * @param day_type
     */
    changeDayType(context, day_type) {
      let whale = getInstance();

      // set sew simulation day_type
      context.commit("CHANGE_DAY_TYPE", { day_type });

      // save simulation day_type in user preferences
      whale.updateCapucinePreferences({ simulation: { day_type } });

      // get the simulation parameters of the selected day_type
      context.dispatch("updateParamsContents");
    },

    /**
     * Update the contents of the simulation parameters tables.
     * Fetch the base values of the parameters for the selected environment.
     * Try to fetch the parameters from the scenario backup, and if there are none,
     * set the parameters with default values (corresponding to unchanged scenario).
     * @param context
     * @returns
     */
    async updateParamsContents(context) {
      let whale = getInstance();
      let route_data = context.getters.route.route_data_id;
      let day_type = context.state.day_type;
      let first_last = context.state.first_last;
      let scenario = context.state.scenario;

      context.commit("ADD_SIMULATION_LOADER");

      // get the base values of the parameters
      try {
        let parameters = await whale.getCapucineBaseValues(route_data, day_type, first_last);
        context.commit("CHANGE_BASE_VALUES", parameters);

        // get the parameters of the scenario for this route and day type
        let params = await whale.getCapucineScenarioParams(scenario, route_data, day_type);

        if (params.message == "Aucun résultat") {
          // if there are no parameters stored, initialise the parameters with default values
          console.log("Set default params");
          context.dispatch("initTravelTimeParameters");
          context.dispatch("initBufferTimeParameters");
          context.dispatch("initHeadwayParameters");
          context.dispatch("initCapacityParameter");
          context.dispatch("initNeutralisationParameter");
        } else {
          // otherwise, use the values from the backup
          console.log("Set params from scenario");
          context.commit("SET_SIMULATION_PARAMETERS", params);
        }

        // update the travel time graph
        context.dispatch("updateGraphData");

        // update the parameters watchers
        context.dispatch("watchParameters", "headway_params");
        context.dispatch("watchParameters", "buffer_time_params");
        context.dispatch("watchParameters", "travel_time_params");
        context.dispatch("watchParameters", "capacity_param");
        context.dispatch("watchNeutralisationParameter");
      } catch (e) {
        console.log(e);
        let message = "Erreur lors du chargement des paramètres de simulation";
        alert({ message, type: "error" });
      } finally {
        context.commit("REMOVE_SIMULATION_LOADER");
      }
    },

    /**
     * Save the current parameters tables in the scenario backup and use them to run a simulation.
     * Only the tables of the selected route and day type are saved and run (but this contains both directions).
     * @param context
     */
    async saveParamsRunSimulation(context) {
      let whale = getInstance();

      try {
        // set loader
        context.commit("ADD_SIMULATION_LOADER");

        // include the scenario, lot, route, day type, and the parameters tables
        let params = {
          scenario_id: context.state.scenario,
          route_data_id: context.getters.route.route_data_id,
          route: context.state.route_id,
          day_type: context.state.day_type,
          first_last_only: context.state.first_last,
          travel_time_params: context.state.travel_time_params,
          section_travel_time: context.state.base_values.section_travel_time,
          buffer_time_params: context.state.buffer_time_params,
          headway_params: context.state.headway_params,
          capacity_param: context.state.capacity_param,
          neutralisation_param: context.state.neutralisation_param
        };

        await whale.runCapucineSimulation(params);
        console.log("Saved parameters and ran the simulation");
      } catch (e) {
        let message = "Erreur lors du lancement de la simulation";
        alert({ message, type: "error" });
      } finally {
        context.commit("REMOVE_SIMULATION_LOADER");
      }
    },

    /**
     * Get and set the simulation summary of the current scenario
     * @param context
     */
    async getScenarioSummary(context) {
      let whale = getInstance();
      let scenario_id = context.state.scenario;
      let scenario_summary = await whale.getCapucineScenarioSummary(scenario_id);
      context.commit("CHANGE_SCENARIO_SUMMARY", scenario_summary.scenario_summary);
    },

    /**
     * Initialise the parameters watchers
     * @param context
     */
    initParamWatcher(context) {
      let param_watcher = [];
      let params = ["travel_time_params", "buffer_time_params", "headway_params"];

      // browse all day types of the base values
      let item = {};
      // initialise the watchers to 'unchanged' for all the tables
      for (let i = 0; i < params.length; i++) {
        item[params[i] + "_aller"] = "unchanged";
        item[params[i] + "_retour"] = "unchanged";
      }
      item["capacity_param"] = "unchanged";
      item["neutralisation_param"] = "unchanged";
      param_watcher.push(item);

      // update store variable
      context.commit("CHANGE_PARAM_WATCHER", param_watcher);
    },

    /**
     * Update the watcher of the given parameter.
     * Watch the tables of both directions and see if there is a change from the base values.
     * @param context
     * @param param
     */
    watchParameters(context, param) {
      let items = context.state[param];
      let param_watcher = context.state.param_watcher;

      // browse both directions
      for (let direction = 0; direction < 2; direction++) {
        let direction_items = items;
        if (param !== "capacity_param") {
          direction_items = items[direction];
        }
        let change = "unchanged";
        // for each item (line) of the table, call the parameter specific function to test if there is a change
        for (let i = 0; i < direction_items.length; i++) {
          let item = direction_items[i];
          if (param == "headway_params") {
            change = watchHeadwayParams(item);
          } else if (param == "buffer_time_params") {
            change = watchBufferTimeParams(item);
          } else if (param == "travel_time_params") {
            if (context.state.first_last) {
              change = watchTravelTimeParams("total", item);
            } else {
              change = watchTravelTimeParams(context.getters.sections, item);
            }
          } else if (param == "capacity_param") {
            change = watchCapacityParam(item);
          }
          if (change == "changed") {
            break;
          }
        }

        // update the relevant watcher item
        let direction_text = "_aller";
        if (direction == 1) {
          direction_text = "_retour";
        }
        if (param == "capacity_param") {
          direction_text = "";
        }
        let param_item = param_watcher[0];
        param_item[param + direction_text] = change;
      }
    },

    /**
     * Update the neutralisation parameter watcher
     * @param context
     */
    watchNeutralisationParameter(context) {
      let change = "unchanged";
      if (context.state.neutralisation_param !== 0) {
        change = "changed";
      }
      context.state.param_watcher[0].neutralisation_param = change;
    },

    /**
     * Compute default values for the simulation parameters tables
     * @param context
     * @returns
     */
    async initDefaultParams(context) {
      let params = {
        travel_time_params: await context.dispatch("initTravelTimeParameters"),
        buffer_time_params: await context.dispatch("initBufferTimeParameters"),
        headway_params: await context.dispatch("initHeadwayParameters"),
        capacity_param: await context.dispatch("initCapacityParameter"),
        neutralisation_param: await context.dispatch("initNeutralisationParameter")
      };
      return params;
    },

    /**
     * Compute and set the default table for the headway parameters
     * @param context
     */
    initHeadwayParameters(context) {
      let base_values = context.state.base_values;

      let result = {};
      for (let direction in [0, 1]) {
        let headway_data = base_values.headway[direction];

        let items = headway_data.map(item => {
          let res = {};
          for (const key in item) {
            if (key == "période") {
              res["période"] = item["période"];
            } else {
              res[key + "_theoretical"] = item[key];
              res[key + "_adjusted"] = item[key];
            }
          }
          return res;
        });
        result[direction] = items;
      }
      context.commit("SET_SIMULATION_PARAMETERS", { headway_params: result });
    },

    /**
     * Compute and set the default table for the capacity parameter
     * @param context
     */
    initCapacityParameter(context) {
      let base_values = context.state.base_values;
      let capacity = base_values.capacity;
      let result = [{ theoretical: capacity, adjusted: capacity }];
      context.commit("SET_SIMULATION_PARAMETERS", { capacity_param: result });
    },

    /**
     * Compute and set the default value for the neutralisation parameter
     * @param context
     */
    initNeutralisationParameter(context) {
      context.commit("SET_SIMULATION_PARAMETERS", { neutralisation_param: 0 });
    },

    /**
     * Compute and set the default table for the travel time parameters
     * @param context
     */
    initTravelTimeParameters(context) {
      let sections = context.getters.sections;
      let periods = [
        "03:30 → 06:30",
        "06:30 → 09:30",
        "09:30 → 12:30",
        "12:30 → 16:30",
        "16:30 → 20:00",
        "20:00 → 27:00"
      ];
      let result = {};

      for (let direction in [0, 1]) {
        let items = periods.map(period => {
          let item = { période: period, total: 0 };
          for (let i = 0; i < sections.length; i++) {
            item[sections[i]] = 0;
          }
          return item;
        });
        result[direction] = items;
      }
      context.commit("SET_SIMULATION_PARAMETERS", { travel_time_params: result });
    },

    /**
     * Compute and set the default table for the buffer time parameters
     * @param context
     */
    initBufferTimeParameters(context) {
      let base_values = context.state.base_values;

      let result = {};
      for (let direction in [0, 1]) {
        let buffer_time_data = base_values.buffer_time[direction];

        let items = buffer_time_data.map(item => {
          let res = {};
          for (const key in item) {
            if (key == "période") {
              res["période"] = item["période"];
            } else {
              res["theoretical"] = item[key];
              res["adjusted"] = item[key];
            }
          }
          return res;
        });
        result[direction] = items;
      }
      context.commit("SET_SIMULATION_PARAMETERS", { buffer_time_params: result });
    },

    /**
     * Set the value of one or more simulation parameters
     * @param context
     * @param params
     */
    setSimulationParameters(context, params) {
      context.commit("SET_SIMULATION_PARAMETERS", params);
    },

    /**
     * Compute and update the 'total' column of travel time parameters
     * @param context
     */
    updateTravelTimeParamTotals(context) {
      let travel_time_params = context.state.travel_time_params[context.state.direction_param];
      let sections = context.getters.sections;
      for (let i = 0; i < travel_time_params.length; i++) {
        let item = travel_time_params[i];
        let item_total = 0;
        for (let key in item) {
          if (sections.includes(key)) {
            item_total += parseInt(item[key]);
          }
        }
        item.total = item_total;
      }
    },

    /**
     * Update adjusted travel time with travel time adjustements parameters
     * @param context
     */
    updateAdjustedTravelTimeParam(context) {
      let parameters = context.state.base_values.section_travel_time[context.state.direction_param];
      let travel_time_params = context.state.travel_time_params[context.state.direction_param];
      let sections = Object.keys(parameters["théorique"]);

      // copy theoretical travel time
      let data = JSON.parse(JSON.stringify(parameters["théorique"]));
      for (let s = 0; s < sections.length; s++) {
        for (let j = 0; j < parameters["théorique"]["total"].length; j++) {
          if (data[sections[s]][j] != "") {
            data[sections[s]][j] += travel_time_params[MAP_PERIODS[j]][sections[s]];
            data[sections[s]][j] = Math.round(data[sections[s]][j] * 10) / 10;
          }
        }
      }
      parameters["ajusté"] = data;
    },

    /**
     * Update the travel time graph data from the travel time params and base values.
     * This action is called when the parameters tables change, or when the section selector changes
     * @param context
     */
    updateGraphData(context) {
      context.dispatch("updateAdjustedTravelTimeParam");
      let series = updateSectionTravelTimeSeries(
        context.state.direction_param,
        context.state.section_param,
        context.state.base_values.section_travel_time
      );
      context.commit("CHANGE_GRAPH_DATA", series);
    },

    setSimulationDirection(context, direction) {
      context.commit("SET_DIRECTION", direction);
      context.commit("SET_SECTION", "total");
    },

    setSimulationSection(context, section) {
      context.commit("SET_SECTION", section);
    }
  },

  // Mutations
  mutations: {
    ADD_SIMULATION_LOADER(state) {
      state.simulation_loading_count += 1;
    },
    REMOVE_SIMULATION_LOADER(state) {
      state.simulation_loading_count -= 1;
    },
    SET_LOADING(state, payload) {
      state.loading[payload.kpi] = payload.value;
    },
    CHANGE_SCENARIO_LIST(state, scenarios) {
      state.scenarios = scenarios;
    },
    SET_LOT_FILTER(state, value) {
      state.lot_filter = value;
    },
    CHANGE_SCENARIO(state, { scenario_id, scenario_info }) {
      // change the value of the selected scenario id and name
      state.scenario = scenario_id;
      state.scenario_name = scenario_info.scenario_name;
      // set the value of the selected scenario QS
      state.scenario_qs = scenario_info.scenario_params.qs_share;
      // set the value of the first_last parameter
      state.first_last = scenario_info.scenario_params.first_last_stop || false;
      // set the data batch used in the scenario
      state.scenario_data_batch = scenario_info.data_batch;
    },
    CHANGE_LOT(state, { lot, routes }) {
      // set new lot
      state.lot = lot;

      // update list of routes
      state.routes = routes;
    },
    CHANGE_ROUTE(state, route) {
      state.route_id = route;
    },
    CHANGE_DAY_TYPE(state, { day_type }) {
      state.day_type = day_type;
    },
    CHANGE_BASE_VALUES(state, base_values) {
      state.base_values = base_values;
    },
    CHANGE_SCENARIO_SUMMARY(state, scenario_summary) {
      state.scenario_summary = scenario_summary;
    },
    CHANGE_PARAM_WATCHER(state, param_watcher) {
      state.param_watcher = param_watcher;
    },
    SET_SIMULATION_PARAMETERS(state, params) {
      for (const key in params) {
        if (
          ![
            "travel_time_params",
            "buffer_time_params",
            "headway_params",
            "capacity_param",
            "neutralisation_param",
            // we should avoid storing first_last in simulation parameters instead of accepting it here
            "first_last_only"
          ].includes(key)
        ) {
          throw new Error("Unknown simulation parameter " + key);
        }
        state[key] = params[key];
      }
    },
    SET_SCENARIO_QS(state, scenario_qs) {
      state.scenario_qs = scenario_qs;
    },
    CHANGE_GRAPH_DATA(state, series) {
      state.series_param = series;
    },
    SET_DIRECTION(state, direction) {
      state.direction_param = direction;
    },
    SET_SECTION(state, section) {
      state.section_param = section;
    }
  }
};
