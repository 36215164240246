<!--
  Dialog to aggregate flows
-->

<template>
  <v-dialog :value="flows != null" max-width="800px" @click:outside="closeDialog">
    <v-card height="550px">
      <kite-card-title :title="$t('flows.aggregation.title')" :tooltip="$t('flows.aggregation.information')" />
      <v-card-text class="px-6 pt-6 pb-0">
        {{ $t("flows.aggregation.selection") }}
        <v-radio-group v-model="method" row>
          <v-radio
            v-for="(method_name, index) in methods"
            :label="$t(`flows.aggregation.${method_name}.title`)"
            :value="method_name"
            :key="index"
          />
        </v-radio-group>
        <v-divider class="pb-5" />
        <component :is="agg_component" @cancel="closeDialog" @validate="aggregation" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { FlowsView } from "@/models";
import CustomZoningAggregation from "./custom_zoning_aggregation.vue";
import AdminAggregation from "./admin_aggregation.vue";
import DbscanAggregation from "./dbscan_aggregation.vue";
import AttributesAggregation from "./attributes_aggregation.vue";
import CenteredAggregation from "./centered_aggregation.vue";
import * as turf from "@turf/turf";

export default Vue.component("aggregation-dialog", {
  components: {
    AdminAggregation,
    CustomZoningAggregation,
    DbscanAggregation,
    AttributesAggregation,
    CenteredAggregation
  },
  props: ["flows"],
  data: function () {
    return {
      method: null
    };
  },
  watch: {
    methods(value) {
      this.method = value[0];
    }
  },
  computed: {
    ...mapGetters("flows", ["getAttributes", "getType"]),
    methods() {
      if (this.getType == "STARLING") {
        return ["admin", "dbscan", "attributes", "custom-zoning"];
      } else if (this.getType == "FLOWMAP") {
        return ["admin", "custom-zoning", "centered"];
      } else {
        return [];
      }
    },
    agg_component() {
      if (this.method) {
        return `${this.method}-aggregation`;
      } else {
        return null;
      }
    }
  },
  methods: {
    /**
     * Call API to aggregate flows.
     */
    async aggregation(payload) {
      this.closeDialog();

      let { attributes, options } = payload;

      let name = this.$t(`flows.aggregation.${this.method}.name_format`, {
        original_name: this.flows.name,
        // TODO : zoning is dep, needs to be converted in Département
        ...options
      });

      let data = await this.$whale.aggregateFlows({
        method: this.method,
        data: this.flows.data,
        attributes,
        options
      });

      // create new FlowsView
      let new_flows_view = FlowsView.newFlowsView(data, "FLOWMAP", name);
      new_flows_view.addToKite();
    },
    closeDialog() {
      this.$emit("closeDialog");
    }
  }
});
</script>
