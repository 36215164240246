<template>
  <base-aggregation v-on="$listeners" :agg-options="options" :loading="loading">
    <p>
      <span v-html="$t('flows.aggregation.custom-zoning.text')"></span>
    </p>
    <v-file-input
      v-model="file_input"
      :label="$t('flows.aggregation.custom-zoning.file_input')"
      accept=".geojson"
      :rules="[error_message, mandatoryRule]"
      @change="newFileInput(inputState)"
    />
  </base-aggregation>
</template>

<script lang="ts">
import Vue from "vue";
import { readFiles, parse_json } from "@/io";
import { validateZoningGeojson } from "@/validation";
import { mandatoryRule } from "@/validation";
import BaseAggregation from "./base_aggregation.vue";

export default Vue.component("custom-zoning-aggregation", {
  data: function () {
    return {
      file_input: null,
      options: {
        zoning_geojson: null
      },
      error_message: true,
      loading: false
    };
  },

  computed: {},

  methods: {
    async newFileInput() {
      this.loading = true;

      // manage case of no select
      if (!this.file_input) {
        this.setZoningOption(null);
        return;
      }

      readFiles(this.file_input)
        .then(readResult => {
          let data = parse_json(readResult[0].data);
          // check result is a valid zoning geojson
          try {
            validateZoningGeojson(data);
          } catch (e) {
            this.inputError(e.message);
          }
          // send geojson once checks are passed
          this.setZoningOption(data);
        })
        .catch(e => {
          this.inputError(e.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    inputError(message) {
      this.error_message = message;
    },
    setZoningOption(zoning_geojson) {
      this.error_message = true;
      this.options.zoning_geojson = zoning_geojson;
      this.loading = false;
    },
    mandatoryRule
  }
});
</script>
