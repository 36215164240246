/**
 * Store Kite models/interfaces here
 */

import { getInstance } from "@/api/index";
import store from "./store";
import { generateNewNameIfExists, zoomOnGeojson, zoomOnFlowmap, convertJsonToCsv } from "@/functions-tools";
import {
  describeUsers,
  filtersFromFlowView,
  attributesFromFilters,
  filterStarlingData,
  filterFlowmapData,
  aggregateFlowMapData,
  flowmapFlowsSum,
  starlingStats
} from "@/flows";
import i18n from "./plugins/lang";
import { downloadData, parse_json, readFlowFromHash } from "@/io";
import JSZip from "jszip";
import {
  KiteLayer,
  addCustomLayer,
  create_kite_layer_from_db,
  repair_edit_attributes,
  convert_edit_attributes_to_mapping
} from "./kite_layers";
import { ViewItemError } from "@/map_view";
import { BinaryAlreadyAdded } from "@/api/index";
import { FlowsFilters } from "@/flows";

// Kite alert

interface KiteAlert {
  // alert text message
  message: String;
  action?: {
    // action button text
    text: String;
    // action button click handler
    handler: Function;
  };
  // content type (defines snackbar color)
  type?: "success" | "info" | "warning" | "error";
  // display time (in milliseconds). -1 to display indefinitely
  timeout?: number;
}

// Kite data views

type ViewTypes = "custom_layer" | "database_layer" | "builtin_layer" | "flows" | "gtfs_network";

function personalDataSourceName() {
  return i18n.t("map_view.sources.user").toString();
}

/**
 * A view of some data in Kite
 */
abstract class KiteView {
  // unique identifier of the view
  id: string;

  // displayed name of the vue
  name: string;

  // data source
  source: string;

  // hash of the saved binary,
  // or boolean indicating if data is saved in project
  saved: boolean | string;

  constructor(id, name, saved = null, source = personalDataSourceName()) {
    this.id = id;
    this.name = name;
    this.source = source;
    this.saved = saved;
  }

  /**
   * Change view name. Supposed to be valid.
   * @param new_name new view name
   */
  rename(new_name: string) {
    this.name = new_name;
  }

  /**
   * Add view to Kite and possibly display and zoom on it.
   * @param {boolean} visible
   * @param {boolean} zoom
   */
  addToKite(visible = true, zoom = true) {
    this.addToStore();
    if (visible) {
      this.updateViewDisplay(true, zoom);
    }
  }

  /**
   * Update view display in Kite and possibly zoom on it.
   * View is expected to be added to store.
   * @param visible
   * @param zoom
   */
  async updateViewDisplay(visible: boolean, zoom: boolean = true) {
    if (this.isDisplayed() == visible) {
      return;
    }
    await this._updateViewDisplay(visible);
    if (visible && zoom) {
      this.zoomOnView();
    }
  }

  /**
   * Add view to relevant Kite store.
   */
  abstract addToStore();

  /**
   * Private method for updating view display.
   * Prefer using updateViewDisplay method.
   */
  abstract _updateViewDisplay(visible: boolean);

  /**
   * Zoom on view content.
   * View is expected to be displayed.
   */
  abstract zoomOnView();

  /**
   * Remove view from Kite store.
   */
  abstract removeFromStore();

  /**
   * Indicate if the view is currently displayed.
   */
  abstract isDisplayed();

  /**
   * View type (redundant with class ?)
   */
  abstract getViewType(): ViewTypes;

  /**
   * Ids of layers corresponding to this view
   * @returns array of layer ids
   */
  correspondingLayers(): Array<string> {
    return;
  }

  /**
   * Indicates if the view can be saved in the project.
   */
  canBeSaved(): boolean {
    return !(<boolean>this.saved);
  }

  async saveToProject(progressHandler?: (progress: number) => void, displayAlert = true) {
    await this._saveToProject(progressHandler).catch(e => {
      if (e instanceof BinaryAlreadyAdded) {
        this.setSaved(e.binary_info);
      }
      if (displayAlert) {
        alert(e.message);
      }
    });
  }

  /**
   * Set saved attribute from saved binary info
   * @param binary_info
   */
  setSaved(binary_info) {
    this.saved = binary_info.hash;
  }

  /**
   * Save view related data in the project binaries.
   */
  async _saveToProject(_progressHandler?: (progress: number) => void) {
    throw new Error("Save to project not implemented");
  }

  canBeDownloaded(): boolean {
    return false;
  }

  downloadView() {
    throw new Error("Download not implemented");
  }

  /**
   * Create an item exported in a MapView.contents
   * @returns
   */
  async toMapViewItem(save_to_project = false): Promise<MapViewItem> {
    try {
      // save view data to project
      if (save_to_project && !this.saved) {
        await this.saveToProject(undefined, false);
      }

      // create a MapViewItem
      return {
        type: this.getViewType(),
        name: this.name,
        data: this.mapViewItemData(),
        source: this.source,
        options: this.mapViewItemOptions(),
        layer_options: this.mapViewItemLayerOptions()
      };
    } catch (e) {
      if (!save_to_project) {
        console.log(e);
        let message = new ViewItemError(this.name, this.getViewType(), "creation");
        alert({ message, type: "error" });
      }
    }
  }

  /**
   * Object used to retrieve view related data
   */
  mapViewItemData() {
    return this.saved;
  }

  /**
   * Object containing view fields used in MapView item
   */
  abstract mapViewItemOptions();

  /**
   * Evaluate options for the layers corresponding to the view
   * @returns { layer_id: { LayerOptions } }
   */
  mapViewItemLayerOptions() {
    let layer_options;
    if (this.correspondingLayers) {
      layer_options = {};
      let layer_ids = this.correspondingLayers();
      let layer;
      for (var layer_id of layer_ids) {
        layer = store.getters["layers/getLayer"](layer_id);
        layer_options[layer_id] = {
          isVisible: layer.isVisible,
          editAttributes: {
            ...layer.editAttributes,
            tooltip: layer.tooltipAttribute
          },
          filter: layer.filter
        };
      }
    }
    return layer_options;
  }

  /**
   * Create a new class instance from the given MapViewItem
   * @param _map_view_item
   */
  static addFromMapViewItem(_map_view_item: MapViewItem) {
    throw new Error("Cannot instantiate abstract class KiteView from MapViewItem");
  }

  /**
   * Use the given layer options to update the MapViewItem related layers.
   * @param layer_options
   */
  static updateLayersFromOptions(layer_options) {
    if (layer_options) {
      for (const layer_id in layer_options) {
        let options = layer_options[layer_id];
        let layer = store.getters["layers/getLayer"](layer_id);
        if (options.editAttributes) {
          layer.readEditAttributes(repair_edit_attributes(layer.class_name, options.editAttributes));
          layer.updateEditAttributes(layer.editAttributes);
        }
        if (options.isVisible !== undefined) {
          store.dispatch("layers/setLayersVisibility", { ids: layer_id, isVisible: options.isVisible });
        }
        if (options.filter) {
          layer.setFilter(options.filter);
        }
      }
    }
  }
}

/**
 * A layer view of spatial data
 */
abstract class LayerView extends KiteView {
  // layer object displaying the spatial data
  layer: KiteLayer;

  constructor(layer) {
    super(layer.id, layer.getName(), layer.saved, layer.dataSource);
    this.layer = layer;
  }

  addToStore() {
    throw new Error("Layer views are added to Kite using layer method addLayerToKite");
  }

  removeFromStore() {
    store.dispatch("layers/deleteLayer", this.layer);
  }

  // for layers, don't zoom by default
  async updateViewDisplay(visible: boolean, zoom: boolean = false) {
    await this._updateViewDisplay(visible);
    if (visible && zoom) {
      this.zoomOnView();
    }
  }

  _updateViewDisplay(visible: boolean) {
    store.dispatch("layers/setLayersVisibility", { ids: this.id, isVisible: visible });
  }

  zoomOnView() {
    let map = store.state.layers.map;
    let stops_layer = store.state.layers.allLayers[this.id];
    if (stops_layer.source.type == "geojson") {
      zoomOnGeojson(map, stops_layer.source.getData());
    } else {
      console.log("Cannot zoom on non geojson layer");
    }
  }

  correspondingLayers() {
    return [this.layer.id];
  }

  isDisplayed() {
    return this.layer.isVisible;
  }

  async _saveToProject(progressHandler?: (progress: number) => void) {
    let whale = getInstance();

    // get data (geojson string)
    let data = JSON.stringify(this.layer.source.getData());

    // add data to project
    let binary_info = await whale.addProjectFile(
      "spatial_data",
      data,
      this.layer.getName(true),
      {
        metadata: {
          name: this.layer.getName(true)
        }
      },
      progressHandler
    );

    this.setSaved(binary_info);
  }

  setSaved(binary_info: any): void {
    this.saved = binary_info.hash;
    this.layer.saved = binary_info.hash;
  }

  canBeDownloaded(): boolean {
    return !!this.layer.source;
  }

  async downloadView(): Promise<void> {
    // check min zoom
    let minzoom = this.layer.getMinZoom();
    if (minzoom && this.layer.map.getZoom() < minzoom) {
      throw new Error(i18n.t("map_layers.layer_table.errors.minzoom").toString());
    }

    // get geojson from current layout
    let data = await this.layer.source.getSpatialData();

    // only keep properties available for tooltip selection, if provided
    if (this.layer.dataProperties) {
      let tooltip_keys = Object.keys(this.layer.dataProperties);
      data.features.forEach(feature => {
        feature.properties = tooltip_keys.reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: feature.properties[key]
          });
        }, {});
      });
    }

    // download geojson
    let name = this.name + ".geojson";
    downloadData(JSON.stringify(data), name, "application/json");
  }
}

/**
 * A view of user-provided layer
 */
class CustomLayerView extends LayerView {
  rename(value) {
    this.name = value;
    this.layer.name = value;
  }

  getViewType(): ViewTypes {
    return "custom_layer";
  }

  mapViewItemOptions() {
    return {
      class: this.layer.class_name
    };
  }

  static async addFromMapViewItem(map_view_item: MapViewItem): Promise<void> {
    let whale = getInstance();
    // get geojson from project binaries
    let geojson = await whale.getProjectBinaryFromHash(map_view_item.data, "spatial_data", true);
    geojson = parse_json(await geojson.text());

    // ignore id used for storing layer options
    let temp_id = Object.keys(map_view_item.layer_options)[0];
    let layer_options = map_view_item.layer_options[temp_id];

    // create and add custom layer
    let id = addCustomLayer({
      layer_class_name: map_view_item.options.class,
      data: geojson,
      name: map_view_item.name,
      editAttributes: repair_edit_attributes(map_view_item.options.class, layer_options.editAttributes),
      startsVisible: layer_options["isVisible"],
      saved: map_view_item.data
    });

    // update layer option id with newly created one
    delete map_view_item.layer_options[temp_id];
    map_view_item.layer_options[id] = layer_options;

    return store.getters["layers/getLayerView"](id);
  }
}

/**
 * A view of database layers
 */
class DatabaseLayerView extends LayerView {
  rename(_name) {
    throw new Error("Cannot rename database layers");
  }

  getViewType(): ViewTypes {
    return "database_layer";
  }

  mapViewItemData(): string | boolean {
    return this.id;
  }

  mapViewItemOptions() {
    return {};
  }

  static async addFromMapViewItem(map_view_item: MapViewItem): Promise<void> {
    // get database layer information from complete table
    let database_layers = store.state.layers.full_database_layers;
    let db_layer_id = map_view_item.data;
    let layer_data = database_layers.filter(v => v.layer.id == db_layer_id);
    if (layer_data.length != 1) {
      throw new Error("Could not find layer '" + db_layer_id + "' in database layers");
    } else {
      layer_data = layer_data[0];
    }

    // check access
    if (!layer_data.available) {
      throw new Error("Missing rights for adding database layer " + db_layer_id);
    }

    let view = store.getters["layers/getLayerView"](db_layer_id);
    let edit_attributes = repair_edit_attributes(
      layer_data.layer.layer_class,
      map_view_item.layer_options[db_layer_id].editAttributes
    );
    if (view) {
      // if database layer is already present in the current views, just update editAttributes and visibility
      if (edit_attributes) {
        view.layer.readEditAttributes(edit_attributes);
        view.layer.updateEditAttributes(view.layer.editAttributes);
      }
      view.updateViewDisplay(map_view_item.layer_options[db_layer_id].isVisible);
    } else {
      // otherwise, modify database layer info and create a new view
      layer_data.layer.additionalProperties.editAttributes =
        edit_attributes || layer_data.layer.additionalProperties.editAttributes;
      let id = create_kite_layer_from_db(layer_data.layer, map_view_item.layer_options[db_layer_id].isVisible);
      view = store.getters["layers/getLayerView"](id);
    }

    return view;
  }
}

/**
 * Kite builtin layer view
 */
class BuiltinLayerView extends LayerView {
  rename(_name) {
    throw new Error("Cannot rename builtin layers");
  }

  getViewType(): ViewTypes {
    return "builtin_layer";
  }

  mapViewItemOptions() {
    throw new Error("No MapViewItem export for builtin layers");
  }

  async toMapViewItem(): Promise<MapViewItem> {
    return undefined;
  }
}

/**
 * A view of movement flows
 */
abstract class FlowsView extends KiteView {
  // raw flows data (expected to be valid flows data)
  data: any;

  // flows type
  type: "STARLING" | "FLOWMAP";

  // filters on raw flows data
  filters: any;

  // list of attributes of the data
  attributes: Array<string>;

  // filtered flow view data
  filtered_data: any;

  constructor(data, type, name, saved = null, filters?: FlowsFilters) {
    super(name, name, saved);

    this.data = data;
    this.type = type;
    if (!filters) {
      filters = filtersFromFlowView(this);
    }
    this.filters = filters;
    this.attributes = attributesFromFilters(filters);
  }

  setFilters(filters) {
    this.filters = filters;
    this.attributes = attributesFromFilters(filters);
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.evaluateFilteredData();
    if (this.isDisplayed()) {
      store.dispatch("flows/updateDisplayedData", this.filtered_data);
    }
  }

  abstract evaluateFilteredData();

  rename(newName) {
    this.name = newName;
    this.id = newName;
  }

  isDisplayed() {
    return store.state.flows.currentFlowsView === this;
  }

  getViewType(): ViewTypes {
    return "flows";
  }

  addToStore() {
    // get unique name (used as id)
    let unique_name = generateNewNameIfExists(this.name, store.getters["flows/allNames"]);
    this.id = unique_name;
    this.name = unique_name;
    // add View to flows store
    store.commit("flows/ADD_OD_ITEM", this);
  }

  _updateViewDisplay(visible: boolean) {
    if (visible) {
      store.dispatch("flows/displayFlows", this);
    } else {
      store.dispatch("flows/displayFlows", null);
    }
  }

  abstract zoomOnView();

  removeFromStore() {
    if (this.isDisplayed()) {
      this.updateViewDisplay(false);
    }
    store.commit("flows/REMOVE_OD_ITEM", this);
  }

  async _saveToProject(progressHandler?: (progress: number) => void) {
    let whale = getInstance();

    // Get data to save
    let res = await this.getData();

    // upload data to whale
    let info = await whale.addProjectFile(
      "flows",
      res.data,
      res.name,
      {
        metadata: {
          type: this.type,
          name: res.name
        }
      },
      progressHandler
    );
    // Add the saved status directly on the item
    this.saved = info.hash;
  }

  canBeDownloaded(): boolean {
    return true;
  }

  async downloadView() {
    let res = await this.getData();
    downloadData(res.data, res.name, res.mimetype);
  }

  abstract getData(): Promise<{ data: any; name: string; mimetype: string }>;

  mapViewItemOptions() {
    return {
      flows_type: this.type,
      filters: this.filters
    };
  }

  static async addFromMapViewItem(map_view_item) {
    let result = await readFlowFromHash(map_view_item.data);
    // maintain compatibility
    let selected_location_id =
      map_view_item.options.selectedLocationId || map_view_item.options.filters.selectedLocationId;
    let view = FlowsView.newFlowsView(
      result.data,
      result.type,
      map_view_item.name,
      map_view_item.data,
      map_view_item.options.filters,
      selected_location_id
    );

    // add to Kite without zooming
    view.addToKite(true, false);

    // update individual layers
    this.updateLayersFromOptions(map_view_item.layer_options);

    return view;
  }

  static newFlowsView(data, type, name, saved?, filters?, selected_location_id?) {
    let view;
    switch (type) {
      case "STARLING":
        view = new StarlingFlowView(data, name, saved, filters);
        break;
      case "FLOWMAP":
        view = new FlowMapView(data, name, saved, filters, selected_location_id);
        break;
      default:
        throw new Error("Unknown FlowsView type");
    }
    return view;
  }

  abstract computeStatistics(): any;
}

class FlowMapView extends FlowsView {
  type: "FLOWMAP";
  // id of the selected location (null if no location is selected)
  selectedLocationId: any;

  // filtered FlowMap data aggregated by OD
  aggregated_filtered_data: any;

  constructor(data, name, saved = null, filters?, selected_location_id?) {
    super(data, "FLOWMAP", name, saved, filters);
    this.selectedLocationId = selected_location_id || null;
  }

  setSelectedLocationId(selected_location_id) {
    if (selected_location_id == this.selectedLocationId) {
      this.selectedLocationId = null;
    } else {
      this.selectedLocationId = selected_location_id;
    }
    this.updateDisplayedData();
  }

  correspondingLayers(): string[] {
    return ["users-flowmap"];
  }

  zoomOnView() {
    zoomOnFlowmap(store.state.layers.map, this.data);
  }

  evaluateFilteredData() {
    // store the filtered data with flows aggregated by ODs and attributes
    this.filtered_data = aggregateFlowMapData(
      filterFlowmapData(this.data, this.filters, this.selectedLocationId),
      this.attributes
    );
    // store a FlowMap data version aggregated by ODs only (used for layer, stats, summary table)
    this.aggregated_filtered_data = aggregateFlowMapData(this.filtered_data);
  }

  async getData(): Promise<{ data: any; name: string; mimetype: string }> {
    var flows = convertJsonToCsv(this.data.flows);
    var locations = convertJsonToCsv(this.data.locations);
    // use jszip and filesaver to zip files and download it
    let zip = new JSZip();
    zip.file("flows.csv", flows);
    zip.file("locations.csv", locations);
    return {
      name: this.name + ".zip",
      data: await zip.generateAsync({ type: "blob" }),
      mimetype: "application/zip"
    };
  }

  mapViewItemOptions() {
    let options = super.mapViewItemOptions();
    if (this.selectedLocationId) {
      options["selectedLocationId"] = this.selectedLocationId;
    }
    return options;
  }

  computeStatistics() {
    let count_raw = flowmapFlowsSum(this.data.flows);
    let count_filtered = flowmapFlowsSum(this.filtered_data.flows);

    return [
      { label: "volume_filtered", value: count_filtered.toFixed(1) },
      { label: "volume", value: count_raw.toFixed(1) },
      { label: "perc_filtered", value: `${((count_filtered / count_raw) * 100).toFixed(1)} %` },
      { label: "pairs", value: this.filtered_data.flows.length }
    ];
  }
}

class StarlingFlowView extends FlowsView {
  type: "STARLING";

  constructor(data, name, saved = null, filters?) {
    // preprocessing for Starling data
    describeUsers(data);
    super(data, "STARLING", name, saved, filters);
  }

  correspondingLayers(): string[] {
    return ["users-arclayer"];
  }

  zoomOnView() {
    zoomOnGeojson(store.state.layers.map, this.data);
  }

  evaluateFilteredData() {
    this.filtered_data = filterStarlingData(this.data, this.filters);
  }

  async getData(): Promise<{ data: any; name: string; mimetype: string }> {
    return {
      name: this.name + ".geojson",
      data: JSON.stringify(this.data),
      mimetype: "application/octet-stream"
    };
  }

  computeStatistics() {
    let raw_data_stats = starlingStats(this.data);
    let filtered_data_stats = starlingStats(this.filtered_data);

    let stats = [
      { label: "volume_filtered", value: filtered_data_stats.volume },
      { label: "volume", value: raw_data_stats.volume },
      { label: "perc_filtered", value: `${((filtered_data_stats.volume / raw_data_stats.volume) * 100).toFixed(1)} %` },
      { label: "pairs", value: filtered_data_stats.groups }
    ];

    if (filtered_data_stats.average_distance) {
      stats.push({ label: "average_distance", value: filtered_data_stats.average_distance });
    }

    return stats;
  }
}

class GtfsView extends KiteView {
  // gtfs model
  gtfs: any;

  // route models
  routes: Array<any>;

  // stop models
  stops: Array<any>;

  constructor(gtfs_data, name) {
    super(gtfs_data.uuid, name, true, gtfs_data.pt_network.source);
    this.gtfs = gtfs_data;
  }

  addToStore() {
    // Gtfs views are not stored except the one being displayed
  }

  isDisplayed() {
    return store.state.network.currentNetworkView === this;
  }

  correspondingLayers() {
    return ["gtfs-stops", "gtfs-lines"];
  }

  async _updateViewDisplay(visible: boolean) {
    if (!visible) {
      throw new Error("Network views should be removed, not undisplayed");
    }

    await store.dispatch("network/displayNetworkView", this);
  }

  zoomOnView() {
    let map = store.state.layers.map;
    let stops_layer = store.state.layers.allLayers["gtfs-stops"];
    zoomOnGeojson(map, stops_layer.source.getData());
  }

  removeFromStore() {
    store.dispatch("network/removeNetworkView", this);
  }

  /**
   * GtfsView instances are based on already saved gtfs.
   * @returns
   */
  canBeSaved() {
    return false;
  }

  mapViewItemData() {
    return this.id;
  }

  mapViewItemOptions() {
    // store selected routes and stops
    let selected_routes = store.state.network.selectedRoutes;
    if (selected_routes.length == this.routes.length) {
      selected_routes = undefined;
    } else {
      selected_routes = selected_routes.map(route => route.route_id);
    }
    let selected_stops = store.state.network.selectedStops;
    if (selected_stops.length == this.stops.length) {
      selected_stops = undefined;
    } else {
      selected_stops = selected_stops.map(stop => stop.stop_id);
    }

    return {
      selected_routes,
      selected_stops
    };
  }

  /**
   * Set the gtfs, routes and stops fields.
   */
  async fetch_gtfs_data() {
    let whale = getInstance();
    let routes_and_stops = await whale.getGtfsRoutesAndStops(this.id);
    this.routes = routes_and_stops.routes;
    this.routes.sort((a, b) => {
      if (a.route_sort_order == undefined || b.route_sort_order == undefined) {
        return 0;
      } else {
        return a.route_sort_order - b.route_sort_order;
      }
    });
    this.stops = routes_and_stops.stops;
  }

  getViewType(): ViewTypes {
    return "gtfs_network";
  }

  static addFromMapViewItem(map_view_item: MapViewItem) {
    let database_gtfs = store.state.network.database_gtfs;
    let gtfs_data = database_gtfs.filter(gtfs => gtfs.uuid == map_view_item.data)[0];
    let view = new GtfsView(gtfs_data, map_view_item.name);

    // add to Kite without zooming
    view.addToStore();
    view.updateViewDisplay(true, false).then(() => {
      // update individual layers
      this.updateLayersFromOptions(map_view_item.layer_options);

      // set selected stops and routes
      if (map_view_item.options?.selected_routes) {
        store.dispatch(
          "network/setSelectedRoutes",
          view.routes.filter(route => {
            return map_view_item.options.selected_routes.includes(route.route_id);
          })
        );
      }
      if (map_view_item.options?.selected_stops) {
        store.dispatch(
          "network/setSelectedStops",
          view.stops.filter(stop => {
            return map_view_item.options.selected_stops.includes(stop.stop_id);
          })
        );
      }
    });

    return view;
  }

  getRoute(route_id) {
    let matching_routes = this.routes.filter(full_route => {
      return full_route.route_id == route_id;
    });
    if (matching_routes.length != 1) {
      throw new Error("Number of matching routes should be exactly one");
    }
    return matching_routes[0];
  }

  getStop(stop_id) {
    let matching_stops = this.stops.filter(full_stop => {
      return full_stop.stop_id == stop_id;
    });
    console.log(matching_stops);
    if (matching_stops.length != 1) {
      throw new Error("Number of matching stops should be exactly one");
    }
    return matching_stops[0];
  }
}

// MapView models

interface MapViewItem {
  name: string;
  // used to decide what kind of object is created from the item
  type: ViewTypes;
  // where the data comes from
  source: string;
  // data identifier
  data: any;
  // item options
  options?: any;
  // corresponding layer options
  layer_options?: any;
}

// app preset
interface AppPreset {
  // type of content of the preset, used to define app display
  type: "MapView" | string;
  // uuid of the object displayed in the preset
  uuid: string;
  // user that shared the preset
  sharingUser?: string;
}

export {
  FlowsView,
  GtfsView,
  DatabaseLayerView,
  CustomLayerView,
  BuiltinLayerView,
  KiteAlert,
  MapViewItem,
  AppPreset,
  personalDataSourceName
};
