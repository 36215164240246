<!--
  Kite main app bar
-->

<template>
  <div>
    <v-app-bar app color="primary" dark dense clipped-left>
      <slot name="leftAction"></slot>
      <v-img :src="logo" max-width="36" alt="Tellae" />
      <v-toolbar-title style="margin-right: 30px" class="ml-0 pl-4">
        <slot name="title"></slot>
      </v-toolbar-title>
      <v-spacer />
      <slot name="midAction"></slot>
      <v-spacer />
      <menu-project v-if="!hideProject" />
      <slot name="rightAction3"></slot>
      <slot name="rightAction2"></slot>
      <slot name="rightAction1"></slot>
      <menu-user @about="about_dialog = true" @my-settings="my_settings_dialog = true" />
    </v-app-bar>
    <about :dialog="about_dialog" @handleUpdateDialog="about_dialog = $event" />
    <my-settings :dialog="my_settings_dialog" @handleUpdateDialog="my_settings_dialog = $event" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import MenuProject from "@/components/projects/menu_project.vue";
import MenuUser from "@/components/user/menu_user.vue";
import About from "@/components/user/about.vue";
import MySettings from "@/components/user/my_settings.vue";

export default Vue.component("kite-app-bar", {
  components: {
    MenuUser,
    MenuProject,
    About,
    MySettings
  },
  props: {
    logo: {
      type: String,
      required: true
    },
    hideProject: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    about_dialog: false,
    my_settings_dialog: false
  }),
  computed: {},
  methods: {}
});
</script>
