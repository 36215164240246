<!--
  Travel time & buffer time parameters
-->

<template>
  <div>
    <v-row>
      <v-col md="6">
        <v-card-text class="my-n5">
          <capucine-excel-param
            :items="travel_time_params[direction_param]"
            :headers="currentHeader"
            title="Ajustement des TP par rapport au théorique actuel (en minutes)"
            :update="onTravelTimeParamChange"
            param="travel_time_params"
          />
        </v-card-text>
        <v-card-text class="my-n5">
          <capucine-excel-param
            :items="buffer_time_params[direction_param]"
            :headers="bufferTimeHeaders"
            title="Ajustement des battements (en %)"
            param="buffer_time_params"
          />
        </v-card-text>
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="4">
            <v-select
              v-if="!first_last"
              label="Tronçon"
              v-model="section_param"
              :items="travel_time_sections"
              @change="onTravelTimeParamChange"
            ></v-select>
          </v-col>
        </v-row>
        <capucine-sim-tp-plot height="350" :series="series_param"></capucine-sim-tp-plot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import CapucineExcelParam from "./capucine_excel_param.vue";
import { computeSectionPairsItems } from "@/capucine_utils";
import CapucineSimTpPlot from "@/components/capucine/capucine_sim_tp_plot.vue";

export default Vue.component("travel-time-param", {
  components: {
    CapucineExcelParam,
    CapucineSimTpPlot
  },
  data: function () {
    return {
      bufferTimeHeaders: [
        {
          text: "Tranche horaire",
          value: "période",
          readonly: true,
          type: "string",
          width: "120px"
        },
        {
          text: "Théorique",
          value: "theoretical",
          readonly: true,
          type: "number"
        },
        {
          text: "Ajusté",
          value: "adjusted",
          type: "number",
          validate: this.validateBufferTimeParams,
          "to-value": parseFloat
        }
      ]
    };
  },

  watch: {
    page(value) {
      if (this.page == "simulation" && this.series_param.length > 0) {
        window.dispatchEvent(new Event("resize"));
      }
    }
  },
  computed: {
    ...mapState("capucine_analysis", ["page"]),
    ...mapState("capucine_simulation", [
      "section_param",
      "travel_time_params",
      "buffer_time_params",
      "direction_param",
      "series_param",
      "first_last"
    ]),
    ...mapGetters("capucine_simulation", ["segmentsPairs", "sections"]),
    travel_time_sections() {
      return computeSectionPairsItems(this.sections, this.segmentsPairs, this.direction_param, true);
    },
    section_param: {
      get() {
        return this.$store.state.capucine_simulation.section_param;
      },
      set(value) {
        this.setSimulationSection(value);
      }
    },
    headers() {
      let headers = {
        0: this.getTravelTimeHeaders(this.sections, this.segmentsPairs, 0),
        1: this.getTravelTimeHeaders(this.sections, this.segmentsPairs, 1)
      };
      return headers;
    },
    currentHeader() {
      return this.headers[this.direction_param];
    }
  },
  methods: {
    ...mapActions("capucine_simulation", ["updateTravelTimeParamTotals", "updateGraphData", "setSimulationSection"]),
    onTravelTimeParamChange() {
      if (!this.first_last) {
        this.updateTravelTimeParamTotals();
      }
      this.updateGraphData();
    },
    getTravelTimeHeaders(sections, segmentsPairs, direction) {
      if (direction == 1) {
        sections = [...sections].reverse();
      }
      let headers;
      if (this.first_last) {
        headers = [];
      } else {
        headers = sections.slice(0, sections.length - 1).map((element, index) => {
          return {
            text: segmentsPairs[direction][index],
            value: element,
            type: "number",
            width: "75px",
            "to-value": parseFloat,
            mandatory: "Cette valeur est obligatoire"
          };
        });
      }
      headers.unshift({
        text: "Tranche horaire",
        value: "période",
        readonly: true,
        width: "120px"
      });
      headers.push({
        text: "Total",
        value: "total",
        readonly: !this.first_last,
        width: "75px",
        type: "number",
        "to-value": parseFloat
      });
      return headers;
    },
    validateBufferTimeParams(value) {
      let int_val = parseFloat(value);
      if (value < 0) {
        return "Valeur positive requise";
      }
      return "";
    }
  }
});
</script>
