<!-- 
Stepper for tt_pt delete routes use case 
-->

<template>
  <simulation-stepper>
    <template v-slot:environment>
      <v-form v-model="valid_step[STEPS.environment]">
        <v-row>
          <v-col md="4">
            <v-select
              v-model="parameters['metropolis']"
              :items="metropolises"
              :rules="[mandatoryRule]"
              :label="getLabel('metropolis')"
              required
              prepend-icon="mdi-city"
              return-object
              @change="getGtfsRoute()"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:demand>
      <v-form v-model="valid_step[STEPS.demand]">
        <v-row>
          <v-col md="2">
            <v-text-field
              v-model="parameters['penetration_rate']"
              :rules="penetrationRateRules"
              type="float"
              :label="getLabel('penetration_rate')"
              required
              suffix="%"
              prepend-icon="mdi-label-percent-outline"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:offer>
      <v-form v-model="valid_step[STEPS.offer]">
        <v-row>
          <v-col md="6">
            <v-autocomplete
              v-model="parameters['closed_routes']"
              :items="routes"
              :disabled="async.routes > 0"
              :loading="async.routes > 0"
              :label="getLabel('closed_routes')"
              required
              multiple
              prepend-icon="mdi-bus-alert"
              item-text="route_short_name"
              return-object
              :placeholder="$t('simulations.use_cases.TT_PT.tt_pt_delete_bus_routes.placeholders.closed_routes')"
            >
              <template v-slot:selection="{ item }">
                <v-chip class="short" :color="item.route_color" :text-color="item.route_text_color">{{
                  item.route_short_name
                }}</v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip class="short" :color="item.route_color" :text-color="item.route_text_color">{{
                  item.route_short_name
                }}</v-chip>
                <v-spacer></v-spacer>
                {{ item.route_long_name }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </simulation-stepper>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import StepperMixin from "./stepper_mixin.vue";

export default Vue.component("tt-pt-delete-routes", {
  mixins: [StepperMixin],
  data: () => ({
    metropolises: [
      { text: "Nantes Métropole", value: "GTFS_nantes" },
      { text: "Vélizy", value: "GTFS_Vélizy" }
    ],
    routes: []
  }),

  computed: {},
  methods: {
    async getGtfsRoute() {
      let res = await this.$whale.gtfsRoutes(this.parameters["metropolis"].value);
      this.routes = res.information;
    }
  }
});
</script>

<style>
.short {
  width: 60px;
  justify-content: center;
}
</style>
