<template>
  <v-card height="751px">
    <v-card-text>
      <v-row align="center" justify="center" class="px-5">
        <v-col v-for="(model_item, code) in SERVICES" :key="code" cols="6">
          <v-hover v-slot="{ hover }">
            <v-card
              height="200px"
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 12 : 2"
              @click="$whale.runIfHasAccess(`SIMULATION_${code.toUpperCase()}`, selectModel, model_item)"
            >
              <v-img
                :src="getImg(model_item)"
                class="white--text align-end"
                gradient="to bottom, rgba(50,50,50,0.1), rgba(50,50,50,0.5)"
                height="200px"
              >
                <v-card-title>{{ $t(`simulations.models.${code}.title`) }}</v-card-title>
                <!-- <v-card-text v-if="model_item.description_displayed">
              {{ model_item.description }}
            </v-card-text> -->
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { SERVICES } from "@/simulation";

// let images = require.context("@/../public/static/", true, /\.jpg$/);

export default Vue.component("model-choice", {
  computed: {
    // Disable based on permission
    SERVICES: function () {
      Object.values(SERVICES).forEach(s => {
        s.available = s.available && this.$whale.hasAccess(`SIMULATION_${s.code.toUpperCase()}`);
      });
      return SERVICES;
    }
  },
  methods: {
    ...mapActions("simulations", ["changeService", "changePage"]),
    getImg(model_item) {
      try {
        return model_item.img || `./static/simulation/models/${model_item.code}.jpg`;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    selectModel(modelItem) {
      this.changeService(modelItem.code);
      this.changePage("model_scenarios");
    }
  }
});
</script>

<style>
.v-card__title {
  word-break: normal;
}
</style>
