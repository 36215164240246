<!--
  Day type selection component for info dialogs of network page
-->

<template>
  <day-type-selector
    v-model="model"
    :day-types="dayTypes"
    :all-available="false"
    :item-text="day_type_text"
    return-object
  />
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import DayTypeSelector from "@/components/transports/day_type_selector.vue";

export default Vue.component("info-day-type-selector", {
  components: {
    DayTypeSelector
  },
  props: [],
  data: () => ({}),
  computed: {
    ...mapState(["language"]),
    ...mapGetters("network", ["dayTypes"]),
    model: {
      get() {
        return this.$store.state.network.infoDayType;
      },
      set(value) {
        this.$store.commit("network/SET_INFO_DAY_TYPE", value);
      }
    }
  },

  methods: {
    day_type_text(item) {
      let parts = item.value.split("-");
      let date = new Date(item.date);
      return this.$t("basic_transport.day_types.complete_day_type", {
        weekday: this.$t(`basic_transport.day_types.${parts[0]}`),
        period: this.$t(`basic_transport.day_types.${parts[1]}`),
        date: date.toLocaleString(this.language, { dateStyle: "short" })
      });
    }
  }
});
</script>
